<template>
  <div>
    <div
      v-for="(lineItemStatus, index) in liStatusFilterSelectedOptions"
      :id="index"
      :key="index"
      :class="[
        isSelected(lineItemStatus.displayName) > 0 ? 'ql' : '',
        lineItemStatus.count > 0 ? '' : 'no-click',
        lineItemStatus.selected ? 'ql-selected' : '',
        isLoading ? 'no-click' : '',
      ]"
      @click="
        lineItemStatus.selected = !lineItemStatus.selected;
        applyLineItemStatusFilter(lineItemStatus);
      "
    >
      <div v-if="lineItemStatus.checked">
        {{ lineItemStatus.displayName }}
        <div
          v-if="lineItemStatus.count"
          class="qlnum"
          :style="workFlowColor(lineItemStatus.displayName)"
        >
          {{ lineItemStatus.count }}
        </div>
      </div>
    </div>

    <div
      v-if="displayNamesWithStatusIds.length > 0"
      ref="lineItemStatusOptions"
      class="workflow-toggle"
    >
      <font-awesome-icon
        class="fa-icon-table"
        :icon="['fas', 'cog']"
        size="1x"
        @click="lineItemStatusToggleEnabled = !lineItemStatusToggleEnabled"
      />

      <div v-if="lineItemStatusToggleEnabled" class="ql-settings-drop">
        <div class="multidrop dropdown active">
          <div class="btn-wraps btn-wraps-top active">
            <div
              class="btn-wraps-txt selinf"
              @click="lineItemStatusDropdownEnabled = !lineItemStatusDropdownEnabled"
            >
              Select Quicklinks
              <font-awesome-icon
                class="float-right"
                :icon="['far', lineItemStatusDropdownEnabled ? 'chevron-up' : 'chevron-down']"
                size="1x"
              />
            </div>
            <div
              v-if="lineItemStatusDropdownEnabled"
              class="drop-single-content"
              style="display: block"
            >
              <a class="drp-itm showall alloff"
                >SHOW ALL
                <k-checkbox
                  name="Select"
                  class="float-right"
                  :checked="displayNamesWithStatusIds.every((wsc) => wsc.checked === true)"
                  @selected="showAllSelect"
              /></a>
              <a
                v-for="(val, index) in displayNamesWithStatusIds"
                :id="val.displayName"
                :key="index"
                class="drp-itm dq0 dropdown-content-selected"
                >{{ val.displayName }}
                <k-checkbox
                  name="Select"
                  :value="val.displayName"
                  class="float-right"
                  :checked="val.checked"
                  @selected="selectWorkflow"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';

import {
  displayNameVsStatusIdsMap,
  LI_DISPLAY_STATUS_COLOR_CODES,
  LI_STATUS_DISPLAY_NAME_CANCELED,
  LI_STATUS_DISPLAY_NAME_ENDED,
} from '~/helpers/line-item-status-helpers';

export default {
  name: 'LineItemStatusFilters',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lineItemStatusToggleEnabled: false,
      lineItemStatusDropdownEnabled: true,
      selectedWorkflowIds: [],
      liStatusFilterOptions: [],
      displayNamesWithStatusIds: [],
    };
  },

  computed: {
    lineItemStatusCount: get('common/liStatusCount'),
    liStatusOptions: get('common/liStatusOptions'),
    liStatusFilterSelectedOptions() {
      this.calculateLineStatusCount();
      return this.displayNamesWithStatusIds.filter((li) => li.checked);
    },
  },

  watch: {
    lineItemStatusCount() {
      this.calculateLineStatusCount();
    },
    liStatusFilterSelectedOptions(newVal, oldVal) {
      if (newVal.length && !oldVal.length) {
        this.makeDefaultSelection();
      }
    },
  },

  mounted() {
    const displayObj = displayNameVsStatusIdsMap();

    const wIds = localStorage.getItem('WORKFLOW_CHECKED_IDS');
    let workflowCheckedIds = wIds ? wIds.split(',') : null;

    // remove old status ids found in localStorage.
    if (workflowCheckedIds) {
      const hasNumber = workflowCheckedIds.some((w) => Number.isInteger(parseInt(w, 10)));
      if (hasNumber) {
        localStorage.removeItem('WORKFLOW_CHECKED_IDS');
        workflowCheckedIds = null;
      }
    }

    this.displayNamesWithStatusIds = Object.values(displayObj).map((v) => {
      if (workflowCheckedIds) {
        v.checked = workflowCheckedIds.includes(v.displayName);
      } else {
        v.checked = true;
      }
      v.selected = false;
      v.count = 0;
      return v;
    });
    this.calculateLineStatusCount();
    setTimeout(() => {
      window.addEventListener('click', this.closelineItemStatusOptions);
    }, 0);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closelineItemStatusOptions);
  },

  methods: {
    calculateLineStatusCount() {
      if (!isBlank(this.lineItemStatusCount)) {
        this.displayNamesWithStatusIds = this.displayNamesWithStatusIds.map((li) => {
          const cumulativeStatusCount = li.ids
            .map((id) => {
              let count = 0;
              const statusCountObj = this.lineItemStatusCount.find(
                (l) => l.id === parseInt(id, 10)
              );
              if (statusCountObj) {
                count = statusCountObj.count ? statusCountObj.count : count;
              }
              return count;
            })
            .reduce((acc, c) => acc + c, 0);
          li.count = cumulativeStatusCount;
          return li;
        });
      }
    },

    isSelected(id) {
      const selectedIds = localStorage.getItem('WORKFLOW_CHECKED_IDS');
      if (isBlank(selectedIds)) {
        return true;
      }
      return selectedIds.includes(id);
    },

    showAllSelect(event) {
      this.displayNamesWithStatusIds = this.displayNamesWithStatusIds.map((v) => {
        v.checked = event.checked;
        return v;
      });
      this.workflowsToLocalStore();
    },

    selectWorkflow(wf) {
      const workflow = this.displayNamesWithStatusIds.find((w) => w.displayName === wf.value);
      workflow.checked = wf.checked;
      this.workflowsToLocalStore();
    },

    workflowsToLocalStore() {
      const checkedIds = this.displayNamesWithStatusIds
        .filter((k) => k.checked)
        .map((k) => k.displayName);
      localStorage.setItem('WORKFLOW_CHECKED_IDS', checkedIds);
    },

    closelineItemStatusOptions(event) {
      const el = this.$refs.lineItemStatusOptions;
      if (el && !(el === event.target || el.contains(event.target))) {
        this.lineItemStatusToggleEnabled = false;
      }
    },

    applyLineItemStatusFilter(statusObj) {
      const ids = statusObj.ids.map((i) => parseInt(i, 10));
      let workflowIds = [...this.selectedWorkflowIds];
      ids.forEach((i) => {
        if (statusObj.selected) {
          if (workflowIds.indexOf(i) < 0) {
            workflowIds.push(i);
          }
        } else {
          workflowIds = workflowIds.filter((s) => s !== i);
        }
      });
      this.selectedWorkflowIds = workflowIds;
      this.$emit('applyLineItemStatusFilter', workflowIds);
    },
    makeDefaultSelection() {
      let selectedCombinedOptions = [];
      this.liStatusFilterSelectedOptions.forEach((filterOption) => {
        if (
          filterOption.displayName !== LI_STATUS_DISPLAY_NAME_ENDED &&
          filterOption.displayName !== LI_STATUS_DISPLAY_NAME_CANCELED &&
          filterOption.count > 0
        ) {
          const ids = filterOption.ids.map((id) => +id);
          filterOption.selected = true;
          selectedCombinedOptions = [...selectedCombinedOptions, ...(ids ?? [])];
        }
      });
      this.selectedWorkflowIds = selectedCombinedOptions;
      this.$emit('applyLineItemStatusFilter', selectedCombinedOptions);
    },
    workFlowColor(displayName) {
      const code = LI_DISPLAY_STATUS_COLOR_CODES[displayName];
      if (isBlank(code)) {
        return '';
      }
      return `background-color: ${code} !important`;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabletabs-container {
  background-color: #fff;
}
.greyed {
  color: white !important;
  background-color: #444 !important;
}
.no-click {
  color: #757373 !important;
  background-color: #e8edef !important;
  pointer-events: none;
}

.float-right {
  float: right;
}
.light-theme {
  .workflow-toggle {
    .ql-settings-drop {
      .drop-single-content {
        background-color: #f9f9f9;
      }
    }
  }
}
.workflow-toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  left: 12px;
  // ::v-deep svg {
  //   color: #8c8c8c;

  //   &:hover {
  //     color: #54a8c7;
  //   }
  // }
  ::v-deep svg path {
    fill: #8c8c8c;
  }
  ::v-deep svg:hover path {
    fill: var(--selfserveprimarycolor);
  }

  .ql-settings-drop {
    position: absolute;
    top: 150%;
    z-index: 3;
    width: 210px;
    ::v-deep .checkbox-btn svg.checkbox-inactive {
      width: 20px;
      height: 20px;
      margin: 0;
      font-size: 20px;
      color: #f4f4f7 !important;
      background: #f4f4f7 !important;
      border: 1px solid #d6d7da;
      border-radius: 2px;
    }
    ::v-deep .checkbox-btn svg.checkbox-active {
      width: 20px;
      height: 20px;
      margin: 0;
      font-size: 20px;
      color: #f4f4f7 !important;
      background: var(--selfserveprimarycolor) !important;
      border: 1px solid #d6d7da;
      border-radius: 2px;
    }
    ::v-deep .checkbox-btn svg path {
      fill: #f4f4f7 !important;
    }
    .btn-wraps-txt.selinf {
      padding: 8px 10px;
      font-size: 15px;
      color: #222;
      text-align: left;
      cursor: pointer;
      background-color: #b0bbc8;
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: none;
      border-left: 1px solid #ddd;
      border-radius: 4px 4px 0px 0px;
    }

    .drop-single-content {
      padding: 4px 0px 1px 0px;
      letter-spacing: 0.03em;
      background-color: #fff;
      border-top: none;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-radius: 0px 0px 4px 4px;
      box-shadow: 0px 5px 3px 1px rgb(0 0 0 / 10%);
      a {
        color: #222;
      }
      .drp-itm {
        padding: 10px 17px;
        font-size: 13px;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}

.tabletabs {
  display: inline-block;
  margin-right: 12px;
}
.non-ql {
  display: none;
}
.ql {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 600;
  color: #757373;
  cursor: pointer;
  background-color: #e8edef;
  border-radius: 3px;

  .qlnum {
    position: absolute;
    top: -8px;
    right: -10px;
    min-width: 19px;
    padding: 0px 6px 0px 6px;
    font-size: 11px;
    font-weight: 700;
    line-height: 1.8em;
    color: #fff;
    text-align: center;
    border-radius: 30px;
    background-color: var(--selfserveprimarycolor);
    z-index: 2;
  }
  &.ql-selected {
    color: #fff !important;
    background-color: #555 !important;
  }
}
.ql:hover {
  color: #fff;
  background-color: #555;
}
</style>
