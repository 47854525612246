<template>
  <div
    id="AD_GROUP_INFO"
    class="mvc-form-wrap3 s3-wrap"
    style="padding: 40px 50px; text-align: left;"
  >
    <div style="height: 0px;"></div>
    <div v-if="orgLineItemName" class="body-sec-title" style="margin-bottom: 30px;">
      {{ orgLineItemName }}
    </div>
    <div v-else class="body-sec-title" style="margin-bottom: 30px;">
      New Ad Group
    </div>
    <div
      :class="`ad-grp-name mvc-half mvc-half-left ${validationMap.name ? 'error-msg-field' : ''}`"
      style="margin-bottom: 30px;"
    >
      <div class="ss-form-label2">Ad Group Name</div>
      <k-text-input
        :required="true"
        :value="lineItem ? lineItem.name : ''"
        :max-length="255"
        :readonly="isReadonly"
        @change="onChangeLineItemName"
        @focusout="(val) => checkForAdGroupErrors(val, 'name')"
      />
      <b-error-msg v-if="validationMap.name" :error-msg="validationMap.name" />
    </div>
    <div
      :class="
        `mvc-half mvc-half-right mvc-flex ${validationMap.lineItemBudget ? 'error-msg-field' : ''}`
      "
    >
      <div class="mvc-half-f-left">
        <div class="ss-form-label2">Budget</div>
        <div
          :class="`${!budgetReadOnly ? 'dollarvalue2' : 'ss-form-label2'}`"
          style="width: 366px;"
        >
          <k-text-input
            :required="true"
            :value="lineItemBudgetDisplay"
            :max-length="14"
            :readonly="budgetReadOnly"
            @change="onChangeLineItemBudget"
            @focusout="(val) => checkForAdGroupErrors(val, 'lineItemBudget')"
          />
          <b-error-msg
            v-if="validationMap.lineItemBudget"
            :error-msg="validationMap.lineItemBudget"
          />
        </div>
      </div>
      <div class="mvc-half-s-right" style="display: none">
        <div class="bl-options" style="">
          <a
            v-for="(bT, index) in budgetType"
            :key="bT.id"
            class="bl-btn"
            :class="[
              `${bT.id === activeType.id ? 'bl-btn-selected' : ''}`,
              `${index === 0 ? 'lend' : ''} ${index === budgetType.length - 1 ? 'rend' : ''}`,
            ]"
            @click="onSelectOption('budgetType', bT)"
          >
            <font-awesome-icon class="bl-icon" :icon="['fas', 'check']" size="1x" />
            <div class="bl-text">{{ bT.value }}</div>
            <span v-if="bT.desc" :data-tool-tip="bT.desc" class="intip4">
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="s2-date-wrap">
      <div
        :class="
          `ad-grp-start-date mvc-half mvc-half-left  ${
            validationMap.flightStartDate ? 'error-msg-field' : ''
          } `
        "
      >
        <div class="mvc-date ficon ss-date filled" style="margin-bottom: 20px !important;">
          <div class="ss-form-label2">Start Date</div>
          <b-date-picker-field
            :range="false"
            icon-pos="right"
            :value-start="lineItem ? lineItem.flightStartDate : null"
            :lower-bound="getLowerBound()"
            :upper-bound="getUpperBound()"
            :allow-dates-in-past="false"
            :readonly="isReadonly"
            @change="onChangeStartDate"
            @focusout="(val) => checkForAdGroupErrors(val.startDate, 'flightStartDate')"
          />
          <b-error-msg
            v-if="validationMap.flightStartDate"
            :error-msg="validationMap.flightStartDate"
          />
        </div>
      </div>
      <div
        :class="
          `ad-grp-end-date mvc-half mvc-half-right ${
            validationMap.flightEndDate ? 'error-msg-field' : ''
          }  `
        "
        style="margin-bottom: 20px !important;"
      >
        <div class="mvc-date  ficon ss-date filled">
          <div class="ss-form-label2">End Date</div>
          <b-date-picker-field
            :range="false"
            icon-pos="right"
            :value-start="lineItem ? lineItem.flightEndDate : null"
            :lower-bound="getLowerBound()"
            :upper-bound="getUpperBound()"
            :allow-dates-in-past="false"
            :readonly="isReadonly"
            @change="onChangeEndDate"
            @focusout="(val) => checkForAdGroupErrors(val.endDate, 'flightEndDate')"
          />
          <b-error-msg
            v-if="validationMap.flightEndDate"
            :error-msg="validationMap.flightEndDate"
          />
        </div>
      </div>
    </div>
    <div style="height: 30px;"></div>
    <div class="sec-title">
      Media
      <b-error-msg v-if="validationMap.publishers" :error-msg="validationMap.publishers" />
    </div>
    <div>
      <publisher-targeting-form
        :line-item="lineItem"
        :plan="plan"
        :selected-targeting-id="TAR_PUBLISHER_FLIP_OTT_TARGETING"
        :targetings="targetings"
        :publisher-options="allDataOptions"
        :readonly="isReadonly"
        @validatePublisher="(val) => checkForAdGroupErrors(val, 'publishers')"
        @onInfoCardClick="onInfoCardClick"
      />
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import moment from 'moment';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import { dateDiff } from '~/util/utility-functions';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';
import BErrorMsg from '../elements/b-error-msg.vue';
import { TAR_PUBLISHER_FLIP_OTT_TARGETING } from '~/constant';
import PublisherTargetingForm from '~/components/campaigns/targetings/publisher-targeting-form.vue';
import lineItemHelpers from '~/helpers/line-item-helpers';
import BDatePickerField from '../elements/b-date-picker-field.vue';
import * as util from '~/util/utility-functions';
import { formatDateInEST } from '~/util/apiDateFormat';

export default {
  name: 'AdGroupInfo',
  components: {
    PublisherTargetingForm,
    BErrorMsg,
    BDatePickerField,
  },
  mixins: [lineItemMixin, campaignValidationMixin],
  props: {
    lineItem: {
      type: Object,
      required: false,
      default: null,
    },
    pageIndexVisited: {
      required: true,
      type: Object,
      default: () => {},
    },
    selectedPageIndex: {
      required: true,
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      TAR_PUBLISHER_FLIP_OTT_TARGETING,
      allDataOptions: [],
      validationMap: this?.pageIndexValidationObj[this?.selectedPageIndex] || {},
      budgetType: [
        {
          id: 1,
          value: 'overall',
          desc: 'Overall budget.',
        },
        {
          id: 2,
          value: 'daily',
          desc: 'Daily budget.',
        },
      ],
      formatDateInEST,
    };
  },
  computed: {
    mediaPublisherCPMData: get('common/mediaPublisherCPMData'),
    orgLineItems: get('common/orgLineItems'),
    isReadonly() {
      return lineItemHelpers.isAdGroupReadonly(this.lineItem);
    },
    isLineChange() {
      if (!isBlank(this.lineItem) && this.lineItem.type === 'CHANGE') {
        return true;
      }
      return false;
    },
    orgLineItemName() {
      if (this.isLineChange) {
        const orgLineItem = this.orgLineItems.find((l) => l.id === this.lineItem.parentId);
        if (orgLineItem) {
          return orgLineItem.name;
        }
      }
      return this?.lineItem?.name ? this.lineItem.name : '';
    },
    budgetReadOnly() {
      return (
        (lineItemHelpers.isAdGroupReadonly(this.lineItem) ||
          isBlank(this.lineItem.lineItemBudget) ||
          this.lineItem.lineItemBudget === 0) &&
        !isBlank(this.lineItem.budgetGroup)
      );
    },
    lineItemBudgetDisplay() {
      if (!this.lineItem) {
        return '';
      }
      return (isBlank(this.lineItem.lineItemBudget) || this.lineItem.lineItemBudget === 0) &&
        !isBlank(this.lineItem.budgetGroup)
        ? 'Flexible'
        : this.lineItem.lineItemBudget;
    },
    targetings() {
      return this?.lineItem?.targetings ? this?.lineItem?.targetings : [];
    },
    activeType() {
      if (this.lineItem && !isBlank(this.lineItem.budgetType)) {
        return this.lineItem.budgetType;
      }
      return {
        budgetType: { id: null, value: '', desc: '' },
      };
    },
  },
  watch: {
    plan: {
      deep: true,
      handler() {
        if (this.pageIndexVisited[this.selectedPageIndex] > 1) {
          this.pageIndexValidationObj[this.selectedPageIndex] = this.validateAdGroupInfo();
          this.validationMap = this.pageIndexValidationObj[this.selectedPageIndex] || {};
        }
      },
    },
  },
  async mounted() {
    util.scrollToElement('AD_GROUP_INFO');
    this.allDataOptions = [];
    setTimeout(() => {
      this.pageIndexVisited[this.selectedPageIndex] = this.pageIndexVisited[this.selectedPageIndex]
        ? this.pageIndexVisited[this.selectedPageIndex] + 1
        : 1;
    }, 0);
    this.mediaPublisherCPMData.forEach((ob) => {
      const option = {
        id: ob.id,
        text: ob.publisherName,
        category: ob.streamingCategory,
        logo: ob.logoURL,
        infoURL: ob.infoURL ? ob.infoURL : '',
      };
      this.allDataOptions.push(option);
    });
  },
  methods: {
    onInfoCardClick(payload) {
      this.$emit('onPublisherInfoClick', payload);
    },
    getLowerBound() {
      if (this.plan && this.plan.flightStartDate && this.plan.flightEndDate) {
        const days = dateDiff(this.plan.flightStartDate, this.plan.flightEndDate);
        const stDt = days >= 0 ? this.plan.flightStartDate : this.plan.flightEndDate;
        return stDt;
      }
      return moment().add(1, 'days');
    },
    getUpperBound() {
      if (this.plan && this.plan.flightStartDate && this.plan.flightEndDate) {
        const days = dateDiff(this.plan.flightStartDate, this.plan.flightEndDate);
        const enDt = days >= 0 ? this.plan.flightEndDate : this.plan.flightStartDate;
        return enDt;
      }
      return undefined;
    },
    formatForStore(list) {
      return list.map((o) => {
        return {
          id: o.id,
          displayName: o.text,
        };
      });
    },
    formatForDisplay(list) {
      return list.map((o) => {
        return {
          id: o.id,
          text: o.displayName,
        };
      });
    },
    onChangeStartDate(date) {
      const updates = { flightStartDate: date.start };
      this.checkForAdGroupErrors(date.startDate, 'flightStartDate');
      this.updateLineItem(updates, this.lineItem._uuid);
    },
    onChangeEndDate(date) {
      const updates = { flightEndDate: date.end };
      this.checkForAdGroupErrors(date.endDate, 'flightEndDate');
      this.updateLineItem(updates, this.lineItem._uuid);
    },
    onChangeLineItemName(val) {
      this.checkForAdGroupErrors(val, 'name');
      const updates = { name: val };
      this.updateLineItem(updates, this.lineItem._uuid);
    },
    onChangeLineItemBudget(val) {
      this.checkForAdGroupErrors(val, 'lineItemBudget');
      const updates = { lineItemBudget: val };
      this.updateLineItem(updates, this.lineItem._uuid);
    },
    onSelectOption(key, val) {
      const updates = {};
      updates[key] = { id: val.id, value: val.value };
      this.updateLineItem(updates, this.lineItem._uuid);
    },
    checkForAdGroupErrors(val, fieldName) {
      switch (fieldName) {
        case 'name':
          {
            const nameError = lineItemHelpers.validateAdGroupName(val);
            if (nameError) {
              this.addErrorInValidationMap(nameError, 'name');
            } else {
              this.addErrorInValidationMap('', 'name');
            }
          }
          break;
        case 'flightStartDate':
          {
            const flightStartDateError = lineItemHelpers.validateFlightDates(
              val,
              formatDateInEST(this.lineItem.flightEndDate),
              'start'
            );
            if (flightStartDateError) {
              this.addErrorInValidationMap(flightStartDateError, 'flightStartDate');
            } else {
              this.addErrorInValidationMap('', 'flightStartDate');
              this.addErrorInValidationMap('', 'flightEndDate');
            }
          }
          break;
        case 'flightEndDate':
          {
            const flightEndDateError = lineItemHelpers.validateFlightDates(
              formatDateInEST(this.lineItem.flightStartDate),
              val
            );
            if (flightEndDateError) {
              this.addErrorInValidationMap(flightEndDateError, 'flightEndDate');
            } else {
              this.addErrorInValidationMap('', 'flightEndDate');
              this.addErrorInValidationMap('', 'flightStartDate');
            }
          }
          break;
        case 'lineItemBudget':
          {
            const lineItemBudgetError = lineItemHelpers.validateAdGroupBudget(val);
            if (lineItemBudgetError) {
              this.addErrorInValidationMap(lineItemBudgetError, 'lineItemBudget');
            } else {
              this.addErrorInValidationMap('', 'lineItemBudget');
            }
          }
          break;
        case 'publishers':
          {
            const publisherError = lineItemHelpers.validatePublisherTargeting(val);
            if (publisherError) {
              this.addErrorInValidationMap(publisherError, 'publishers');
            } else {
              this.addErrorInValidationMap('', 'publishers');
            }
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';
@import 'https://use.fontawesome.com/releases/v5.5.0/css/all.css';
.mvc-form-wrap3 {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  color: #48525c;
  width: 860px;
  min-height: 680px;
}
.mvc-date::before {
  display: none;
}
.mvc-date {
  position: relative;
  letter-spacing: 0em;
}
.body-sec-title {
  font-size: 1.6em;
  font-weight: 500;
  color: var(--selfserveprimarycolor);
}

.mvc-half {
  display: inline-block;
  width: 48%;
}
.mvc-half-left {
  margin-right: 4%;
}
.mvc-half-right {
  float: right;
}
.pure-input-1 {
  width: 100%;
  height: 50px !important;
  padding: 10px 18px !important;
  font-size: 14px;
  font-weight: 400;
}
.sec-title {
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
  color: #444;
  font-size: 20px;
  letter-spacing: 0.02em;
  display: flex;
}
.flex-wrap {
  display: flex;
  justify-content: space-between;
  & .custom-dropdown-cont {
    width: 31%;
    margin-right: unset;
  }
}

.ss-form-label2 {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: inherit;
  color: #48525c;
  text-align: left;
  vertical-align: baseline;
  border: 0;
}
.ss-third {
  display: inline-block;
  margin-right: 2%;
  vertical-align: top;
}
.dollarvalue2 {
  display: inline-block;
  position: relative;
  &:after {
    content: '.00';
    font-size: 15px;
    color: #bdbdbd;
    position: absolute;
    top: 9px;
    right: 12px;
  }
  :before {
    content: '$';
    font-size: 15px;
    color: #bdbdbd;
    position: absolute;
    top: 9px;
    left: 12px;
  }
  ::v-deep .form-input-field {
    padding-left: 24px;
    padding-right: 38px;
  }
}
.mvc-date::before {
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  color: #444;
  pointer-events: none;
  content: '\f133';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  top: 35px;
  right: 17px;
}
.bl-options {
  display: flex;
  .bl-icon {
    display: none;
  }
  .bl-text {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 5px;
  }
  .bl-btn {
    color: #7f7f7f !important;
    border: 1px solid #b3b3b3 !important;
    padding: 7px 12px !important;
    margin: 27px 0px 0px 0px;
    text-align: center;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    border-right-width: 0.5px !important;
    border-left-width: 0.5px !important;
    &.lend {
      border-left-width: 1px !important;
      border-radius: 3px 0px 0px 3px;
    }
    &.rend {
      border-right-width: 1px !important;
      border-radius: 0px 3px 3px 0px;
    }
    &:hover {
      color: #222 !important;
      background-color: #eee !important;
    }
    &.bl-btn-selected:hover {
      color: #fff !important;
      background-color: var(--selfserveprimarycolor) !important;
    }
  }
  ::v-deep .bl-btn-selected .fa-info-circle:hover {
    color: #fff !important;
  }

  .bl-btn-selected {
    color: #fff !important;
    background-color: var(--selfserveprimarycolor) !important;
    border: 1px solid var(--selfserveprimarycolor) !important;
    .bl-icon {
      display: block;
      margin-right: 5px;
      color: #fff !important;
      transform: scale(0.8);
    }
    &:hover {
      background-color: #fff;
    }
  }
}
.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 50px;
  z-index: 99;
  display: block;
  padding: 10px 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: left;
  text-transform: none;
  content: attr(data-tool-tip);
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
  background-color: #464646;
  color: #fff;
  font-size: 13px;
  pointer-events: none;
  text-transform: none;
  max-width: 245px;
  margin-left: -120px;
  width: max-content;
}

.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 150%;
}
.vc-half-f-left {
}
.vc-half-s-right {
  display: inline-block;
  width: 50%;
  padding-left: 20px;
}
.mvc-flex {
  display: inline-flex !important;
}
::v-deep .error-msg-field .form-input .form-input-field {
  border: 2px solid #ff0202;
}
.s2-date-wrap {
  width: 100%;
  display: flex;
}
</style>
