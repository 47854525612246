<template>
  <div
    id="AD_GROUP_TARGETING"
    class="ad-group-targeting-cont"
    style="padding: 40px 50px; text-align: left;"
  >
    <p class="ad-group-heading">
      Targeting
      <b-error-msg
        v-if="validationMap.targetings_first_party"
        :error-msg="validationMap.targetings_first_party"
      />
    </p>
    <p class="sec-title">Audience Name</p>
    <div
      :class="`audience-name mvc-input ${validationMap.audienceName ? 'error-msg-field' : ''}`"
      style="margin-bottom: 30px;"
    >
      <k-text-input
        :required="true"
        :value="lineItem ? lineItem.audienceName : ''"
        :max-length="255"
        :readonly="isReadonly"
        @change="onChangeAudienceName"
        @focusout="(val) => checkForTargetingErrors(val, 'audienceName')"
      />
      <b-error-msg v-if="validationMap.audienceName" :error-msg="validationMap.audienceName" />
    </div>
    <p class="sec-title">Third Party Targeting</p>
    <div class="flex-wrap audience-demographic-form-container">
      <audience-demographic-form
        :line-item="lineItem"
        :plan="plan"
        :selected-targeting-id="TAR_DEMOGRAPHIC_TARGETING"
        :targetings="targetings"
        :disabled="disableThirdPartyTargeting || isReadonly"
        @onChangeThirdPartyTargeting="onChangeThirdPartyTargeting"
      />
    </div>
    <div class="form-group">
      <behavioral-segments-form
        :line-item="lineItem"
        :plan="plan"
        :selected-targeting-id="TAR_BEHAVIOURAL_SEGMENT"
        :targetings="targetings"
        :disabled="disableThirdPartyTargeting || isReadonly"
        @onChangeThirdPartyTargeting="onChangeThirdPartyTargeting"
      />
    </div>
    <div class="sec-spacer"></div>
    <first-party-data-form
      :line-item="lineItem"
      :plan="plan"
      :selected-targeting-id="TAR_AUDIENCE_FIRST_PARTY_TARGETING"
      :targetings="targetings"
      :disabled="disableFirstPartyTargeting"
      :readonly="isReadonly"
      @onChangeFirstPartyTargeting="onChangeFirstPartyTargeting"
    />
    <div class="sec-spacer"></div>
    <div class="sec-title">Contextual Targeting</div>
    <div class="form-group" style="margin-top: 0px;">
      <contextual-targeting-form
        :line-item="lineItem"
        :plan="plan"
        :selected-targeting-id="TAR_CONTEXTUAL_TARGETING"
        :targetings="targetings"
        :readonly="isReadonly"
      />
    </div>
    <div class="sec-spacer"></div>
    <div class="sec-title">Device Targeting</div>
    <div style="height: 10px;"></div>
    <device-targeting
      :line-item="lineItem"
      :plan="plan"
      :device-type-options="allDeviceTypes"
      :device-types="lineItem.deviceTypes"
      :readonly="isReadonly"
    />
    <div class="sec-spacer"></div>
    <geo-targeting-container
      hide-national-form
      :line-item="lineItem"
      :plan="plan"
      :targetings="targetings"
      :selected-targeting-id="0"
      :readonly="isReadonly"
    />
    <div class="sec-spacer"></div>
    <frequency-recency :line-item="lineItem" :plan="plan" :readonly="isReadonly" />
    <div class="sec-spacer"></div>
    <day-parting :line-item="lineItem" :plan="plan" :readonly="isReadonly" />
  </div>
</template>

<script>
import {
  TAR_BEHAVIOURAL_SEGMENT,
  TAR_DEMOGRAPHIC_TARGETING,
  TAR_CONTEXTUAL_TARGETING,
  TAR_AUDIENCE_FIRST_PARTY_TARGETING,
} from 'adready-api/constant';

import { get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import AudienceDemographicForm from '~/components/campaigns/targetings/audience-demographic-form.vue';

import BehavioralSegmentsForm from '~/components/campaigns/targetings/behavioral-segments-form.vue';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import forklift from '~/components/mixins/forklift-mixin';

import FirstPartyDataForm from '~/components/campaigns/targetings/first-party-data-form.vue';
import ContextualTargetingForm from '~/components/campaigns/targetings/contextual-targeting.vue';
import DeviceTargeting from '~/components/campaigns/targetings/device-targeting.vue';
import FrequencyRecency from '~/components/campaigns/targetings/frequency-recency.vue';
import DayParting from '~/components/campaigns/targetings/day-parting.vue';
import GeoTargetingContainer from './targetings/geo-targeting-container.vue';
import lineItemHelpers from '~/helpers/line-item-helpers';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';
import BErrorMsg from '../elements/b-error-msg.vue';
import * as util from '~/util/utility-functions';

export default {
  name: 'AdGroupTargetings',

  components: {
    AudienceDemographicForm,
    BehavioralSegmentsForm,
    FirstPartyDataForm,
    ContextualTargetingForm,
    DeviceTargeting,
    FrequencyRecency,
    DayParting,
    GeoTargetingContainer,
    BErrorMsg,
  },
  mixins: [lineItemMixin, forklift, campaignValidationMixin],
  props: {
    pageIndexVisited: {
      required: true,
      type: Object,
      default: () => {},
    },
    selectedPageIndex: {
      required: true,
      type: Number,
      default: () => {},
    },
  },

  data() {
    return {
      TAR_BEHAVIOURAL_SEGMENT,
      TAR_DEMOGRAPHIC_TARGETING,
      TAR_CONTEXTUAL_TARGETING,
      TAR_AUDIENCE_FIRST_PARTY_TARGETING,
      disableThirdPartyTargeting: false,
      disableFirstPartyTargeting: false,
      validationMap: this?.pageIndexValidationObj[this?.selectedPageIndex] || {},
    };
  },

  computed: {
    allDeviceTypes: get('common/allDeviceTypes'),
    isReadonly() {
      return lineItemHelpers.isAdGroupReadonly(this.lineItem);
    },
    targetings() {
      return this?.lineItem?.targetings ? this?.lineItem?.targetings : [];
    },
    isLineChange() {
      if (this.lineItem.type === 'CHANGE') {
        return true;
      }
      return false;
    },
  },
  watch: {
    lineItem: {
      deep: true,
      handler() {
        if (this.pageIndexVisited[this.selectedPageIndex] > 1) {
          this.pageIndexValidationObj[this.selectedPageIndex] = this.validateAdGroupTargetings();
          this.validationMap = this.pageIndexValidationObj[this.selectedPageIndex] || {};
        }
      },
    },
  },
  mounted() {
    util.scrollToElement('AD_GROUP_TARGETING');
    this.loadDeviceTypes();
    if (this.isFirstPartyTargetingSelected()) {
      this.disableThirdPartyTargeting = true;
    } else if (this.isThirdPartyTargetingSelected()) {
      this.disableFirstPartyTargeting = true;
    }
    setTimeout(() => {
      this.pageIndexVisited[this.selectedPageIndex] = this.pageIndexVisited[this.selectedPageIndex]
        ? this.pageIndexVisited[this.selectedPageIndex] + 1
        : 1;
    }, 0);
  },
  methods: {
    isThirdPartyTargetingSelected() {
      const targetings = lineItemHelpers.getLineItemFieldValue(this.lineItem, 'targetings');
      const behavioralTargetingValue = lineItemHelpers.fetchBehavioralTargetingValue(targetings);
      const genderValue = lineItemHelpers.fetchDemographicTargetingValue(targetings, 'gender');
      const ageValue = lineItemHelpers.fetchDemographicTargetingValue(targetings, 'age');
      const incomeValue = lineItemHelpers.fetchDemographicTargetingValue(targetings, 'income');
      const isDemoTargetingSelected = !isBlank(`${genderValue}${ageValue}${incomeValue}`);
      const isBehavioralTargetingSelected = !isBlank(behavioralTargetingValue);
      return isDemoTargetingSelected || isBehavioralTargetingSelected;
    },
    isFirstPartyTargetingSelected() {
      const targetings = lineItemHelpers.getLineItemFieldValue(this.lineItem, 'targetings');
      const firstPartyTargetingValue = lineItemHelpers.fetchFirstPartyTargetingValue(targetings);
      return !isBlank(firstPartyTargetingValue);
    },
    checkForTargetingErrors(val, fieldName) {
      if (fieldName === 'audienceName') {
        const audienceNameError = lineItemHelpers.validateAudienceName(val);
        if (audienceNameError) {
          this.addErrorInValidationMap(audienceNameError, 'audienceName');
        } else {
          this.addErrorInValidationMap('', 'audienceName');
        }
      }
    },

    onChangeAudienceName(val) {
      this.checkForTargetingErrors(val, 'audienceName');
      const updates = { audienceName: val };
      this.updateLineItem(updates, this.lineItem._uuid);
    },
    onChangeThirdPartyTargeting() {
      this.disableFirstPartyTargeting = this.isThirdPartyTargetingSelected();
    },
    onChangeFirstPartyTargeting() {
      this.disableThirdPartyTargeting = this.isFirstPartyTargetingSelected();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';
.form-group {
  width: 100%;
}
.mvc-form-wrap3 {
  min-height: 900px;
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  line-height: 1.2em;
  color: #48525c;
  background: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.body-sec-title {
  font-size: 1.6em;
  font-weight: 500;
  color: var(--selfserveprimarycolor);
}
.sec-title {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #444;
  letter-spacing: 0.02em;
}
.flex-wrap {
  display: flex;
  justify-content: space-between;
}
.sec-spacer {
  padding-top: 40px;
  margin-bottom: 40px;
  border-bottom: 1px dashed #ddd;
}
.switch {
  position: relative;
  display: inline-block !important;
  width: 57px;
  height: 22px;
  padding: 3px;
  margin: 0.25em 0;
  vertical-align: top;
  cursor: pointer;
  border-radius: 18px;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  right: 7px;
  content: attr(data-off);
  color: #aaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  left: 9px;
  content: attr(data-on);
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: var(--selfserveprimarycolor);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-input:checked ~ .switch-handle {
  left: 33px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}
.ad-group-targeting-cont {
  width: 860px;
  & .ad-group-heading {
    margin-bottom: 30px;
    font-size: 1.6em;
    font-weight: 500;
    color: var(--selfserveprimarycolor);
  }
  & .sec-title {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #444;
    letter-spacing: 0.02em;
  }
}
.audience-demographic-form-container {
  width: 100%;
}
::v-deep .error-msg-field .form-input .form-input-field {
  border: 2px solid #ff0202;
}
</style>
