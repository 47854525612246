<template>
  <div
    id="NEW_CAMPAIGN_DASHBOARD"
    class="mvc-form-wrap3 s3-wrap"
    style="padding: 40px 50px; text-align: left;"
  >
    <div style="height: 0px;"></div>
    <div class="body-sec-title">{{ title }}</div>
    <div style="height: 30px;"></div>
    <div
      :class="`campaign-name mvc-input ${validationMap.campaignName ? 'error-msg-field' : ''}`"
      style="margin-bottom: 30px;"
    >
      <div class="ss-form-label2">Campaign Name</div>
      <k-text-input
        ref="campaignName"
        :required="true"
        :value="campaignName"
        :max-length="255"
        :readonly="isReadonly"
        @change="onChangeCampaignName"
        @focusout="(val) => checkForNewCampaignErrors(val, 'campaignName')"
      />
      <b-error-msg v-if="validationMap.campaignName" :error-msg="validationMap.campaignName" />
    </div>
    <div class="s2-date-wrap" style="margin-bottom: 40px;">
      <div class="mvc-half mvc-half-left ">
        <div
          :class="
            `campaign-start-date mvc-date ficon ss-date filled ${
              validationMap.flightStartDate ? 'error-msg-field' : ''
            }`
          "
        >
          <div class="ss-form-label2">Start Date</div>
          <b-date-picker-field
            required
            :range="false"
            icon-pos="right"
            class="mr-20 cursor"
            :lower-bound="getValueStartDate()"
            :upper-bound="getUpperBound()"
            :value-start="flightStartDate"
            :allow-dates-in-past="false"
            :readonly="isReadonly"
            @change="onChangeStartDate"
            @focusout="(val) => checkForNewCampaignErrors(val.startDate, 'flightStartDate')"
          />
          <b-error-msg
            v-if="validationMap.flightStartDate"
            :error-msg="validationMap.flightStartDate"
          />
        </div>
      </div>
      <div class="mvc-half mvc-half-right ">
        <div
          :class="
            `campaign-end-date mvc-date  ficon ss-date filled ${
              validationMap.flightEndDate ? 'error-msg-field' : ''
            }`
          "
        >
          <div class="ss-form-label2">End Date</div>
          <b-date-picker-field
            required
            :range="false"
            icon-pos="right"
            class="cursor"
            :lower-bound="getLowerBound()"
            :allow-dates-in-past="false"
            :value-start="flightEndDate"
            :readonly="isReadonly"
            @change="onChangeEndDate"
            @focusout="(val) => checkForNewCampaignErrors(val.endDate, 'flightEndDate')"
          />
          <b-error-msg
            v-if="validationMap.flightEndDate"
            :error-msg="validationMap.flightEndDate"
          />
        </div>
      </div>
    </div>

    <BPlanFlipCampaignInfo
      :error-obj="validationMap"
      :goal-data="goalData"
      :readonly="isReadonly"
      @checkForError="checkForNewCampaignErrors"
    />
  </div>
</template>

<script>
/* eslint-disable */

import { get, sync } from 'vuex-pathify';
import _ from 'underscore';
import { inert, isBlank } from 'adready-api/helpers/common';
import advertiserApi from 'adready-api/api/advertiser';
import BPlanFlipCampaignInfo from '~/components/campaigns/flip/b-plan-flip-campaign-info.vue';
import { eBus } from '~/main';
import forklift from '~/components/mixins/forklift-mixin';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';
import BErrorMsg from '../elements/b-error-msg.vue';
import ioHelper from '~/helpers/io-helpers';
import BDatePickerField from '../elements/b-date-picker-field.vue';
import * as util from '~/util/utility-functions';
import moment from 'moment';
import { formatDateInEST } from '~/util/apiDateFormat';
export default {
  name: 'NewCampaign',
  components: {
    BPlanFlipCampaignInfo,
    BErrorMsg,
    BDatePickerField,
  },

  mixins: [forklift, campaignValidationMixin],
  props: {
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  asyncComputed: {
    goalData: {
      default: [],
      async get() {
        if (this.advertiser && this.advertiser.id) {
          const data = await advertiserApi.events(this.advertiser.id, {
            client: this.account.name,
            advertiser: this.advertiser.name,
            xandrId: this.advertiser.xandrAdvertiserId,
          });
          return data;
        }
        return [];
      },
    },
  },

  data() {
    return {
      errorMsg: undefined,
      validationMap: this?.pageIndexValidationObj[this?.selectedPageIndex] || {},
      formatDateInEST,

    };
  },

  computed: {
    mediaPublisherCPMData: sync('common/mediaPublisherCPMData'),
    allDeviceTypes: get('common/allDeviceTypes'),
    plan: sync('common/plan'),
    campaignName: sync('common/plan@campaignName'),
    flightStartDate: sync('common/plan@flightStartDate'),
    flightEndDate: sync('common/plan@flightEndDate'),
    updatePlan: sync('common/plan@updatePlan'),

    isNewPlan() {
      return isBlank(this.plan.id);
    },
    title() {
      return 'Campaign Detail';
    },
    account: get('common/account'),
    advertiser: get('common/advertiser'),
  },

  async mounted() {
    util.scrollToElement('NEW_CAMPAIGN_DASHBOARD');
    await this.loadProductCategories();
    await this.loadDeviceTypes();
    await this.loadMediaPublishers();
  },

  watch: {
    plan: {
      immediate: true,
      handler() {
        if (this.pageIndexVisited[1] > 1) {
          this.pageIndexValidationObj[this.selectedPageIndex] = this.validateCampaign(this.plan);
          this.validationMap = this.pageIndexValidationObj[this.selectedPageIndex] || {};
        }
      },
    },
  },

  created() {
    eBus.$on('error', this.onError);
  },

  methods: {
    onError(errorMsg) {
      this.errorMsg = errorMsg;
    },
    getValueStartDate(){
      if (this.flightStartDate === null) {
        return moment().add(1, 'days');
      }

      return this.flightStartDate;
    },
    onChangeCampaignName(val) {
      this.checkForNewCampaignErrors(val, 'campaignName');
      this.campaignName = val;
      this.updatePlan = true;
    },
    checkForNewCampaignErrors(val, fieldName) {
      switch (fieldName) {
        case 'campaignName':
          const campaignNameError = ioHelper.validateCampaignName(val);
          if (campaignNameError) {
            this.addErrorInValidationMap(campaignNameError, 'campaignName');
          } else {
            this.addErrorInValidationMap('', 'campaignName');
          }
          break;
        case 'flightStartDate':
          const flightStartDateError = ioHelper.validateFlightDates(val, formatDateInEST(this.flightEndDate),'start');
          if (flightStartDateError) {
            this.addErrorInValidationMap(flightStartDateError, 'flightStartDate');
          } else {
            this.addErrorInValidationMap('', 'flightStartDate');
            this.addErrorInValidationMap('', 'flightEndDate');
          }
          break;
        case 'flightEndDate':
          const flightEndDateError = ioHelper.validateFlightDates(formatDateInEST(this.flightStartDate),val);
          if (flightEndDateError) {
            this.addErrorInValidationMap(flightEndDateError, 'flightEndDate');
          } else {
            this.addErrorInValidationMap('', 'flightEndDate');
            this.addErrorInValidationMap('', 'flightStartDate');
          }
          break;
        case 'conversionWindow':
          const flipCampaignInfo = inert(ioHelper.getPlanFieldValue(this.plan, 'flipCampaignInfo'));
          let updatedFlipInfo = {
            ...flipCampaignInfo,
            conversionWindow: { ...flipCampaignInfo.conversionWindow, value: val },
          };
          const conversionWindowError = ioHelper.validateCampaignInfo(
            updatedFlipInfo,
            'conversionWindow'
          );
          if (conversionWindowError) {
            this.addErrorInValidationMap(conversionWindowError, 'conversionWindow');
          } else {
            this.addErrorInValidationMap('', 'conversionWindow');
          }
          break;
        default:
          break;
      }
    },
    onChangeEndDate(data) {
      this.flightEndDate = data.end;
      this.checkForNewCampaignErrors(data.endDate, 'flightEndDate');
    },
    onChangeStartDate(data) {
      this.flightStartDate = data.start;
      this.checkForNewCampaignErrors(data.startDate, 'flightStartDate');
    },
    getLowerBound() {
      const maxEndDate =  this.getLineItemsMaxEndDate();
      if (!isBlank(this.flightStartDate) ){
        return this.flightStartDate;
      }
      if(!maxEndDate){
        return moment().add(1, 'days');
      }
      return maxEndDate;
    },
    getUpperBound() {

      if ( this.flightEndDate !==null){
        return this.flightEndDate;
      }
      return this.getLineItemsMinStartDate() ;
    },
    getLineItemsMinStartDate() {
      return this.plan.lineItems.reduce(
        (min, p) => (p.flightStartDate < min ? p.flightStartDate : min),
        this.plan.lineItems[0].flightStartDate
      );
    },
    getLineItemsMaxEndDate() {
      return this.plan.lineItems.reduce(
        (max, p) => (p.flightEndDate > max ? p.flightEndDate : max),
        this.plan.lineItems[0].flightEndDate
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ss-right-content {
  max-height: calc(100vh - 130px);
  max-width: 860px;
  padding: 0px 50px 40px 50px;
  text-align: left;
}
.mvc-form-wrap3 {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  color: #48525c;
  width: 860px;
}
.mvc-date::before {
  display: none;
}
.mvc-date {
  position: relative;
  letter-spacing: 0em;
}
.body-sec-title {
  color: var(--selfserveprimarycolor);
  font-weight: 500;
  font-size: 1.6em;
}
.mvc-half {
  display: inline-block;
  width: 48%;
}
.mvc-half-left {
  margin-right: 3%;
}
.mvc-half-right {
  float: right;
}
.pure-input-1 {
  height: 50px !important;
  font-size: 14px;
  padding: 10px 18px !important;
  font-weight: 400;
  width: 100%;
  margin: 4px 0;
}
.error {
  color: #ff0202;
}
.mr-20 {
  margin-right: 20px;
}
.cursor {
  cursor: pointer;
}
.mvc-date::before {
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  color: #444;
  pointer-events: none;
  content: '\f133';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  top: 35px;
  right: 17px;
}
::v-deep .error-msg-field .form-input .form-input-field {
  border: 2px solid #ff0202;
}
</style>
