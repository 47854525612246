import { render, staticRenderFns } from "./contextual-targeting.vue?vue&type=template&id=19da2dda&scoped=true&"
import script from "./contextual-targeting.vue?vue&type=script&lang=js&"
export * from "./contextual-targeting.vue?vue&type=script&lang=js&"
import style0 from "./contextual-targeting.vue?vue&type=style&index=0&id=19da2dda&lang=scss&scoped=true&"
import style1 from "./contextual-targeting.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19da2dda",
  null
  
)

export default component.exports