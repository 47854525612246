<template>
  <div
    id="AD_GROUP_ADS"
    :class="`mvc-form-wrap3 s3-wrap ${isReadonly ? 'readonly-ag' : ''}`"
    style="padding: 40px 50px; text-align: left;"
  >
    <div style="height: 0px;"></div>
    <div class="body-sec-title" style="margin-bottom: 30px;">Ads</div>

    <!--
    <div class="sec-title">Files</div>
    -->
    <b-error-msg v-if="validationMap.creatives" :error-msg="validationMap.creatives" />
    <div class="sub2-attachments attach-other">
      <k-file-browse
        ref="fileUpload"
        :type="'CREATIVE'"
        name="AttachFile"
        label="upload"
        prompt="or drag files here"
        :required="true"
        :readonly="isReadonly"
        :line-item-id="lineItem.id"
        :plan-id="lineItem.planId"
        @upload="onFileUpload"
      />
      <div>
        <div v-if="errorFileUpload" class="error">{{ errorFileUpload }}</div>
        <table class="attach-table">
          <tbody>
            <div v-for="(row, ci) in lineItem.creatives" :key="ci">
              <tr v-if="ci === 0" class="creative-header-row">
                <td></td>
                <td class="atcolWidth220">Creative Name</td>
                <td></td>
                <td class="atcolWidth220">File Name</td>
                <td class="atcolWidth80">Size</td>
                <td></td>
              </tr>
              <tr
                v-if="row && row.files && row.files[0].type === 'CREATIVE'"
                :class="ci === lineItem.creatives.length - 1 ? '' : 'creative-data-row'"
              >
                <td>
                  <!-- <video /> -->
                  <div
                    class="vidthumb-wrap"
                    @click="onClickVideoPreview(fileDownloadPath(row.files[0]))"
                  >
                    <video width="50" height="38" class="vidthumb">
                      <source :src="fileDownloadPath(row.files[0])" type="video/mp4" />
                      <source :src="fileDownloadPath(row.files[0])" type="video/mov" />
                      Your browser does not support the html video tag.
                    </video>
                  </div>
                  <!-- <font-awesome-icon :icon="['far', 'video']" class="fa-w-18 dropi1" /> -->
                </td>
                <td class="atcolWidth220">
                  <k-text-input
                    v-if="!isReadonly"
                    id="creativeName"
                    ref="creativeName"
                    :required="true"
                    style="width: 220px"
                    :value="row.name"
                    :readonly="enableReadOnly || isReadonly"
                    @focusin="hasFocus = true"
                    @focusout="
                      (hasFocus = false),
                        onChangeLineItemCreativeName({
                          _uuid: row._uuid,
                          name: row.name,
                        })
                    "
                    @change="getChangedName"
                  />
                </td>
                <td>
                  <span class="intip4" data-tool-tip="Edit Creative Name">
                    <font-awesome-icon :icon="['fas', 'pencil']" @click="onClickEdit(ci)" />
                  </span>
                </td>
                <td class="atcolWidth220">
                  <p class="row-name-text" :title="`${row.files[0].name}`">
                    {{ row.files[0].name }}
                  </p>
                </td>
                <td>
                  <p class="row-filename-text">( {{ getFileSize(row.files[0].size) }} )</p>
                </td>
                <td>
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="fa-w-14 dropi2"
                    style="margin-left: 12px;"
                    @click="onDelete({ value: row._uuid })"
                  />
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
    </div>

    <div class="upload-info">
      <ol>
        <li>Raw creative files are required even if VAST tags are preferred</li>
        <li>Only .mp4 and .mov files are allowed</li>
        <li>Max file size 100mB</li>
        <li>Up to 10 videos can be uploaded</li>
        <li>
          <a href="#" @click="handleShowCreativeSpecs">Full creative file specs</a>
        </li>
      </ol>
    </div>

    <div class="sec-spacer"></div>

    <div class="sec-title">Tags &amp; Trackers</div>
    <div style="height: 10px;"></div>
    <div class="lf-txt">
      I have tags and/or trackers
    </div>
    <div class="rt-txt">
      <b-campaign-toggle
        class-prefix="tags-n-tracker"
        :checked="lineItem.tagsTrackers"
        :change="onChangeTagsToggle"
        :disabled="isReadonly"
        :required="true"
        on-label="Yes"
        off-label="No"
      />
    </div>
    <div v-if="tagsAndTrackers()" class="toggle-warning">
      {{ tagTracker }}
    </div>
    <div v-if="showCreativePreview">
      <b-modal
        :header="true"
        :enable-click-out-side="true"
        :header-text="'Creative Preview'"
        :is-self-serve-modal="true"
        @close="onClickPreviewClose"
      >
        <div slot="modal-body" class="modal-wrapper-box">
          <div class="modal-container">
            <video controls class="video-preview">
              <source :src="previewVideoUrl" type="video/mp4" controls />
              <source :src="previewVideoUrl" type="video/mov" controls />
              Your browser does not support the html video tag.
            </video>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { Creative } from 'adready-api/model/creative';
import fileMixin from 'adready-vue/components/mixins/file-mixin';
import { isBlank } from 'adready-api/helpers/common';
import { File } from 'adready-api/model/file';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import config from '~/config';
import KFileBrowse from '~/components/elements/k-file-browse.vue';
import { formatFileSize } from '~/util/utility-functions';
import BCampaignToggle from '~/components/elements/b-campaign-toggle.vue';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';
import BErrorMsg from '../elements/b-error-msg.vue';
import * as util from '~/util/utility-functions';
import lineItemHelpers from '~/helpers/line-item-helpers';
import BModal from '../elements/b-modal.vue';

export default {
  name: 'AdGroupAds',
  components: {
    KFileBrowse,
    BCampaignToggle,
    BErrorMsg,
    BModal,
  },
  mixins: [lineItemMixin, fileMixin, campaignValidationMixin],
  props: {
    lineItem: {
      type: Object,
      required: false,
      default: null,
    },
    plan: {
      type: Object,
      required: false,
      default: null,
    },
    pageIndexVisited: {
      required: true,
      type: Object,
      default: () => {},
    },
    selectedPageIndex: {
      required: true,
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      showCreativePreview: false,
      previewVideoUrl: '',
      tagTracker: '',
      creativeName: '',
      fileName: '',
      enableReadOnly: true,
      fileSize: 0,
      FILE_DOWNLOAD_PATH: '/api/files/download',
      errorMessage: null,
      changedName: '',
      validationMap: this?.pageIndexValidationObj[this?.selectedPageIndex] || {},
    };
  },
  computed: {
    advertiser: get('common/advertiser'),
    isReadonly() {
      return lineItemHelpers.isAdGroupReadonly(this.lineItem);
    },
    errorFileUpload() {
      if (!isBlank(this.errorMessage)) {
        return this.errorMessage;
      }
      return null;
    },
  },
  watch: {
    lineItem: {
      deep: true,
      handler() {
        if (this.pageIndexVisited[this.selectedPageIndex] > 1) {
          this.pageIndexValidationObj[this.selectedPageIndex] = this.validateAdGroupAds();
          this.validationMap = this.pageIndexValidationObj[this.selectedPageIndex] || {};
        }
      },
    },
  },
  mounted() {
    util.scrollToElement('AD_GROUP_ADS');
    setTimeout(() => {
      this.pageIndexVisited[this.selectedPageIndex] = this.pageIndexVisited[this.selectedPageIndex]
        ? this.pageIndexVisited[this.selectedPageIndex] + 1
        : 1;
    }, 0);
  },
  methods: {
    onClickVideoPreview(url) {
      this.showCreativePreview = true;
      this.previewVideoUrl = url;
    },
    onClickPreviewClose() {
      this.showCreativePreview = false;
      this.previewVideoUrl = '';
    },
    clearMsg() {
      this.errorMessage = null;
    },
    handleShowCreativeSpecs() {
      this.$emit('toggleCreativeSpecs', true);
    },
    getFileSize(bytes) {
      return formatFileSize(bytes);
    },
    fileDownloadPath(fileData) {
      return encodeURI(
        `${config.ADREADY_URL}${this.FILE_DOWNLOAD_PATH}/${fileData.id}?download=true`
      );
    },
    tagsAndTrackers() {
      if (this.lineItem.tagsTrackers) {
        this.tagTracker = 'An account manager will reach out to help get these implemented.';
      } else {
        this.tagTracker = '';
      }
      return this.lineItem.tagsTrackers;
    },
    onChangeTagsToggle(val) {
      if (val) {
        this.tagTracker = 'An account manager will reach out to help get these implemented.';
      } else {
        this.tagTracker = '';
      }
      const updates = { tagsTrackers: val };
      this.updateLineItem(updates, this.lineItem._uuid);
    },
    onClickEdit(ci) {
      this.enableReadOnly = false;
      this.changedName = 'edit';
      if (this.$refs.creativeName) {
        if (this.$refs.creativeName[ci]) {
          this.$refs.creativeName[ci].focus();
        }
      }
    },
    getChangedName(chgdName) {
      this.changedName = chgdName;
    },
    onChangeLineItemCreativeName(name) {
      this.enableReadOnly = true;
      if (
        !isBlank(this.changedName) &&
        this.changedName !== name.name &&
        this.changedName !== 'edit'
      ) {
        this.errorMessage = null;
        this.enableReadOnly = true;
        const creatives = [...this.lineItem.creatives];

        const modified = creatives.map((c) => {
          if (c._uuid === name._uuid) {
            const m = c.inertClone();
            m.name = this.changedName;
            return m;
          }
          return c;
        });

        const update = {
          creatives: modified,
        };
        this.updateLineItem(update, this.lineItem._uuid);
      }
      this.changedName = '';
    },
    isValidForUpload(fileData) {
      if (
        !isBlank(fileData.name) &&
        (this.isFileTypeMP4(fileData.name) || this.isFileTypeMOV(fileData.name))
      ) {
        return Math.ceil(fileData.size / (1024 * 1024)) <= 100; // max shud be 100mB
      }
      return false;
    },
    isFileTypeMP4(fileName) {
      const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
      return fileType === 'mp4' || fileType === 'MP4';
    },
    isFileTypeMOV(fileName) {
      const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
      return fileType === 'mov' || fileType === 'MOV';
    },
    theFileName(fileName) {
      return fileName.substr(0, fileName.lastIndexOf('.'));
    },
    newCreative(fileData) {
      const creative = new Creative();
      creative.name = this.theFileName(fileData.name);
      creative.creativeStartDate = this.lineItem.flightStartDate;
      creative.creativeEndDate = this.lineItem.flightEndDate;
      creative.creativeType = 'NATIVE_VIDEO';
      this.fileName = fileData.name;
      if (this.advertiser.selfServe) {
        creative.clickThroughURL = this.advertiser.domainName;
      }
      this.fileSize = formatFileSize(fileData.size);
      const attach = new File({
        id: fileData.id,
        path: fileData.path,
        name: fileData.name,
        size: fileData.size,
        type: 'CREATIVE',
      });
      creative.files.push(attach);
      return creative;
    },
    onFileUpload(fileData) {
      this.errorMessage = null;
      if (!this.isValidForUpload(fileData)) {
        this.errorMessage =
          'Either the File size is more than 100Mb, or the File type is not mp4 and mov';
        this.$refs.fileUpload.reset();
        return;
      }
      const copyCreatives = [...this.lineItem.creatives];
      if (copyCreatives.length >= 10) {
        this.errorMessage = 'Cannot upload more than 10 videos.';
        this.$refs.fileUpload.reset();
        return;
      }
      const creative = this.newCreative(fileData);
      copyCreatives.push(creative);
      const update = {
        creatives: copyCreatives,
      };
      this.updateLineItem(update, this.lineItem._uuid);
      this.$refs.fileUpload.reset();
    },

    onDelete(deleteAttach) {
      this.errorMessage = null;
      const updatedCreatives = this.lineItem.creatives.filter(
        (c) => c._uuid !== deleteAttach.value
      );
      const update = {
        creatives: updatedCreatives,
      };
      this.updateLineItem(update, this.lineItem._uuid);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';

.mvc-form-wrap3 {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  line-height: 1.2em;
  line-height: 1.7;
  font-weight: 500;
  color: #48525c;
  min-height: 900px;
  background: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 860px;
}
.body-sec-title {
  color: var(--selfserveprimarycolor);
  font-weight: 500;
  font-size: 1.6em;
}

.sec-title {
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
  color: #444;
  font-size: 20px;
  letter-spacing: 0.02em;
}

.sub2-attachments {
  border: 2px dashed #ccc;
  padding: 20px;
  margin-bottom: 43px;
  position: relative;
}

.sub2-attachments:hover {
  border: 2px dashed var(--selfserveprimarycolor);
}

a:link,
a:visited,
a:active {
  color: var(--selfserveprimarycolor);
  text-decoration: none;
}

a:hover {
  color: #333;
  text-decoration: none;
}
.attach-table {
  min-width: 100% !important;
  text-align: left !important;
  border: 0px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}

.attach-table tr.creative-header-row td {
  padding-bottom: 0px !important;
  font-weight: 600;
}
.attach-table td {
  text-align: left;
  padding: 15px 9px !important;
  border: 0px !important;
  font-size: 14px;
  vertical-align: middle;
}

// .attach-table tr {
//   border: 0px !important;
// }

.atcolWidth220 {
  width: 220px;
}
.atcolWidth80 {
  width: 80px;
}

.row-name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
}
.row-filename-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
}
.dropi1 {
  font-size: 20px;
  position: relative;
  top: 3px;
}
.dropi2 {
  font-size: 14px;
}
.dropi2:hover {
  color: var(--selfserveprimarycolor);
}

.upload-info {
  margin-left: 30px;
  font-size: 14px;
}
.upload-info ol,
.upload-info ul {
  list-style: revert !important;
}
.sec-spacer {
  padding-top: 40px;
  border-bottom: 1px dashed #ddd;
  margin-bottom: 40px;
}

.ss-half {
  display: inline-block;
  width: 48%;
  margin-right: 3%;
  vertical-align: top;
}
.ss-half.last {
  margin-right: 0%;
  vertical-align: top;
}

a {
  display: inline-block !important;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

::v-deep .form-input .form-input-field {
  border-color: transparent !important;
  border: 1px solid transparent;
  resize: none !important;
  padding: 2px !important;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
.lf-txt {
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
}
.rt-txt {
  display: inline-block;
  vertical-align: top;
}
.toggle-warning {
  font-style: italic;
  margin-top: 5px;
}
::v-deep .focused.form-input .form-input-field {
  border: 1px solid #aaaaaa !important;
}
.readonly-ag {
  cursor: not-allowed;
  pointer-events: none;
}
.vidthumb-wrap {
  position: relative;
  display: inline-block;
  background: #2a2a2a;
  height: 38px;
  border-radius: 3px;
  overflow: hidden;
  vertical-align: middle;
  &:hover:after {
    opacity: 1;
    transform: scale(1);
  }
  &:after {
    font-family: 'Font Awesome 5 free';
    content: '\f04b';
    font-weight: 600;
    position: absolute;
    color: #eee;
    font-size: 20px;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    transform: scale(0.5);
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    margin-top: -8px;
    pointer-events: none;
    position: absolute;
    margin: 2px 0px 0px -32px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .vidthumb:hover {
    position: relative;
    opacity: 0.6;
  }
  .vidthumb {
    position: relative;
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
.modal-container {
  width: 650px;
  .video-preview {
    width: 100%;
    height: auto;
    margin: -10px 0px -29px;
  }
}
::v-deep .selfServBox .modal-container .modal-footer {
  padding: unset;
  height: unset;
}
.creative-data-row {
  border-bottom: 1px dashed #ddd !important;
}
</style>
