<template>
  <div class="lineitem-rw-parting">
    <div class="makeFlex">
      <div class="sec-title">Day Parting</div>
      <div :class="`toggle-btn-cont ${readonly ? 'readonly-dp' : ''}`">
        <b-campaign-toggle
          class-prefix="day-parting"
          :checked="dayPartingEnabled"
          :change="onChangeLineItemDayParting"
          :readonly="readonly"
        />
      </div>
      <div v-if="dayPartingEnabled" class="ss-half last" style="width: 62%; top: 6px;">
        <div
          class="ss-half last mitm-removeall"
          style="width: 62%; top: 6px;"
          @click="onUnSelectAll()"
        >
          Unselect All
        </div>
      </div>
    </div>
    <div class="dpNotifyText">All times in exposed household time zone</div>
    <div v-if="dayPartingEnabled" class="day-parting-cont">
      <drag-select-day-parting
        v-if="isUnSelect"
        :on-change-day-parting="onChangeDayParting"
        :day-parting="dayPartValueUnSelectAll"
        :readonly="readonly"
      />
      <div v-else class="flp-panel-amt">
        <drag-select-day-parting
          v-if="!isUnSelect"
          :on-change-day-parting="onChangeDayParting"
          :day-parting="dayPartValue"
          :readonly="readonly"
        />
      </div>
      <div class="notifyText">Drag over the dayparting boxes to select or deselect.</div>
    </div>
  </div>
</template>

<script>
import { inert, isBlank } from 'adready-api/helpers/common';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import BCampaignToggle from '~/components/elements/b-campaign-toggle.vue';
import DragSelectDayParting from './drag-select-day-parting.vue';
import { DEFAULT_DAY_PARTING } from '~/constant';

export default {
  name: 'LineItemDayParting',

  components: {
    BCampaignToggle,
    DragSelectDayParting,
  },

  mixins: [lineItemMixin],

  props: {
    showCheckbox: {
      required: false,
      type: Boolean,
      default: false,
    },
    expandedView: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      hasFocus: false,
      copy: {},
      dayPartingDeSelectAll: false,
    };
  },
  computed: {
    isUnSelect() {
      return this.dayPartingDeSelectAll;
    },
    dayPartValueUnSelectAll() {
      const updates = {};
      updates.dayParting = {
        enabled: true,
      };
      this.updateLineItem(inert(updates));
      return {};
    },
    dayPartValue() {
      if (isBlank(this.lineItem.dayParting)) {
        return {};
      }
      return inert(this.lineItem.dayParting);
    },
    dayPartingEnabled() {
      if (this?.lineItem?.dayParting?.enabled) {
        return true;
      }
      return false;
    },
    dayPartingCount() {
      if (this.dayPartingEnabled) {
        return 1;
      }
      return 0;
    },
    disablePlusIcon() {
      if (this.dayPartingCount) {
        return true;
      }
      return false;
    },
    hideEdit() {
      if (this.dayPartingCount > 0) {
        return false;
      }
      return true;
    },
  },

  methods: {
    onChangeLineItemDayParting(val) {
      this.dayPartingDeSelectAll = false;
      const updates = {};
      if (val) {
        updates.dayParting = {
          enabled: true,
          ...DEFAULT_DAY_PARTING,
        };
      } else {
        updates.dayParting = {
          enabled: false,
        };
      }
      this.updateLineItem(inert(updates));
    },
    onUnSelectAll() {
      this.dayPartingDeSelectAll = true;
    },

    onChangeDayParting(val) {
      this.dayPartingDeSelectAll = false;
      const dayPartingObj = {
        enabled: this?.lineItem?.dayParting?.enabled || false,
        ...val,
      };
      if (this?.lineItem?.dayParting?.text) {
        dayPartingObj.text = this?.lineItem?.dayParting?.text;
      }
      this.updateLineItem(inert({ dayParting: dayPartingObj }));
    },
  },
};
</script>
<style lang="scss" scoped>
.ss-half {
  position: relative;
  display: inline-block;
  width: 48%;
  margin-right: 3%;
  vertical-align: top;
}
.ss-half.last {
  margin-right: -260px;
  vertical-align: top;
}
.mitm-removeall {
  display: inline-block;
  background-color: transparent !important;
  color: #777 !important;
  margin-bottom: 15px;
  float: right;
  font-size: 12px;
}
.mitm-removeall:hover {
  color: var(--selfserveprimarycolor) !important;
  cursor: pointer;
}
.lineitem-rw-parting {
  width: 860px;
}
.day-parting-cont {
  font-family: 'Manrope';
  color: #000;
}
.sec-title {
  display: inline-block;
  margin-right: 60px;
  color: #444;
  font-size: 20px;
  letter-spacing: 0.02em;
}
::v-deep .cell-hover-wrap .cell-hover-header span.edit {
  display: none !important;
}
::v-deep .day-parting-cont .row .demo-form-section .form-input {
  display: none;
}
::v-deep .dtable .days .day {
  text-align: right;
}
::v-deep .dtable .hours .hour {
  font-size: 12px;
}
.toggle-btn-cont {
  margin-top: 3px;
}
::v-deep .drag-selector__item {
  background: #f6f6f6;
  width: 32px;
  height: 49px;
}
::v-deep .drag-selector__item.selectedC {
  background-color: var(--selfserveprimarycolor);
  border-color: #2b8a9b;
}
.lineitem-rw {
  display: inline-flex !important;
  align-items: center;
  margin-right: 0 !important;
  margin-left: 0 !important;
  text-align: left;
  vertical-align: middle;
  .select-all {
    line-height: 15px;
  }
  ::v-deep .form-input .form-input-field {
    // width: 480px !important;
    color: #666 !important;
    resize: none !important;
  }

  .alert-wrap {
    position: absolute;
    right: 0;
    display: inline-block;
    margin-right: 5px;
    .tr-alert {
      min-width: 17px;
      padding: 3px 0px 2px;
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      border-radius: 20px;
    }
    .flg-alert {
      background-color: #d92a36;
      svg {
        margin: 0 !important;
      }
    }
    .cr-alert {
      background-color: #2a80d9;
    }
  }
  .notes-wrap {
    margin-top: -8px;
  }
}
.makeFlex {
  display: flex;
}
.notifyText {
  font-size: 12px;
  color: #999;
  margin: 0px 0px 0px 32px;
}
.readonly-dp {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.25;
}
.dpNotifyText {
  margin-right: 60px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #999;
  font-style: italic;
}
</style>
