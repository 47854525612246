<template>
  <div>
    <table class="k-sheet">
      <thead :class="{ collapsed: !expanded }">
        <tr
          class="table-header-row"
          :class="{ 'active-header': !expanded, 'inactive-header': expanded }"
          style="border-bottom: 0px;"
        >
          <th class="table-header flag-header"></th>
          <th
            v-for="(header, key) in headers"
            :key="key"
            :class="[
              'table-header',
              { 'clickable-header': header.defaultSortOrder },
              header.sortCol,
            ]"
            @mouseover="header.hover = true"
            @mouseleave="header.hover = false"
          >
            <span>{{ header.name }}</span>
          </th>
          <th class="table-header actions-header">
            ACTIONS
          </th>
        </tr>
      </thead>
      <b-loading-spinner v-if="loader" class="txt-center" />
      <plan-body v-else :selected-status-ids="selectedStatusIds" />
    </table>
  </div>
</template>

<script>
import { formatDate } from '~/util/apiDateFormat';
import PlanBody from '~/components/campaigns/plan-body.vue';

export default {
  name: 'PlanTable',

  components: {
    PlanBody,
    BLoadingSpinner: () => import('~/components/elements/b-loading-spinner.vue'),
  },
  props: {
    loader: { require: false, type: Boolean, default: true },
    selectedStatusIds: { require: false, type: Array, default: () => [] },
  },

  data() {
    return {
      formatDate,
      expanded: false,
    };
  },

  computed: {
    headers() {
      const headers = [
        {
          name: 'CAMPAIGN NAME',
          sortCol: 'campaignName',
        },
        {
          name: 'START',
          sortCol: 'flightStartDate',
        },
        {
          name: 'END',
          sortCol: 'flightEndDate',
        },
        {
          name: 'BUDGET',
          sortCol: 'totalBudget',
        },
        {
          name: 'SPEND',
          sortCol: 'totalBudget',
        },
        {
          name: 'PACING',
          sortCol: 'pacing',
        },
        {
          name: 'GOAL',
          sortCol: 'goal',
        },
        {
          name: 'CPA/ROAS',
          sortCol: 'metric',
        },
        {
          name: 'STATUS',
          sortCol: 'status',
        },
      ];

      return headers.filter((h) => h.show !== false);
    },

    defaultSort() {
      return {
        sortCol: 'id', // FIXME: what do we sort by if ID is not shown (for MNI users)?
        sortOrder: 'desc',
      };
    },
  },
};
</script>

<style lang="scss">
.k-sheet {
  box-shadow: none;
  thead.collapsed {
    tr,
    tr th {
      position: sticky;
      top: 57px;
      //z-index: 2;
    }
  }
}
th.flag-header {
  width: 30px;
  padding-bottom: 10px !important;
}
th.campaignName {
  width: 85%;
  text-align: left !important;
}
th.flightStartDate,
th.flightEndDate,
th.totalBudget,
th.spend,
th.pacing,
th.goal,
th.metric,
th.status {
  width: 20%;
}
th.actions-header {
  width: 200px;
}
tbody {
  position: relative;
}
.mvc-btn {
  background-color: #54a8c7;
  font-size: 13px;
  padding: 15px 18px;
  color: var(--selfserveovertext) !important;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  display: inline-block;
  font-weight: 500;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 170px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-size2 {
  font-size: 11px;
  padding: 6px 8px;
  min-width: 20px;
  font-weight: 600;
  margin: 0 2px;
  background-color: transparent !important;
  color: #777d81;
  &:hover {
    background-color: var(--selfserveprimarycolor) !important;
    color: #fff !important;
    border: 1px solid var(--selfserveprimarycolor) !important;
  }
  .icon-wrap svg {
    width: 1em !important;
    &:not(.disabled):hover {
      color: #fff !important;
    }
  }
}
.mvc-btn:hover {
  background-color: #424242;
  color: #fff !important;
  border: 1px solid #424242;
}
.plan-expand:hover {
  cursor: pointer;
  color: var(--selfserveprimarycolor);
}
.actions-header.no-save-send {
  width: 110px;
}

table {
  font-weight: 500;
}
.mvc-io-wrap table {
  border: 0px solid #888;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  min-width: 1100px;
  line-height: 1.25;
  background-color: #fff;
  border-radius: 0px;
  // box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  /*border: 1px solid #e4e4e4;*/
  box-sizing: border-box;
}
.mvc-io-wrap table tr {
  border: 0px solid #e4e4e4;
  padding: 0.35em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 2;
}
.defaultPlanRow:hover {
  box-shadow: inset 0px 0px 0px 2px rgb(0 60 80 / 20%) !important;
}
tr.intr {
  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  &:hover {
    box-shadow: inset 0px 0px 0px 2px rgb(0 60 80 / 20%) !important;
  }
}
.mvc-io-wrap table th,
.mvc-io-wrap table td {
  padding: 0.6em 0.4em 0.6em 0.3em;
  text-align: center;
  font-size: 0.76em;
  vertical-align: middle;
  // border-left: 1px solid rgba(0, 0, 0, 0.04);
  // box-shadow: inset 1px 0px 0px 0px rgb(0 0 0 / 7%);
  position: relative;
}
.mvc-io-wrap table td {
  border-left: 1px solid rgba(0, 0, 0, 0.04);
  color: #646464;
  // box-shadow: inset 1px 0px 0px 0px rgb(0 0 0 / 7%);
}
.mvc-io-wrap table th {
  background-color: #3b3d48;
  padding: 1.5em 0.7em 1.5em 0.7em;
  color: var(--selfserveovertext);
  font-size: 0.701em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 600;
  // border-left: 1px solid #3b3d48 !important;
  position: inherit;
}
.mvc-io-wrap table td:nth-child(1) {
  border-left: 0px;
}
.mvc-io-wrap table th:nth-child(1) {
  border-left: 0px;
}
.tleft {
  text-align: left !important;
  padding-left: 20px !important;
}
.int-sub-tr {
  padding-bottom: 0px !important;
  background-color: #e7f1f7;
  // box-shadow: 0px 20px 37px -14px rgb(0 20 50 / 18%) !important;
}
.tablewrapper.tbw1::before {
  content: 'Ad Groups';
  display: inline-block;
  position: absolute;
  background-color: #68a7c4;
  border-bottom: 2px solid #68a7c4;
  z-index: 1;
  margin: -16px 0px 0px 30px;
  left: 0px;
  border-radius: 4px 4px 0px 0px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 1px 5px;
  color: #fff;
  box-shadow: -1px 0px 2px -1px rgb(0 0 0 / 40%);
}
td.tablewrapper.tbw1 {
  border-bottom: 1px solid #c8cbd1;
  overflow: visible;
  padding-top: 0;
}
td.tablewrapper {
  tr {
    td {
      font-size: 11.5px;
    }
  }
}
.int-sub-table {
  width: calc(100% - 21px) !important;
  margin-left: 26px;
  margin-bottom: 12px !important;
}
.intr {
  background-color: #f3f8f9;
  border-left: 3px solid #54a8c7 !important;
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  -ms-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  td {
    /* border-left: 1px solid rgba(0,0,0,0.04); */
    // box-shadow: inset 1px 0px 0px 0px rgb(0 0 0 / 7%);
  }
  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
}
.lineItem-name {
  width: 84.8%;
}
.lineItem-td {
  width: 20%;
}
.lineItem-action {
  width: 200px;
}
::v-deep .k-btn {
  background-color: #54a8c7 !important;
  color: #fff !important;
  margin: 0 4px;
}
::v-deep .k-btn:hover {
  background-color: #3b3d48 !important;
}
::v-deep .mvc-io-wrap table tr.activePlanRow:hover {
  box-shadow: inset 0px 0px 0px 0px rgb(0 60 80 / 20%) !important;
}
</style>
<style lang="scss" scoped>
::v-deep .in-center {
  width: 1430px !important;
}
</style>
