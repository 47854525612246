<template>
  <b-dropdown-multiselect-v2
    class-prefix="gender"
    :options="genderOptions"
    :label="'Select Gender'"
    :value="genderValues"
    @select="onInput"
  />
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import targetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import BDropdownMultiselectV2 from '~/components/elements/b-dropdown-multiselect-v2.vue';

export default {
  name: 'GenderSelect',
  components: { BDropdownMultiselectV2 },

  mixins: [lineItemMixin, targetableElementOptionsMixin, validationsMixin, authzMixin],
  props: {
    targetingValue: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      targetingType: 'audienceTargeting',
      targetableElementId: 1,
      counter: 0,
    };
  },
  computed: {
    multiple() {
      return !this.isMni;
    },
    genderValues() {
      let values = [];
      if (this.counter >= 0 && this?.targetingValue?.gender) {
        values = this.targetingValue.gender.map((gender) => {
          return { text: gender };
        });
      }
      return values;
    },
    genderOptions() {
      return this.options.map((option) => {
        return { text: option };
      });
    },
  },

  methods: {
    onInput(val) {
      const valString = val.map((v) => v.text);
      this.counter += 1;
      this.$emit('onInput', valString, 'gender');
    },
  },
};
</script>
