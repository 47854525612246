<template>
  <div :class="` ${readonly ? 'readonly-bp' : ''}`">
    <div class="flip-title" :class="goalBrandLift ? 'disabled' : ''">
      <span
        >{{ goalName }} Optimization
        <b-error-msg v-if="errorObj.optimization" :error-msg="errorObj.optimization" />
      </span>
    </div>
    <div :class="goalBrandLift ? 'disabled' : ''">
      <div class="bl-options">
        <a
          v-for="(attribution, index) in attributions"
          :key="attribution.id"
          class="bl-btn"
          :class="[
            `${attribution.id === activeOptimization.attribution.id ? 'bl-btn-selected' : ''}`,
            `${index === 0 ? 'lend' : ''} ${index === attributions.length - 1 ? 'rend' : ''}`,
          ]"
          @click="onSelectOptimization('attribution', attribution)"
        >
          <font-awesome-icon class="bl-icon" :icon="['fas', 'check']" size="1x" />
          <div class="bl-text">{{ attribution.value }}</div>
          <span v-if="attribution.desc" :data-tool-tip="attribution.desc" class="intip4">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </span>
        </a>
      </div>
      <b-error-msg v-if="errorObj.attribution" :error-msg="errorObj.attribution" />
      <div class="bl-options">
        <a
          v-for="(i, index) in impressions"
          :key="i.id"
          class="bl-btn"
          :class="[
            `${i.id === activeOptimization.impressions.id ? 'bl-btn-selected' : ''}`,
            `${index === 0 ? 'lend' : ''} ${index === impressions.length - 1 ? 'rend' : ''}`,
          ]"
          @click="onSelectOptimization('impressions', i)"
        >
          <font-awesome-icon class="bl-icon" :icon="['fas', 'check']" size="1x" />
          <div class="bl-text">{{ i.value }}</div>
          <span v-if="i.desc" :data-tool-tip="i.desc" class="intip4">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </span>
        </a>
      </div>
      <b-error-msg v-if="errorObj.impressions" :error-msg="errorObj.impressions" />
      <div class="bl-options">
        <a
          v-for="(weightage, index) in weightages"
          :key="weightage.id"
          class="bl-btn"
          :class="[
            `${weightage.id === activeOptimization.weightage.id ? 'bl-btn-selected' : ''}`,
            `${index === 0 ? 'lend' : ''} ${index === weightages.length - 1 ? 'rend' : ''}`,
          ]"
          @click="onSelectOptimization('weightage', weightage)"
        >
          <font-awesome-icon class="bl-icon" :icon="['fas', 'check']" size="1x" />
          <div class="bl-text">{{ weightage.value }}</div>
          <span v-if="weightage.desc" :data-tool-tip="weightage.desc" class="intip4">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </span>
        </a>
      </div>
      <b-error-msg v-if="errorObj.weightage" :error-msg="errorObj.weightage" />
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get, sync } from 'vuex-pathify';
import bErrorMsg from '~/components/elements/b-error-msg.vue';
import { FLIP_CAMPAIGN_INFO_DEFAULT } from '~/constant';

export default {
  name: 'BPlanFlipCampaignOptimization',
  components: { bErrorMsg },
  props: {
    errorObj: { required: false, type: Object, default: () => {} },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      attributions: [
        {
          id: 1,
          value: 'Full Conversions',
          desc:
            'Full conversions will count a full event as long as that event occurred within the selected date range in your reporting dashboard. This means that even if impressions that drove the conversion occurred outside the date range, the conversion will be counted in full.',
        },
        {
          id: 2,
          value: 'Partial Conv',
          desc:
            'Partial conversions will count only conversions where both the impressions and the event occurred within the selected date range in the your reporting dashboard. For example, if linear methodology is chosen and two impressions occur outside the date range, but two impressions and the conversion occur within the date range, .5 conversions will be counted.',
        },
      ],
      impressions: [
        {
          id: 11,
          value: 'total',
        },
        {
          id: 22,
          value: 'unique',
          desc: 'Filters out all repeat conversions',
        },
      ],
      weightages: [
        {
          id: 111,
          value: 'direct',
          desc:
            'Counts all events weighted at 100% (this does not account for any event lift over the weightage group)',
        },
        {
          id: 222,
          value: 'incremental',
          desc:
            'Applies an incremental weighting to every event based on analysis of the campaign over a control group of like households.',
        },
      ],
    };
  },
  computed: {
    account: get('common/account'),
    plan: get('common/plan'),
    flipCampaignInfoOptimization: sync('common/plan@flipCampaignInfo.optimization'),
    flipCampaignInfoGoal: get('common/plan@flipCampaignInfo.goal'),

    goalBrandLift() {
      if (!isBlank(this.flipCampaignInfoGoal)) {
        return this.flipCampaignInfoGoal.category === 'brandlift';
      }
      return false;
    },

    goalName() {
      if (!isBlank(this.flipCampaignInfoGoal)) {
        return this.flipCampaignInfoGoal.name;
      }
      return undefined;
    },
    activeOptimization() {
      if (!isBlank(this.flipCampaignInfoOptimization)) {
        return this.flipCampaignInfoOptimization;
      }
      return FLIP_CAMPAIGN_INFO_DEFAULT.optimization;
    },
  },
  methods: {
    onSelectOptimization(key, val) {
      let updates = {};
      if (isBlank(this.flipCampaignInfoOptimization)) {
        updates = JSON.parse(JSON.stringify(FLIP_CAMPAIGN_INFO_DEFAULT.optimization));
      } else {
        updates = JSON.parse(JSON.stringify(this.flipCampaignInfoOptimization));
      }
      updates[key] = { id: val.id, value: val.value };
      this.flipCampaignInfoOptimization = updates;
    },
  },
};
</script>

<style lang="scss" scoped>
.bl-options {
  display: flex;
  .bl-icon {
    display: none;
  }
  .bl-text {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 5px;
  }
  .bl-btn {
    color: #7f7f7f !important;
    border: 1px solid #b3b3b3 !important;
    padding: 9px 12px !important;
    margin: 10px 0px;
    text-align: center;
    letter-spacing: 0.02em;
    display: flex;
    height: 40px;
    align-items: center;
    border-right-width: 0.5px !important;
    border-left-width: 0.5px !important;
    &.lend {
      border-left-width: 1px !important;
      border-radius: 3px 0px 0px 3px;
    }
    &.rend {
      border-right-width: 1px !important;
      border-radius: 0px 3px 3px 0px;
    }
    &:hover {
      background-color: #eee !important;
      color: #222 !important;
    }
    &.bl-btn-selected:hover {
      background-color: var(--selfserveprimarycolor) !important;
      color: #fff !important;
    }
  }
  .bl-btn-selected {
    background-color: var(--selfserveprimarycolor) !important;
    color: #fff !important;
    border: 1px solid var(--selfserveprimarycolor) !important;
    .bl-icon {
      display: block;
      color: #fff !important;
      transform: scale(0.8);
      margin-right: 5px;
    }
    &:hover {
      background-color: #fff;
    }
  }
}
::v-deep .bl-btn-selected .fa-info-circle:hover {
  color: #fff !important;
}

.flip-title {
  margin-bottom: 20px;
  color: #444;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.02em;
}
.disabled {
  pointer-events: none;
  opacity: 0.25;
}
::v-deep .fa-info-circle {
  transform: scale(0.8);
}
.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  padding: 10px 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: left;
  text-transform: none;
  content: attr(data-tool-tip);
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
  background-color: #464646;
  color: #fff;
  font-size: 13px;
  pointer-events: none;
  text-transform: none;
  max-width: 245px;
  margin-left: -120px;
  width: max-content;
}

.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 170%;
}
.readonly-bp {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.25;
}
</style>
