<template>
  <div class="in-center">
    <svg v-if="!showMessage" class="spinner" width="30px" height="30px" viewBox="0 0 66 66">
      <circle
        class="path"
        fill="none"
        stroke-width="4"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
    <div v-else class="message">
      <span
        >{{ customMessage }}
        <span class="ellipsis-anim"><span>.</span><span>.</span><span>.</span></span></span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enableCustomMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
    customMessage: {
      type: String,
      required: false,
      default: "Wow, that's a lot of data! We're grabbing it for you now, hang tight",
    },
  },
  data() {
    return {
      showMessage: false,
      messageTimeout: null,
    };
  },
  mounted() {
    const vm = this;
    if (!this.enableCustomMessage) {
      return;
    }
    vm.messageTimeout = setTimeout(() => {
      vm.showMessage = true;
    }, 7000);
  },
  beforeDestroy() {
    if (this.messageTimeout) {
      clearTimeout(this.messageTimeout);
    }
    this.showMessage = false;
  },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@-webkit-keyframes colors {
  0% {
    stroke: #666;
  }
  25% {
    stroke: #fdce07;
  }
  50% {
    stroke: #00abb7;
  }
  75% {
    stroke: #00abb7;
  }
  100% {
    stroke: #666;
  }
}
@keyframes colors {
  0% {
    stroke: #666;
  }
  25% {
    stroke: #fdce07;
  }
  50% {
    stroke: #00abb7;
  }
  75% {
    stroke: #00abb7;
  }
  100% {
    stroke: #666;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
.in-center {
  text-align: center;
  height: 76px;
  position: relative;
}
.spinner {
  position: absolute;
  top: 30%;
  left: 47%;
}
.message {
  padding: 0 1rem;
  @-webkit-keyframes ellipsis-dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes ellipsis-dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .ellipsis-anim span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
  }
  .ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
}
</style>
