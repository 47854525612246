<template>
  <label class="switch" :class="`${classPrefix}-switch`">
    <input
      type="checkbox"
      class="switch-input"
      :class="`${classPrefix}-switch-input`"
      :checked="checked"
      @change="handleChangeToggle"
    />
    <span class="switch-label" :data-on="onLabel" :data-off="offLabel"></span>
    <span class="switch-handle"></span>
  </label>
</template>
<script>
export default {
  name: 'BCampaignToggle',
  props: {
    checked: {
      required: true,
      type: Boolean,
    },
    change: {
      required: true,
      type: Function,
    },
    onLabel: {
      type: String,
      required: false,
      default: () => 'On',
    },
    offLabel: {
      type: String,
      required: false,
      default: () => 'Off',
    },
    classPrefix: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  methods: {
    handleChangeToggle() {
      this.change(!this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block !important;
  vertical-align: top;
  width: 57px;
  height: 22px;
  padding: 3px;
  border-radius: 18px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.switch-label:before {
  content: attr(data-off);
  right: 7px;
  color: #aaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 9px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: var(--selfserveprimarycolor);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-input:checked ~ .switch-handle {
  left: 33px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
