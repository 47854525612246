<template>
  <div class="ss-footer-content">
    <div class="save-btn">
      <div
        v-if="this.visitedIndexes && this.visitedIndexes.length > 1"
        style="display: inline-block;margin-right: 20px;"
        class="mvc-btn btn-teal"
        @click="handleBackBtn"
      >
        Back
      </div>
      <div
        class="mvc-btn btn-teal"
        :class="`${selectedPage}` === REVIEW_CAMPAIGN ? 'no-show' : ''"
        @click="handleReviewBtn"
      >
        Review
      </div>
    </div>
    <div class="save-btn">
      <div
        v-if="showSaveDraft"
        class="mvc-btn btn-outline"
        :class="isSaveDraftDisabled ? 'disabled' : ''"
        style="display: inline-block;margin-right: 20px;"
        @click="handleSaveDraftBtn"
      >
        Save Draft
      </div>
      <div
        v-if="showSaveChanges && !hideSaveChanges"
        class="mvc-btn btn-teal"
        style="display: inline-block;margin-right: 20px;"
        :class="isTransformPlanLoading || !validationChecksPassed || isReadonly ? 'disabled' : ''"
        @click="handleSaveChangesBtn"
      >
        Save Changes
      </div>

      <div
        v-if="selectedPage === REVIEW_CAMPAIGN && showSubmit"
        class="mvc-btn btn-teal"
        :class="!isTransformPlanLoading && validationChecksPassed ? '' : 'disabled'"
        @click="handleSubmitBtn"
      >
        Submit
      </div>

      <div v-if="selectedPage !== REVIEW_CAMPAIGN" class="mvc-btn btn-teal" @click="handleNextBtn">
        Next
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import {
  IOS_DRAFT,
  IOS_NEW,
  LIS_DRAFT,
  LIS_NEW,
  LIW_NEW_AM,
  LIT_CHANGE,
  IOS_CHANGE_NEW,
  IOS_CHANGE_READY_FOR_ACTIVATION,
  LIS_CHANGE_PENDING_ACTIVATION,
  IOS_LIVE,
  LIS_PENDING_ACTIVATION,
  LIW_PENDING_AM,
  IO_STATE_CHANGE,
  LI_STATUS_FOR_CR_ENABLED,
} from 'adready-api/constant';
import { isBlank } from 'adready-api/helpers/common';
import { diff } from 'json-diff';
import { Plan } from 'adready-api/model/plan';
import planApi from 'adready-api/api/plans';
import { LineItem } from 'adready-api/model/line-item';
import deepcloneHelpers from 'adready-api/helpers/deepclone-helpers';
import campaignMixin from '~/components/mixins/campaign-mixin';
import {
  NEW_CAMPAIGN_PAGES,
  CAMPAIGN_NAV_BAR_OPTIONS,
  AD_GROUP_NAV_BAR_OPTIONS,
  LAYOUT_TYPES,
  CR_LINEITEM_STATUS,
} from '~/constant';
import lucidMixin from '~/components/mixins/lucid-mixin';
import ioHelpers from '~/helpers/io-helpers';
import { eBus } from '~/main';
import lineItemHelpers from '~/helpers/line-item-helpers';
import emailMixin from '~/components/mixins/email-mixin';

const { REVIEW_CAMPAIGN } = NEW_CAMPAIGN_PAGES;
const { CAMPAIGN_INFO } = NEW_CAMPAIGN_PAGES;

export default {
  name: 'CampaignBottomNavbar',
  mixins: [campaignMixin, lucidMixin, emailMixin],
  props: {
    totalSelectableOptions: {
      type: Number,
      required: false,
      default: () => 0,
    },
    selectedPage: {
      type: String,
      required: true,
      default: () => '',
    },
    selectedPageIndex: {
      type: Number,
      required: true,
      default: () => 0,
    },
    lineItemIndexArray: {
      type: Array,
      required: true,
      default: () => [],
    },
    visitedIndexes: {
      required: true,
      type: Array,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      CAMPAIGN_INFO,
      REVIEW_CAMPAIGN,
      IOS_DRAFT,
      IOS_NEW,
      isCRSubmitted: false,
      hideSaveChanges: false,
      crPlanSubmittedStatus: [IOS_CHANGE_NEW, IOS_CHANGE_READY_FOR_ACTIVATION],
      crLineItemSubmittedStatus: CR_LINEITEM_STATUS,
      expectedImpressionCount: 0,
      lucidCampaignViable: false,
      validationChecksPassed: true,
      isSaveDraftDisabled: false,
    };
  },

  computed: {
    plan: sync('common/plan'),
    plans: get('common/plans'),
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    updatePlan: sync('common/plan@updatePlan'),
    user: get('common/currentUser'),
    mediaPublisherCPMData: get('common/mediaPublisherCPMData'),
    planStatusId: sync('common/plan@statusId'),
    orgLineItems: sync('common/orgLineItems'),
    isTransformPlanLoading: get('common/isTransformPlanLoading'),
    showSaveDraft() {
      // campaign is new
      if (isBlank(this.plan) || isBlank(this.plan.id)) {
        return true;
      }
      if (this.planStatusId === IOS_DRAFT) {
        return true;
      }
      // campaign is saved in draft mode.
      if (!isBlank(this.plan.parentId)) {
        const orgPlan = this.plans.find((p) => p.id === this.plan.parentId);
        if (orgPlan && orgPlan.statusId === IOS_DRAFT) {
          return true;
        }
      }
      return false;
    },
    showSubmit() {
      return this.showSaveDraft;
    },
    showSaveChanges() {
      if (this.showSaveDraft) {
        return false;
      }
      return this.selectedPage === REVIEW_CAMPAIGN;
    },
    crSubmitted() {
      if (this.isCRSubmitted) {
        return true;
      }
      let crPlanSubmitted = false;
      if (this.plan.state === 'CHANGE') {
        if (this.crPlanSubmittedStatus.includes(this.plan.statusId)) {
          crPlanSubmitted = true;
        }
      }
      let crLineItemSubmitted = false;
      if (!isBlank(this.orgLineItems)) {
        this.orgLineItems.forEach((l) => {
          const { crLineItems } = l;
          if (!isBlank(crLineItems)) {
            const crLineItem = crLineItems.find((l1) =>
              this.crLineItemSubmittedStatus.includes(l1.statusId)
            );
            if (crLineItem) {
              crLineItemSubmitted = true;
            }
          }
        });
      }
      return crPlanSubmitted || crLineItemSubmitted;
    },
  },
  created() {
    eBus.$on('validationChecksPassed', this.onValidationChecksPassed);
  },

  methods: {
    onValidationChecksPassed(passed) {
      this.validationChecksPassed = passed;
    },
    handleReviewBtn() {
      this.$emit('pageSelected', REVIEW_CAMPAIGN, this.totalSelectableOptions);
    },
    handleSaveChangesBtn() {
      planApi
        .read(this?.plan?.id, {
          includePacing: false,
        })
        .then((orgPlan) => {
          // Editing original Campaign
          if (orgPlan.statusId === IOS_NEW) {
            const crPlan = this.getCRPlan();
            let planToUpdate = orgPlan;
            // change in Original Campaign
            if (!isBlank(crPlan)) {
              planToUpdate = deepcloneHelpers.mergePlan(orgPlan, crPlan);
            }
            // Change In Orignal lineItem
            planToUpdate.lineItems = this.plan.lineItems;
            // Update creatives
            const updatedLineItems = [];
            planToUpdate.lineItems.forEach((l) => {
              const updatedLineItem = this.updateCreatives(l);
              updatedLineItems.push(updatedLineItem);
            });
            planToUpdate.lineItems = updatedLineItems;
            this.hideSaveChanges = true;
            this.saveOrUpdatePlan(planToUpdate, this.refreshPlansDataFromDb);
          } else if (orgPlan.statusId === IOS_LIVE) {
            // Edit of CR campaign
            const crPlan = this.getCRPlan();
            if (!isBlank(crPlan)) {
              this.updatePlan = false;
              this.saveOrUpdateCRPlan(crPlan);
            }
            // Update CR lineItem
            const updatedLines = this.getChangedLineItems();
            let sendExportEmail = false;
            const adGroupIds = [];
            if (!isBlank(updatedLines.crLineItems)) {
              sendExportEmail = true;
              updatedLines.crLineItems.forEach((crLineItem) => {
                // Tweak the values (start date, end date and clickThroughURL) of Creatives for each lineItem
                const updatedLineItem = this.updateCreatives(crLineItem);
                crLineItem = lineItemHelpers.resetIdsOfLineItem(updatedLineItem);
                this.saveOrUpdateCRLineItems(crLineItem);
                adGroupIds.push(crLineItem.parentId);
              });
            }
            if (!isBlank(updatedLines.updatedOrgLineItem)) {
              sendExportEmail = true;
              // Tweak the values (start date, end date and clickThroughURL) of Creatives for each lineItem
              const upatedLis = [];
              updatedLines.updatedOrgLineItem.forEach((l) => {
                const updatedLineItem = this.updateCreatives(l);
                upatedLis.push(updatedLineItem);
              });
              this.updateOrgLineItems(upatedLis);
              const updatedIds = upatedLis.map(function(el) {
                return el.id;
              });
              adGroupIds.push(updatedIds);
            }
            if (sendExportEmail) {
              const notes = `There are new changes in following Ad Groups ${adGroupIds.join(',')}`;
              this.sendExportEmail(orgPlan, null, notes);
            }
            this.hideSaveChanges = true;
            this.redirectToDashboard(this.plan);
            const orgLineItemsCount = this.orgLineItems.length;
            const addedUpdateLineItemCount = this.plan.lineItems.length;
            if (addedUpdateLineItemCount > orgLineItemsCount) {
              const planToUpdate = orgPlan;
              planToUpdate.lineItems = this.plan.lineItems;
              this.saveOrUpdatePlan(planToUpdate, this.refreshPlansDataFromDb);
            }
          }
        });
    },

    refreshPlansDataFromDb(updatedPlan) {
      const q = `accountId==${this.account.id};advertiserId==${this.advertiser.id};state!=${IO_STATE_CHANGE}`;
      this.loadPlansData(q, {}, true, true, true);
      if (updatedPlan.statusId !== IOS_DRAFT) {
        const notes = `There are new changes in Campaign or Adgroup - Campaign ID: ${updatedPlan.id} , see attached csv for details`;
        this.sendEmailNotification(updatedPlan, notes);
      }
      this.redirectToDashboard(this.plan);
    },

    getExistingCRLineItem(orgLineItem) {
      if (isBlank(orgLineItem) || isBlank(orgLineItem.crLineItems)) {
        return new LineItem();
      }
      const crLineItem = orgLineItem.crLineItems.find((crl) =>
        CR_LINEITEM_STATUS.includes(crl.statusId)
      );
      return crLineItem || new LineItem();
    },
    getExistingCRPlan(orgPlan) {
      if (isBlank(orgPlan.crPlans)) {
        return new Plan();
      }
      const crPlan = orgPlan.crPlans.find((p) =>
        [IOS_CHANGE_NEW, IOS_CHANGE_READY_FOR_ACTIVATION].includes(p.statusId)
      );
      return crPlan ? crPlan.inertClone() : new Plan();
    },

    getChangedLineItems() {
      const changedlineItems = this.plan.lineItems;
      const crLineItems = [];
      if (isBlank(this.orgLineItems) || isBlank(changedlineItems)) {
        return undefined;
      }
      const updatedOrgLineItem = [];
      changedlineItems.forEach((changeLineItem) => {
        const orgLineItem = this.orgLineItems.find((l) => l.id === changeLineItem.id);
        const diffLineItem = diff(orgLineItem, changeLineItem, { outputNewOnly: true });
        let isCR = false;
        if (!isBlank(orgLineItem) && diffLineItem) {
          const crLineItemC = this.getExistingCRLineItem(orgLineItem);
          const crLineItem = crLineItemC.inertClone();
          const exculdedKey = [
            'statusId',
            'updatedLineItem',
            'parentId',
            'type',
            'mediaSpend',
            'validations',
            'isValid',
            'crLineItems',
            'budgetGroup',
            'budgetGroupId',
          ];
          const changedKeys = Object.keys(diffLineItem);
          changedKeys.forEach((k) => {
            if (
              !exculdedKey.includes(k) &&
              (crLineItem[k] !== undefined || changeLineItem[k] !== undefined)
            ) {
              if (!isCR) {
                isCR = true;
              }
              crLineItem[k] = changeLineItem[k];
            }
          });
          crLineItem.type = LIT_CHANGE;
          if (isBlank(crLineItem.id)) {
            crLineItem.statusId = LIS_CHANGE_PENDING_ACTIVATION;
          }
          crLineItem.parentId = orgLineItem.id;
          crLineItem.planId = orgLineItem.planId;
          crLineItem.productTypes = [];
          crLineItem.crLineItems = [];
          crLineItem.flip = true;
          crLineItem.incrementality = true;
          const orgLineStatusId = orgLineItem.statusId;
          const createCR = LI_STATUS_FOR_CR_ENABLED.includes(orgLineStatusId);
          if (isCR) {
            if (createCR) {
              crLineItem.workflowId = LIW_PENDING_AM;
              crLineItems.push(crLineItem);
            } else {
              // Update Existing line item.
              const mergeLine = deepcloneHelpers.mergeLineItem(orgLineItem, crLineItem);
              if (orgLineItem.statusId === LIS_PENDING_ACTIVATION) {
                mergeLine.workflowId = LIW_PENDING_AM;
              }
              updatedOrgLineItem.push(mergeLine);
            }
          }
        }
      });
      return { crLineItems, updatedOrgLineItem };
    },
    getCRPlan() {
      const orgPlan = this.plans.find((p) => p.id === this.plan.parentId);
      const diffPlan = diff(orgPlan, this.plan, { outputNewOnly: true });
      let isCR = false;
      if (diffPlan) {
        diffPlan.lineItems = [];
        const crPlan = this.getExistingCRPlan(orgPlan);
        const changedKeys = Object.keys(diffPlan);
        const exculdedKey = [
          'updatePlan',
          '_uuid',
          'statusId',
          'state',
          'crPlans',
          'spend',
          'pace',
          'validations',
          'isValid',
          'goalMetric',
          'lineItems',
          'parentId',
        ];

        changedKeys.forEach((k) => {
          if (!exculdedKey.includes(k)) {
            if (!isCR) {
              isCR = true;
            }
            crPlan[k] = this.plan[k];
          }
        });
        crPlan.state = 'CHANGE';
        if (isBlank(crPlan.id)) {
          crPlan.statusId = IOS_CHANGE_NEW;
        }
        crPlan.parentId = this.plan.id;
        crPlan.advertiserId = this.plan.advertiserId;
        crPlan.accountId = this.plan.accountId;
        crPlan.crPlans = [];
        return isCR ? crPlan : null;
      }
      return null;
    },
    handleSaveDraftBtn() {
      this.isSaveDraftDisabled = true;
      this.updatePlan = false;
      const impressionCount = ioHelpers.calculateImpressionCount(
        this.plan,
        this.mediaPublisherCPMData
      );
      const lucidViable = ioHelpers.isLucidViable(impressionCount);
      if (lucidViable && ioHelpers.isLucidFormDataProvided(this.lucidCampaignInfo) !== 'no') {
        const errorMsg = this.validateLucidData(impressionCount);
        if (!isBlank(errorMsg)) {
          eBus.$emit('error', errorMsg);
          return;
        }
      }
      eBus.$emit('error', undefined);
      const updates = { statusId: LIS_DRAFT };
      if (lucidViable && ioHelpers.isLucidFormDataProvided(this.lucidCampaignInfo) !== 'no') {
        updates.lucid = true;
      }
      this.plan.lineItems.forEach((l) => {
        // Tweak the values (start date, end date and clickThroughURL) of Creatives for each lineItem
        const updatedLineItem = this.updateCreatives(l);
        Object.assign(updatedLineItem, updates);
        this.$store.dispatch('common/updateLineItem', {
          _uuid: l._uuid,
          lineItem: updatedLineItem,
        });
      });
      this.$store.dispatch('common/updatePlan', {
        plan: { ...this.plan, parentId: null, statusId: IOS_DRAFT, state: 'IO' },
      });
      const planToUpdate = { ...this.plan, parentId: null, statusId: IOS_DRAFT, state: 'IO' };
      this.saveOrUpdatePlan(planToUpdate, this.saveDraftCallback);
    },
    saveDraftCallback(updatedPlan) {
      this.isSaveDraftDisabled = false;
      this.updatePlan = true;
      this.plan.id = updatedPlan.id;
      this.plan.source = updatedPlan.source;
    },
    handleSubmitBtn() {
      this.hideSaveChanges = true;
      const impressionCount = ioHelpers.calculateImpressionCount(
        this.plan,
        this.mediaPublisherCPMData
      );
      const lucidViable = ioHelpers.isLucidViable(impressionCount);
      this.expectedImpressionCount = impressionCount;
      this.lucidCampaignViable = lucidViable;
      const updates = { statusId: LIS_NEW, workflowId: LIW_NEW_AM };
      if (lucidViable && ioHelpers.isLucidFormDataProvided(this.lucidCampaignInfo) !== 'no') {
        updates.lucid = true;
      }
      this.plan.lineItems.forEach((l) => {
        // Tweak the values (start date, end date and clickThroughURL) of Creatives for each lineItem
        const updatedLineItem = this.updateCreatives(l);
        Object.assign(updatedLineItem, updates);
        this.$store.dispatch('common/updateLineItem', {
          _uuid: l._uuid,
          lineItem: updatedLineItem,
        });
      });
      this.$store.dispatch('common/updatePlan', {
        plan: { ...this.plan, statusId: IOS_NEW, parentId: null, state: 'IO' },
      });
      const planToUpdate = { ...this.plan, statusId: IOS_NEW, parentId: null, state: 'IO' };
      this.saveOrUpdatePlan(planToUpdate, this.submitCallback);
    },
    updateCreatives(lineItem) {
      const { flightStartDate } = lineItem;
      const { flightEndDate } = lineItem;
      const { creatives } = lineItem;

      creatives.forEach((c) => {
        c.creativeEndDate = flightEndDate;
        c.creativeStartDate = flightStartDate;
        if (this.advertiser.selfServe) {
          c.clickThroughURL = this.advertiser.domainName;
        }
      });
      return lineItem;
    },
    submitCallback(updatedPlan) {
      this.hideSaveChanges = false;
      if (
        this.lucidCampaignViable &&
        ioHelpers.isLucidFormDataProvided(this.lucidCampaignInfo) !== 'no'
      ) {
        this.createAndLaunchLucidCampaign(this.plan, this.account, this.expectedImpressionCount);
      }
      const notes = `There is a new campaign submission - Campaign ID: ${updatedPlan.id} , see attached csv for details`;
      this.sendEmailNotification(updatedPlan, notes);
      this.redirectToDashboard(updatedPlan);
    },
    redirectToDashboard(updatedPlan) {
      // Take user to campaign list screen and show submitted campaign as expanded
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.CAMPAIGNS);
      eBus.$emit('on-expand-plan', updatedPlan);
    },
    nextPageName(currentPage) {
      const tso = this.totalSelectableOptions;
      const spi = this.selectedPageIndex;

      const campaignPageNames = CAMPAIGN_NAV_BAR_OPTIONS.map((o) => o.pageName);
      const adGroupPageNames = AD_GROUP_NAV_BAR_OPTIONS.map((o) => o.pageName);
      const isCampaignPage = campaignPageNames.includes(currentPage);
      const isAdGroupPage = adGroupPageNames.includes(currentPage);
      let retVal = { index: 1, nextPageName: CAMPAIGN_NAV_BAR_OPTIONS[0].pageName };

      if (!isCampaignPage && !isAdGroupPage) {
        return retVal;
      }

      if (isCampaignPage) {
        const currentOptionIndex = CAMPAIGN_NAV_BAR_OPTIONS.findIndex(
          (o) => o.pageName === this.selectedPage
        );
        if (currentOptionIndex < 0) {
          return retVal;
        }

        // If current option within the campaign block is BS&V move to ad groups
        if (currentOptionIndex === CAMPAIGN_NAV_BAR_OPTIONS.length - 2) {
          if (tso === CAMPAIGN_NAV_BAR_OPTIONS.length + 1) {
            retVal = { index: this.totalSelectableOptions, nextPageName: REVIEW_CAMPAIGN };
          } else {
            retVal = { index: spi + 2, nextPageName: AD_GROUP_NAV_BAR_OPTIONS[0].pageName };
          }
        } else if (currentOptionIndex === CAMPAIGN_NAV_BAR_OPTIONS.length - 1) {
          retVal = { index: tso, nextPageName: REVIEW_CAMPAIGN };
        } else {
          retVal = {
            index: spi + 1,
            nextPageName: CAMPAIGN_NAV_BAR_OPTIONS[currentOptionIndex + 1].pageName,
          };
        }
      }

      if (isAdGroupPage) {
        const currentOptionIndex = AD_GROUP_NAV_BAR_OPTIONS.findIndex(
          (o) => o.pageName === this.selectedPage
        );
        if (currentOptionIndex < 0) {
          return retVal;
        }
        // If current page = last option in AD_GROUP_NAV_BAR_OPTIONS
        if (currentOptionIndex === AD_GROUP_NAV_BAR_OPTIONS.length - 1) {
          if (spi === tso - 1) {
            const impressionCount = ioHelpers.calculateImpressionCount(
              this.plan,
              this.mediaPublisherCPMData
            );
            const lucidViable = ioHelpers.isLucidViable(impressionCount);

            if (lucidViable) {
              retVal = { index: 3, nextPageName: CAMPAIGN_NAV_BAR_OPTIONS[2].pageName };
            } else {
              retVal = { index: tso, nextPageName: REVIEW_CAMPAIGN };
            }
          } else {
            retVal = { index: spi + 1, nextPageName: AD_GROUP_NAV_BAR_OPTIONS[0].pageName };
          }
        } else {
          retVal = {
            index: spi + 1,
            nextPageName: AD_GROUP_NAV_BAR_OPTIONS[currentOptionIndex + 1].pageName,
          };
        }
      }
      return retVal;
    },
    handleNextBtn() {
      const retVal = this.nextPageName(
        this.selectedPage,
        this.selectedPageIndex,
        this.totalSelectableOptions
      );
      const pageName = retVal.nextPageName;
      const nextPageIndex = retVal.index;
      const currLineItemIndex = this.lineItemIndexArray[nextPageIndex - 1];
      this.visitedIndexes.push({ name: pageName, pIndex: nextPageIndex, li: currLineItemIndex });
      this.$emit('pageSelected', pageName, nextPageIndex, currLineItemIndex);
    },
    handleBackBtn() {
      const temp = this.visitedIndexes;
      if (temp.length >= 1) {
        temp.pop();
        const goTo = temp[temp.length - 1];
        this.$emit('pageSelected', goTo.name, goTo.pIndex, goTo.li);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ss-footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 7px 50px;
}
.save-btn {
  display: flex;
}
.mvc-btn {
  display: inline-block;
  min-width: 100px;
  padding: 10px 18px;
  font-size: 14px;
  font-weight: 500;
  color: var(--selfserveovertext);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #fdce07;
  border-radius: 3px;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.disabled {
    background-color: #555 !important;
    pointer-events: none;
    cursor: not-allowed;
    color: black !important;
  }
  &:hover {
    color: #fff;
    background-color: #555;
  }
}
.btn-teal {
  color: var(--selfserveovertext);
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
}
.btn-outline {
  color: #555 !important;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  &:hover {
    color: #fff !important;
    background-color: #555;
  }
}
.no-show {
  visibility: hidden !important;
}
</style>
