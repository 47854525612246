<template>
  <b-hover-options-dropdown
    label="Select DMAs"
    :options="options"
    :fancy="true"
    :multiple="true"
    label-key="value"
    :value="selectedValue"
    :class-name="'dma-form'"
    :readonly="readonly"
    @input="onInput"
    @validation="onValidation"
  />
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import { inert } from 'adready-api/helpers/common';
import TargetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import BHoverOptionsDropdown from '~/components/elements/b-hover-options-dropdown.vue';
import { targetingSelectMixin } from '~/components/mixins/targeting-mixin';
import { TAR_DMA } from '~/constant';

export default {
  name: 'DMAForm',
  components: { BHoverOptionsDropdown },

  mixins: [
    lineItemMixin,
    TargetableElementOptionsMixin,
    validationsMixin.events,
    targetingSelectMixin,
  ],
  props: {
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      targetingType: 'geoTargeting',
      targetableElementId: TAR_DMA,
      selectedTargeting: null,
    };
  },
  computed: {
    selectedValue() {
      if (this.selectedTargeting?.value) {
        return this.selectedTargeting.value;
      }
      return null;
    },
  },
  beforeMount() {
    const selectedTargeting = this.targetings.find(
      (tp) => tp.targetableElementId === this.targetableElementId
    );
    if (selectedTargeting) {
      this.selectedTargeting = inert(selectedTargeting);
    } else {
      this.selectedTargeting = this.createSelectedTargeting(this.targetableElementId);
    }
  },
  methods: {
    // events
    onInput(val) {
      this.updateLineItemTargeting('geoTargeting', val);
    },
  },
};
</script>
