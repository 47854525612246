<template>
  <div :class="` ${readonly ? 'readonly-bp' : ''}`">
    <div class="flip-title" :class="goalBrandLift ? 'disabled' : ''">Conversion Window</div>
    <div class="bl-options conversion-input" :class="goalBrandLift ? 'disabled' : ''">
      <b-number-input
        :required="true"
        :value="activeConversion.value"
        :readonly="readonly"
        type="number"
        @change="onInput"
        @validation="onValidation"
        @focusout="(val) => checkConversionError(val, 'conversionWindow')"
      />
      <a
        v-for="(unit, index) in units"
        :key="unit.id"
        class="bl-btn"
        :class="[
          `${unit.id === activeConversion.unit.id ? 'bl-btn-selected' : ''}`,
          `${index === 0 ? 'lend' : ''} ${index === units.length - 1 ? 'rend' : ''}`,
        ]"
        @click="onSelectPeriod(unit)"
      >
        <font-awesome-icon class="bl-icon" :icon="['fas', 'check']" size="1x" />
        <div class="bl-text">{{ unit.value }}</div>
      </a>
    </div>
    <b-error-msg v-if="errorObj.conversionWindow" :error-msg="errorObj.conversionWindow" />
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get, sync } from 'vuex-pathify';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import bErrorMsg from '~/components/elements/b-error-msg.vue';
import BNumberInput from '~/components/elements/b-number-input.vue';

export default {
  name: 'BPlanFlipCampaignConversion',
  components: { bErrorMsg, BNumberInput },
  mixins: [validationsMixin.events],
  props: {
    errorObj: { required: false, type: Object, default: () => {} },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      units: [
        {
          id: 1,
          value: 'days',
        },
        {
          id: 2,
          value: 'hours',
        },
      ],
    };
  },
  computed: {
    account: get('common/account'),
    plan: get('common/plan'),
    flipCampaignInfo: get('common/plan@flipCampaignInfo'),
    flipCampaignInfoGoal: get('common/plan@flipCampaignInfo.goal'),
    flipCampaignInfoConversionWindow: sync('common/plan@flipCampaignInfo.conversionWindow'),

    goalBrandLift() {
      if (!isBlank(this.flipCampaignInfoGoal)) {
        return this.flipCampaignInfoGoal.category === 'brandlift';
      }
      return false;
    },

    activeConversion() {
      if (!isBlank(this.flipCampaignInfo) && !isBlank(this.flipCampaignInfo.conversionWindow)) {
        return this.flipCampaignInfo.conversionWindow;
      }
      return { value: null, unit: { id: '', value: '' } };
    },
  },
  methods: {
    onInput(val) {
      this.checkConversionError(val, 'conversionWindow');
      this.flipCampaignInfoConversionWindow = {
        ...this.flipCampaignInfoConversionWindow,
        value: val,
      };
    },
    onSelectPeriod(val) {
      this.flipCampaignInfoConversionWindow = {
        ...this.flipCampaignInfoConversionWindow,
        unit: val,
      };
    },
    checkConversionError(val, fieldName) {
      this.$emit('checkForError', val, fieldName);
    },
  },
};
</script>

<style lang="scss" scoped>
.bl-options {
  display: flex;
  .bl-icon {
    display: none;
  }
  .bl-text {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 5px;
  }
  .bl-btn {
    color: #7f7f7f !important;
    border: 1px solid #b3b3b3 !important;
    padding: 9px 12px !important;
    // margin: 10px 0px;
    text-align: center;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    height: 40px;
    border-right-width: 0.5px !important;
    border-left-width: 0.5px !important;
    &.lend {
      border-left-width: 1px !important;
      border-radius: 3px 0px 0px 3px;
    }
    &.rend {
      border-right-width: 1px !important;
      border-radius: 0px 3px 3px 0px;
    }
    &:hover {
      background-color: #eee !important;
      color: #222 !important;
    }
    &.bl-btn-selected:hover {
      background-color: var(--selfserveprimarycolor) !important;
      color: #fff !important;
    }
  }
  .bl-btn-selected {
    background-color: var(--selfserveprimarycolor) !important;
    color: #fff !important;
    border: 1px solid var(--selfserveprimarycolor) !important;
    .bl-icon {
      display: block;
      color: #fff !important;
      transform: scale(0.8);
      margin-right: 5px;
    }
    &:hover {
      background-color: #fff;
    }
  }
  .form-input {
    margin-right: 6px !important;
    width: 53px;
    height: 40px;
  }
}
.flip-title {
  margin-bottom: 26px;
  color: #444;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.02em;
}
.disabled {
  pointer-events: none;
  opacity: 0.25;
}
::v-deep .fa-info-circle {
  transform: scale(0.8);
  &:hover {
    color: #fff !important;
  }
}
::v-deep .bl-options.conversion-input .form-input .form-input-field {
  height: 40px;
}
.readonly-bp {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.25;
}
</style>
