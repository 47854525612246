<template>
  <div class="col-lg-12 cntx-form-container">
    <b-hover-options-dropdown
      name="Publishers"
      label="Select Categories"
      :required="true"
      :options="options"
      :multiple="true"
      :fancy="true"
      label-key="value"
      :value="selectedTargeting.value"
      :class-name="'contextual-cont'"
      :readonly="readonly"
      @input="onInput"
    />
  </div>
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import { TAR_CONTEXTUAL_TARGETING } from 'adready-api/constant';
import { inert } from 'adready-api/helpers/common';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import { targetingSelectMixin } from '~/components/mixins/targeting-mixin';
import TargetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import bHoverOptionsDropdown from '~/components/elements/b-hover-options-dropdown.vue';

export default {
  name: 'ContextualTargetingForm',
  components: { bHoverOptionsDropdown },
  mixins: [
    lineItemMixin,
    TargetableElementOptionsMixin,
    targetingSelectMixin,
    validationsMixin.events,
  ],
  props: {
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      targetingType: 'publisherTargeting',
      targetingTypeName: 'Contextual Targeting',
      targetableElementId: TAR_CONTEXTUAL_TARGETING,
      selectedTargeting: null,
    };
  },

  beforeMount() {
    const selectedTargeting = this.targetings.find(
      (tp) => tp.targetableElementId === this.targetableElementId
    );
    if (selectedTargeting) {
      this.selectedTargeting = inert(selectedTargeting);
    } else {
      this.selectedTargeting = this.createSelectedTargeting(this.targetableElementId);
    }
  },
  methods: {
    onInput(val) {
      this.updateLineItemTargeting('publisherTargeting', val);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #fff;
}
::v-deep .form-input-wrap {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #cccccc;
  min-height: 20px;
  border-radius: unset;
  position: relative;
}

::v-deep .form-input .form-input-field.v-select.multiple-input-field .vs__selected-options {
  padding: 10px;
}

::v-deep .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  background: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  font-weight: 300;
  font-size: 13px;
  font-family: 'Manrope', sans-serif;
  padding: 3px 7px;
  margin: 0px 4px 1px 10px;
  border-radius: 2px;
  display: inline-block;
  border: unset;
  opacity: 1;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: rgba(255, 255, 255, 0.8);
}
</style>
<style>
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--selected {
  display: none;
  background-color: #d9d8d8;
  color: #525b65;
}
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
  color: #525b65;
  background-color: #d9d8d8;
}
body .vs__dropdown-menu .vs__dropdown-option:hover {
  background: #d9d8d8;
}

body .vs__dropdown-hover:active {
  background: #717171 !important;
  box-shadow: inset 0 0 10px #000000 !important;
}
body .vs__dropdown-hover span:active {
  color: white;
}
.cntx-form-container {
  padding-left: 0;
  padding-right: 0;
}
</style>
