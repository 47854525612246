import { render, staticRenderFns } from "./CampaignDashboard.vue?vue&type=template&id=bb2dbf2a&scoped=true&"
import script from "./CampaignDashboard.vue?vue&type=script&lang=js&"
export * from "./CampaignDashboard.vue?vue&type=script&lang=js&"
import style0 from "./CampaignDashboard.vue?vue&type=style&index=0&id=bb2dbf2a&lang=scss&scoped=true&"
import style1 from "./CampaignDashboard.vue?vue&type=style&index=1&id=bb2dbf2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb2dbf2a",
  null
  
)

export default component.exports