<template>
  <b-modal
    :hide-footer="true"
    :enable-click-out-side="true"
    :is-self-serve-modal="true"
    @close="onClickCancel"
  >
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="cdp-top">
          <div class="ss-mdrop-itm mitm1">
            <span class="optionText">
              <img :src="infoIconModalPayload.logo || DEFAULT_PUBLISHER_LOGO" class="optionLogo" />
            </span>
            <div class="optionText">
              <div class="line1">
                {{ infoIconModalPayload.text }}
              </div>
              <div class="line2">{{ infoIconModalPayload.category }}</div>
            </div>
          </div>
          <div class="header-close">
            <font-awesome-icon :icon="['far', 'times']" @click="onClickCancel" />
          </div>
        </div>
        <div id="publisher-info-loader" class="publisher-info-loader-css">
          <b-loading-spinner class="txt-center" />
        </div>
        <iframe
          class="info-modal-iframe"
          :src="infoIconModalPayload.infoURL"
          onload="document.getElementById('publisher-info-loader').style.display='none';"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import BLoadingSpinner from '~/components/elements/b-loading-spinner.vue';

export default {
  name: 'PublisherInfoModal',
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    BLoadingSpinner,
  },
  props: {
    infoIconModalPayload: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      DEFAULT_PUBLISHER_LOGO:
        'https://storage.googleapis.com/flip-assets/publisher_logos/color/otherhub_80x80.png',
    };
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    onClickRemove(ev) {
      this.$emit('remove', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-modal-iframe {
  width: 100%;
  overflow: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
}
::v-deep .modal-wrapper-box {
  padding: unset !important;
}
::v-deep .modal-container {
  width: 780px !important;
  padding: unset !important;
  min-height: 490px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}
::v-deep .modal-header.row.end-xs {
  display: none;
}

.cdp-top {
  position: sticky;
  min-height: 68px;
  border-bottom: 1px solid #e9eaeb;
}
.publisher-info-loader-css {
  margin: 200px auto;
}
.ss-mdrop-itm {
  display: inline-block;
  padding: 5px 25px 5px 10px;
  border: 0px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  background-color: #fff;
  width: auto;
  margin: 10px 2px 0px 16px;
  color: #444;
  position: relative;
  cursor: pointer;
}
.optionText {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.optionLogo {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}
.line1 {
  font-size: 18px;
  color: #444;
  display: flex;
  align-items: center;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  .info-icon {
    margin-left: 5px;
    color: #7f7f7f;
  }
}
.line2 {
  font-size: 12px;
  color: #7a8596;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  height: 20px;
  bottom: -2px;
  position: relative;
}
.header-close {
  position: absolute;
  right: 28px;
  top: 25px;
  cursor: pointer;
  color: #b8c2cc;
  // svg {
  //   width: 0.5em;
  // }
  &:hover {
    color: var(--adminprimarycolor);
  }
}
.mr-20 {
  margin-right: 20px;
}
</style>
