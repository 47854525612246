import { render, staticRenderFns } from "./b-plan-flip-campaign-goal.vue?vue&type=template&id=21a58814&scoped=true&"
import script from "./b-plan-flip-campaign-goal.vue?vue&type=script&lang=js&"
export * from "./b-plan-flip-campaign-goal.vue?vue&type=script&lang=js&"
import style0 from "./b-plan-flip-campaign-goal.vue?vue&type=style&index=0&id=21a58814&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a58814",
  null
  
)

export default component.exports