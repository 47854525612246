import _ from 'underscore';
import { get, sync } from 'vuex-pathify';

import { ValidationError } from 'adready-api/model/errors';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import { LineItem } from 'adready-api/model/line-item';
import {
  LIT_CHANGE,
  LIS_DRAFT,
  LIS_CHANGE_DRAFT,
  LIS_CHANGE_NEW,
  IO_STATE_CHANGE,
  LI_MNI_CLIENT_EDITABLE_STATUS,
  LI_STATUS_FOR_CR_ENABLED,
  LIS_NEW,
  LIW_NEW_CHANGE_AM,
  LIS_PENDING_ACTIVATION,
  LIW_PENDING_AM,
} from 'adready-api/constant';
import lineItemApi from 'adready-api/api/line-items';
import { YOUTUBE_PRODUCT_TYPES, SOCIAL_AUDIO_PRODUCT_TYPES } from '~/constant';

import store from '~/store';
import authzMixin from './authz-mixin';
import tabIndexMixin, { tabOrderHelpers } from './tab-index-mixin';
// import accountMixin from '~/components/mixins/account-mixin';
// import { ALL_PARTNER_ACCOUNT_ID } from '~/helpers/io/account-helpers';
import forklift from '~/components/mixins/forklift-mixin';
// import { LIS_CHANGE_CANCELLED, LIS_CHANGE_REQUEST_CANCEL } from '~/fsm/CrLIStatusMachine';

const props = {
  expanded: {
    required: false,
    type: Boolean,
    default: false,
  },
  lineItem: {
    required: true,
    type: Object,
  },
  plan: {
    required: true,
    type: Object,
  },
  ...tabIndexMixin.props,
};

const computed = {
  lineItems: sync('io/plan@lineItems'),
  // FIXME: change var name to isPlanActive (more descriptive)
  show: get('io/plan@show'),
  targetableElementCache: get('io/targetableElementCache'),

  ...tabOrderHelpers.computed,
  // ...accountMixin.computed,

  currentAccountId() {
    // if (this.cAccountId && this.cAccountId !== ALL_PARTNER_ACCOUNT_ID) {
    //   return this.cAccountId;
    // }
    if (this.isChangeRequest && this.parentPlan) {
      return this.parentPlan.accountId;
    }
    if (this.plan) {
      return this.plan.accountId;
    }
    if (this.internalPlan) {
      return this.internalPlan.accountId;
    }
    return null;
  },

  /**
   * Refers to the plan this line belongs to, whether it is a CR or not.
   */
  internalPlan() {
    if (this.plan) {
      return this.plan;
    }
    return store.getters['io/getPlan'](this.lineItem.planId);
  },

  productCategoryId() {
    return this.getValue('productCategoryId');
  },

  /**
   * Return the first productTypeId from the list of selected productTypes.
   *
   * Note: this assumes that multiselect == false
   *
   * @param {*} lineItem
   *
   */
  productTypeId() {
    // get productTypeId(s) from parent line item, if needed
    const productTypes = this.getValue('productTypes');
    return isBlank(productTypes) ? null : productTypes[0].productTypeId;
  },

  /**
   * Read Change Request Plan from this line item's Plan.
   *
   * This is an alternative to `this.parentPlan`, before a CR is created for a
   * line item. This allows us to locate the `crPlan` and add a new `crLineItem`
   * to it.
   */
  crPlan() {
    if (this.plan && !isBlank(this.plan.crPlans)) {
      if (this.plan.crPlans[0].state === IO_STATE_CHANGE) {
        return this.plan.crPlans[0];
      }
    }
    return null;
  },

  /**
   * Open Change Request Line Item, if there is one.
   *
   * @return {LineItem}
   */
  crLineItem() {
    if (!isBlank(this.lineItem.crLineItems)) {
      if (this.lineItem.crLineItems[0].type === LIT_CHANGE) {
        return this.lineItem.crLineItems[0];
      }
    }
    return null;
  },

  /**
   * If this line item is a CR, retrieves the parent Plan object.
   */
  parentPlan() {
    if (!this.lineItem.parentId) {
      return undefined;
    }

    return this.$store.getters['io/getPlan'](this.lineItem.planId);
  },

  /**
   * If this line item is a CR, retrieves the parent Line Item object from the parent Plan.
   */
  parentLineItem() {
    if (this.isChangeRequest) {
      if (!isBlank(this.lineItem._parent)) {
        return this.lineItem._parent;
      }
      if (this.lineItem.parentId && this.plan) {
        return this.plan.lineItems.find((lineItem) => lineItem.id === this.lineItem.parentId);
      }
    }
    return undefined;
  },

  /**
   * Returns true if this LineItem is a Change Request (child in the pair)
   */
  isChangeRequest() {
    return this.lineItem.type === 'CHANGE';
  },

  /**
   * Returns true if this is a CR representing the addition of a new line to the order.
   */
  isNewLineChangeRequest() {
    return (
      this.isChangeRequest && this.parentLineItem && this.parentLineItem.statusId === LIS_DRAFT
    );
  },

  /**
   * Returns true if this CR is an existing lineItem modification change request.
   */
  isLineChangeRequest() {
    return this.isChangeRequest && !this.isNewLineChangeRequest;
  },

  /**
   * Returns true if this LineItem is the parent half of a Change Request pair
   */
  // isChangeRequestParent() {
  //   return (
  //     this.crLineItem &&
  //     !_.contains([LIS_CHANGE_APPLIED, LIS_CHANGE_CANCELLED], this.crLineItem.statusId)
  //   );
  // },

  /**
   * Returns true if this LineItem is the parent half of a newly added LineItem Change Request pair
   */
  isNewLineChangeRequestParent() {
    return this.isChangeRequestParent && this.lineItem.statusId === LIS_DRAFT;
  },

  /**
   * Returns true if this LineItem is either the parent or child in a Change Request pair
   */
  isPartOfChangeRequest() {
    // True if either has a child CR or this line is the CR.
    return this.isChangeRequestParent || this.isChangeRequest;
  },

  // need authz for readonly
  ...authzMixin.computed,

  isLineItemReadOnly() {
    if (this.lineItem._isStatusChanged || this.isExternalMni) {
      return true;
    }
    // if (this.lineItem.statusId === LIS_CHANGE_REQUEST_CANCEL) {
    //   return true;
    // }

    if (
      !this.isClientFacing &&
      this.lineItem.statusId === LIS_PENDING_ACTIVATION &&
      this.lineItem.workflowId === LIW_PENDING_AM
    ) {
      return false;
    }

    if (!this.isMni) {
      return this.lineItem.statusId !== LIS_NEW && !this.isChangeRequest;
    }

    return (
      this.isMni &&
      !this.isAccountManager &&
      (this.isNewLineChangeRequestParent ||
        !LI_MNI_CLIENT_EDITABLE_STATUS.includes(this.lineItem.statusId)) &&
      this.lineItem.statusId !== LIS_CHANGE_DRAFT
    );
  },
  productModal() {
    return YOUTUBE_PRODUCT_TYPES.includes(this.productTypeId);
  },
  socialAudioProductModal() {
    return SOCIAL_AUDIO_PRODUCT_TYPES.includes(this.productTypeId);
  },
  // This prop is used to hide the error tool tip for disabled line(new line change request)
  showErrorToolTip() {
    return !(this.isLineItemReadOnly && this.isChangeRequestParent);
  },
  /**
   *
   * Create CR button enabled for MNI Users and order should have its changeRequst order
   * and changed request order's status should be CHANGE_DRAFT
   * and current LineItem don't have CR
   * and Current LineItem should not be type CR.
   * and Current LineItem should have either of status READY_FOR_ACTIVATION, ACTIVATED, CAMPAIGN_LIVE, PAUSED
   */
  isCRButtonEnabled() {
    const crAllowed = _.contains(LI_STATUS_FOR_CR_ENABLED, this.lineItem.statusId);
    return (
      crAllowed &&
      !this.isChangeRequestParent &&
      this.lineItem.type !== LIT_CHANGE &&
      this.tabType === 'io'
    );
  },
};

const methods = {
  ...forklift.methods,

  setSelected(val) {
    const updates = {
      _selected: val.checked,
      updatedLineItem: false,
    };
    this.updateLineItem(updates);
  },

  /**
   * Get selected targeting
   *
   * @param {Number} id targetable element ID
   * @return {LineItemTargeting} or undefined if not present
   */
  getSelectedTargeting(id) {
    return this.lineItem.targetings.find((tp) => tp.targetableElementId === id);
  },

  /**
   * Returns TRUE if the line item (or its parent) has the given product type ID
   *
   * @param {Number} needleId productTypeId to test for
   * @returns {Boolean}
   */
  hasProductTypeId(needleId) {
    if (this.isChangeRequest && this.parentLineItem && isBlank(this.lineItem.productTypes)) {
      return this.parentLineItem.hasProductTypeId(needleId);
    }
    return this.lineItem.hasProductTypeId(needleId);
  },

  /**
   * Update line item CPM based on currently selected values
   *
   * Not currently used. This was previously used to display the floating CPM
   * indicator in the "IO Builder" tool. It may get used again for MNI since we
   * only show the impression count for them, and nothing else.
   */
  updateLineItemCpm() {
    lineItemApi.calculateCpm(this.insertionOrderId, this.lineItem).then((lineItem) => {
      this.$store.dispatch('io/setCpmValue', lineItem);
    });
  },

  /**
   * Update current line item in the store
   *
   * @param {Object} updates Changed fields in the line item object
   * @param {String} uuid UUID of line item to update (optional, defaults to this.lineItem._uuid)
   */
  updateLineItem(updates, uuid) {
    const _uuid = uuid || this.lineItem._uuid;
    this.$store.dispatch('common/updateLineItem', { _uuid, lineItem: updates });
  },

  /**
   * Get the value of the given field from either the lineitem or it's parent,
   * whichever is available.
   *
   * If the value is either `null` or `defaultVal`, fall back to parent value.
   *
   * @param {String} field name
   * @param {Object} defaultVal other than null (i.e., 0)
   */
  getValue(field, defaultVal) {
    const v = this.lineItem[field];
    if (v !== null && ((Array.isArray(v) && !isBlank(v)) || v !== defaultVal)) {
      if (Array.isArray(v)) {
        if (!isBlank(v)) {
          return v;
        }
      } else if (v !== defaultVal) {
        return v;
      }
    }
    if (this.parentLineItem) {
      return this.parentLineItem[field];
    }
    return this.lineItem[field];
  },

  // events

  /**
   * Create LineItem Change Request from this line
   *
   * Called from line-item-row or line-item-status-select.
   */
  onCreateChangeRequest(plan) {
    // Create CR line
    const newLineItem = new LineItem({
      planId: plan.id,
      updatedLineItem: true,
      parentId: this.lineItem.id,
      statusId: LIS_CHANGE_DRAFT,
      workflowId: LIW_NEW_CHANGE_AM,
      _isWorkflowChanged: true,
      createdBy: this.currentUserId,
      type: LIT_CHANGE,
      flagId: commonHelper.getFlagOptionId(LIS_CHANGE_DRAFT, this.flagOptions),
    });
    if (!this.isClientFacing) {
      newLineItem.statusId = LIS_CHANGE_NEW;
    }

    // Add the new CR LineItem adjacent to its Parent.
    const { lineItems } = plan;
    const parentLineIdx = lineItems.findIndex((li) => li.id === this.lineItem.id);
    const content = [];
    for (let i = 0; i < lineItems.length; i++) {
      content.push(lineItems[i]);
      if (i === parentLineIdx) {
        // Push the CR lineItem to the list.
        content.push(newLineItem);
      }
    }

    // Update the plan & original line item
    this.$store.dispatch('io/setLineItems', {
      _uuid: plan._uuid,
      lineItems: { content },
    });

    this.updateLineItem({
      crLineItems: [newLineItem],
      updatedLineItem: false,
    });
  },

  onCrValidation(errors, key, deferErrors) {
    // For new line change request the lineItem validations should be applicable.
    if (!this.isLineChangeRequest) {
      this.onValidation(errors, key, deferErrors);
      return;
    }

    // filter out errors which we can safely ignore for change requests
    Object.keys(errors.errs).forEach((errKey) => {
      if (errKey === 'required') {
        // key used by k-select
        delete errors.errs[errKey];
        return;
      }
      const err = errors.errs[errKey];
      if (err instanceof ValidationError && err.validity && err.validity.valueMissing) {
        // remove 'required' field validation error
        delete errors.errs[errKey];
      }
    });

    if (!errors.isEmpty()) {
      // only if something left to do
      this.onValidation(errors, key, deferErrors);
    }
  },
  updateStatus(statusId, updatedLineItem, others) {
    const flagOption = commonHelper.flagOption(statusId, this.flagOptions);
    const flagId = flagOption ? flagOption.id : null;
    const updates = {
      statusId,
      flagId,
      updatedLineItem,
      _isStatusChanged: true,
      ...others,
    };
    this.$store.dispatch('io/updateLineItem', {
      _uuid: this.lineItem._uuid,
      lineItem: updates,
    });
  },
};

export default {
  props,
  computed,
  methods,
};
