<template>
  <div class="dtable">
    <div class="row middle-xs hours">
      <div v-for="hour in hours" :key="hour.value" class="hour">
        {{ hour.text }}
        <span v-if="hour.subText" class="sub-text">{{ hour.subText }}</span>
      </div>
    </div>
    <div class="row middle-xs days">
      <div v-for="day in days" :key="day" class="day">{{ day }}</div>
    </div>
    <drag-select-item-container
      selector-class="item"
      :day-parting="dayParting"
      :readonly="readonly"
      @change="handleChangeSelection"
    >
      <template slot-scope="{ selectedItems }">
        <div
          v-for="hour in gridHours"
          :id="`${hour.day}_${hour.value}`"
          :key="`${hour.day}_${hour.value}`"
          :class="getClasses(`${hour.day}_${hour.value}`, selectedItems)"
          @click="(el) => handleClickItem(el, selectedItems)"
        ></div>
      </template>
    </drag-select-item-container>
  </div>
</template>

<script>
import DragSelectItemContainer from '~/components/campaigns/targetings/drag-select-item-container.vue';
import { DAY_PARTING_DAYS, DAY_PARTING_HOURS } from '~/constant';

export default {
  name: 'DragSelectDayParting',
  components: {
    DragSelectItemContainer,
  },
  props: {
    onChangeDayParting: {
      required: true,
      type: Function,
    },
    dayParting: {
      required: true,
      type: Object,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      days: DAY_PARTING_DAYS,
      hours: DAY_PARTING_HOURS,
    };
  },
  computed: {
    gridHours() {
      const gh = [];
      for (let i = 0; i < this.days.length; i++) {
        gh.push(
          ...this.hours.map((hour) => {
            return { ...hour, day: this.days[i] };
          })
        );
      }
      return gh;
    },
  },
  methods: {
    handleClickItem(el, selectedItems) {
      const index = selectedItems.findIndex((item) => item.id === el.target.id);
      if (index !== -1) {
        el.target.classList.remove('active');
        selectedItems.splice(index, 1);
      } else {
        el.target.classList.add('active');
        selectedItems.push(el.target);
      }

      return true;
    },
    handleChangeSelection(val) {
      let formattedVal = { enabled: true };
      for (let i = 0; i < val.length; i++) {
        const [day, value] = val[i].id.split('_');

        formattedVal = {
          ...formattedVal,
          [day]: [...(formattedVal[day] ? formattedVal[day] : []), +value],
        };
      }
      this.onChangeDayParting(formattedVal);
    },
    getClasses(item, selectedItems) {
      let isActive = false;
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i].id === item) {
          isActive = true;
        }
      }
      return {
        drag_selector__item: true,
        item: true,
        active: isActive,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/* Basic reset */

/* Custom styling */
::v-deep .dtable .days .day {
  text-align: right;
}
.dtable .hours .hour {
  font-size: 12px;
  text-align: left;
  margin: 0 0px 0px 0;
  position: relative;
  left: -3px;
  min-width: 32px;
}
.dtable .hours .hour .sub-text {
  color: #ccc;
  font-size: 9px;
}
.toggle-btn-cont {
  margin-top: 3px;
}
::v-deep .drag-selector__item {
  background: #f6f6f6;
  width: 32px;
  height: 49px;
}

.dtable {
  cursor: crosshair;
  margin-top: 10px;
  width: 100%;
  display: block;
  max-width: 865px;
  font-size: 13px;
  text-transform: uppercase;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  position: relative;
  > * {
    letter-spacing: 0;
  }

  .hours {
    padding: 0 0 5px;
    padding-left: 44px;

    .hour {
      font-size: 12px;
      text-align: left;
      margin: 0 0px 0px 0;
      position: relative;
      left: -3px;
      min-width: 32px;
      .sub-text {
        color: #ccc;
        font-size: 9px;
      }
    }
  }
  .days {
    padding: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 5px;
    .day {
      margin: 0 10px 0 0;
      /* width: 70px; */

      padding-top: 10px;
      height: 49px;
    }
  }
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.middle-xs {
  align-items: center;
}
.item.active {
  background-color: var(--selfserveprimarycolor);
  border-color: #2b8a9b;
}
.item.hovered {
  background-color: #ccc;
  border-color: #999;
}
.drag_selector__item {
  width: 32px;
  height: 49px;
  border: 0.5px solid #d0d0d0;
  background: #f6f6f6;
}
</style>
