<template>
  <div ref="bDropdownMultiSelect" :class="`ss-mdrop noselect ${showDropdownOptions ? 'open' : ''}`">
    <div class="ss-mdrop-btn" :class="`${classPrefix}-ss-mdrop-btn`" @click="handleOpenDropdown">
      {{ label }}
      <font-awesome-icon
        :icon="['far', showDropdownOptions ? 'chevron-up' : 'chevron-down']"
        class="droparw arrowicon"
      />
    </div>
    <div class="ss-mdrop-content">
      <div
        v-if="!hideSelectAllOption"
        class="ss-mdrop-itm mitm-all"
        :class="`${classPrefix}-mitm-all`"
        @click="handleSelectAllOptions"
      >
        SELECT ALL
        <span v-if="selectAllInfo" class="additionalInfoText">{{ selectAllInfo }}</span>
        <font-awesome-icon :icon="['fal', 'plus']" class="droparw supportIcon plusIcon" />
      </div>
      <div
        v-for="(option, index) in options"
        :key="`${option}_dropDown_${index}`"
        :class="
          `${classPrefix}-ss-mdrop-itm-${index} ss-mdrop-itm mitm1 ${
            isSelectedOption(option)
              ? showDropdownOptions
                ? 'selected'
                : 'selected outsideTag'
              : ''
          }`
        "
        @click="handleOptionClick(option)"
      >
        <span :class="`optionText ${option.additionalInfo ? '' : 'widthMaxContent'}`">{{
          option.text
        }}</span>
        <span v-if="option.additionalInfo" class="additionalInfoText">{{
          option.additionalInfo
        }}</span>
        <font-awesome-icon
          :icon="[
            'fal',
            `${showDropdownOptions ? (isSelectedOption(option) ? 'minus' : 'plus') : 'times'}`,
          ]"
          :class="`droparw supportIcon ${showDropdownOptions ? '' : 'outsideCrossIcon'}`"
        />
      </div>
      <div
        v-if="value.length > 0 && !showDropdownOptions"
        class="ss-mdrop-itm mitm-removeall"
        @click="handleRemoveAllOptions"
      >
        Remove All
      </div>
    </div>
    <div v-if="enableScrollIntoView" :id="`dropdownMultiSelectV2Scroll_${label}`"></div>
  </div>
</template>

<script>
export default {
  name: 'BDropdownMultiselectV2',
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: () => '',
    },
    hideSelectAllOption: {
      type: Boolean,
      required: false,
      deafult: () => false,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectAllInfo: {
      type: String,
      required: false,
      default: () => '',
    },
    enableScrollIntoView: {
      type: Boolean,
      required: false,
      deafult: () => false,
    },
    classPrefix: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      showDropdownOptions: false,
    };
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener('click', this.onClickOutsideEvent);
      window.addEventListener('keydown', this.onClickOutsideEventKey);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutsideEvent);
    window.removeEventListener('keydown', this.onClickOutsideEventKey);
  },
  methods: {
    handleOpenDropdown() {
      if (this.enableScrollIntoView) {
        const el = document.getElementById(`dropdownMultiSelectV2Scroll_${this.label}`);
        if (el) {
          setTimeout(() => {
            el.scrollIntoView({ behavior: 'smooth' });
          }, 20);
        }
      }
      this.showDropdownOptions = !this.showDropdownOptions;
    },
    handleSelectAllOptions() {
      if (this.value.length !== this.options.length) {
        this.$emit('select', this.options);
      }
    },
    handleRemoveAllOptions() {
      this.$emit('select', []);
    },
    handleOptionClick(option) {
      if (this.value.find((val) => option.text === val.text)) {
        this.$emit(
          'select',
          this.value.filter((val) => val.text !== option.text)
        );
      } else {
        this.$emit('select', [...this.value, option]);
      }
    },
    isSelectedOption(option) {
      return this.value.find((val) => option.text === val.text);
    },
    onClickOutsideEvent(event) {
      const el = this.$refs.bDropdownMultiSelect;
      if (el && !(el === event.target || el.contains(event.target))) {
        this.showDropdownOptions = false;
      }
    },
    onClickOutsideEventKey(event) {
      if (event.key === 'Escape') {
        if (!(this.$el === event.target || this.$el.contains(event.target))) {
          this.showDropdownOptions = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ss-mdrop {
  position: relative;
}

.ss-mdrop-btn {
  display: inline-block;
  padding: 11px 20px 10px 17px;
  position: relative;
  border: 1px solid #aaa;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
}
.ss-mdrop-btn:hover {
  border: 1px solid var(--selfserveprimarycolor);
}

.fa-chevron-down:before {
  content: '\f078';
}

.open .ss-mdrop-content {
  max-height: 255px;
  overflow-x: scroll;
  position: absolute;
  z-index: 11;
  top: 47px;
  width: 100%;
}
.open .additionalInfoText {
  display: inline-block;
}
.additionalInfoText {
  margin-right: 10px;
  margin-left: 5px;
  font-size: 12px;
  opacity: 0.7;
  display: none;
  position: absolute;
  right: 32px;
  top: 11px;
}
.droparw {
  position: relative;
  float: right;
  font-size: 11px;
  line-height: 12px;
  cursor: pointer;
  font-weight: 600;
  &.arrowicon {
    top: 8px;
  }
  &.supportIcon {
    font-size: 13px;
    top: 5px;
  }
}
.ss-mdrop-itm {
  display: inline-block;
  padding: 5px 25px 5px 10px;
  border: 0px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  background-color: #fff;
  width: auto;
  margin: 5px 2px 0px 0px;
  color: #444;
  display: none;
  position: relative;
  cursor: pointer;
}

.ss-mdrop-itm:hover {
  filter: brightness(0.93);
}

.open .ss-mdrop-itm {
  display: inline-block;
  width: 100%;
  padding: 9px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f7f9fb;
  font-size: 14px;
  color: #5a6269;
  margin: 0px;
  display: inline-block;
}

.ss-mdrop-itm.selected {
  background-color: var(--selfserveprimarycolor);
  color: #fff;
  display: inline-block;
}
.ss-mdrop-itm.mitm1.outsideTag {
  border-radius: 3px;
  padding: 5px 10px 0px 10px;
}
.droparw.supportIcon.plusIcon {
  top: 3px;
}
.droparw.supportIcon.outsideCrossIcon {
  margin-left: 7px;
  top: 4px;
  opacity: 0.7;
  stroke: var(--selfserveprimarycolor);
  stroke-width: 1px;
}
.mitm-removeall {
  display: inline-block;
  padding: 5px 10px;
  background-color: transparent !important;
  color: #777 !important;
  bottom: 5px;
}
.mitm-removeall:hover {
  color: var(--selfserveprimarycolor) !important;
}
.ss-mdrop-itm:hover {
  filter: brightness(0.93);
}
.displayFlex {
  display: flex;
}
.optionText {
  display: inline-block;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.optionText.widthMaxContent {
  max-width: unset;
}
</style>
