<template>
  <div>
    <geo-targeting
      :line-item="lineItem"
      :plan="plan"
      :selected-targeting-id="targetableElementId"
      :targetings="targetings"
      :on-select-targeting-element="onSelectTargetingElement"
      :target-type-name="targetTypeName"
      :hide-national-form="hideNationalForm"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import TargetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import targetingMixin from '~/components/mixins/targeting-mixin';
import GeoTargeting from './geo-targeting.vue';

export default {
  name: 'GeoTargetingContainer',
  components: { GeoTargeting },
  mixins: [lineItemMixin, targetingMixin, TargetableElementOptionsMixin, validationsMixin.events],
  props: {
    lineItem: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideNationalForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      targetingType: 'geoTargeting',
      targetTypeName: 'National',
      targetableElementId: this?.lineItem?.geoTagElementId || this.getSelectedGeoTaggingId(),
    };
  },
  methods: {
    onSelectTargetingElement(te) {
      this.updateGeoTaggingTargetingsToStore(te.id);
      this.targetTypeName = te.name;
      this.targetableElementId = te.id;
      this.updateLineItem({ geoTagElementId: te.id });
    },
  },
};
</script>
