<template>
  <tbody v-if="plans">
    <template v-for="plan in plans">
      <plan-row
        :key="plan.id + plan._uuid"
        :plan="getPlanToDisplay(plan)"
        :selected-status-ids="selectedStatusIds"
        @onClickDeletePlan="onClickDeletePlan"
        @onClickDiscardPlan="onClickDiscardPlan"
        @onClickEditPlan="onClickEditPlan"
        @toggleLineItemsLoading="toggleLineItemsLoading"
        @expandPlan="expandPlan"
      >
      </plan-row>

      <tr
        v-show="lineItemsLoading && plan._uuid === expandedPlanId"
        :id="plan.id"
        :key="plan._uuid"
        class="int-sub-tr"
      >
        <td class="tablewrapper tbw1" colspan="11">
          <table class="int-table int-sub-table nolive noshowall">
            <tbody class="io-lineitems-table int-tbody">
              <tr class="intr">
                <td>
                  <b-loading-spinner :key="plan.id" class="txt-center" />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>

      <tr
        v-show="!lineItemsLoading && plan._uuid === expandedPlanId"
        :id="plan.id"
        :key="`${plan._uuid}_expandedId`"
        class="int-sub-tr"
      >
        <td v-if="flipLineItems && flipLineItems.length > 0" class="tablewrapper tbw1" colspan="11">
          <table class="int-table int-sub-table nolive noshowall">
            <tbody class="io-lineitems-table int-tbody">
              <lineItem-row
                v-for="(li, idx) in flipLineItems"
                :key="li._uuid"
                :line-item="getLineItemToDisplay(li)"
                :plan="getPlanToDisplay(plan)"
                :index="idx"
                @onClickDeleteLineItem="onClickDeleteLineItem"
                @onClickDiscardLineItem="onClickDiscardLineItem"
              >
              </lineItem-row>
            </tbody>
          </table>
        </td>
      </tr>
    </template>
    <ConfirmationModal
      v-if="showDeleteModal"
      message="Are you sure you want to delete this campaign?"
      header-text="Delete Campaign"
      positive-button-label="YES"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      @close="showDeleteModal = false"
      @onPositiveButtonClick="onConfirmDelete"
      @onNegativeButtonClick="showDeleteModal = false"
    />
    <ConfirmationModal
      v-if="showLineItemDeleteModal"
      message="Are you sure you want to delete this ad group?"
      header-text="Delete Ad Group"
      positive-button-label="YES"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      @close="showLineItemDeleteModal = false"
      @onPositiveButtonClick="onConfirmDeleteLineItem"
      @onNegativeButtonClick="showLineItemDeleteModal = false"
    />
    <ConfirmationModal
      v-if="showLineItemDiscardModal"
      message="Are you sure you want to delete this ad group or cancel the changes?"
      header-text="Delete Ad Group/Changes"
      positive-button-label="YES"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      @close="showLineItemDiscardModal = false"
      @onPositiveButtonClick="onConfirmDiscardLineItem"
      @onNegativeButtonClick="showLineItemDiscardModal = false"
    />
    <ConfirmationModal
      v-if="showPlanDiscardModal"
      message="Are you sure you want to delete this campaign or cancel the changes?"
      header-text="Delete Campaign/Changes"
      positive-button-label="YES"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      @close="showPlanDiscardModal = false"
      @onPositiveButtonClick="onConfirmDiscardPlan"
      @onNegativeButtonClick="showPlanDiscardModal = false"
    />
  </tbody>
</template>

<script>
import { sync, get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import planApi from 'adready-api/api/plans';
import lineItemsApi from 'adready-api/api/line-items';
import { IOS_CHANGE_APPLIED, LIS_CHANGE_APPLIED } from 'adready-api/constant';
import deepcloneHelpers from 'adready-api/helpers/deepclone-helpers';
import PlanRow from '~/components/campaigns/plan-row.vue';
import LineItemRow from '~/components/campaigns/lineItem-row.vue';
import ConfirmationModal from '~/components/campaigns/confirmation-modal.vue';
import forklift from '~/components/mixins/forklift-mixin';

export default {
  name: 'PlanBody',

  components: {
    PlanRow,
    LineItemRow,
    ConfirmationModal,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  mixins: [forklift],
  props: {
    selectedStatusIds: { require: false, type: Array, default: () => [] },
  },
  data() {
    return {
      showDeleteModal: false,
      lineItemToBeDeleted: undefined,
      planToBeDeleted: undefined,
      showLineItemDeleteModal: false,
      showLineItemDiscardModal: false,
      showPlanDiscardModal: false,
      showEditPlanModal: false,
      message: 'Are you sure you want to delete this IO?',
      lineItemsLoading: false,
      isErrorWarning: false,
      labelYes: 'YES',
      headerText: 'Cancel Campaign Changes',
    };
  },
  computed: {
    lineItems: get('common/plan@lineItems'),
    expandedPlanId: sync('common/expandedPlanId'),
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    plans: get('common/plans'),
    plan: sync('common/plan'),
  },
  asyncComputed: {
    flipLineItems: {
      default: [],
      async get() {
        if (this.lineItems && this.lineItems.length > 0) {
          return this.lineItems.filter((l) => l.flip);
        }
        return [];
      },
    },
  },

  methods: {
    loadLineItems(planId) {
      return planApi
        .read(planId, {
          includePacing: false,
        })
        .then((freshPlan) => {
          return freshPlan;
        });
    },
    getPlanToDisplay(plan) {
      if (!isBlank(plan.crPlans)) {
        const crPlan = plan.crPlans.find(
          (cp) => cp.state === 'CHANGE' && cp.statusId !== IOS_CHANGE_APPLIED
        );
        if (crPlan) {
          return deepcloneHelpers.mergePlan(plan, crPlan);
        }
      }
      return plan;
    },
    getLineItemToDisplay(lineItem) {
      if (!isBlank(lineItem.crLineItems)) {
        const crLineItem = lineItem.crLineItems.find((cl) => cl.statusId !== LIS_CHANGE_APPLIED);
        if (crLineItem) {
          return deepcloneHelpers.mergeLineItem(lineItem, crLineItem);
        }
      }
      return lineItem;
    },

    // events
    expandPlan(plan) {
      this.plan = plan;
      this.expandedPlanId = plan._uuid;
    },
    toggleLineItemsLoading(val) {
      this.lineItemsLoading = val;
    },
    closePlan() {
      this.expandedPlanId = '';
    },
    onConfirmDelete() {
      if (this.planToBeDeleted && this.planToBeDeleted.id) {
        planApi.delete(this.planToBeDeleted.id).then(() => {
          this.$store.dispatch('common/deletePlan', this.planToBeDeleted);
        });
      } else {
        this.$store.dispatch('common/deletePlan', this.planToBeDeleted);
      }
      this.showDeleteModal = false;
    },
    onClickDeletePlan(plan) {
      this.planToBeDeleted = plan;
      this.showDeleteModal = true;
    },
    onClickDeleteLineItem(lineItem) {
      this.lineItemToBeDeleted = lineItem;
      this.showLineItemDeleteModal = true;
      this.headerText = 'Cancel Ad Group Changes';
      this.labelYes = 'YES';
    },
    onConfirmDeleteLineItem() {
      if (this.lineItemToBeDeleted && this.lineItemToBeDeleted.id) {
        lineItemsApi
          .delete({ planId: this.lineItemToBeDeleted.planId, id: this.lineItemToBeDeleted.id })
          .then(() => {
            this.$store.dispatch('common/deleteLineItem', this.lineItemToBeDeleted);
            this.refreshLineItemStatusCount();
          });
      } else {
        this.$store.dispatch('common/deleteLineItem', this.lineItemToBeDeleted);
      }
      this.showLineItemDeleteModal = false;
    },
    refreshLineItemStatusCount() {
      this.loadLineItemStatusCount(this.account.id, this.advertiser.id, true, true);
      this.loadLineItemStatusCountForPlans(this.account.id, this.advertiser.id, true, true);
    },
    onClickDiscardLineItem(lineItem) {
      this.lineItemToBeDeleted = lineItem;
      this.showLineItemDiscardModal = true;
    },
    onConfirmDiscardLineItem() {
      this.$store.dispatch('common/deleteLineItem', this.lineItemToBeDeleted);
      this.showLineItemDiscardModal = false;
    },
    onClickDiscardPlan(plan) {
      this.planToBeDeleted = plan;
      this.showPlanDiscardModal = true;
    },
    onConfirmDiscardPlan() {
      this.$store.dispatch('common/deletePlan', this.planToBeDeleted);
      this.showPlanDiscardModal = false;
    },
    onClickEditPlan(plan) {
      this.plan = plan;
      this.showEditPlanModal = true;
    },
    onSaveAndClose() {
      this.plan.updatedPlan = true;
      this.showEditPlanModal = false;
    },
    onCloseEdit() {
      this.showEditPlanModal = false;
    },
  },
};
</script>

<style lang="scss">
.header-btns {
  .k-btn {
    background-color: var(--selfserveprimarycolor);
    color: #fff !important;
  }
  .btn-outline {
    color: #565656 !important;
  }
}
.txt-center {
  position: absolute;
  width: 85%;
  margin-top: 15px;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
</style>
