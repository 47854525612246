<template>
  <div :class="`col-xs-12 ${readonly ? 'readonly-bp' : ''}`">
    <div class="flip-title">
      What is your campaign goal? <b-error-msg v-if="errorObj.goal" :error-msg="errorObj.goal" />
    </div>

    <div class="row-flex pixel-setup-popup" @mouseleave="isShowPixelSetup = false">
      <div
        v-for="goal in goalOptions"
        :key="goal.category"
        class="goals-list"
        :class="[
          goal.category == activeGoal.category ? 'activeGoal' : '',
          goal.isDisabled ? 'disable_goal' : '',
        ]"
        @mouseenter="
          goal.defaultMetric === 'VCR' ? (isShowPixelSetup = false) : (isShowPixelSetup = true)
        "
        @click="onClickGoal(goal)"
      >
        <span class="pop-view">
          <font-awesome-icon class="pcheck2" :icon="['fas', 'check']" size="1x" />
          <font-awesome-icon class="pop-icon pbig" :icon="goal.goal_icon" size="1x" />
        </span>
        <div v-if="goal.isDisabled" class="kpi-alert">
          <div class="kpi-alert-content">
            Additional <a @click="onClickPixelSetup()">pixel setup</a> needed for this goal
          </div>
        </div>
        <div class="pop-view-txt">
          {{ goal.name }}
        </div>
      </div>
      <div
        v-if="
          goalOptions.filter((goal) => !goal.isDisabled).length < 2 && isShowPixelSetup === true
        "
        class="pixel-setup-popup-wrapper"
      >
        <div class="perf-content">
          <div class="perf-txt">
            Performance goals cannot be selected until you've placed and verified your Flip Pixel.
          </div>
          <k-button :size="3" label="PIXEL SETUP" @click="onClickPixelSetupPopupBtn()" />
        </div>
      </div>
    </div>
    <div v-if="activeGoal && activeGoal.category != 'brandlift' && subCategoryOptions.length > 0">
      <k-select
        name="IncludeSubcategories"
        label="Include Subcategories"
        :required="true"
        :options="subCategoryOptions"
        :value="activeGoal.subCategories"
        :fancy="true"
        :multiple="true"
        label-key="value"
        @input="onSelectCategories"
      />
    </div>
    <div v-if="activeGoal.category && !activeGoal.isDisabled" class="flip-category">
      <!--
      <div class="flip-heading">Select One</div>
      -->
      <div v-if="!(activeGoal.category === 'vcr')" class="flip-options">
        <a
          v-for="metric in FLIP_CAMPAIGN_GOAL_METRICS"
          :key="metric.id"
          class="flip-btn"
          :class="[
            `${isMetricSelected(activeGoal, metric) ? 'flip-btn-selected' : ''}`,
            `${activeGoal.category !== 'checkout' && metric.id === 2 ? 'disabled' : ''}`,
            `${metric.id === 1 ? 'lend' : ''} ${metric.id === 2 ? 'rend' : ''}`,
          ]"
          @click="onSelectGoalMetric(metric)"
        >
          <font-awesome-icon class="flip-icon" :icon="['fas', 'check']" size="1x" />
          <div class="flip-text">{{ metric.label }}</div>
        </a>
        <div
          :class="
            `${
              activeGoalMetricLabel === 'ROAS' || activeGoalMetricLabel === 'Inc. Reach %'
                ? 'percent-sign'
                : 'dollar-sign'
            }`
          "
        >
          <b-text-input
            :value="goalMetricValue"
            :required="true"
            :placeholder="metricPlaceholder"
            :readonly="readonly"
            class="cl-subcat"
            @change="onChangeGoalMetricValue"
          />
        </div>
      </div>
      <div v-if="activeGoal.category === 'vcr'" class="flip-options">
        <a
          v-for="metric in FLIP_CAMPAIGN_AWARENESS_METRICS"
          :key="metric.id"
          class="flip-btn"
          :class="[
            `${isAwarenessMetricSelected(activeGoal, metric) ? 'flip-btn-selected' : ''}`,
            `${metric.id === 3 ? 'lend' : ''} ${metric.id === 4 ? 'rend' : ''}`,
          ]"
          @click="onSelectGoalMetric(metric)"
        >
          <font-awesome-icon class="flip-icon" :icon="['fas', 'check']" size="1x" />
          <div class="flip-text">{{ metric.label }}</div>
        </a>
        <div
          v-if="activeGoalAwarenessMetricLabel === 'VCR'"
          :class="`${activeGoalAwarenessMetricLabel === 'VCR' ? 'percent-sign' : ''}`"
        >
          <b-text-input
            :value="goalMetricValue"
            :required="true"
            :placeholder="`Enter Goal ${this.activeGoalAwarenessMetricLabel}`"
            :readonly="readonly"
            class="cl-subcat"
            @change="onChangeGoalMetricValue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import {
  FLIP_CAMPAIGN_INFO_DEFAULT,
  FLIP_CAMPAIGN_GOAL_METRICS,
  FLIP_CAMPAIGN_GOAL_CPA_METRIC,
  FLIP_CAMPAIGN_GOAL_ROAS_METRIC,
  LAYOUT_TYPES,
  FLIP_CAMPAIGN_AWARENESS_METRICS,
  FLIP_CAMPAIGN_GOAL_VCR_METRIC,
} from '~/constant';
import bErrorMsg from '~/components/elements/b-error-msg.vue';
import bTextInput from '~/components/elements/b-text-input.vue';
import { eBus } from '~/main';
import KButton from '~/components/elements/k-button.vue';

export default {
  name: 'BPlanFlipCampaignGoal',
  components: { bErrorMsg, bTextInput, KButton },
  props: {
    goalData: { required: true, type: Array },
    errorObj: { required: true, type: Object, default: () => {} },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      FLIP_CAMPAIGN_GOAL_METRICS,
      FLIP_CAMPAIGN_AWARENESS_METRICS,
      isShowPixelSetup: false,
      goals: [
        {
          category: 'vcr',
          name: 'Awareness',
          goal_icon: ['fal', 'eye'],
          isDisabled: false,
          isActive: false,
          goal_value: [],
          subCategories: [],
          defaultMetric: 'VCR',
        },
        {
          category: 'pagevisit',
          name: 'Page Visits',
          goal_icon: ['fal', 'user-chart'],
          isDisabled: true,
          isActive: false,
          goal_value: [],
          subCategories: [],
          defaultMetric: 'CPA',
        },
        {
          category: 'lead',
          name: 'Leads',
          goal_icon: ['fal', 'compress-arrows-alt'],
          isDisabled: true,
          isActive: false,
          goal_value: [],
          subCategories: [],
          defaultMetric: 'CPA',
        },
        {
          category: 'checkout',
          name: 'Checkouts',
          goal_icon: ['fal', 'shopping-cart'],
          isDisabled: true,
          isActive: false,
          goal_value: [],
          subCategories: [],
          defaultMetric: 'ROAS',
        },
        {
          category: 'activity',
          name: 'Activities',
          goal_icon: ['fal', 'mouse-pointer'],
          isDisabled: true,
          isActive: false,
          goal_value: [],
          subCategories: [],
          defaultMetric: 'CPA',
        },
        /*
        {
          category: 'Location Visits',
          name: 'Foot Traffic',
          goal_icon: ['fal', 'walking'],
          isDisabled: true,
          isActive: false,
          goal_value: [],
          subCategories: [],
          defaultMetric: 'CPA',
        },
        */
      ],
    };
  },

  computed: {
    advertiser: get('common/advertiser'),
    plan: sync('common/plan'),
    flipCampaignInfo: sync('common/plan@flipCampaignInfo'),
    flipCampaignInfoGoal: sync('common/plan@flipCampaignInfo.goal'),
    activeGoal() {
      if (!isBlank(this.flipCampaignInfoGoal)) {
        return this.flipCampaignInfoGoal;
      }
      return {
        category: null,
        subCategories: [],
        metric: {},
      };
    },
    activeGoalMetricLabel() {
      const defaultLabel = this.activeGoal.category === 'checkout' ? 'ROAS' : 'CPA';
      return this.activeGoal.metric && this.activeGoal.metric.label
        ? this.activeGoal.metric.label
        : defaultLabel;
    },
    activeGoalAwarenessMetricLabel() {
      const defaultLabel = this.activeGoal.category === 'vcr' ? 'VCR' : 'REACH';
      return this.activeGoal.metric && this.activeGoal.metric.label
        ? this.activeGoal.metric.label
        : defaultLabel;
    },
    metricPlaceholder() {
      return `Enter Goal ${this.activeGoalMetricLabel}`;
    },
    goalOptions() {
      const categories = this.goalData.map((e) => {
        return e.category;
      });
      const updatedGoals = this.goals.map((g) => {
        if (categories.includes(g.category)) {
          const category = this.goalData.find((gd) => gd.category === g.category);
          let subCategories = [];
          if (category.numSubCategories > 0) {
            subCategories = category.children.map((e) => {
              return e.category;
            });
            g.subCategories = subCategories;
          }
          g.isDisabled = false;
        }
        return g;
      });
      this.$emit('onChangeGoal', this.activeGoal.category);
      return updatedGoals;
    },

    subCategoryOptions() {
      const category = this.goalOptions.find(
        (go) => go.category === this.plan.flipCampaignInfo?.goal.category
      );

      return category && category.subCategories ? category.subCategories : [];
    },
    goalMetricValue() {
      return this?.flipCampaignInfo?.goal?.metric?.value || 0;
    },
  },
  mounted() {
    if (isBlank(this.flipCampaignInfo)) {
      this.flipCampaignInfo = FLIP_CAMPAIGN_INFO_DEFAULT;
    }
  },

  methods: {
    isMetricSelected(activeGoal, metric) {
      const goal = this.goals.find((g) => g.category === activeGoal.category);
      const defaultMetric = FLIP_CAMPAIGN_GOAL_METRICS.find((m) => m.label === goal.defaultMetric);
      const activeMetricId = isBlank(activeGoal.metric) ? defaultMetric.id : activeGoal.metric.id;
      return metric.id === activeMetricId;
    },
    isAwarenessMetricSelected(activeGoal, metric) {
      const goal = this.goals.find((g) => g.category === activeGoal.category);
      const defaultMetric = FLIP_CAMPAIGN_AWARENESS_METRICS.find(
        (m) => m.label === goal.defaultMetric
      );
      const activeMetricId = isBlank(activeGoal.metric) ? defaultMetric.id : activeGoal.metric.id;
      return metric.id === activeMetricId;
    },

    onSelectCategories(val) {
      this.flipCampaignInfoGoal = { ...this.flipCampaignInfoGoal, subCategories: val };
    },
    onSelectGoalMetric(val) {
      if (val.label === 'REACH') {
        this.flipCampaignInfoGoal = {
          ...this.flipCampaignInfoGoal,
          metric: { ...val },
        };
      } else {
        this.flipCampaignInfoGoal = {
          ...this.flipCampaignInfoGoal,
          metric: { ...val, value: 0 },
        };
      }
    },

    onChangeGoalMetricValue(val) {
      const metric = { ...this.flipCampaignInfoGoal.metric, value: val.replace(/[^0-9.]/g, '') };
      this.flipCampaignInfoGoal = { ...this.flipCampaignInfoGoal, metric };
    },
    onClickGoal(val) {
      if (val.isDisabled) {
        return;
      }

      let metric = {};
      if (val.category === 'checkout') {
        metric = FLIP_CAMPAIGN_GOAL_ROAS_METRIC;
      } else if (val.category === 'vcr') {
        metric = FLIP_CAMPAIGN_GOAL_VCR_METRIC;
      } else {
        metric = FLIP_CAMPAIGN_GOAL_CPA_METRIC;
      }
      this.flipCampaignInfoGoal = { ...val, metric };
    },
    onClickPixelSetupPopupBtn() {
      let subOption = '';
      let elementId = 'acc-pixel-account-setup';
      if (this.advertiser?.selfServe && !this.advertiser?.pixelSetup?.pixelAdded) {
        subOption = 'ACCOUNT_SETUP';
      } else {
        subOption = 'PIXEL_SETUP_CODE';
        elementId = '';
      }
      eBus.$emit(
        'on-change-selected-menu-option',
        LAYOUT_TYPES.ACCOUNT_SETUP,
        subOption,
        elementId
      );
    },
    onClickPixelSetup() {
      if (this.advertiser?.selfServe) {
        eBus.$emit(
          'on-change-selected-menu-option',
          LAYOUT_TYPES.ACCOUNT_SETUP,
          'PIXEL_SETUP_CODE'
        );
        return;
      }
      eBus.$emit('on-change-selected-menu-option', LAYOUT_TYPES.FLIPPIXEL);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  /* display: inline-block !important; */
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: var(--selfserveprimarycolor) !important;
}
a:hover {
  color: #333 !important;
  text-decoration: none;
}
.row-flex {
  display: flex;
  margin-bottom: 24px;
  &.pixel-setup-popup {
    position: relative;
    .pixel-setup-popup-wrapper {
      display: inline-flex;
      position: absolute;
      background-color: rgba(245, 245, 245, 0.5);
      height: 200px;
      z-index: 9;
      align-items: center;
      justify-content: center;
      padding: 20px;
      font-size: 16px;
      border-radius: 5px;
      width: 658px;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      right: -82px;
      bottom: 0px;
      .perf-content {
        width: 93%;
        padding: 10px;
        text-align: center;
        .perf-txt {
          margin-bottom: 10px;
        }
      }
    }
  }
  .goals-list {
    position: relative;
    cursor: pointer;
    .pop-view {
      display: block;
      width: 130px;
      height: 130px;
      border: 3px solid #f5f5f5;
      margin: 0px 20px;
      border-radius: 100%;
      .pop-icon {
        position: relative;
        top: 40%;
      }
      .pcheck2 {
        background-color: var(--selfserveprimarycolor);
        width: 18px !important;
        height: 18px !important;
        padding: 5px;
        border-radius: 20px;
        position: absolute;
        margin: 20px 0px 0px 110px;
        color: #fff;
        display: block;
        opacity: 0;
        transform: scale(1.4);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      .pbig {
        color: #6b7579;
        width: 32px !important;
        height: 32px !important;
      }
    }
    .pop-view-txt {
      padding: 12px 0;
      position: relative;
      top: 10px;
      pointer-events: pointer;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .kpi-alert {
      .kpi-alert-content {
        position: absolute;
        top: 0;
        margin: 14px 0px 0px 0px;
        line-height: 1.2em;
        text-align: center;
        padding: 30px;
        background-color: #fff;
        z-index: 2;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }

    &:hover {
      .pop-view-txt {
        color: var(--selfserveprimarycolor);
        top: 5px;
      }
      .pop-view {
        border: 3px solid var(--selfserveprimarycolor);
      }
      .pcheck2 {
        opacity: 100;
      }
      .pbig {
        color: #333;
      }
      .kpi-alert {
        .kpi-alert-content {
          opacity: 100 !important;
        }
      }
    }
  }
  .activeGoal {
    &:hover .pop-view-txt {
      top: 10px;
    }
    .pop-view-txt {
      color: var(--selfserveprimarycolor) !important;
    }
    .pop-view {
      border: 3px solid var(--selfserveprimarycolor);
    }
    .pcheck2 {
      opacity: 100 !important;
    }
    .pbig {
      color: #333 !important;
    }
  }
  .disable_goal {
    &:hover .pop-view-txt {
      top: 10px;
    }
    .pop-view-txt {
      opacity: 0.2;
      color: #6b7579 !important;
    }
    .pop-view {
      opacity: 0.2 !important;
      border: 3px solid rgba(245, 245, 245, 0.2) !important;
    }
    .pcheck2 {
      opacity: 0 !important;
    }
  }
}
.flip-category {
  margin-top: 20px;
  .flip-heading {
    margin-bottom: 20px;
    color: #444;
    text-align: left;
    font-size: 20px;
    letter-spacing: 0.02em;
  }
  .flip-options {
    display: flex;
    .flip-icon {
      display: none;
    }
    .flip-text {
      text-transform: uppercase;
      font-family: 'Museo Sans 500', Arial, Helvetica, sans-serif;
      font-size: 12px;
    }
    .flip-btn {
      color: #7f7f7f !important;
      border: 0.5px solid #b3b3b3 !important;
      margin: 10px 0px;
      text-align: center;
      padding: 9px 12px !important;
      height: 40px;
      letter-spacing: 0.02em;
      display: flex;
      align-items: center;
      &:hover {
        background-color: #eee !important;
      }
      &.lend {
        border-left-width: 1px !important;
        border-radius: 3px 0px 0px 3px;
      }
      &.rend {
        border-right-width: 1px !important;
        border-radius: 0px 3px 3px 0px;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }
    .flip-btn-selected {
      background-color: var(--selfserveprimarycolor) !important;
      color: #fff !important;
      border: 1px solid var(--selfserveprimarycolor) !important;
      .flip-icon {
        display: block;
        color: #fff !important;
        transform: scale(0.8);
        margin-right: 5px;
      }
      &:hover {
        background-color: var(--selfserveprimarycolor) !important;
      }
    }
  }
}
.flip-title {
  margin-bottom: 20px;
  color: #444;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
}
.cl-subcat {
  margin: 10px !important;
  position: relative;
}
.dollar-sign {
  display: inline-block;
  position: relative;
  &:before {
    content: '$';
    font-size: 15px;
    color: #bdbdbd;
    position: absolute;
    top: 18px;
    left: 22px;
    z-index: 2;
  }
  ::v-deep .form-input-field {
    padding-left: 24px;
    padding-right: 24.5px;
  }
}
.percent-sign {
  display: inline-block;
  position: relative;
  &:after {
    content: '%';
    font-size: 15px;
    color: #bdbdbd;
    position: absolute;
    top: 18px;
    right: 12px;
    z-index: 2;
  }
  ::v-deep .form-input-field {
    padding-right: 38px;
  }
}
::v-deep .form-input-wrap {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #cccccc;
  min-height: 20px;
  border-radius: unset;
  position: relative;
  max-width: 848px;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  background: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  font-weight: 300;
  font-size: 13px;
  font-family: 'Manrope', sans-serif;
  padding: 3px 7px;
  margin: 0px 4px 1px 0px;
  border-radius: 2px;
  display: inline-block;
  border: unset;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #fff;
  cursor: pointer;
  min-height: 20px;
  border-radius: unset;
  position: relative;
}
::v-deep .form-input .form-input-field.v-select.multiple-input-field .vs__selected-options {
  padding: 10px;
}
::v-deep .form-input .form-input-field.v-select .vs__search {
  font-family: 'Manrope', sans-serif;
  color: #757575;
  font-weight: 300;
  background: #fff;
}
::v-deep .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__actions {
  z-index: 10;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: rgba(255, 255, 255, 0.8);
}
::v-deep .cl-subcat.form-input .form-input-field {
  height: 40px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.readonly-bp {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
