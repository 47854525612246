<template>
  <div
    :class="`vue-drag-select row middle-xs ${readonly ? 'readonly-ds' : ''}`"
    @mousedown="onMouseDown"
  >
    <slot :selectedItems="selectedItems" />
    <div v-if="mouseDown" class="vue-drag-select-box" :style="selectionBoxStyling"></div>
  </div>
</template>

<script>
import { DAY_PARTING_DAYS } from '~/constant';
// Takes an array and returns a copy of the array without duplicates
function uniqueArray(array) {
  const newArray = array.concat();
  for (let i = 0; i < newArray.length; ++i) {
    for (let j = i + 1; j < newArray.length; ++j) {
      if (newArray[i] === newArray[j]) {
        newArray.splice(j--, 1);
      }
    }
  }
  return newArray;
}

export default {
  name: 'DragSelectItemContainer',
  props: {
    selectorClass: {
      type: String,
      required: true,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    dayParting: { type: Object, required: true },
  },

  data() {
    return {
      mouseDown: false,
      concat: false,
      startPoint: null,
      endPoint: null,
      edtest: 'edtest',
      selectedItems: [],
    };
  },
  computed: {
    selectionBox() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) return {};

      const clientRect = this.$el.getBoundingClientRect();
      const scroll = this.getScroll();

      // Calculate position and dimensions of the selection box
      const left = Math.min(this.startPoint.x, this.endPoint.x) - clientRect.left - scroll.x;
      const top = Math.min(this.startPoint.y, this.endPoint.y) - clientRect.top - scroll.y;
      const width = Math.abs(this.startPoint.x - this.endPoint.x);
      const height = Math.abs(this.startPoint.y - this.endPoint.y);

      // Return the styles to be applied
      return {
        left,
        top,
        width,
        height,
      };
    },
    selectionBoxStyling() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) {
        return { background: '#fdcf09', opacity: '0.65' };
      }
      const { left, top, width, height } = this.selectionBox;

      // Return the styles to be applied

      return {
        opacity: '0.65',
        border: '1px solid #888',
        background: '#fdcf09',
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
      };
    },
  },
  watch: {
    selectedItems(val) {
      this.$emit('change', val);
    },
  },
  mounted() {
    const dayPartingDays = Object.keys(this.dayParting) || [];
    const selectedElArray = [];
    for (let i = 0; i < dayPartingDays.length; i++) {
      const day = dayPartingDays[i];
      const values = this.dayParting[dayPartingDays[i]];
      if (DAY_PARTING_DAYS.includes(day)) {
        for (let j = 0; j < values.length; j++) {
          selectedElArray.push(document.getElementById(`${day}_${values[j]}`));
        }
      }
    }
    this.selectedItems = [...selectedElArray];
  },

  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);

    this.$children.forEach((child) => {
      child.$off('click');
    });
  },
  methods: {
    getScroll() {
      // If we're on the server, default to 0,0
      if (typeof document === 'undefined') {
        return {
          x: 0,
          y: 0,
        };
      }

      return {
        x: this.$el.scrollLeft || document.body.scrollLeft || document.documentElement.scrollLeft,
        y: this.$el.scrollTop || document.body.scrollTop || document.documentElement.scrollTop,
      };
    },
    onMouseDown(event) {
      // Ignore right clicks
      if (event.button === 2) return;

      // Check if shift is down
      this.concat = true;

      // Register begin point
      this.mouseDown = true;
      this.startPoint = {
        x: event.pageX,
        y: event.pageY,
      };

      // Start listening for mouse move and up events
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove(event) {
      // Update the end point position
      if (this.mouseDown) {
        this.endPoint = {
          x: event.pageX,
          y: event.pageY,
        };
      }
      const children = this.$children.length ? this.$children : this.$el.children;
      if (children) {
        Array.from(children).map((item) => {
          if (this.hoveredItem(item.$el || item) && !item.classList.contains('active')) {
            item.classList.add('hovered');
          } else {
            item.classList.remove('hovered');
          }
          return true;
        });
      }
    },
    onMouseUp() {
      const children = this.$children.length ? this.$children : this.$el.children;
      if (children) {
        const newSelected = [];
        Array.from(children).map((item) => {
          const result = this.changeItemSelection(item.$el || item);
          if (result.selectedType === 'NEW' || result.selectedType === 'PREV_SELECTED') {
            newSelected.push(item);
          }
          return true;
        });
        // If shift was held during mousedown the new selection is added to the current. Otherwise the new selection
        // will be selected
        this.selectedItems = uniqueArray(this.selectedItems.concat(newSelected));
      }
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);

      // Reset state
      this.mouseDown = false;
      this.concat = false;
      this.startPoint = null;
      this.endPoint = null;
    },
    hoveredItem(el) {
      if (el.classList.contains(this.selectorClass)) {
        const boxA = this.selectionBox;
        const boxB = {
          top: el.offsetTop,
          left: el.offsetLeft,
          width: el.clientWidth,
          height: el.clientHeight,
        };

        return !!(
          boxA.left <= boxB.left + boxB.width &&
          boxA.left + boxA.width >= boxB.left &&
          boxA.top <= boxB.top + boxB.height &&
          boxA.top + boxA.height >= boxB.top
        );
      }
      return false;
    },

    changeItemSelection(el) {
      if (el.classList.contains(this.selectorClass)) {
        const boxA = this.selectionBox;
        const boxB = {
          top: el.offsetTop,
          left: el.offsetLeft,
          width: el.clientWidth,
          height: el.clientHeight,
        };

        if (
          boxA.left <= boxB.left + boxB.width &&
          boxA.left + boxA.width >= boxB.left &&
          boxA.top <= boxB.top + boxB.height &&
          boxA.top + boxA.height >= boxB.top &&
          el.classList.contains('active')
        ) {
          return { selectedType: 'PREV_SELECTED' };
        }

        if (
          boxA.left <= boxB.left + boxB.width &&
          boxA.left + boxA.width >= boxB.left &&
          boxA.top <= boxB.top + boxB.height &&
          boxA.top + boxA.height >= boxB.top
        ) {
          return { selectedType: 'NEW' };
        }
      }

      return { selectedType: 'NOT_SELECTED' };
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-drag-select {
  position: relative;
  user-select: none;
  width: 798px;
  left: 40px;
  bottom: 3px;
}

.vue-drag-select-box {
  position: absolute;
  z-index: 99;
}
.readonly-ds {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
</style>
