import { get } from 'vuex-pathify';
import { isBlank, inert } from 'adready-api/helpers/common';
import { LUCID_CAMPAIGN_INFO_DEFAULT, NEW_CAMPAIGN_PAGES } from '~/constant';
import ioHelpers from '~/helpers/io-helpers';
import lineItemHelpers from '~/helpers/line-item-helpers';

const props = {
  pageIndexValidationObj: {
    required: true,
    type: Object,
    default: () => {},
  },
  pageIndexVisited: {
    required: true,
    type: Object,
    default: () => {},
  },
  lineItem: {
    type: Object,
    required: false,
    default: null,
  },
  selectedPageIndex: {
    type: Number,
    required: false,
    default: 0,
  },
};
const computed = {
  lucidCampaignInfo: get('common/plan@lucidCampaignInfo'),
  mediaPublisherCPMData: get('common/mediaPublisherCPMData'),
  activeBrandLiftInfo() {
    if (!isBlank(this.lucidCampaignInfo) && !isBlank(this.lucidCampaignInfo.brandLiftInfo)) {
      return this.lucidCampaignInfo.brandLiftInfo;
    }
    return LUCID_CAMPAIGN_INFO_DEFAULT;
  },
};
const methods = {
  validateCampaign(plan) {
    return ioHelpers.validateCampaign(plan);
  },
  validateAdGroupInfo() {
    return lineItemHelpers.validateAdGroupInfo(this.lineItem);
  },
  validateAdGroupAds() {
    return lineItemHelpers.validateAdGroupAds(this.lineItem);
  },
  validateAdGroupTargetings() {
    return lineItemHelpers.validateAdGroupTargetings(this.lineItem);
  },
  validateBlCampaign(plan) {
    const impressionCount = ioHelpers.calculateImpressionCount(plan, this.mediaPublisherCPMData);
    const lucidViable = ioHelpers.isLucidViable(impressionCount);
    if (
      lucidViable &&
      (this.activeBrandLiftInfo?.competitors?.length !== 0 ||
        !isBlank(this.activeBrandLiftInfo?.category))
    ) {
      return ioHelpers.validateLucidData(plan, this.activeBrandLiftInfo);
    }

    return { isValidated: true };
  },
  validateCampaignPages(option, plan) {
    const { pageName } = option;
    switch (pageName) {
      case NEW_CAMPAIGN_PAGES.CAMPAIGN_INFO:
        return this.validateCampaign(plan);
      case NEW_CAMPAIGN_PAGES.AD_GROUP_INFO:
        return this.validateAdGroupInfo();
      case NEW_CAMPAIGN_PAGES.AD_GROUP_ADS:
        return this.validateAdGroupAds();
      case NEW_CAMPAIGN_PAGES.AD_GROUP_TARGETING:
        return this.validateAdGroupTargetings();
      case NEW_CAMPAIGN_PAGES.LUCID:
        return this.validateBlCampaign(plan);
      default:
        return { isValidated: true };
    }
  },
  checkForPageIndexValidation(optionsArray, plan) {
    const tempOpts =
      optionsArray.map((opt) => {
        if (
          this.pageIndexVisited[opt.pageIndex] &&
          !this.pageIndexValidationObj[opt.pageIndex]?.isValidated
        ) {
          this.pageIndexValidationObj[opt.pageIndex] = this.validateCampaignPages(opt, plan);
          if (!this.pageIndexValidationObj?.[opt?.pageIndex]?.isValidated) {
            opt.rightIcon = 'exclamation';
          }
        } else {
          opt.rightIcon = 'check';
        }
        return opt;
      }) || [];
    return inert(tempOpts);
  },
  isPageIndexValid(option) {
    return this.pageIndexValidationObj[option.pageIndex]?.isValidated || false;
  },
  addErrorInValidationMap(error, fieldName) {
    this.pageIndexValidationObj[this.selectedPageIndex] = {
      ...this.pageIndexValidationObj[this.selectedPageIndex],
      [fieldName]: error,
    };
    this.validationMap = { ...this.validationMap, [fieldName]: error, isValidated: false };
  },
};
export default { methods, props, computed };
