<template>
  <div :class="`device-targeting-base ${readonly ? 'readonly-dt' : ''}`">
    <div
      v-for="(obj, index) in deviceTypeOptions"
      :key="index"
      class="pop-view-devices"
      :class="isDeviceTypeSelected(obj.id) ? 'pactive' : ''"
    >
      <span
        class="pop-view9b pteal dv1"
        :class="isDeviceTypeSelected(obj.id) ? 'pactive' : ''"
        @click="onDeviceInput(obj)"
      >
        <font-awesome-icon :icon="['fas', 'check']" class="fa-w-16 pcheck2" />
        <font-awesome-icon :icon="['fal', getDeviceIcon(obj.id)]" class="fa-w-20 pop-icon pbig" />
        <div class="pop-view-txt">{{ obj.deviceType }}</div>
      </span>
    </div>
    <div style="height: 70px;"></div>
  </div>
</template>

<script>
import { inert, isBlank } from 'adready-api/helpers/common';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';

export default {
  mixins: [lineItemMixin, validationsMixin.events],

  props: {
    // list of device type options
    deviceTypeOptions: {
      required: true,
      type: Array,
    },

    // list of selected device types
    deviceTypes: {
      required: true,
      type: Array,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      deviceTypeIcons: { 1: 'mobile', 2: 'laptop', 3: 'tv-alt', 5: 'tablet' },
    };
  },

  computed: {
    /**
     * Return a list of selected options
     */
    deviceTypeValues() {
      if (!isBlank(this.deviceTypes)) {
        return this.deviceTypes
          .map((device) => {
            return this.deviceTypeOptions.find((dev) => dev.id === device.deviceTypeId);
          })
          .filter((d) => d !== undefined);
      }
      return [];
    },
  },

  methods: {
    getDeviceIcon(id) {
      return this.deviceTypeIcons[id] || 'tv-alt';
    },
    isDeviceTypeSelected(id) {
      if (isBlank(this.deviceTypes)) {
        return false;
      }
      const deviceTypeIds = this.deviceTypes.map((d) => d.deviceTypeId);
      return deviceTypeIds.includes(id);
    },
    onDeviceInput(val) {
      // handles the deleted deviceType case
      // filter our existing list with those still present in the new list

      let dt = inert(this.deviceTypes);
      if (this.deviceTypes.some((deviceType) => deviceType.deviceTypeId === val.id)) {
        // not in updated values
        // removed.push({ id: val.deviceTypeId });
        dt = this.deviceTypes.filter((c) => c.deviceTypeId !== val.id);
      } else {
        dt.push({ deviceTypeId: val.id });
      }
      this.updateLineItem({ deviceTypes: dt });
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-view-devices {
  display: inline-block;
  max-width: 30%;
  width: 130px;
  height: 130px;
  margin: 0px 2%;
  cursor: pointer;
  text-align: center;
  .pop-view9b {
    display: inline-block;
    /*max-width: 30%;*/
    width: 130px;
    height: 130px;
    border: 3px solid #f5f5f5;
    /*margin: 0px 2%;*/
    border-radius: 120px;
    padding-top: 42px;
    cursor: pointer;
    text-align: center;
    &.pactive {
      border: 3px solid var(--selfserveprimarycolor);
    }
  }
  &:hover {
    .pcheck2 {
      opacity: 1;
      transform: scale(1);
    }
    .pbig {
      color: #333;
    }
    &.pactive .pop-view-txt {
      top: 55px;
    }
    .pop-view-txt {
      color: var(--selfserveprimarycolor) !important;
      top: 50px;
    }
  }
}
.pcheck2 {
  background-color: var(--selfserveprimarycolor) !important;
  width: 24px !important;
  height: 24px !important;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  margin: -37px 0px 0px 98px;
  color: #fff;
  display: block;
  opacity: 0;
  transform: scale(1.4);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pactive .pcheck2 {
  opacity: 1;
  transform: scale(1);
}

.pactive .pop-view-txt {
  color: var(--selfserveprimarycolor) !important;
}
.pop-icon {
  font-size: 39px;
  color: #666;
  margin-bottom: 10px;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pbig {
  font-size: 48px;
  margin-top: 10px;
  color: #6b7579;
}

.pactive .pbig {
  color: #333;
}

.pop-view-txt {
  font-size: 15px;
  color: #a7aaaf;
  color: #4d535a;
  position: relative;
  top: 55px;
  pointer-events: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pactive .pop-view-txt {
  color: var(--selfserveprimarycolor);
  top: 55px;
}
.readonly-dt {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
