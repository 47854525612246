<template>
  <div>
    <div :class="`tag-user ${readonly ? 'readonly-bp' : ''}`">
      <div id="brand_lift_tag" class="tagsinput">
        <span v-for="competitor in selectedCompetitors" :key="competitor" class="tag">
          <span>{{ competitor }}</span>
          <span class="tag-deselected">
            <font-awesome-icon :icon="['fal', 'times']" @click="clearData(competitor)" />
          </span>
        </span>
        <input
          class="tag-input"
          :class="[isTagExists ? 'error' : '']"
          placeholder="Add a competitor and hit Enter"
          :value="inputVal"
          :disabled="selectedCompetitors.length >= 4"
          @change="onFocusOut"
          @focusout="onFocusOut"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';

export default {
  name: 'BAddTag',
  props: {
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputVal: '',
      isTagExists: false,
    };
  },

  computed: {
    plan: get('common/plan'),
    selectedCompetitors() {
      if (
        !isBlank(this.plan.lucidCampaignInfo) &&
        !isBlank(this.plan.lucidCampaignInfo.brandLiftInfo)
      ) {
        return this.plan.lucidCampaignInfo.brandLiftInfo.competitors;
      }
      return [];
    },
  },

  methods: {
    onFocusOut(val) {
      let d = val.target.value;
      if (!isBlank(d)) {
        d = d.trim();
        const competitor = this.selectedCompetitors ? [...this.selectedCompetitors] : [];
        const isExist = competitor.some((a) => a === d);
        if (!isExist) {
          competitor.push(d);
          this.$emit('addTags', competitor);
          this.inputVal = '';
        }
      }
    },
    onInputFocusOut(val) {
      this.$emit(val.target.value, 'focusout');
    },
    clearData(val) {
      const competitor = this.selectedCompetitors.filter((d) => d !== val);
      this.$emit('addTags', competitor);
    },
    createItem(val) {
      return { checked: false, value: val };
    },

    onKeyUp(val) {
      if (this.selectedCompetitors && val.target && !isBlank(val.target.value)) {
        const d = val.target.value.trim().toUpperCase();
        this.isTagExists = this.selectedCompetitors.some((a) => a.value === d);
      } else {
        this.isTagExists = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';

.tagsinput {
  width: auto;
  min-height: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: #fff;
  font-size: 14px;
  line-height: 20px;
  color: #556270;
  padding: 5px 5px 2px 5px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  &:focus-visible {
    outline: none !important;
  }
  .tag {
    position: relative;
    background: var(--selfserveprimarycolor);
    display: inline-block;
    max-width: 100%;
    word-wrap: break-word;
    color: #fff;
    padding: 6px 10px;
    border-radius: 2px;
    margin: 0 5px 5px 0;
    top: 2px;
    border: unset;
    font-weight: 300;
    font-size: 14px;
  }
  .tag-deselected {
    line-height: 13px;
    margin-left: 4px;
    svg {
      color: #fff;
    }
  }
  .tag-input {
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    padding: 5px 5px 2px 5px;
    outline: none;
    flex-grow: 1;
    height: 35px;
    font-size: 13px;
    font-family: 'Manrope', sans-serif;
  }
}
.tag-user {
  max-width: 860px;
  min-width: 600px;
  margin-bottom: 16px;
}
.readonly-bp {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
