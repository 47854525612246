<template>
  <tr
    :class="
      `${plan._uuid === expandedPlanId ? 'activePlanRow' : 'defaultPlanRow'} ${
        isSaveLoading ? 'disable-row' : ''
      }`
    "
  >
    <td>
      <div v-if="plan._uuid !== expandedPlanId" class="plan-expand" @click="onExpandPlan(plan)">
        <font-awesome-icon :icon="['far', 'chevron-right']" class="inline-fa" />
      </div>
      <div v-else class="plan-expand" @click="onClosePlan()">
        <font-awesome-icon :icon="['far', 'times']" class="inline-fa" />
      </div>
    </td>
    <td class="tleft ">
      <div v-if="!isPlanEditable" class="plan-row-campaign-name" @click="onClickCampaignName(plan)">
        {{ plan.campaignName }}
      </div>
      <div v-else>
        <k-text-input
          ref="campaignName"
          :required="true"
          class="plan-row-campaign-name_no_edit"
          :value="tempCampaignName"
          :max-length="255"
          @change="onChangeCampaignName"
        />
      </div>
    </td>

    <td>
      <div v-if="!isPlanEditable">
        {{ planStartDate }}
      </div>
      <div v-else>
        <k-date-picker
          :range="false"
          icon-pos="right"
          class="temp-plan-date"
          :lower-bound="nextDayDate"
          :upper-bound="getUpperBound()"
          :allow-dates-in-past="false"
          :value-start="formatDate(tempPlanStartDate)"
          @change="onChangePlanStartDate"
        />
      </div>
    </td>
    <td>
      <div v-if="!isPlanEditable">
        {{ planEndDate }}
      </div>
      <div v-else>
        <k-date-picker
          :range="false"
          icon-pos="right"
          class="temp-plan-date"
          :lower-bound="getEndDateLowerBound()"
          :allow-dates-in-past="false"
          :value-start="formatDate(tempPlanEndDate)"
          @change="onChangePlanEndDate"
        />
      </div>
    </td>
    <td>{{ planBudget }}</td>
    <td>
      {{ formatNumberAsFullNumber(plan.spend, 'CURRENCY') }}
    </td>
    <td
      :class="`pacing-column ${plan.pace ? '' : 'disableTooltip'}`"
      :data-tool-tip="
        `Campaign pacing at ${Math.round(Number(plan.pace))}% of where expected to date.`
      "
    >
      <plan-pacing-chart :pacing="Math.round(Number(plan.pace))" />
    </td>

    <td
      class="goal-column"
      :class="[showGoalTooltip(plan.flipCampaignInfo) ? '' : 'disableTooltip']"
      :data-tool-tip="goalTooltip(plan.flipCampaignInfo)"
    >
      <span v-if="plan.flipCampaignInfo && plan.flipCampaignInfo.goal">
        {{ plan.flipCampaignInfo.goal.name }}
      </span>
    </td>
    <td>
      <span
        v-if="
          plan.flipCampaignInfo && plan.flipCampaignInfo.goal && plan.flipCampaignInfo.goal.metric
        "
      >
        {{ formatMetricValue(plan.flipCampaignInfo.goal.metric.label, plan.goalMetric) }}
      </span>
    </td>

    <td>
      <div v-if="showLoader" class="loading-spinner">
        <b-loading-spinner />
      </div>
      <div
        v-for="(s, idx) in liStatusCount"
        v-else
        :key="idx"
        class="stat-bubble st-activated"
        :style="workFlowColor(s.displayName)"
      >
        {{ s.count }}&nbsp;{{ s.displayName }}
      </div>
    </td>

    <td :class="`col-activation-actions ${showLoader ? 'disable-btn' : ''}`">
      <div v-if="!plan.updatedPlan && plan.id">
        <div v-if="canPlayCampaign" class="mvc-btn btn-size2 report-btn">
          <k-icon-tooltip
            tooltip="Play"
            :icon="['fas', 'play']"
            class="inline-fa fa-w-16"
            @click="playCampaign"
          />
        </div>
        <div v-else-if="canPauseCampaign" class="mvc-btn btn-size2 report-btn">
          <k-icon-tooltip
            tooltip="Pause"
            :icon="['fas', 'pause']"
            class="inline-fa fa-w-16"
            @click="pauseCampaign"
          />
        </div>
        <!-- disabled play button displayed if no Play/Paused condition satisfied -->
        <div v-else class="mvc-btn btn-size2 report-btn disabled">
          <k-icon-tooltip
            tooltip="Play"
            :icon="['fas', 'play']"
            class="inline-fa fa-w-16"
            :disabled="true"
          />
        </div>
        <div
          :class="`mvc-btn btn-size2 pen-btn ${isCampaignEndedOrCanceled ? 'disabled' : ''}`"
          @click="onClickEditPlan(plan)"
        >
          <k-icon-tooltip
            tooltip="Edit"
            :icon="['fas', 'pen']"
            class="inline-fa fa-w-16"
            :disabled="isCampaignEndedOrCanceled"
          />
        </div>

        <div class="mvc-btn btn-size2 clone-btn" @click="onClonePlan(plan)">
          <k-icon-tooltip tooltip="Clone" :icon="['fas', 'clone']" class="inline-fa fa-w-16" />
        </div>

        <div class="mvc-btn btn-size2 report-btn" @click="handleDashBoardSwitch">
          <k-icon-tooltip
            tooltip="Dashboard"
            :icon="['fas', 'chart-pie']"
            class="inline-fa fa-w-16"
          />
        </div>

        <div class="mvc-btn btn-size2 trash-btn" @click="onClickDeletePlan(plan)">
          <k-icon-tooltip tooltip="Cancel" :icon="['fas', 'trash']" class="inline-fa fa-w-16" />
        </div>
      </div>
      <div v-else-if="(!plan.id || isPlanEditable) && onEditClonedPlan(plan)">
        <div
          v-if="!isPlanEditable"
          :class="`mvc-btn btn-size2 pen-btn`"
          @click="onClickEditPlan(plan)"
        >
          <k-icon-tooltip tooltip="Edit" :icon="['fas', 'pen']" class="inline-fa fa-w-16" />
        </div>
        <div class="mvc-btn btn-size2 cancel-btn ft-12" @click="handleDiscardChanges(plan)">
          <k-icon-tooltip tooltip="Cancel" :icon="['fas', 'times']" class="inline-fa fa-w-16" />
        </div>
        <div class="mvc-btn btn-size2 save-btn ft-12" @click="onClickSavePlan(plan)">
          <k-icon-tooltip tooltip="Save" :icon="['fas', 'save']" class="inline-fa fa-w-16" />
        </div>
      </div>
    </td>
    <ConfirmationModal
      v-if="showPlanEditWarning"
      :message="warningTitle"
      :show-confirm-btn="showConfirmBtn"
      :cancel-btn-text="cancelBtnText"
      :header-text="headerText"
      :positive-button-label="labelYes"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      @close="handleCancelChanges"
      @onNegativeButtonClick="handleConfirmPlanChanges"
      @onPositiveButtonClick="handleCancelChanges"
    />
    <ConfirmationModal
      v-if="showPlanEditConflictWarning"
      :message="warningTitle"
      :show-confirm-btn="showConfirmBtn"
      header-text="Cancel Ad Group Changes"
      :cancel-btn-text="cancelBtnText"
      positive-button-label="YES"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      @close="handleCancelChanges"
      @onNegativeButtonClick="handleCancelChanges"
      @onPositiveButtonClick="adjustLineItemDates"
    />
  </tr>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import _ from 'underscore';
import moment from 'moment';
import { Plan } from 'adready-api/model/plan';
import commonHelper, { isBlank, inert } from 'adready-api/helpers/common';
import deepClone from 'adready-api/helpers/deepclone-helpers';
import {
  IOS_DRAFT,
  IOS_LIVE,
  LIS_DRAFT,
  IO_STATE_CHANGE,
  IOS_CHANGE_APPLIED,
  IOS_CHANGE_NEW,
  LIS_CANCELED,
  LIS_ENDED,
  LIS_PENDING_CANCELLATION,
} from 'adready-api/constant';
import planApi from 'adready-api/api/plans';
import KIconTooltip from 'adready-vue/components/elements/k-icon-tooltip.vue';
import KDatePicker from 'adready-vue/components/elements/k-date-picker.vue';
import lineItemsApi from 'adready-api/api/line-items';
import ConfirmationModal from '~/components/campaigns/confirmation-modal.vue';
import { formatDate, formatDateInEST } from '~/util/apiDateFormat';
import forklift from '~/components/mixins/forklift-mixin';
import { formatNumberAsFullNumber } from '~/util/numeral';
import { formatMetricValue, addDaysToDate, isFlamingoInstance } from '~/util/utility-functions';
import { formatDateString } from '~/helpers/global/misc-helpers';
import emailMixin from '~/components/mixins/email-mixin';
import fetchMediaTypesMethodMixin from '~/components/mixins/fetch-mediatypes-method-mixin';
import lineItemHelpers from '~/helpers/line-item-helpers';
import { LAYOUT_TYPES, NEW_CAMPAIGN_PAGES } from '~/constant';
import { eBus } from '~/main';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import ioHelpers from '~/helpers/io-helpers';

import {
  canPauseLineItem,
  canPlayLineItem,
  displayLIStatusNames,
  LI_DISPLAY_STATUS_COLOR_CODES,
  STATUS_CAN_PLAY,
  pausedStatusAndWorkflowChange,
  playStatusAndWorkflowChange,
  isStatusReadonly,
} from '~/helpers/line-item-status-helpers';

export default {
  name: 'PlanRow',
  components: {
    PlanPacingChart: () =>
      import(
        /* webpackChunkName: "plan-pacing-chart" */ '~/components/charts/plan-pacing-chart.vue'
      ),
    KIconTooltip,
    KDatePicker,
    ConfirmationModal,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  mixins: [forklift, emailMixin, fetchMediaTypesMethodMixin],

  props: {
    plan: {
      type: Object,
      required: true,
    },
    selectedStatusIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      formatDate,
      formatNumberAsFullNumber,
      addDaysToDate,
      formatMetricValue,
      LAYOUT_TYPES,
      STATUS_FOR_PLAY_BUTTON: [
        LIS_DRAFT,
        LIS_CANCELED,
        LIS_PENDING_CANCELLATION,
        LIS_ENDED,
        ...STATUS_CAN_PLAY,
      ],
      STATUS_CAN_PLAY,
      isPlanEditable: false,
      tempPlanStartDate: '',
      tempPlanEndDate: '',
      tempCampaignName: '',
      showPlanEditWarning: false,
      showPlanEditConflictWarning: false,
      showConfirmBtn: true,
      cancelBtnText: 'NO',
      warningTitle: '',
      lineItemsWithDateConflict: [],
      isSaveLoading: false,
      isErrorWarning: false,
      checkValidEndDateForCampaign: false,
      labelYes: 'YES',
      headerText: 'Cancel Campaign Changes',
    };
  },
  computed: {
    planIdForLineItemStatusCountLoading: sync('common/planIdForLineItemStatusCountLoading'),
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    universalPixelId: get('common/universalPixelId'),
    ioOptions: get('dashboard/filters@ioOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    lineItemStatusCountForPlans: get('common/liStatusCountForPlans'),
    activePlan: sync('common/plan'),
    expandedPlanId: sync('common/expandedPlanId'),
    allUsers: get('common/allUsers'),
    liStatusOptions: get('common/liStatusOptions'),
    eventOptions: get('dashboard/filters@eventOptions'),
    showLoader() {
      if (isBlank(this.planIdForLineItemStatusCountLoading)) {
        return false;
      }
      return this.planIdForLineItemStatusCountLoading === this.plan.id;
    },
    /**
     * To show the play button on campaign actions
     * At least one lineItem should have paused status to enable it
     */
    canPlayCampaign() {
      return (
        !this.lineStatusObjList.some((l) => !this.STATUS_FOR_PLAY_BUTTON.includes(l.id)) &&
        this.lineStatusObjList.some((l) => this.STATUS_CAN_PLAY.includes(l.id))
      );
    },

    isCampaignEndedOrCanceled() {
      if (isBlank(this.lineStatusObjList)) {
        return true;
      }

      const hasStatusOtherThanEndedOrCanceled = this.lineStatusObjList.some(
        (l) => !isStatusReadonly(l.id)
      );
      return !hasStatusOtherThanEndedOrCanceled;
    },

    /**
     * To show the pause button on campaign actions
     */
    canPauseCampaign() {
      return this.lineStatusObjList.some((l) => canPauseLineItem(l.id));
    },

    /**
     * Added adGroup display status name to the status count list.
     */
    lineStatusObjList() {
      if (this.lineItemStatusCountForPlans && this.lineItemStatusCountForPlans.length > 0) {
        return this.lineItemStatusCountForPlans
          .filter(
            (l) =>
              l.planId === this.plan.id &&
              l.type === 'LI_Status' &&
              this.liStatusOptions.some((s) => l.id === s.id)
          )
          .map((l) => {
            const tmp = { ...l };
            tmp.displayName = displayLIStatusNames(tmp.id);
            return tmp;
          });
      }
      return [];
    },

    liStatusCount() {
      const liCount = this.plan.lineItems ? this.plan.lineItems.length : 0;
      const DefaultLiStatusCount = {
        Draft: {
          count: liCount,
          id: 1,
          name: 'Draft',
          parentId: null,
          planId: this.plan.id,
          type: 'LI_Status',
          displayName: 'Draft',
          ids: [],
        },
      };
      if (isBlank(this.lineStatusObjList)) {
        return DefaultLiStatusCount;
      }
      return this.lineStatusObjList.reduce((acc, val) => {
        const d = acc[val.displayName];
        if (d) {
          d.count += val.count;
          d.ids.push(val.id); // adding status ids for grouping of status.
        } else {
          acc[val.displayName] = val;
          acc[val.displayName].ids = [val.id];
        }
        return acc;
      }, {});
    },

    planBudget() {
      if (this.plan.statusId === IOS_DRAFT && !isBlank(this.plan.lineItems)) {
        const budget = this.plan.lineItems
          .map((li) => li.lineItemBudget)
          .reduce((prev, next) => prev + next);
        return !isBlank(budget) ? formatNumberAsFullNumber(budget, 'CURRENCY') : '';
      }
      if (this.lineItemStatusCountForPlans && this.lineItemStatusCountForPlans.length > 0) {
        const planBudgetData = this.lineItemStatusCountForPlans.filter(
          (l) => l.planId === this.plan.id && l.type === 'total_budget' && l.name === 'planBudget'
        );
        return !isBlank(planBudgetData)
          ? formatNumberAsFullNumber(planBudgetData[0].count, 'CURRENCY')
          : '';
      }
      return '';
    },
    planStartDate() {
      return this.plan.flightStartDate ? formatDateInEST(this.plan.flightStartDate) : '';
    },
    planEndDate() {
      return this.plan.flightEndDate ? formatDateInEST(this.plan.flightEndDate) : '';
    },

    payload() {
      return {
        includePacing: true,
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
        pixel: this.universalPixelId,
      };
    },
    currentDate() {
      return moment(formatDateString(''), 'YYYY-MM-DD');
    },
    nextDayDate() {
      return addDaysToDate(new Date(), 1);
    },
  },

  methods: {
    /**
     * Change the campaign's lines to the 'Ready for Activation' status.
     */
    async playCampaign() {
      const loadedPlan = await this.loadLineItems(this.plan);
      if (loadedPlan) {
        const linesToPlay = loadedPlan.lineItems
          .filter((l) => l.flip)
          .filter((l) => canPlayLineItem(l.statusId))
          .map((l) => {
            const updateStatus = playStatusAndWorkflowChange(l.statusId);
            const updatedLineItem = Object.assign(l.inertClone(), updateStatus);
            return updatedLineItem;
          });
        this.changeStatus(linesToPlay, this.plan);
      }
    },

    /**
     * Change the campaign's lines to the 'Ready for Pause'/'Paused' status.
     */
    async pauseCampaign() {
      const loadedPlan = await this.loadLineItems(this.plan);
      if (loadedPlan) {
        const linesToPause = loadedPlan.lineItems
          .filter((l) => l.flip)
          .filter((l) => canPauseLineItem(l.statusId))
          .map((l) => {
            const updateStatus = pausedStatusAndWorkflowChange(l.statusId);
            const updatedLineItem = Object.assign(l.inertClone(), updateStatus);
            return updatedLineItem;
          });
        this.changeStatus(linesToPause, this.plan);
      }
    },

    changeStatus(linesToChange, plan) {
      this.planIdForLineItemStatusCountLoading = this.plan.id;
      const updateLinesPromise = linesToChange.map((line) => {
        return lineItemsApi
          .update(
            {
              planId: plan.id,
              id: line.id,
            },
            line
          )
          .then((updated) => {
            this.$store.dispatch('common/updateLineItem', {
              _uuid: line._uuid,
              lineItem: updated,
            });
            return updated;
          });
      });

      Promise.all(updateLinesPromise).then((lines) => {
        this.loadLineItemStatusCount(this.account.id, this.advertiser.id, true, true);
        this.loadLineItemStatusCountForPlans(this.account.id, this.advertiser.id, true, true);
        const emailData = {
          emails: [],
          notes: '',
          additionalEmails: [],
          skipNotification: false,
          emailSource: 'FLIPPLUS',
        };
        emailData.lineItems = lines;
        const ownerUser = this.allUsers.find((item) => item.id === plan.ownerId);
        emailData.emails = [ownerUser.email];
        planApi.updateStatusEmailNotification(this.plan.id, emailData);
      });
    },

    async loadLineItems(plan) {
      try {
        this.$emit('toggleLineItemsLoading', true);
        const data = await planApi.read(plan.id, this.payload);
        this.activePlan = {
          ...data,
          lineItems: data.lineItems.filter((d) => this.selectedStatusIds.includes(d.statusId)),
        };
        this.$emit('toggleLineItemsLoading', false);
        return data;
      } catch (err) {
        console.error('error fetching goals ->', err);
        this.$emit('toggleLineItemsLoading', false);
      }
      this.$emit('toggleLineItemsLoading', false);
      return undefined;
    },

    async onExpandPlan(plan) {
      this.$emit('expandPlan', plan);
      this.expandedPlanId = plan._uuid;
      if (isBlank(this.activePlan.lineItems)) {
        await this.loadLineItems(plan);
      }
    },

    onClosePlan() {
      this.expandedPlanId = undefined;
    },

    onClickDeletePlan(plan) {
      this.$emit('onClickDeletePlan', plan);
    },
    onClonePlan(plan) {
      if (plan.id && isBlank(plan.lineItems)) {
        planApi.read(plan.id).then((freshPlan) => {
          this.clonePlan(freshPlan);
        });
      } else {
        this.clonePlan(plan);
      }
    },
    clonePlan(plan) {
      const clonedPlan = deepClone.clonePlan(
        plan,
        true,
        IOS_DRAFT,
        LIS_DRAFT,
        undefined,
        undefined,
        true
      );
      clonedPlan.campaignName = `Copy of ${plan.campaignName}`;
      clonedPlan.crPlans = [];
      clonedPlan.flightStartDate = '';
      clonedPlan.flightEndDate = '';

      const lis = clonedPlan.lineItems;
      lis.forEach((i) => {
        if (!isBlank(i.budgetGroup)) {
          i.budgetGroup = {};
          i.budgetGroupId = null;
        }
      });
      clonedPlan.lineItems = lis;
      // add the cloned plan
      this.$store.dispatch('common/addPlan', clonedPlan);
    },
    async onEditableSavePlan(plan) {
      let planLineItems = [];
      this.lineItemsWithDateConflict = [];
      this.labelYes = 'YES';
      const updatedPlan = {
        ...plan,
        flightStartDate: this.tempPlanStartDate,
        flightEndDate: this.tempPlanEndDate,
        campaignName: this.tempCampaignName,
      };

      if (isBlank(this.tempPlanStartDate)) {
        this.warningTitle = 'Please enter campaign start date';
        this.showConfirmBtn = false;
        this.isErrorWarning = true;
        this.cancelBtnText = 'CLOSE';
        this.showPlanEditWarning = true;
        this.tempPlanStartDate = this.planStartDate;
        this.labelYes = 'OK';
        this.headerText = 'Unable to save edits';
        this.isPlanEditable = true;
        return { isSaveEnabled: false, updatedPlan };
      }
      if (isBlank(this.tempPlanEndDate)) {
        this.warningTitle = 'Please enter campaign end date';
        this.showConfirmBtn = false;
        this.isErrorWarning = true;
        this.cancelBtnText = 'CLOSE';
        this.showPlanEditWarning = true;
        this.tempPlanEndDate = this.planEndDate;
        this.labelYes = 'OK';
        this.headerText = 'Unable to save edits';
        this.isPlanEditable = true;
        return { isSaveEnabled: false, updatedPlan };
      }

      if (moment(this.tempPlanStartDate).isAfter(this.tempPlanEndDate)) {
        this.warningTitle = 'Campaign start date must be before campaign end date.';
        this.showConfirmBtn = false;
        this.isErrorWarning = true;
        this.cancelBtnText = 'CLOSE';
        this.showPlanEditWarning = true;
        this.tempPlanStartDate = this.planStartDate;
        this.tempPlanEndDate = this.planEndDate;
        this.labelYes = 'OK';
        this.headerText = 'Unable to save edits';
        this.isPlanEditable = true;
        return { isSaveEnabled: false, updatedPlan };
      }
      if (isBlank(this.tempCampaignName)) {
        this.warningTitle = 'Please enter a campaign name.';
        this.showConfirmBtn = false;
        this.isErrorWarning = true;
        this.cancelBtnText = 'CLOSE';
        this.showPlanEditWarning = true;
        this.tempCampaignName = this.plan.campaignName;
        this.labelYes = 'OK';
        this.headerText = 'Unable to save edits';
        this.isPlanEditable = true;
        return { isSaveEnabled: false, updatedPlan };
      }
      if (this.checkValidEndDateForCampaign) {
        await planApi.read(plan.id, { includePacing: true }).then((freshPlan) => {
          planLineItems = freshPlan?.lineItems || [];
        });
        if (planLineItems.length > 0) {
          for (let i = 0; i < planLineItems.length; i++) {
            if (planLineItems[i]?.flip) {
              if (moment(this.tempPlanEndDate).isBefore(planLineItems[i]?.flightEndDate))
                this.lineItemsWithDateConflict.push(planLineItems[i]);
            }
          }
        }
        if (this.lineItemsWithDateConflict.length > 0) {
          this.showPlanEditConflictWarning = true;
          this.warningTitle =
            'There are ad groups that end outside of your new campaign end date. Do you want to adjust any group end dates that fall outside of your new campaign flight to match new campaign end date?';

          if (plan.statusId === IOS_LIVE) {
            this.showConfirmBtn = false;
            this.cancelBtnText = 'CLOSE';
          } else {
            this.showConfirmBtn = true;
            this.cancelBtnText = 'NO';
          }

          return { isSaveEnabled: false, updatedPlan };
        }
      }

      return {
        isSaveEnabled: true,
        updatedPlan: {
          ...plan,
          flightStartDate: this.tempPlanStartDate,
          flightEndDate: this.tempPlanEndDate,
          campaignName: this.tempCampaignName,
        },
      };
    },
    async onClickSavePlan(plan) {
      let savingPlan = new Plan(plan);
      let savingEnabled = true;
      this.isSaveLoading = true;
      const { isSaveEnabled, updatedPlan } = await this.onEditableSavePlan(plan);
      savingPlan = new Plan(updatedPlan);
      savingEnabled = isSaveEnabled;
      if (savingEnabled) {
        let dbPlan = plan;
        if (plan.id) {
          dbPlan = await planApi.read(plan.id, {
            includePacing: false,
          });
        }

        if (dbPlan.statusId === IOS_LIVE) {
          this.isSaveLoading = true;
          this.createOrUpdatePlanCR(plan);
          this.isSaveLoading = false;
          this.isPlanEditable = false;
          return;
        }
        const planToSave = savingPlan.inertClone();
        if (savingPlan.id) {
          this.updatePlanInDb(planToSave);
        } else {
          const planToCreate = savingPlan.inertClone();
          planToSave.lineItems = [];
          plan.source = 'FLIPPLUS';
          await planApi.create(planToSave).then((updatedPlan1) => {
            planToCreate.id = updatedPlan1.id;
            planToCreate.source = updatedPlan1.source;
            planToCreate.updatedPlan = false;
            this.$store.dispatch('common/updatePlan', {
              _uuid: updatedPlan._uuid,
              plan: planToCreate,
            });
            this.loadLineItemStatusCount(this.account.id, this.advertiser.id, true, true);
            this.loadLineItemStatusCountForPlans(this.account.id, this.advertiser.id, true, true);
            this.onExpandPlan(planToCreate);
            this.$emit('toggleLineItemsLoading', false);
          });
        }
        if (this.isPlanEditable && this.expandedPlanId) {
          if (isBlank(savingPlan.lineItems) && this.expandedPlanId) {
            this.loadLineItems(savingPlan);
          }
        }
        this.isPlanEditable = false;
      }
      this.isSaveLoading = false;
    },
    async updatePlanInDb(planToSave, callback) {
      await planApi.update(planToSave.id, planToSave).then((updatedPlan) => {
        planToSave.updatedPlan = false;
        if (updatedPlan.statusId !== IOS_DRAFT) {
          const notes = `There are new changes in Campaign - Campaign ID: ${updatedPlan.id} , see attached csv for details`;
          this.sendEmailNotification(updatedPlan, notes);
        }
        this.$store.dispatch('common/updatePlan', { plan: planToSave });
        this.loadLineItemStatusCount(this.account.id, this.advertiser.id, true, true);
        this.loadLineItemStatusCountForPlans(this.account.id, this.advertiser.id, true, true);
        if (callback) {
          callback(updatedPlan);
        }
      });
    },
    async handleConfirmPlanChanges() {
      this.showPlanEditWarning = false;
      this.isSaveLoading = false;
      this.isPlanEditable = true;
    },
    async createOrUpdatePlanCR(parentPlan) {
      let crPlan;
      if (!isBlank(parentPlan.crPlans)) {
        crPlan = parentPlan.crPlans.find(
          (cp) => cp.state === 'CHANGE' && cp.statusId !== IOS_CHANGE_APPLIED
        );
        if (crPlan) {
          crPlan.flightStartDate = this.tempPlanStartDate;
          crPlan.flightEndDate = this.tempPlanEndDate;
          crPlan.campaignName = this.tempCampaignName;
          // update Exist CR plan
        } else {
          crPlan = new Plan();
          crPlan.state = IO_STATE_CHANGE;
          crPlan.statusId = IOS_CHANGE_NEW;
          crPlan.parentId = parentPlan.id;
          crPlan.flightStartDate = this.tempPlanStartDate;
          crPlan.flightEndDate = this.tempPlanEndDate;
          crPlan.campaignName = this.tempCampaignName;
        }
      } else {
        crPlan = new Plan();
        crPlan.state = IO_STATE_CHANGE;
        crPlan.statusId = IOS_CHANGE_NEW;
        crPlan.parentId = parentPlan.id;
        crPlan.flightStartDate = this.tempPlanStartDate;
        crPlan.flightEndDate = this.tempPlanEndDate;
        crPlan.campaignName = this.tempCampaignName;
      }
      if (crPlan) {
        crPlan.accountId = this.account.id;
        crPlan.advertiserId = this.advertiser.id;
        this.saveOrUpdateCRPlan(crPlan);
        const updatedPlan = {
          ...parentPlan.inertClone(),
          flightStartDate: this.tempPlanStartDate,
          flightEndDate: this.tempPlanEndDate,
          campaignName: this.tempCampaignName,
          updatedPlan: false,
        };
        this.$store.dispatch('common/updatePlan', { plan: updatedPlan });
      }
    },
    saveOrUpdateCRPlan(planToUpdate) {
      const plan = new Plan(inert(planToUpdate));
      plan.lineItems = [];
      if (plan.id) {
        planApi.update(planToUpdate.id, planToUpdate).then((updatedPlan) => {
          this.sendEmailNotification(updatedPlan);
        });
      } else {
        planApi.create(plan).then((updatedPlan) => {
          this.sendEmailNotification(updatedPlan);
        });
      }
    },
    async fetchAdGroupData(planId) {
      let adGroupData = [];
      let response = '';
      if (planId) {
        response = await advertiserReportsAPI.adGroupOptions(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString({ planIds: planId, includeNonFlip: isFlamingoInstance() })
        );
        if (response?.data?.length > 0) {
          adGroupData = response?.data;
        }
        if (adGroupData.length > 0) {
          adGroupData = adGroupData.map((agd) => {
            return { ...agd, value: agd?.lineItemName || '', checked: true };
          });
        }
      }
      return adGroupData;
    },
    async handleDashBoardSwitch() {
      const { flightStartDate } = this.plan;
      const currentDate = moment(formatDateString(''), 'YYYY-MM-DD');
      const isCampaignStarted = moment(new Date(flightStartDate)).isBefore(currentDate);

      if (!isCampaignStarted) {
        eBus.$emit('on-show-intermediate-screen');
        return;
      }

      const initialIoOptions = JSON.parse(JSON.stringify(this.ioOptions));
      const adGroupData = await this.fetchAdGroupData(this.plan?.id);
      let initialAdGroupOptions = JSON.parse(JSON.stringify(adGroupData));
      const selectedIoOptionIndex = this.ioOptions.findIndex(
        (ioOption) => ioOption?.campaignId === this.plan?.id
      );
      if (selectedIoOptionIndex !== -1) {
        initialIoOptions.forEach((ioOption, index) => {
          if (selectedIoOptionIndex === index) {
            ioOption.checked = true;
          } else {
            ioOption.checked = false;
          }
        });
        initialAdGroupOptions.forEach((adGroupOption) => {
          adGroupOption.checked = true;
        });
      } else {
        initialIoOptions.forEach((ioOption) => {
          ioOption.checked = true;
        });
        initialAdGroupOptions = JSON.parse(JSON.stringify(this.adGroupOptions));
        initialAdGroupOptions.forEach((adGroupOption) => {
          adGroupOption.checked = true;
        });
      }

      const { flightEndDate } = this.plan;

      const { flipCampaignInfo } = this.plan;

      const dateObj = ioHelpers.calculateDatesForDashboardSwitch(
        formatDateInEST(flightStartDate),
        formatDateInEST(flightEndDate),
        flipCampaignInfo
      );
      const methodology = ioHelpers.getMethodology(flipCampaignInfo?.methodology?.value);
      const showUniques = flipCampaignInfo?.optimization?.impressions?.value === 'unique';
      const attribution =
        flipCampaignInfo?.optimization?.attribution?.value === 'Full Conversions'
          ? 'full'
          : 'partial';

      const conversionWindowValue = flipCampaignInfo?.conversionWindow?.value
        ? Number(flipCampaignInfo?.conversionWindow?.value)
        : 31;

      const conversionWindowType = flipCampaignInfo?.conversionWindow?.unit?.value || 'days';

      const conversionWindow =
        isBlank(conversionWindowType) || conversionWindowType === 'days'
          ? conversionWindowValue * 24
          : conversionWindowValue;

      const { eventOptions } = this;
      const localEventOpt = inert(eventOptions);
      const category = flipCampaignInfo?.goal?.category;
      const subCategory = flipCampaignInfo?.goal?.subCategories;
      const metricLabel = flipCampaignInfo?.goal?.metric?.label;
      const event = category === 'checkout' && metricLabel === 'ROAS' ? 'Revenue' : category;
      localEventOpt.forEach((element) => {
        element.selected =
          event === 'Revenue' ? element.event === event : element.category === category;
        element.open = element.selected;
        const { children } = element;
        children?.forEach((child) => {
          child.selected = subCategory?.includes(child.category);
        });
      });
      this.$store.dispatch('common/setShowFilters', false);
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      if (flipCampaignInfo !== null) {
        this.$store.set('dashboard/filters@eventOptions', localEventOpt);
        this.$store.set('dashboard/filters@conversionWindowDisplay', conversionWindowValue);
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          conversionWindowType.toUpperCase()
        );
        this.$store.set('dashboard/filters@conversionWindow', conversionWindow);
        this.$store.set('dashboard/filters@attribution', attribution);
        const totalOrUniqueRadioOptions = this.$store.copy(
          'dashboard/filters@totalOrUniqueRadioOptions'
        );
        totalOrUniqueRadioOptions[1].disabled = event === 'Revenue';
        totalOrUniqueRadioOptions.forEach((tuo) => {
          tuo.active =
            flipCampaignInfo?.optimization?.impressions?.value.toUpperCase() === tuo.text || false;
        });
        this.$store.set(
          'dashboard/filters@totalOrUniqueRadioOptions',
          JSON.parse(JSON.stringify(totalOrUniqueRadioOptions))
        );
        this.$store.set('dashboard/filters@showUniques', showUniques);
        this.$store.set('dashboard/filters@methodology', methodology);
      }
      if (dateObj !== null) {
        this.$store.set('dashboard/dates@startDate', dateObj.startDate);
        this.$store.set('dashboard/dates@endDate', dateObj.endDate);
        this.$store.set('dashboard/dates@compareStartDate', dateObj.compareStartDate);
        this.$store.set('dashboard/dates@compareEndDate', dateObj.compareEndDate);
      }
      this.$store.set('dashboard/filters@ioOptions', initialIoOptions);
      this.$store.set('dashboard/filters@adGroupOptions', initialAdGroupOptions);
      const mediaTypeOptions = await this.fetchSpecificMediaTypeOptions(
        initialIoOptions,
        this.adGroupOptions
      );
      this.$store.set('dashboard/filters@mediaTypeOptions', mediaTypeOptions);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.DASHBOARD);
    },

    workFlowColor(displayName) {
      const code = LI_DISPLAY_STATUS_COLOR_CODES[displayName];
      if (isBlank(code)) {
        return '';
      }
      return `background-color: ${code} !important`;
    },
    onClickCampaignName(plan) {
      this.activePlan = plan;
      this.expandedPlanId = undefined;
      eBus.$emit('on-edit-item', {
        pageName: NEW_CAMPAIGN_PAGES.REVIEW_CAMPAIGN,
      });
      eBus.$emit('on-change-selected-menu-option', LAYOUT_TYPES.NEW_CAMPAIGNS);
    },
    onEditClonedPlan(plan) {
      this.isPlanEditable = true;
      if (!this.tempCampaignName && !plan.id) {
        this.tempCampaignName = plan.campaignName;
      }
      return true;
    },
    onClickEditPlan(plan) {
      this.isPlanEditable = true;
      this.tempCampaignName = plan.campaignName;
      if (isBlank(this.planStartDate)) {
        this.tempPlanStartDate = null;
      } else {
        this.tempPlanStartDate = commonHelper.createUTCDate(this.planStartDate);
      }
      if (isBlank(this.planEndDate)) {
        this.tempPlanEndDate = null;
      } else {
        this.tempPlanEndDate = commonHelper.createUTCDate(this.planEndDate);
      }
      this.$store.dispatch('common/updatePlan', {
        _uuid: plan._uuid,
        plan: {
          ...plan,
          updatedPlan: true,
        },
      });
    },
    onChangePlanStartDate(data) {
      this.tempPlanStartDate = data.start;
    },
    onChangePlanEndDate(data) {
      this.tempPlanEndDate = data.end;
    },
    handleCancelChanges() {
      this.showPlanEditWarning = false;
      this.showPlanEditConflictWarning = false;
      this.isPlanEditable = true;
      this.isSaveLoading = false;
      this.$store.dispatch('common/updatePlan', {
        _uuid: this.plan._uuid,
        plan: { ...this.plan, updatedPlan: false },
      });
      this.tempPlanStartDate = !isBlank(this.tempPlanStartDate) ? this.tempPlanStartDate : '';
      this.tempPlanEndDate = !isBlank(this.tempPlanEndDate) ? this.tempPlanEndDate : '';

      this.tempCampaignName = this.plan.campaignName;

      this.isErrorWarning = false;
    },
    updateLineItemDates(plan) {
      this.lineItemsWithDateConflict.forEach((l) => {
        l.flightEndDate = plan.flightEndDate;
        lineItemHelpers.addOrUpdateLineItem(l.planId, l);
        this.onClosePlan();
      });
    },
    adjustLineItemDates() {
      this.showPlanEditConflictWarning = false;
      this.isPlanEditable = false;
      this.isSaveLoading = false;
      const planToSave = this.plan.inertClone();
      planToSave.flightEndDate = this.tempPlanEndDate;
      planToSave.flightStartDate = this.tempPlanStartDate;
      this.updatePlanInDb(planToSave, this.updateLineItemDates);
    },
    handleDiscardChanges(plan) {
      if (!isBlank(this.plan.id) && this.isPlanEditable) {
        if (this.isCampaignChanged()) {
          this.isErrorWarning = false;
          this.showPlanEditWarning = true;
          this.warningTitle = 'Are you sure you want to cancel the changes?';
          this.showConfirmBtn = true;
          this.cancelBtnText = 'NO';
          this.headerText = 'Cancel Campaign Changes';
          this.labelYes = 'YES';
        } else {
          this.showPlanEditWarning = false;
          this.showPlanEditConflictWarning = false;
          this.isPlanEditable = false;
          this.isSaveLoading = false;
          this.$store.dispatch('common/updatePlan', {
            _uuid: this.plan._uuid,
            plan: { ...this.plan, updatedPlan: false },
          });
        }
      } else {
        this.$emit('onClickDiscardPlan', plan);
      }
    },
    isCampaignChanged() {
      return !(
        moment(commonHelper.createUTCDate(this.planEndDate)).isSame(this.tempPlanEndDate) &&
        moment(commonHelper.createUTCDate(this.planStartDate)).isSame(this.tempPlanStartDate) &&
        this.tempCampaignName === this.plan.campaignName
      );
    },
    onChangeCampaignName(val) {
      this.tempCampaignName = val;
    },
    getUpperBound() {
      if (this.tempPlanEndDate <= this.currentDate) {
        return undefined;
      }
      if (this.lineItemStatusCountForPlans && this.lineItemStatusCountForPlans.length > 0) {
        const planMinStartDate = this.lineItemStatusCountForPlans.filter(
          (l) => l.planId === this.plan.id && l.type === 'startDate'
        );
        const planMinStartDateVal = planMinStartDate[0]?.name
          ? new Date(planMinStartDate[0].name)
          : null;
        if (planMinStartDateVal <= this.currentDate) {
          return this.tempPlanEndDate;
        }
        if (!isBlank(planMinStartDateVal)) {
          return new Date(Math.min(planMinStartDateVal, this.tempPlanEndDate));
        }
      }
      return this.tempPlanEndDate;
    },
    getEndDateLowerBound() {
      if (!isBlank(this.tempLineItemEndDate) && this.tempPlanEndDate <= this.currentDate) {
        return this.nextDayDate;
      }
      if (this.lineItemStatusCountForPlans && this.lineItemStatusCountForPlans.length > 0) {
        const planEndDateData = this.lineItemStatusCountForPlans.filter(
          (l) => l.planId === this.plan.id && l.type === 'endDate'
        );
        const maxLineItemEndDate = planEndDateData[0]?.name
          ? new Date(planEndDateData[0].name)
          : null;
        if (!isBlank(maxLineItemEndDate)) {
          return new Date(Math.max(this.currentDate, maxLineItemEndDate, this.tempPlanStartDate));
        }
      }
      return new Date(Math.max(this.currentDate, this.tempPlanStartDate));
    },
    goalTooltip(flipCampaignInfo) {
      if (!flipCampaignInfo || !flipCampaignInfo.goal) {
        return '';
      }
      const { subCategories } = flipCampaignInfo.goal;
      return !isBlank(subCategories) && _.isArray(subCategories)
        ? `${flipCampaignInfo.goal.name}: ${subCategories.join(', ')}`
        : '';
    },
    showGoalTooltip(flipCampaignInfo) {
      return !isBlank(this.goalTooltip(flipCampaignInfo));
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-btn {
  pointer-events: none;
  opacity: 0.6;
}
.plan-row-campaign-name {
  cursor: pointer;
  &:hover {
    color: var(--selfserveprimarycolor);
  }
}
plan-row-campaign-name_no_edit {
  font-size: 12.16px;
  font-family: Manrope, sans-serif;
}
tr.activePlanRow {
  background-color: #e7f1f7;
  border-top: 1px solid #d9e4f9 !important;
  box-shadow: 0px -4px 6px rgb(0 60 100 / 18%);
  border-bottom: none;
  td {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
    height: 60px;
    &:first-child {
      border-color: #e7f1f7 !important;
      border: none;
    }
  }
  .tleft {
    padding-left: 20px !important;
  }
  .plan-expand {
    svg {
      color: #646464;
      font-size: 18px;
      &:hover {
        color: var(--selfserveprimarycolor);
      }
    }
  }
  // &:first-child:hover {
  //   box-shadow: inset 0px 0px 0px 0px rgb(0 60 80 / 20%) !important;
  // }
}
::v-deep .mvc-io-wrap table tr.activePlanRow:hover {
  box-shadow: inset 0px 0px 0px 0px rgb(0 60 80 / 20%) !important;
}
.stat-bubble {
  display: inline-block;
  background-color: #d92a36;
  color: #fff;
  padding: 2px 5px;
  border-radius: 12px;
  font-size: 11px;
  margin: 0px 5px 5px 0px;
}
.stat-bubble.st-activated {
  background-color: var(--selfserveprimarycolor);
}
.loading-spinner {
  display: inline-block;
  position: relative;
  top: 56%;
  left: 85%;
  transform: translate(-50%, -50%);
}
::v-deep .k-btn {
  &:hover {
    span.label {
      color: #fff !important;
    }
  }
}
::v-deep .filled .form-input-field {
  height: 30px;
  border: 2px solid #afc6e4 !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
}
</style>
<style lang="scss">
.pacing-column {
  &:hover::after {
    transform: scale(1);
  }
  &.disableTooltip::after {
    display: none;
  }
  &::after {
    content: attr(data-tool-tip);
    display: block;
    font-weight: 400;
    position: absolute;
    line-height: 1.2em;
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
    text-align: center;
    width: 160px;
    margin-left: -35px !important;
    font-size: 12px;
    pointer-events: none;
    padding: 10px 12px;
    border-radius: 3px;
    left: 0;
    bottom: 70%;
    transform: scale(0);
    transition: ease 0.3s;
    transform-origin: bottom center;
    z-index: 99;
  }
}
.temp-plan-date.form-input.form-date .form-input-field {
  padding: 0.6em 0.4em 0.6em 0.3em;
  text-align: center;
  font-size: 100%;
  font-family: 'Manrope', sans-serif;
  color: #646464;
  vertical-align: middle;
  position: relative;
}
.plan-row-campaign-name_no_edit.form-input,
.temp-plan-date.form-input {
  margin-bottom: 5px !important;
}
.plan-row-campaign-name_no_edit.form-input .form-input-field {
  padding: 0.6em 0.4em 0.6em 0.3em;
  text-align: left;
  font-size: 100%;
  font-family: 'Manrope', sans-serif;
  color: #646464;
  vertical-align: middle;
  position: relative;
}
.disable-row {
  opacity: 0.8;
  pointer-events: none;
}
.ft-12 {
  font-size: 12px !important;
}
.goal-column {
  &:hover::after {
    transform: scale(1);
  }
  &.disableTooltip::after {
    display: none;
  }
  &::after {
    content: attr(data-tool-tip);
    display: block;
    position: absolute;
    line-height: 1.2em;
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
    text-align: left;
    width: 160px;
    margin-left: -35px !important;
    font-size: 12px;
    font-weight: 400;
    pointer-events: none;
    padding: 10px 12px;
    border-radius: 3px;
    left: 0;
    bottom: 70%;
    transform: scale(0);
    transition: ease 0.3s;
    transform-origin: bottom center;
    z-index: 99;
  }
}
</style>
