<template>
  <b-modal
    :header="true"
    :enable-click-out-side="false"
    :header-text="'Creative Specs'"
    :is-self-serve-modal="true"
    @close="onClickCancel"
  >
    <div slot="modal-body">
      <div class="modal-container">
        <div class="creative-specs-container">
          <p class="creative-specs-title">
            Flip is a premium content destination, which means your Ad may run on large screens—make
            sure it looks good! All Ads must be in HD and have stereo sound. For more details, see
            the requirements below.
          </p>
          <ul class="creative-specs-list">
            <li class="creative-specs-list-labels">
              <div>Attribute</div>
              <div>Video Track</div>
              <div>Audio Channels</div>
            </li>
            <li>
              <div><strong>Bit Depth</strong></div>
              <div>8 or 16 bits</div>
              <div>16 or 24 bits (for audio channel 2)</div>
            </li>
            <li>
              <div><strong>Bit Rate </strong></div>
              <div>10 Mbps - 40 Mbps</div>
              <div>Min. 192 kb/s</div>
            </li>
            <li>
              <div><strong>Channels</strong></div>
              <div></div>
              <div>2-channel stereo</div>
            </li>
            <li>
              <div><strong>Chroma Sub-Sampling</strong></div>
              <div>4:2:0 or 4:2:2</div>
              <div></div>
            </li>
            <li>
              <div><strong>Codec ID</strong></div>
              <div>Preferred: ProRes HQ <br />Accepted: H.264</div>
              <div></div>
            </li>
            <li>
              <div><strong>Color Space</strong></div>
              <div>YUV</div>
              <div></div>
            </li>
            <li>
              <div><strong>Dimensions</strong></div>
              <div>Preferred: 1920x1080 <br />Accepted: 1280x720</div>
              <div></div>
            </li>
            <li>
              <div><strong>Display Aspect Ratios</strong></div>
              <div>
                All files must be in 16:9. <br />Not accepted are files in 2.39:1, 1.375:1, 3:4, or
                4:3 formats. <br />
                If there is a stylistic decision for the Ad to appear in 2.39:1, 1.375:1, 3:4, or
                4:3 dimensions, then matting on the top and bottom is permissible (meaning the file
                may contain black bars but they must be contained within the 16:9 file itself.)
              </div>
              <div></div>
            </li>
            <li>
              <div><strong>Durations</strong></div>
              <div>15-30 seconds</div>
              <div>Must match video duration</div>
            </li>
            <li>
              <div><strong>File Extension</strong></div>
              <div>.mov or .mp4</div>
              <div></div>
            </li>
            <li>
              <div><strong>File Size</strong></div>
              <div>Max. 1 GB</div>
              <div></div>
            </li>
            <li>
              <div><strong>Format</strong></div>
              <div>QuickTime, MOV, or MPEG-4</div>
              <div>PCM, AAC</div>
            </li>
            <li>
              <div><strong>Frame Rate</strong></div>
              <div>23.98, 24, 25, 29.97, 30 fps</div>
              <div></div>
            </li>
            <li>
              <div><strong>Frame Rate Mode</strong></div>
              <div>Constant</div>
              <div></div>
            </li>
            <li>
              <div><strong>Sampling Rate</strong></div>
              <div></div>
              <div>48.0 kHz</div>
            </li>
            <li>
              <div><strong>Scan Type</strong></div>
              <div>Progressive Scan</div>
              <div></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="action-ftr">
      <div class="actions-btn">
        <k-button :size="3" label="EXPORT" class="mr-20" @click="downloadURI" />
        <k-button :size="3" label="CLOSE" class="ml-20" @click="onClickCancel" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CreativeSpecModal',
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
  },
  props: {
    hideConfirmBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClickCancel(ev) {
      this.$emit('cancel', ev);
    },
    onClickRemove(ev) {
      this.$emit('remove', ev);
    },
    downloadURI() {
      const link = document.createElement('a');
      link.download = 'Creative_Specs.pdf';
      link.target = '_blank';
      link.href = 'Creative_Specs.pdf';
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-container {
  width: 800px !important;
}
::v-deep .modal-header.row.end-xs {
  display: none;
}
.creative-specs {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: start;
    padding: 25px 25px 30px 25px;
  }
  &-list {
    margin-top: 15px;
    &-labels {
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: var(--selfserveprimarycolor);
      text-transform: uppercase;
    }
    li {
      display: flex;
      margin: 0;
      list-style-type: none;
      padding: 15px 20px;
      justify-content: space-between;
      div:first-child {
        flex-basis: 20%;
      }
      div {
        flex-basis: 35%;
      }
    }
    li:nth-child(even) {
      background-color: #f5f6f7;
    }
  }
  &-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px 100px;
  }
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
  float: right;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--selfserveprimarycolor);
  border-radius: 3px;
  appearance: none;
  margin-bottom: 25px;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 10px 32px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
</style>
