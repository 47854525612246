<template>
  <div class="d-board">
    <b-flip-header
      :show-logo="account != null && !isEmbed"
      :show-title="isEmbed"
      :title="embedTitle"
    />
    <div v-if="selectedMenuOption === LAYOUT_TYPES.DASHBOARD && !loading">
      <embedded-dashboard v-if="isFlamingoInstance()" />
      <dashboard v-else />
    </div>
    <AccountSetupScreen v-if="selectedMenuOption === LAYOUT_TYPES.ACCOUNT_SETUP && !loading" />
    <CampaignDashboard v-if="selectedMenuOption === LAYOUT_TYPES.CAMPAIGNS && !loading" />
    <flip-pixel v-if="selectedMenuOption === LAYOUT_TYPES.FLIPPIXEL && !loading" />
    <BrandLiftCampaignSetup
      v-if="selectedMenuOption === LAYOUT_TYPES.BL_CAMPAIGN_SETUP && !loading"
    />
    <Admin v-if="selectedMenuOption === LAYOUT_TYPES.ADMIN && !loading" />
    <div v-if="selectedMenuOption === LAYOUT_TYPES.NEW_CAMPAIGNS && !loading">
      <div v-if="isFlamingoInstance()" class="custom-board">
        <iframe
          src="https://www.cognitoforms.com/f/McvegEnQxkKNB5eQvpaqsg/1"
          style="height:100vh;width:100vw; padding-bottom: 60px;"
          title="Cognito form"
        ></iframe>
      </div>
      <NewCampaignDashboard v-else />
    </div>
    <div v-if="loading" class="loading-spinner">
      <b-loading-spinner />
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { isEqual } from 'lodash';
import { isBlank } from 'adready-api/helpers/common';
import _ from 'underscore';
import { LAYOUT_TYPES } from '~/constant';
import { eBus } from '~/main';
import config from '~/config';
import CampaignDashboard from '~/pages/CampaignDashboard.vue';
import NewCampaignDashboard from '../pages/NewCampaignDashboard.vue';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import { isDemoInstance, isFlamingoInstance } from '~/util/utility-functions';
import campaignMixin from '~/components/mixins/campaign-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import { getQueryParams } from '~/helpers/global/url-helpers';

export default {
  components: {
    Dashboard: () => import(/* webpackChunkName: "dashboard" */ '~/pages/Dashboard.vue'),
    EmbeddedDashboard: () =>
      import(/* webpackChunkName: "embedded-dashboard" */ '~/pages/EmbeddedDashboard.vue'),
    FlipPixel: () => import(/* webpackChunkName: "flip-pixel" */ '~/pages/FlipPixel.vue'),
    BrandLiftCampaignSetup: () =>
      import(/* webpackChunkName: "bl-campaign-setup" */ '~/pages/BrandLiftCampaignSetup.vue'),
    Admin: () => import(/* webpackChunkName: "Admin" */ '~/pages/Admin.vue'),
    BFlipHeader: () =>
      import(/* webpackChunkName: "b-flip-header" */ '~/components/elements/b-flip-header.vue'),
    CampaignDashboard,
    NewCampaignDashboard,
    AccountSetupScreen: () =>
      import(/* webpackChunkName: "AccountSetupScreen" */ '~/pages/AccountSetupScreen.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  mixins: [gaEventsMixin, campaignMixin, authzMixin],
  data() {
    return {
      isDemoInstance,
      isFlamingoInstance,
      LAYOUT_TYPES,
      showAccounts: false,
      loading: false,
    };
  },
  computed: {
    selectedMenuOption: get('common/selectedMenuOption'),
    activeItem: sync('common/activeItem'),

    isEmbed() {
      return this.$route.query.embed === 'true';
    },
    embedTitle() {
      const params = getQueryParams(window.location.href);
      const { embedTitle } = params;
      return embedTitle;
    },
  },
  watch: {
    account(account) {
      // const isSelfServe =
      //   account?.advertisers?.length === 1 ? account?.advertisers[0]?.selfServe : false;
      if (account?.organization?.whitelabelEnabled) {
        this.$flipTheme.setTheme(account?.organization?.theme);
      } else {
        this.$flipTheme.setDefaultTheme();
      }
    },
    advertiser: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue) && !newValue?.isAdvUpdatedInAccSetup && newValue) {
          // this.changeSelectedMenuOption(this.handleLandingPage(newValue));
          this.changeSelectedMenuOption(LAYOUT_TYPES.DASHBOARD);
        }
      },
    },
  },

  created() {
    eBus.$on('on-edit-item', this.onEditItem);
    eBus.$on('on-change-selected-menu-option', this.changeSelectedMenuOption);
  },
  beforeDestroy() {
    eBus.$off('on-edit-item', this.onEditItem);
    eBus.$off('on-change-selected-menu-option', this.changeSelectedMenuOption);
  },
  methods: {
    getDefaultLandingPageForSelfServe(adv) {
      const { accountSetup = {}, pixelSetup = {} } = adv || {};
      const accountSetupComplete =
        accountSetup?.accountInfoAvailable && accountSetup?.paymentInfoAvailable;
      const pixelSetupComplete = pixelSetup?.pixelAdded && pixelSetup?.pixelVerified;
      const selectedPage =
        accountSetupComplete && pixelSetupComplete
          ? LAYOUT_TYPES.CAMPAIGNS
          : LAYOUT_TYPES.ACCOUNT_SETUP;

      return selectedPage;
    },
    getRoleHasAccessToPage(role) {
      const pages = [];
      if (role === 'INTERNAL_USER') {
        pages.push(LAYOUT_TYPES.DASHBOARD);
      } else if (
        role === 'EXTERNAL_USER' ||
        role === 'EXTERNAL_SUB_ADMIN' ||
        role === 'INTERNAL_SUB_ADMIN' ||
        role === 'INTERNAL_ADMIN'
      ) {
        pages.push(LAYOUT_TYPES.DASHBOARD);
        // pages.push(LAYOUT_TYPES.CAMPAIGNS);
        // pages.push(LAYOUT_TYPES.NEW_CAMPAIGNS);
        // pages.push(LAYOUT_TYPES.ACCOUNT_SETUP);
      }
      return pages;
    },
    onEditItem(activeLi) {
      this.activeItem = activeLi;
    },
    hasPixelTabAccess() {
      if (isFlamingoInstance()) {
        return false;
      }
      return !this.advertiser?.selfServe;
    },
    hasNewCampaignAccess() {
      // if (isFlamingoInstance()) {
      //   return true;
      // }
      // return this.isInternalAdmin || this.advertiser?.selfServe;
      return false;
    },
    hasCampaignManagerAccess() {
      // if (isFlamingoInstance()) {
      //   return false;
      // }
      // return this.isInternalAdmin || this.advertiser?.selfServe;
      return false;
    },
    hasReportingDashboardAccess() {
      // return this.hasNewCampaignAccess || this.hasCampaignManagerAccess || this.hasPixelTabAccess;
      return true;
    },
    hideAccountSwitchers() {
      this.showAccounts = false;
    },
    // handleLandingPage(adv) {
    //   const { selfServe = false } = adv || {};
    //   // 1 For non-selfServe always return dashboard page.
    //   if (!selfServe) {
    //     localStorage.setItem(FLIP_SELECTED_MENU_PAGE, LAYOUT_TYPES.DASHBOARD);
    //     return LAYOUT_TYPES.DASHBOARD;
    //   }
    //   let landingPage = '';
    //   const lasVisitedPage = localStorage.getItem(FLIP_SELECTED_MENU_PAGE);
    //   // 2.1 if last visited page is empty
    //   if (!lasVisitedPage) {
    //     landingPage = this.getDefaultLandingPageForSelfServe(adv);
    //   } else {
    //     // 2.2.1 Get the list of allowed pages for logged user's role
    //     const pageList = this.getRoleHasAccessToPage(this.currentUserRole);
    //     // 2.2.2 if last visited page exists in the 2.2.1 list
    //     if (pageList.includes(lasVisitedPage)) {
    //       if (lasVisitedPage === LAYOUT_TYPES.NEW_CAMPAIGNS) {
    //         this.addNewCampaignButtonClick();
    //       }
    //       landingPage = lasVisitedPage;
    //     } else {
    //       landingPage = this.getDefaultLandingPageForSelfServe(adv);
    //     }
    //   }
    //   localStorage.setItem(FLIP_SELECTED_MENU_PAGE, landingPage);
    //   return landingPage;
    // },
    getAdvertisersList() {
      if (this.account) {
        const { advertisers } = this.account;
        if (this.account && !isBlank(advertisers)) {
          return _.sortBy(advertisers, (a) => a.name.toLowerCase());
        }
      }
      return [];
    },
    fireTopLevelMenuSelection(option) {
      if (config.gaEnabled()) {
        const gaEv = {
          item: option,
        };
        this.fireGAEvent('flp_tlpu_selection', gaEv, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-board {
  margin-top: 60px;
  text-align: center;
  background-color: #ffffff;
  height: 100vh;
  position: relative;
}
// new header
.hicon {
  position: relative;
  display: inline-block;
  width: 56px;
  padding: 20px 0px;
  margin: 0px;
  font-size: 18px;
  color: #a1a5af !important;
  text-align: center;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  &:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
  &.active {
    color: #fff !important;
  }
  &:hover {
    top: -6px;
    height: 66px;
    color: #fff;
  }
}
.hicon.active::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 2px;
  content: '';
  background-color: var(--primarycolor);
}
.nav-item:hover {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.2);

  .ss-icon-txt {
    margin-top: 26px;
    opacity: 1;
    &.twolines {
      margin-top: 21px;
    }
  }
}
.ss-icon-txt {
  position: absolute;
  left: 50%;
  display: inline-block;
  width: 60px;
  margin-top: 10px;
  margin-left: -30px;
  font-size: 10px;
  line-height: 1.1em;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.light-theme {
  .ss-header {
    border-bottom: 1px solid #ddd;
    background-color: #fbfcfe;
    .ss-header-content {
    }
  }
  .hicon {
    position: relative;
    display: inline-block;
    width: 56px;
    padding: 20px 0px;
    margin: 0px;
    font-size: 18px;
    color: #444 !important;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    &:last-child {
      border-right: 1px solid rgba(255, 255, 255, 0.05);
    }
    &.active {
      color: #222 !important;
    }
    &:hover {
      top: -6px;
      height: 66px;
      background-color: rgba(0, 0, 0, 0.06) !important;
    }
  }
  .hicon.active::after {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: block;
    width: 100%;
    height: 2px;
    content: '';
    background-color: var(--primarycolor);
  }
  .navpencil:hover {
    color: #464646;
  }
}
.ss-header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  width: 100%;
  height: 60px;
  background-color: #1b1d21;
  .ss-header-content {
    display: block;
    margin: auto;
    .ss-logo {
      display: inline-block;
      width: 80px;
      margin-top: 12px;
      margin-left: 40px;
      .ss-logo-img {
        width: auto;
        img {
          width: auto;
          height: 30px;
        }
      }
    }

    ::v-deep .ss-right {
      display: inline-block;
      float: right;
      margin-top: 3px;
      margin-right: 33px;
      .account-popup {
        top: 65px !important;
        right: 32px !important;
        z-index: 12 !important;
        background-color: #2c3035 !important;
        border: none !important;
        .account-container {
          width: 250px;
          max-height: 308px;
          background-color: #2c3035 !important;
          .form-input-field {
            width: 100% !important;
            height: 35px;
            padding: 10px !important;
            margin-top: 4px !important;
            color: #000 !important;
            background-color: #fff !important;
            border: 1px solid #2c3035 !important;
            border-radius: 3px;
          }
        }
      }
      .mp-nav-acct-name {
        font-size: 12px;
        font-weight: 400;
        color: #a1a5af;
        cursor: pointer;
        .navpencil {
          height: 60px;
          margin-top: -5px;
          font-size: 19px;
          color: #797c8a;
          cursor: pointer;
        }
      }
    }

    .ss-nav {
      display: inline-block;
      float: right;

      .nav-item {
        display: inline-block;
        color: white;
        cursor: pointer;
      }
    }
  }
  ::v-deep .logo img {
    width: auto !important;
    height: 35px !important;
  }
}
.light-theme {
  .ss-header {
    .ss-header-content {
      .ss-logo {
        .ss-logo-img {
          display: none;
        }
      }
      .pg-title {
        font-family: 'Manrope', sans-serif;
        font-size: 19px;
        color: rgba(0, 0, 0, 0.87);
        display: inline-flex;
        width: 250px;
        margin-left: 45px;
        height: 60px;
        align-items: center;
      }
    }
  }
}
.filter-details {
  display: inline-block;
  margin-right: 0.2rem;
  cursor: pointer;
  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.2);
  // }

  .filter {
    display: inline-block;
    padding: 0 4px 0 4px;
    margin-right: 8px;
    text-align: left;

    &:last-child {
      margin-left: 10px;
      border-right: 1px dashed #232222;
      div {
        margin-right: 5px;
      }
    }

    div {
      font-size: 12px;

      &.main {
        padding: 2px 0 2px 0;
        font-weight: 500;
        color: #4c5461;
      }

      &.sub {
        font-weight: 600;
        color: #667184;
      }
    }
  }
}
.light-theme {
  .filter-details.lookup {
    span.main {
      span.sub {
        color: #4f535d;
      }
    }
  }
}
.filter-details.lookup {
  position: relative;
  display: inline-block;
  padding: 8px;
  color: #717482;
  vertical-align: text-bottom;
  cursor: pointer;
  span.main {
    font-size: 11px;
    font-weight: 300;
    color: #717482;
    span.sub {
      display: inline-block;
      min-width: 70px;
      max-width: 125px;
      margin-top: -12px;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
      color: #a1a5af;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
      cursor: pointer;
    }
    svg {
      position: relative;
      display: inline-flex;
      align-items: center;
      font-size: 10px;
      color: #ccc;
    }
  }
}
</style>
