<template>
  <div class="form-input form-date" :class="[{ focused: isFocused, filled: isFilled }]">
    <label v-if="labelText" :for="uid" :class="{ 'required-label': isRequired }">{{
      labelText
    }}</label>
    <input
      :id="uid"
      ref="input"
      :name="name"
      type="text"
      class="form-input-field"
      :readonly="true"
      :required="required"
      :value="selectedDate"
      :tabindex="tabindex"
      @focusin="
        isFocused = true;
        onFocusIn();
      "
      @focusout="onFocusOut"
    />
    <!-- parent components can target the icon class OR <svg> -->
    <font-awesome-icon
      v-if="showIcon"
      ref="icon"
      :class="iconPos"
      class="icon"
      :icon="icon"
      @click="
        isFocused = true;
        openDateModal();
      "
    />
  </div>
</template>

<script>
import _ from 'underscore';
import $ from 'jquery';
import moment from 'moment';

import { createPopper } from '@popperjs/core';
import 'adready-vue/lib/daterangepicker';
import 'adready-vue/lib/daterangepicker.css';

import commonHelper, { isBlank } from 'adready-api/helpers/common';
import uidMixin from 'adready-vue/components/mixins/uid-mixin';
import elementsMixin from 'adready-vue/components/mixins/elements-mixin';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';

const DATE_FORMAT = 'MM/DD/YY';
const DATE_TIME_FORMAT = 'MM/DD/YY hh:mm A';

export default {
  name: 'BDatePickerField',
  mixins: [uidMixin, validationsMixin, elementsMixin],

  props: {
    name: {
      required: false,
      type: String,
      default: '',
    },
    range: {
      required: false,
      type: Boolean,
      default: false,
    },
    valueStart: {
      required: false,
      type: [String, Date, Object],
      default: null,
    },
    valueEnd: {
      required: false,
      type: [String, Date, Object],
      default: null,
    },
    allowDatesInPast: {
      required: false,
      type: Boolean,
      default: true,
    },
    selectTime: {
      required: false,
      type: Boolean,
      default: false,
    },
    icon: {
      // Override the icon which is shown.
      // Ensure it is loaded first!
      required: false,
      type: Array,
      default: () => ['far', 'calendar-alt'],
    },
    iconPos: {
      // Change icon position.
      // Can be "right" or "center"
      required: false,
      type: String,
      default: 'center',
    },
    iconHideOnSelect: {
      required: false,
      type: Boolean,
      default: true,
    },
    offset: {
      // Vertical offset of the dateranger picker
      required: false,
      type: Number,
      default: 28,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    lowerBound: {
      required: false,
      type: [String, Date, Object, Function],
      default: null,
    },
    upperBound: {
      required: false,
      type: [String, Date, Object],
      default: null,
    },
  },

  data() {
    return {
      init: false,
      isFocused: false,
      start: null,
      end: null,
      internalValue: null,
      picker: null,
      popper: null,
      isDateClicked: false,
    };
  },

  computed: {
    isRequired() {
      return this.required && !this.isFilled;
    },
    isFilled() {
      return !_.isEmpty(this.selectedDate);
    },

    showIcon() {
      return !this.iconHideOnSelect || !this.selectedDate;
    },

    // formatted start & end dates
    selectedDate() {
      if (!this.start && !this.end) {
        return null;
      }
      let v = this.formatDate(this.start);
      if (this.range) {
        v += ` - ${this.formatDate(this.end)}`;
      }
      return v;
    },

    divuid() {
      return `div-${this.uid}`;
    },

    dateFormat() {
      return this.selectTime ? DATE_TIME_FORMAT : DATE_FORMAT;
    },
  },

  watch: {
    valueStart(val) {
      this.setStart(val);
    },
    valueEnd(val) {
      this.setEnd(val);
    },
  },

  mounted() {
    this.setStart(this.valueStart);
    this.setEnd(this.valueEnd);
    this.onValidation();
    this.isDateClicked = false;
  },

  destroyed() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  },

  methods: {
    /**
     * Lazily initialize the date picker component
     */
    initComponent() {
      // this.init = true;
      // If lower bound is given, use the lower bound. If not given, check allowed-in-past
      let minDate = this.lowerBound ? this.lowerBound : undefined;
      minDate = minDate || (this.allowDatesInPast ? undefined : new Date());
      const maxDate = this.upperBound ? this.upperBound : undefined;
      const opts = {
        autoUpdateInput: false,
        autoApply: !this.range,
        singleDatePicker: !this.range,
        minDate,
        maxDate,
      };
      if (this.selectTime) {
        Object.assign(opts, {
          timePicker: true,
          locale: {
            format: DATE_TIME_FORMAT,
          },
        });
      }

      // create datepicker
      const input = $(this.$refs.input).daterangepicker(opts);
      this.initEvents(input);

      // replace move method with popper
      this.picker = $(input).data('daterangepicker');
      this.picker.move = () => {};
      $(this.picker.container[0]).append(`<div data-popper-arrow></div>`);

      return input;
    },

    /**
     * Add event handlers to the picker
     */
    initEvents(input) {
      // do not show on focus, in some situations
      if (
        input.parents('td').length > 0 &&
        input.parents('modal-wrapper').length === 0 &&
        input.parents('popup').length === 0
      ) {
        input.off('focus.daterangepicker');
      }

      // fire onChange via trigger instead of applying automatically.
      // this way, we only apply changes when the 'apply' button is clicked for a
      // range, not when the picker is hidden if user clicks outside.
      input.on('apply.daterangepicker', (ev, picker) => {
        this.onDateSelected(picker.startDate, picker.endDate, picker.chosenLabel);
      });

      // Recreate popper everytime the picker is shown
      // This is done to allow for properly handling the case when we hide the
      // icon after making a date selection.
      input.on('show.daterangepicker', () => {
        if (this.popper) {
          this.popper.destroy();
          this.popper = null;
        }
        this.createPopperInst();
      });
    },

    createPopperInst() {
      // if icon is hidden, use the input as the ref el
      const ref = this.$refs.icon || this.$refs.input;
      this.popper = createPopper(ref, this.picker.container[0], {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, this.offset],
            },
          },
        ],
      });
    },

    formatDate(d) {
      let v = d;
      if (!this.selectTime) {
        // when selecting a date, make sure we are using UTC time
        v = d.utc();
      }
      return v.format(this.dateFormat);
    },

    // set start & end based on the passed in prop value
    setStart(val) {
      this.start = val ? moment(val) : null;
    },

    setEnd(val) {
      this.end = val ? moment(val) : null;
    },

    // events

    onDateSelected(start, end) {
      this.isDateClicked = true;
      // when selecting a time, don't force UTC here
      this.start = this.selectTime ? start : commonHelper.createUTCDate(start);
      this.end = this.selectTime ? end : commonHelper.createUTCDate(end);
      const d = {
        start: this.start,
        end: this.end,
        startDate: start.format(this.dateFormat),
        endDate: end.format(this.dateFormat),
      };

      this.input.value = this.selectedDate;
      this.onValidation();
      this.$emit('change', d);
      this.$emit('input', d);
      this.retainFocus(true);
    },

    onFocusIn() {
      if (this.init || this.readonly) {
        return;
      }

      // Render picker DOM on first click (focus)
      this.initComponent().click();
    },
    onFocusOut() {
      this.isFocused = false;
      const d = {
        start: this.start,
        end: this.end,
        startDate: this.start ? this.start.format(this.dateFormat) : '',
        endDate: this.start ? this.start.format(this.dateFormat) : '',
      };
      if (this.isDateClicked) {
        this.$emit('focusout', d);
        return;
      }
      window.onclick = (e) => {
        if (
          !(
            e.target.className === 'next available' ||
            e.target.className === 'prev available' ||
            e.target.tagName === 'SPAN' ||
            e.target.tagName === 'TH'
          ) &&
          !this.selectedDate
        ) {
          this.$emit('focusout', d);
        }
      };
    },

    openDateModal() {
      // Render picker DOM on first click (focus)
      this.initComponent().click();
    },

    onValidation() {
      // Simple required date validation
      let valid = true;
      if (isBlank(this.start)) {
        valid = false;
      }
      if (this.range && isBlank(this.end)) {
        valid = false;
      }
      if (!valid) {
        this.updateError('default', new Error('Please fill out this field'));
      } else {
        this.updateError('default', null);
      }
    },
  },
};
</script>

<style lang="scss">
.daterangepicker {
  .drp-buttons .applyBtn {
    padding: 8px 13px;
    margin-left: 8px;
    font-size: 11px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    background: #28a8b5;
    border: 0;
  }

  .drp-buttons .cancelBtn {
    padding: 8px 13px;
    margin-left: 8px;
    font-size: 11px;
    font-weight: 400;
    color: #b6c3c3;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    background: #fff;
    background-color: #fff;
    border: 1px solid #d7e3e4;
  }

  .drp-buttons .applyBtn:hover,
  .drp-buttons .cancelBtn:hover {
    color: #fff;
    background: #424242;
  }

  td.available:hover,
  th.available:hover {
    border-radius: 0 !important;
  }

  td.in-range {
    color: #000;
    background-color: #ebf4f8;
    border-color: transparent;
    border-radius: 0;
  }

  td.active,
  td.active:hover {
    color: #fff;
    background-color: var(--selfserveprimarycolor);
    border-color: transparent;
  }

  td.end-date {
    border-radius: 0 4px 4px 0;
  }

  td.start-date.end-date {
    border-radius: 4px;
  }

  // Hide the built-in arrow used by daterangepicker
  &.opensright::before {
    left: inherit !important;
  }
  &::before {
    top: inherit !important;
    border-right: inherit !important;
    border-bottom: inherit !important;
    border-left: inherit !important;
  }
  &::before,
  &::after {
    position: inherit !important;
    display: inherit !important;
    content: inherit !important;
    border-bottom-color: inherit !important;
  }

  // Style our custom arrow
  [data-popper-arrow] {
    position: relative;
    background: #fff;

    &::after,
    &::before {
      position: absolute;
      left: 50%;
      width: 0;
      height: 0;
      pointer-events: none;
      content: '';
      border: solid transparent;
    }

    &::after {
      margin-left: -6px;
      border-color: rgba(255, 255, 255, 0);
      border-width: 6px;
    }
    &::before {
      margin-left: -8px;
      border-color: rgba(221, 221, 221, 0);
      border-width: 8px;
    }
  }

  &[data-popper-placement^='bottom'] > [data-popper-arrow] {
    top: 0px;

    &::after,
    &::before {
      bottom: 100%;
    }

    &::before {
      border-bottom-color: #ddd;
    }
    &::after {
      border-bottom-color: #fff;
    }
  }

  &[data-popper-placement^='top'] > [data-popper-arrow] {
    bottom: 0px;

    &::after,
    &::before {
      top: 100%;
    }

    &::before {
      border-top-color: #ddd;
    }
    &::after {
      border-top-color: #fff;
    }
  }

  // below aren't currently used
  &[data-popper-placement^='left'] > [data-popper-arrow] {
    right: -4px;
  }

  &[data-popper-placement^='right'] > [data-popper-arrow] {
    left: -4px;
  }
}
</style>

<style lang="scss" scoped>
.form-date {
  svg {
    pointer-events: none;
  }
}
</style>
