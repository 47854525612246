<template>
  <b-dropdown-multiselect-v2
    class-prefix="age"
    :options="ageOptions"
    :label="'Select Age'"
    :value="ageValues"
    @select="onInput"
  />
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import targetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import bDropdownMultiselectV2 from '~/components/elements/b-dropdown-multiselect-v2.vue';

export default {
  name: 'AgeSelect',
  components: { bDropdownMultiselectV2 },
  mixins: [lineItemMixin, targetableElementOptionsMixin, validationsMixin, authzMixin],

  props: {
    targetingValue: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      targetingType: 'audienceTargeting',
      targetableElementId: 3,
      counter: 0,
    };
  },
  computed: {
    ageOptions() {
      return this.options.map((option) => {
        return { text: option };
      });
    },

    ageValues() {
      let values = [];
      if (this.counter >= 0 && this?.targetingValue?.age) {
        values = this.targetingValue.age.map((age) => {
          return { text: age };
        });
      }
      return values;
    },
  },

  methods: {
    // events
    onInput(val) {
      this.counter += 1;
      const valString = val.map((v) => v.text);
      this.$emit('onInput', valString, 'age');
    },
  },
};
</script>
