import { get, sync } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import { buildQueryString } from 'adready-api/helpers/url-helpers';
import advertiserReportsApi from '~/api/advertiser-reports';
import { LUCID_CAMPAIGN_INFO_DEFAULT } from '~/constant';
import ioHelpers from '~/helpers/io-helpers';

const data = () => {
  return {
    copyText: 'Copy to Clipboard',
    campaignsLoading: false,
    competitors: [],
    type: {},
    activeTab: 'SURVEY',
    errorMsg: null,
    errorBrandCompetitorMsg: null,
    saveAndLaunch: false,
    menuItems: [
      {
        name: 'Survey',
        to: '',
      },
    ],
  };
};
const computed = {
  plan: sync('common/plan'),
  advertiser: get('common/advertiser'),
  account: get('common/account'),
  accountName() {
    return this.account && this.account.name ? this.account.name : '';
  },

  advertiserName() {
    return this.advertiser && this.advertiser.name ? this.advertiser.name : '';
  },

  lucidCampaignInfo: sync('common/plan@lucidCampaignInfo'),
  activeBrandLiftInfo() {
    if (!isBlank(this.lucidCampaignInfo) && !isBlank(this.lucidCampaignInfo.brandLiftInfo)) {
      return this.lucidCampaignInfo.brandLiftInfo;
    }
    return LUCID_CAMPAIGN_INFO_DEFAULT;
  },
  period() {
    if (
      !isBlank(this.plan.flipCampaignInfo) &&
      !isBlank(this.plan.flipCampaignInfo.conversionWindow)
    ) {
      const period = this.plan.flipCampaignInfo.conversionWindow.value;
      if (!isBlank(period)) {
        return `${period} ${this.plan.flipCampaignInfo.conversionWindow.unit.value}`;
      }
      return null;
    }
    return null;
  },
  category() {
    if (!isBlank(this.activeBrandLiftInfo)) {
      return this.activeBrandLiftInfo.category;
    }
    return null;
  },
};

const methods = {
  appliedGlobalParams() {
    const obj = {
      inTargetBrand: this.advertiser.name,
      competitiveBrand1: '',
      competitiveBrand2: '',
      competitiveBrand3: '',
      competitiveBrand4: '',
    };
    if (isBlank(this.activeBrandLiftInfo.competitors)) {
      return obj;
    }
    for (let index = 0; index < this.activeBrandLiftInfo.competitors.length; index++) {
      obj[`competitiveBrand${index + 1}`] = this.activeBrandLiftInfo.competitors[index];
    }
    return obj;
  },

  async prepareCampaign(campaign, expectedImpressionsCount) {
    const competitorsMap = this.appliedGlobalParams();
    const globalParamNames = Object.keys(competitorsMap);
    const payload = JSON.parse(JSON.stringify(campaign));
    const liftData = await this.fetchTemplateQuestions('lift');
    const baselineData = await this.fetchTemplateQuestions('baseline');
    const questions = JSON.parse(JSON.stringify(liftData));
    questions.forEach((q) => {
      // replacing the placeholders in question.
      let questionText = q.question_text;
      if (!isBlank(this.category) && questionText.indexOf('{{product_category}}') > 0) {
        let categoryText = this.category;
        if (!isBlank(this.type)) {
          categoryText = `${this.category} ${this.type.value}`;
        }
        questionText = questionText.replace('{{product_category}}', categoryText);
        q.custom_question_text = questionText;
        q.customQuestion = true;
      }

      if (questionText.indexOf('{{online}}') > 0) {
        questionText = questionText.replace('{{online}}', 'online');
        q.custom_question_text = questionText;
        q.customQuestion = true;
      }

      if (!isBlank(this.period) && questionText.indexOf('{{period}}') > 0) {
        questionText = questionText.replace('{{period}}', this.period);
        q.custom_question_text = questionText;
        q.customQuestion = true;
      }

      if (!isBlank(this.type) && questionText.indexOf('{{brands_or_places_or_institutions}}') > 0) {
        questionText = questionText.replace(
          '{{brands_or_places_or_institutions}}',
          this.type.value
        );
        q.custom_question_text = questionText;
        q.customQuestion = true;
      }
      q.answers = q.answers || [];
      q.answers = q.answers.map((answer, i) => {
        if (q.kpi_type !== 'brand_favorability' && i < globalParamNames.length) {
          return { text: competitorsMap[globalParamNames[i]] || answer.text };
        }
        if (
          q.kpi_type === 'brand_favorability' &&
          q.question_type === 'table-radio' &&
          i < globalParamNames.length
        ) {
          return { text: competitorsMap[globalParamNames[i]] || answer.text };
        }
        return { text: answer ? answer.text : '' };
      });

      this.applyQuestionParams(q);

      // setup the variables for custom questions.
      q.answers = q.answers.map((m) => m.text);
      q.questionParams = q.questionParams.map((m) => m.param);
      q.custom_question_text = q.custom_question_text || '';
      q.is_custom_question_text = q.custom_question_text.trim().length > 0;
      q.target_option_index = 0;
      if (q.multi_target_option_index.length > 0) {
        q.multi_target_option_index[0] = 0;
      }
    });
    payload.draft_settings.questions = questions;

    payload.draft_settings.expected_impressions_count = expectedImpressionsCount;

    const baselineQuestions = JSON.parse(JSON.stringify(baselineData));
    baselineQuestions.forEach((q) => {
      q.answers = q.answers || [];
      q.answers = q.answers.map((m) => m.text);
      q.questionParams = q.questionParams.map((m) => m.param);
      q.custom_question_text = q.custom_question_text || '';
      q.is_custom_question_text = q.custom_question_text.trim().length > 0;

      this.applyQuestionParams(q);
    });
    payload.draft_settings.baseline_questions = baselineQuestions;
    return payload;
  },
  async editCampaignDraft(lucidCampaign, payload) {
    try {
      this.campaignsLoading = true;
      await advertiserReportsApi.editBrandLiftCampaign(
        [this.advertiser ? this.advertiser.id : 0, lucidCampaign.campaign_id],
        payload
      );
      this.campaignsLoading = false;
    } catch (err) {
      this.errorMsg = 'Error editing brandlift campaign';
      console.error('error editing brandlift campaign', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  async createCampaignDraft(payload) {
    try {
      this.campaignsLoading = true;
      await advertiserReportsApi.createBrandLiftCampaign(
        [
          this.advertiser ? this.advertiser.id : 0,
          this.account ? this.account.name : '',
          this.advertiser ? this.advertiser.name : '',
          this.plan ? this.plan.id : '',
          this.plan ? this.plan.campaignName : '',
        ],
        payload
      );
      this.campaignsLoading = false;
    } catch (err) {
      this.errorMsg = 'Error creating brandlift campaign draft';
      console.error('error creating brandlift campaign draft', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },

  createDraft(expectedImpressionCount) {
    this.campaignMode = 'NEW';
    let endDate = new Date();
    if (this.plan.flightEndDate) {
      endDate = new Date(this.plan.flightEndDate);
    }
    endDate.setUTCHours(23, 59, 59);
    const startDate = new Date();
    startDate.setUTCHours(0, 0, 0, 0);
    this.errorBrandCompetitorMsg = null;
    const payload = {
      campaign_id: '',
      campaign_name: this.plan ? this.plan.campaignName : '',
      client_name:
        !isBlank(this.accountName) && !isBlank(this.advertiserName)
          ? `${this.accountName}-${this.advertiserName}`
          : '',
      country_language_id: 1,
      draft_settings: {
        allow_ip: false,
        baseline_questions: [],
        expected_impressions_count: expectedImpressionCount,
        questions: [],
        target_audiences: [],
      },
      end_date: endDate.toISOString(),
      start_date: startDate.toISOString(),
      status: 'draft',
      industry_id: 11,
    };
    return payload;
  },

  getPlaceHolders(questions) {
    const arrayPlaceHolder = [];
    questions.forEach((q) => {
      if (q.question_text.includes('{{')) {
        const part = q.question_text.split('{{');
        part.forEach((i) => {
          if (i.includes('}}')) {
            const eachPart = `{{${i.substring(0, i.lastIndexOf('}') + 1)}`;
            arrayPlaceHolder.push(eachPart);
          }
        });
      }
    });
    return arrayPlaceHolder;
  },
  validatePlaceHolder(payload) {
    const questions = JSON.parse(JSON.stringify(payload.draft_settings.questions));
    const placeholders = this.getPlaceHolders(questions);
    let flagToContinue = true;
    this.errorBrandCompetitorMsg = null;
    questions.every((q) => {
      placeholders.every((p) => {
        if (
          q.question_text.includes(p) &&
          (q.custom_question_text.includes(p) || isBlank(q.custom_question_text))
        ) {
          this.errorBrandCompetitorMsg = `Please enter the place holder for ${p}`;
          flagToContinue = false;
          return flagToContinue;
        }
        return flagToContinue;
      });
      return flagToContinue;
    });
    return flagToContinue;
  },
  validateCompetitiveBrands(payload) {
    const questions = JSON.parse(JSON.stringify(payload.draft_settings.questions));
    const brands = [
      'Competitive Brand 1',
      'Competitive Brand 2',
      'Competitive Brand 3',
      'Competitive Brand 4',
      'Competitive Brand #1',
      'Competitive Brand #2',
      'Competitive Brand #3',
      'Competitive Brand #4',
    ];
    let flagToContinue = true;
    this.errorBrandCompetitorMsg = null;

    questions.every((q) => {
      q.answers.every((v) => {
        if (brands.includes(v)) {
          this.errorBrandCompetitorMsg = `Please enter correct ${v}`;
          flagToContinue = false;
          return flagToContinue;
        }
        return flagToContinue;
      });

      return flagToContinue;
    });
    return flagToContinue;
  },

  applyQuestionParams(item) {
    item.questionParams = [];
    // eslint-disable-next-line
    const reg = new RegExp('[^{}]+(?=})', 'gm');
    let match = reg.exec(item.question_text);
    while (match !== null) {
      item.questionParams.push({
        param: match[0],
        start: match.index,
        end: match.index + match[0].length,
        value: '',
      });
      match = reg.exec(item.question_text);
    }
  },
  async fetchTemplateQuestions(usageValue) {
    const apiData = await advertiserReportsApi.fetchBrandLiftTemplateQuestions(
      this.advertiser ? this.advertiser.id : 0,
      buildQueryString({
        countryLanguageId: 1,
        industryId: 11,
        usage: usageValue,
      })
    );

    const globalParamNames = Object.keys(this.appliedGlobalParams());
    apiData.forEach((item) => {
      item.answers = item.answers || [];
      item.answers = item.answers.map((answer, i) => {
        answer = answer || '';
        if (
          usageValue === 'lift' &&
          item.kpi_type !== 'brand_favorability' &&
          i < globalParamNames.length
        ) {
          return { text: this.appliedGlobalParams[globalParamNames[i]] || answer };
        }
        if (
          usageValue === 'lift' &&
          item.kpi_type === 'brand_favorability' &&
          item.question_type === 'table-radio' &&
          i < globalParamNames.length
        ) {
          return { text: this.appliedGlobalParams[globalParamNames[i]] || answer };
        }
        return { text: answer };
      });
      this.applyQuestionParams(item);
    });
    return apiData;
  },

  async createLucidCampaign(plan, account, expectedImpressionCount) {
    this.errorMsg = null;
    const campaign = this.createDraft(expectedImpressionCount);
    const payload = await this.prepareCampaign(campaign, expectedImpressionCount);
    // console.log('prepareCampaign done ', payload);
    if (this.errorMsg) {
      console.error('error createLucidCampaign', this.errorMsg);
      return;
    }
    const lucidCampaign = await ioHelpers.fetchBrandLiftCampaigns(plan, account);
    let isLucidCampaignCreated = false;
    if (!isBlank(lucidCampaign) && !isBlank(lucidCampaign.campaign_id)) {
      isLucidCampaignCreated = true;
    }
    if (isLucidCampaignCreated) {
      // console.log('campaign already created', lucidCampaign.campaign_id);
      // TODO fix Editing
      // await this.editCampaignDraft(lucidCampaign, payload);
    } else {
      // console.log('create new campaign ', payload);
      // console.log('expectedImpressionCount', expectedImpressionCount);
      await this.createCampaignDraft(payload);
    }
  },
  async createAndLaunchLucidCampaign(plan, account, expectedImpressionCount) {
    await this.createLucidCampaign(plan, account, expectedImpressionCount);
    const lucidCampaign = await ioHelpers.fetchBrandLiftCampaigns(plan, account);
    const payload = await this.prepareCampaign(lucidCampaign, expectedImpressionCount);
    const isLucidCampaignLaunched = await ioHelpers.isLucidCampaignLaunched(plan, account);
    if (!isLucidCampaignLaunched) {
      await this.launchCampaign(payload);
      // console.log('campaign launched');
    } else {
      // console.log('campaign already launched');
    }
  },
  async launchCampaign(payload) {
    const validAnswer = this.validateCompetitiveBrands(payload);
    if (!validAnswer) {
      // console.log('validAnswer ', validAnswer);
      return;
    }
    const validPlaceholder = this.validatePlaceHolder(payload);
    if (!validPlaceholder) {
      // console.log('validPlaceholder ', validPlaceholder);
      return;
    }
    try {
      this.saveAndLaunch = true;
      await advertiserReportsApi.launchBrandLiftCampaign([
        this.advertiser ? this.advertiser.id : 0,
        payload.campaign_id,
      ]);
    } catch (err) {
      this.saveAndLaunch = false;
      console.error('Error launching brandlift campaign', err);
      this.errorMsg = 'Error launching brandlift campaign';
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
};
export default {
  data,
  computed,
  methods,
};
