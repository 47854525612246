<template>
  <div
    :class="`pulisher-targeting-container ${readonly ? 'readonly-pt' : ''}`"
    style="margin-bottom: 750px;"
  >
    <b-listbox-multiselect
      :options="publisherOptions"
      :value="publisherValues"
      :readonly="readonly"
      :info-icon-target-node="'infoURL'"
      @select="onChangeSelectedPublishers"
      @onInfoIconClick="onInfoIconClick"
    />
  </div>
</template>

<script>
import { inert } from 'adready-api/helpers/common';
import { TAR_PUBLISHER_FLIP_OTT_TARGETING } from '~/constant';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import { targetingSelectMixin } from '~/components/mixins/targeting-mixin';
import bListboxMultiselect from '~/components/elements/b-listbox-multiselect.vue';

export default {
  name: 'PublisherTargetingForm',
  components: { bListboxMultiselect },
  mixins: [lineItemMixin, targetingSelectMixin],
  props: {
    publisherOptions: {
      required: true,
      type: Array,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      targetingType: 'publisherTargeting',
      targetingTypeName: 'Flip OTT',
      targetableElementId: TAR_PUBLISHER_FLIP_OTT_TARGETING,
      selectedTargeting: null,
      allDataOptions: [],
    };
  },
  computed: {
    publisherValues() {
      const selectedPublishers = this.selectedTargeting?.value?.precreated || [];
      if (selectedPublishers?.length && this.publisherOptions.length) {
        return this.publisherOptions.filter((o) => selectedPublishers.includes(o.text));
      }
      return [];
    },
  },
  watch: {
    targetings: {
      deep: true,
      handler() {
        this.loadSelectedTargeting();
      },
    },
  },

  beforeMount() {
    this.loadSelectedTargeting();
  },
  methods: {
    loadSelectedTargeting() {
      const selectedTargeting = this.targetings.find(
        (tp) => tp.targetableElementId === this.targetableElementId
      );
      if (selectedTargeting) {
        this.selectedTargeting = inert(selectedTargeting);
      } else {
        this.selectedTargeting = this.createSelectedTargeting(this.targetableElementId);
        if (!this.selectedTargeting.value) {
          this.selectedTargeting.value = {};
        }
        if (!this.selectedTargeting.value.precreated) {
          this.selectedTargeting.value.precreated = [];
        }
      }
    },
    onChangeSelectedPublishers(list) {
      const publisherNames = list.map((o) => o.text);
      this.$emit('validatePublisher', list);
      this.updateLineItemTargetingField('publisherTargeting', 'precreated', publisherNames);
    },
    onInfoIconClick(payload) {
      this.$emit('onInfoCardClick', payload);
    },
  },
};
</script>
