var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ss-nav-wrapper",attrs:{"id":("left-nav-" + (_vm.lineItem.id))}},[_c('div',{staticClass:"ss-title-bar"},[_c('div',{staticClass:"ad-group-title"},[(_vm.lineItem && _vm.lineItem.name)?_c('div',{staticClass:"ss-nav-title"},[_vm._v(_vm._s(_vm.lineItem.name))]):_c('div',{staticClass:"ss-nav-title"},[_vm._v("New Ad Group")]),_c('div',{class:("newgrp-clone mp-btn btn-teal " + (_vm.readonly ? 'readonly-ag' : '')),on:{"click":_vm.onCloneGroup}},[_c('span',{staticClass:"intip4 intip4b",attrs:{"data-tool-tip":"Clone this ad group"}},[_c('font-awesome-icon',{staticClass:"inline-fa",attrs:{"icon":['far', 'clone']}})],1)])]),_c('div',{class:("newgrp-plus mp-btn btn-teal " + (_vm.readonly ? 'readonly-ag' : '')),on:{"click":_vm.onAddNewGroup}},[_c('span',{staticClass:"intip4 intip4b relativePos",attrs:{"data-tool-tip":"Add an additional ad group"}},[_c('font-awesome-icon',{staticClass:"inline-fa",attrs:{"icon":['far', 'plus']}})],1)]),(_vm.canRemoveAdGroup)?_c('div',{staticClass:"delete-adgrp mp-btn",on:{"click":_vm.onRemoveGroup}},[_c('span',{staticClass:"intip4 intip4b",attrs:{"data-tool-tip":"Delete this ad group"}},[_c('font-awesome-icon',{staticClass:"inline-fa",attrs:{"icon":['far', 'trash']}})],1)]):_vm._e()]),_c('div',{staticClass:"ss-lnav-links"},[_vm._l((_vm.lineItemOptions),function(liOptions,index){return _c('div',{key:(_vm.selectedPage + "_" + _vm.selectedPageIndex + "_" + index),class:("ss-lnav-link " + (_vm.selectedPage === liOptions.pageName && _vm.selectedPageIndex === liOptions.pageIndex
            ? 'active'
            : ''))},[_c('font-awesome-icon',{staticClass:"lnav-icon",attrs:{"icon":['far', ("" + (liOptions.leftIcon))]}}),_c('div',{class:("ss-lnav-txt " + (_vm.selectedPage === liOptions.pageName && _vm.selectedPageIndex === liOptions.pageIndex
              ? 'active'
              : '')),on:{"click":function($event){return _vm.openPage(liOptions.pageName, liOptions.pageIndex, _vm.lineItemIndex)}}},[_vm._v(" "+_vm._s(liOptions.title)+" ")]),_c('font-awesome-icon',{class:("lnav-icon2 " + (_vm.isPageIndexValid(liOptions) ? 'active' : '') + " " + (liOptions.rightIcon === 'exclamation' ? 'error' : '')),attrs:{"icon":[
          ("" + (liOptions.rightIcon === 'exclamation' ? 'fas' : 'far')),
          ("" + (liOptions.rightIcon)) ]}})],1)}),_c('div',{staticClass:"side-projection active"},[_c('div',{staticClass:"sdp-item"},[_c('span',{staticClass:"sdp-val-label"},[_vm._v("Estimated CPM: ")]),_c('span',{staticClass:"sdp-val"},[_vm._v(_vm._s(_vm.formatNumber(_vm.cpm, 'CURRENCY')))])]),_c('div',{staticClass:"sdp-item"},[_c('span',{staticClass:"sdp-val-label"},[_vm._v("Estimated Impressions: ")]),_c('span',{staticClass:"sdp-val"},[_vm._v(_vm._s(_vm.formatNumberAsFullNumber(_vm.impressions, 'VISITS')))])]),_c('div',{staticClass:"sdp-item"},[_c('div',{staticClass:"left-nav-alert"},[(_vm.deliveryProbability && _vm.deliveryProbability.level === 'Low')?_c('span',{staticClass:"intip4 intip-right",attrs:{"data-tool-tip":"This ad group is unlikely to deliver in full. Loosen the targeting parameters, add publishers, or decrease budget to increase the delivery probability."}},[_c('font-awesome-icon',{staticClass:"inline-fa font12 low bounce",attrs:{"icon":['fas', 'exclamation-triangle']}})],1):_vm._e()]),_c('span',{staticClass:"sdp-val-label"},[_vm._v("Delivery Probability: ")]),(_vm.deliveryProbability)?_c('font-awesome-icon',{staticClass:"inline-fa sdp-gauge",class:("" + (_vm.deliveryProbability && _vm.deliveryProbability.level
                ? _vm.deliveryProbability.level.toLowerCase()
                : '')),attrs:{"icon":['far', _vm.deliveryProbability.icon]}}):_vm._e(),(_vm.deliveryProbability)?_c('span',{staticClass:"sdp-note"},[_vm._v(" ("+_vm._s(_vm.deliveryProbability.level)+") ")]):_c('span',{staticClass:"sdp-note"},[_vm._v(" NA")])],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }