<template>
  <div>
    <div
      v-if="showUpload && !isUploaded"
      ref="dropZone"
      :class="['upload-wrap', { dragging: dragging }]"
      @drop="onFileChange"
      @dragenter="dragging = true"
      @dragend="dragging = false"
      @dragleave="dragging = false"
    >
      <input
        :id="uid"
        ref="input"
        type="file"
        :required="isRequired"
        :name="name"
        @change="onFileChange"
      />

      <div v-if="canDragDrop" class="sub-dropzone" style="justify-content: center;">
        <span style="width: 5%;">
          <font-awesome-icon :icon="['fal', 'cloud-upload']" class="fa-w-20 dropicon" />
        </span>
        Drop video files here, or<label :for="uid" :class="['k-btn btn-size-2']">browse </label>
      </div>

      <div class="upload-section no-margin">
        <div v-if="showProgress" class="progess-bar-wrap row middle-xs between-xs">
          <div class="progess-bar">
            <div class="progess-bar-value" :style="{ width: `${uploadPercentage}%` }"></div>
          </div>
          <span class="progress-percentage">{{ uploadPercentage }} %</span>
        </div>

        <span v-if="error" class="file-upload-error">{{ error }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import KFileUpload from '~/components/elements/b-file-upload.vue';

export default {
  name: 'KFileBrowse',
  extends: KFileUpload,
};
</script>

<style lang="scss" scoped>
input[type='file'] {
  display: none;
}
.file-upload-error {
  font-weight: 400;
  color: red;
}
.upload-wrap {
  width: 100%;
  // max-width: 400px;
  padding: 20px;
  margin: 0;
  text-align: center;
  background-color: $grey_9;

  a {
    color: $dr_blue;
  }

  .k-btn {
    display: inline-block;
    min-width: 65px;
    padding: 12px 5px;
    font-size: 15px;
    font-weight: 400;
    color: $dr_blue;
    text-align: center;
    text-transform: lowercase;
    letter-spacing: 0.07em;
    cursor: pointer;
    // background-color: $dr_blue !important;
    background-color: transparent !important;
    border-radius: 0;
    transition: all 0.6s ease;

    &:hover {
      color: $black;
      background-color: $grey_3;
    }
  }
  .drag-box {
    margin: 0 0 0 10px;
  }
  .upload-section {
    margin: 0 0 10px;
  }

  .progess-bar-wrap {
    margin: 0;

    .progess-bar {
      width: 90%;

      .progess-bar-value {
        height: 10px;
        background-color: $dr_blue;
      }
    }

    .progress-percentage {
      font-size: 1em;
      color: $text_color;
    }
  }
}

.uploaded-container {
  width: 100%;
  margin: 0;

  .uploaded-wrap {
    padding: 0 0 10px;
    margin: 20px 0;
    text-align: left;
    border-bottom: 1px solid $grey_8;
  }

  .uploaded-img {
    width: 85px;
    height: auto;
    margin: 0 10px 0 0;
  }

  .uploaded-info {
    padding-top: 9px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1em;
    vertical-align: top;

    .uploaded-txt {
      max-width: 230px;
      font-weight: 600;
    }
    .uploaded-size {
      margin-top: 5px;
      font-weight: 400;
      color: $grey_5;
    }
    .uploaded-size-validation {
      margin-top: 5px;
      font-weight: 400;
      color: red;
    }
  }
  .uploaded-icons {
    margin: 0;

    a {
      margin: 0 0 0 5px;
    }

    svg {
      font-size: 16px;
      color: $grey_3;
      cursor: pointer;
      transition: all 0.6s ease;

      &:hover {
        color: $dr_blue;
      }
    }
  }
  .disabled {
    color: #adadad;
    pointer-events: none;
    cursor: not-allowed;
    background-color: $disabled;
    opacity: 0.6;
  }
}

.drag-text {
  padding: 0 10px 0 5px;
  font-size: 14px;
  color: $grey_4;
}

.draghere-wrap {
  position: relative;
  width: 110px;
  padding: 10px 7px;
  color: #888;
  border: 2px dashed $grey_8;
}
.sub-dropzone {
  background-color: #f4f4f4;
  padding: 50px 15px 58px 15px;
  text-align: center;
  // margin-bottom: 20px;
}
</style>
