<template>
  <div>
    <div
      v-if="showUpload && !isUploaded"
      ref="dropZone"
      :class="['upload-wrap', { dragging: dragging }]"
      @drop="onFileChange"
      @dragenter="dragging = true"
      @dragend="dragging = false"
      @dragleave="dragging = false"
    >
      <input
        :id="uid"
        ref="input"
        type="file"
        :required="isRequired"
        :name="name"
        @change="onFileChange"
      />
      <div class="row between-xs middle-xs upload-section">
        <label :for="uid" :class="['k-btn btn-size-4', { 'upload-required': isRequired }]">{{
          labelText
        }}</label>
        <label v-if="!showProgress" :for="uid" class="upload-btn-box">
          <font-awesome-icon :icon="['fal', 'plus']" class="drop-icon-sign" />
        </label>

        <div v-if="canDragDrop" class="drag-box row between-xs middle-xs">
          <span class="drag-text">{{ promptText }}</span>
          <span class="draghere-wrap">
            <font-awesome-icon :icon="['fal', 'cloud-upload']" size="2x" />
          </span>
        </div>
      </div>
      <div class="upload-section no-margin">
        <div v-if="showProgress" class="progess-bar-wrap row middle-xs between-xs">
          <div class="progess-bar">
            <div class="progess-bar-value" :style="{ width: `${uploadPercentage}%` }"></div>
          </div>
          <span class="progress-percentage">{{ uploadPercentage }} %</span>
        </div>

        <span class="drag-text small-text">Maximum size of 1GB per file</span>
        <span v-if="error" class="file-upload-error">{{ error }}</span>
      </div>
    </div>

    <div v-if="isUploaded" class="uploaded-container">
      <div class="uploaded-wrap row between-xs middle-xs">
        <img v-if="isImageType" v-lazyload :data-url="filePath(fileData)" class="uploaded-img" />
        <div class="uploaded-info col-xs">
          <div class="uploaded-txt">{{ cleanFileName }}</div>
          <div class="uploaded-size">
            {{ fileSizeInKB }}KB
            <div v-if="isFileSizeError" class="uploaded-size-validation">{{ errorMessage }}</div>
          </div>
        </div>
        <div class="row between-xs top-xs">
          <font-awesome-icon
            :class="[{ 'uploaded-icon': !readonly }, { disabled: readonly }]"
            :icon="['fas', 'trash']"
            @click="reset()"
          />
          <a :href="fileDownloadPathCustom(fileData)" target="_blank">
            <font-awesome-icon class="uploaded-icon" :icon="['fas', 'cloud-download']" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import S from 'underscore.string';

import commonHelper from 'adready-api/helpers/common';
import filesApi from 'adready-api/api/files-api';
import { BYTES_IN_KB } from 'adready-api/constant';
import LazyLoadImageDirective from 'adready-vue/directives/LazyLoadImageDirective';

import EventBus from 'adready-vue/event-bus';

import uidMixin from 'adready-vue/components/mixins/uid-mixin';
import fileMixin from 'adready-vue/components/mixins/file-mixin';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import config from '~/config';

export default {
  name: 'BFileUpload',

  directives: {
    lazyload: LazyLoadImageDirective,
  },

  mixins: [uidMixin, validationsMixin, fileMixin],

  props: {
    label: {
      required: true,
      type: String,
      default: '',
    },
    type: {
      required: false,
      type: String,
      default: 'UPLOAD',
    },
    prompt: {
      required: true,
      type: String,
      default: '',
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    name: {
      required: false,
      type: String,
      default: 'File Upload',
    },
    lineItemId: {
      required: false,
      type: Number,
      default: -1,
    },
    planId: {
      required: false,
      type: Number,
      default: -1,
    },
    errorMessage: {
      required: false,
      type: String,
      default: '',
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fileData: null,
      dragging: false,
      showProgress: false,
      uploadPercentage: 0,
      error: null,
      FILE_DOWNLOAD_PATH: '/api/files/download',
    };
  },

  computed: {
    // returns 'true' if browser supports drag/drop file upload
    canDragDrop() {
      const div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FileReader' in window
      );
    },
    showUpload() {
      return !this.readonly;
    },
    isUploaded() {
      return this.fileData !== null;
    },
    fileSizeInKB() {
      return Math.ceil(this.fileData.size / BYTES_IN_KB);
    },
    promptText() {
      return S(this.prompt)
        .humanize()
        .words()
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join(' ');
    },
    isFileSizeError() {
      return !commonHelper.isEmptyOrNull(this.errorMessage);
    },

    labelText() {
      return S(this.label)
        .humanize()
        .words()
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join(' ');
    },

    isImageType() {
      return (
        !commonHelper.isNil(this.fileData.name) && commonHelper.isImageType(this.fileData.name)
      );
    },

    // whether or not the field should be marked as required
    // computed to
    isRequired() {
      return this.required;
    },

    cleanFileName() {
      return this.fileData.name;
    },
  },

  watch: {
    isRequired() {
      this.$nextTick(() => {
        // Since we mark the field as required based on some extra logic, we
        // need to revalidate whenever this flag changes. Specifically, since we
        // load options dynamically, often after the form element is loaded,
        // this flag will trigger the html5 constraint validation to run.
        this.onValidation();
      });
    },

    value(newVal) {
      if (!newVal) {
        this.reset();
      } else {
        this.updateError(null);
      }
    },
  },

  mounted() {
    if (this.canDragDrop) {
      $(this.$refs.dropZone).on('drag dragstart dragend dragover dragenter dragleave drop', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    }
    this.fileData = this.value;

    // trigger validation based on incoming props
    if (this.fileData) {
      // nothing to do
      this.updateError(null);
    } else {
      this.onValidation();
    }
  },

  methods: {
    fileDownloadPathCustom(attachment) {
      return attachment.type === 'LINK'
        ? encodeURI(attachment.path)
        : encodeURI(
            `${process.env.VUE_APP_ADREADY_URL}/api/files/download/${attachment.id}?download=true`
          );
    },
    reset() {
      this.fileData = null;
      this.$emit('onDeleteFile');
    },

    // generic file upload method
    // file: file to upload (from input's FileList)
    //
    // returns fileData:
    // {
    //   "fileName": "file.png",
    //   "fileDownloadUri": "http://localhost:8088/api/files/download/file.png",
    //   "fileType": "image/png",
    //   "size": 1047548
    // }
    uploadFile(file) {
      // grab the file that was selected, upload it, and return data
      return new Promise((resolve) => {
        if (window.STORYBOOK_ENV !== undefined) {
          resolve({
            fileName: 'test.png',
            fileDownloadUri: 'http://localhost/download/test.png',
            fileType: 'image/png',
            size: 9000,
          });
          return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', this.type);
        const pathParam = {};
        if (this.order && this.order.id) {
          pathParam.orderId = this.order.id;
        } else {
          pathParam.orderId = this.planId ? this.planId : -1;
        }
        pathParam.lineItemId = this.lineItemId ? this.lineItemId : -1;
        const settings = {
          onUploadProgress: this.uploadProgress,
        };
        this.showProgress = true;
        this.error = null;
        filesApi
          .upload(pathParam, formData, settings)
          .then((data) => {
            this.showProgress = false;
            resolve(data);
          })
          .catch((err) => {
            this.showProgress = false;
            if (err?.response?.data?.message) {
              this.error = err.response.data.message;
            } else {
              this.error = 'File upload error';
            }
          });
      });
    },

    uploadProgress(progressEvent) {
      // eslint-disable-next-line radix
      this.uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },

    // events

    onFileChange(ev) {
      if (this.canDragDrop) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      const files = ev.target.files || (this.canDragDrop ? ev.dataTransfer.files : []);
      if (files.length === 0) {
        this.reset();
        return;
      }
      this.uploadFile(files[0]).then((fileData) => {
        this.fileData = fileData;
        this.input.required = false;
        this.input.setCustomValidity('');
        this.$emit('upload', fileData);
        EventBus.$emit('file:upload', fileData); // also send to event bus (currently for e2e testing)
        this.onValidation();
      });
      this.$refs.input.value = '';
    },

    fileDownloadPath(fileData) {
      return encodeURI(
        `${config.ADREADY_URL}${this.FILE_DOWNLOAD_PATH}/${fileData.id}?download=true`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
input[type='file'] {
  display: none;
}
.file-upload-error {
  font-weight: 400;
  color: red;
}
.upload-wrap {
  width: 100%;
  // max-width: 400px;
  padding: 20px;
  margin: 0;
  text-align: center;
  background-color: $grey_9;

  a {
    color: $dr_blue;
  }

  .k-btn {
    display: inline-block;
    min-width: 100px;
    padding: 12px 22px;
    font-size: 14px;
    font-weight: 400;
    color: $grey_2;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    cursor: pointer;
    background-color: $yellow;
    border-radius: 0;
    transition: all 0.6s ease;

    &:hover {
      color: #fff;
      background-color: $grey_3;
    }
  }
  .drag-box {
    margin: 0 0 0 10px;
  }
  .upload-section {
    margin: 0 0 10px;
  }

  .progess-bar-wrap {
    margin: 0;

    .progess-bar {
      width: 90%;

      .progess-bar-value {
        height: 10px;
        background-color: $dr_blue;
      }
    }

    .progress-percentage {
      font-size: 1em;
      color: $text_color;
    }
  }
}

.uploaded-container {
  width: 100%;
  margin: 0;

  .uploaded-wrap {
    padding: 0 0 10px;
    margin: 20px 0;
    text-align: left;
    border-bottom: 1px solid $grey_8;
  }

  .uploaded-img {
    width: 85px;
    height: auto;
    margin: 0 10px 0 0;
  }

  .uploaded-info {
    padding-top: 9px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1em;
    vertical-align: top;

    .uploaded-txt {
      max-width: 230px;
      font-weight: 600;
    }
    .uploaded-size {
      margin-top: 5px;
      font-weight: 400;
      color: $grey_5;
    }
    .uploaded-size-validation {
      margin-top: 5px;
      font-weight: 400;
      color: red;
    }
  }
  .uploaded-icons {
    margin: 0;

    a {
      margin: 0 0 0 5px;
    }

    svg {
      font-size: 16px;
      color: $grey_3;
      cursor: pointer;
      transition: all 0.6s ease;

      &:hover {
        color: $dr_blue;
      }
    }
  }
  .disabled {
    color: #adadad;
    pointer-events: none;
    cursor: not-allowed;
    background-color: $disabled;
    opacity: 0.6;
  }
}

.drag-text {
  padding: 0 10px 0 5px;
  font-size: 14px;
  color: $grey_4;
}

.draghere-wrap {
  position: relative;
  width: 110px;
  padding: 10px 7px;
  color: #888;
  border: 2px dashed $grey_8;
}
.upload-btn-box {
  position: absolute;
  width: 61px;
  height: 49px;
  background-color: var(--selfserveprimarycolor);
  color: #fff;
  right: 0px;
  padding: 9px 18px 11px 20px;
  transition: all 0.6s ease;
  cursor: pointer;
  top: 0px;
  & .drop-icon-sign {
    width: 12px;
    height: 12px;
  }
}
.upload-btn-box:hover {
  background-color: #444;
}
</style>
