<template>
  <div class="pagination end-xs">
    <!-- per-page dropdown -->
    <label class="pagination-item">Items per page:</label>
    <div>
      <k-select
        class="pagination-item"
        label="ItemPerPage"
        name="ItemPerPage"
        :required="true"
        :value="perPage"
        :options="paginationOptions"
        @input="onChangeItemPerPage"
      />
    </div>

    <!-- Count: X of Y-->
    <label v-if="loader" class="pagination-item">loading</label>
    <label v-else class="pagination-item">{{ displayText }} of {{ totalCount }}</label>

    <!-- arrows -->
    <div class="pagination-arrows" :class="[{ disabled: leftDisabled }]">
      <font-awesome-icon :icon="['far', 'chevron-left']" @click="onLeftClick" />
    </div>
    <div class="pagination-arrows" :class="[{ disabled: rightDisabled }]">
      <font-awesome-icon :icon="['far', 'chevron-right']" @click="onRightClick" />
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import forkliftMixin from '~/components/mixins/forklift-mixin';
import { PAGINATION_OPTIONS } from '~/constant';

export default {
  name: 'Pagination',
  mixins: [forkliftMixin],
  props: {
    pages: {
      required: true,
      type: Number,
      default: 0,
    },

    totalCount: {
      required: true,
      type: Number,
      default: 0,
    },

    resultCount: {
      required: true,
      type: Number,
      default: 0,
    },

    // Whether or not loading is being shown
    loader: {
      require: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      paginationOptions: PAGINATION_OPTIONS,
    };
  },

  computed: {
    currentAccountId: sync('common/currentAccountId'),
    pageNumber: sync('common/orderCriteria@page.num'),
    perPage: sync('common/orderCriteria@page.per'),

    leftDisabled() {
      return this.loader || this.pageNumber === 0;
    },

    rightDisabled() {
      return this.loader || this.pages === this.pageNumber + 1;
    },

    displayText() {
      if (this.totalCount === 0) {
        return '0 - 0';
      }
      let e = (this.pageNumber + 1) * this.perPage;
      if (e > this.totalCount) {
        e = this.totalCount;
      }
      let s = e - this.resultCount + 1;
      if (s <= 0) {
        s = 1; // should always be at least 1. can turn negative when we have CRs
      }
      return `${s} - ${e}`;
    },
  },

  methods: {
    onLeftClick() {
      this.pageNumber -= 1;
    },

    onRightClick() {
      this.pageNumber += 1;
    },

    onChangeItemPerPage(val) {
      this.perPage = val;
      this.pageNumber = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  color: #adadad;
  pointer-events: none;
  background-color: $disabled;
}
// ::v-deep .form-input .form-input-field.v-select .vs__selected-options {
//   font-size: 13px !important;
// }
::v-deep .form-input-wrap .form-click {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  min-height: 40px !important;
  cursor: default;
  .selected-text {
    padding: 0 10px 0 10px;
    font-size: 13px !important;
  }
  &:hover {
    color: var(--selfserveprimarycolor) !important;
    cursor: pointer !important;
  }
}
.pagination {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 13px;
}
::v-deep .pagination-arrows.disabled {
  background-color: transparent;
}
.pagination-item {
  display: inline-block; /*Add this*/
  margin: 0px 0;
  cursor: default;
  color: #999 !important;
  // &:hover {
  //   color: #54a8c7;
  // }
}
// ::v-deep .v-select {
//   border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
//   margin-right: 6px;
// }
.pagination-arrows {
  margin-right: 8px;
  margin-left: 8px;
  cursor: pointer;
  svg {
    color: #999 !important;
    &:hover {
      color: var(--selfserveprimarycolor) !important;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  .v-select {
  }
}

.pagination label {
  color: #999;
}

.sort-icon {
  top: 40%;
  right: 7px;
  margin: 0 0 0 10px;
}
::v-deep .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  background-color: #f9f9f9 !important;
}
::v-deep .form-input .form-input-field.v-select.vs--single .vs__dropdown-toggle {
  background-color: transparent;
  &:hover {
    color: var(--selfserveprimarycolor) !important;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
::v-deep .form-input .form-input-field.v-select.vs--single .vs__dropdown-toggle .vs__selected {
  font-family: 'Manrope', sans-serif !important;
  padding: 0 10px 0 10px !important;
  font-size: 13px;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__actions svg:hover {
  color: var(--selfserveprimarycolor) !important;
}
::v-deep .form-input .form-input-field.v-select:hover {
  color: var(--selfserveprimarycolor) !important;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  font-size: 11px !important;
}

::v-deep .form-input .form-input-field.v-select {
  &:hover {
    color: var(--selfserveprimarycolor) !important;
  }
}
</style>
