var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("col-xs-12 " + (_vm.readonly ? 'readonly-bp' : ''))},[_c('div',{staticClass:"flip-title"},[_vm._v(" What is your campaign goal? "),(_vm.errorObj.goal)?_c('b-error-msg',{attrs:{"error-msg":_vm.errorObj.goal}}):_vm._e()],1),_c('div',{staticClass:"row-flex pixel-setup-popup",on:{"mouseleave":function($event){_vm.isShowPixelSetup = false}}},[_vm._l((_vm.goalOptions),function(goal){return _c('div',{key:goal.category,staticClass:"goals-list",class:[
        goal.category == _vm.activeGoal.category ? 'activeGoal' : '',
        goal.isDisabled ? 'disable_goal' : '' ],on:{"mouseenter":function($event){goal.defaultMetric === 'VCR' ? (_vm.isShowPixelSetup = false) : (_vm.isShowPixelSetup = true)},"click":function($event){return _vm.onClickGoal(goal)}}},[_c('span',{staticClass:"pop-view"},[_c('font-awesome-icon',{staticClass:"pcheck2",attrs:{"icon":['fas', 'check'],"size":"1x"}}),_c('font-awesome-icon',{staticClass:"pop-icon pbig",attrs:{"icon":goal.goal_icon,"size":"1x"}})],1),(goal.isDisabled)?_c('div',{staticClass:"kpi-alert"},[_c('div',{staticClass:"kpi-alert-content"},[_vm._v(" Additional "),_c('a',{on:{"click":function($event){return _vm.onClickPixelSetup()}}},[_vm._v("pixel setup")]),_vm._v(" needed for this goal ")])]):_vm._e(),_c('div',{staticClass:"pop-view-txt"},[_vm._v(" "+_vm._s(goal.name)+" ")])])}),(
        _vm.goalOptions.filter(function (goal) { return !goal.isDisabled; }).length < 2 && _vm.isShowPixelSetup === true
      )?_c('div',{staticClass:"pixel-setup-popup-wrapper"},[_c('div',{staticClass:"perf-content"},[_c('div',{staticClass:"perf-txt"},[_vm._v(" Performance goals cannot be selected until you've placed and verified your Flip Pixel. ")]),_c('k-button',{attrs:{"size":3,"label":"PIXEL SETUP"},on:{"click":function($event){return _vm.onClickPixelSetupPopupBtn()}}})],1)]):_vm._e()],2),(_vm.activeGoal && _vm.activeGoal.category != 'brandlift' && _vm.subCategoryOptions.length > 0)?_c('div',[_c('k-select',{attrs:{"name":"IncludeSubcategories","label":"Include Subcategories","required":true,"options":_vm.subCategoryOptions,"value":_vm.activeGoal.subCategories,"fancy":true,"multiple":true,"label-key":"value"},on:{"input":_vm.onSelectCategories}})],1):_vm._e(),(_vm.activeGoal.category && !_vm.activeGoal.isDisabled)?_c('div',{staticClass:"flip-category"},[(!(_vm.activeGoal.category === 'vcr'))?_c('div',{staticClass:"flip-options"},[_vm._l((_vm.FLIP_CAMPAIGN_GOAL_METRICS),function(metric){return _c('a',{key:metric.id,staticClass:"flip-btn",class:[
          ("" + (_vm.isMetricSelected(_vm.activeGoal, metric) ? 'flip-btn-selected' : '')),
          ("" + (_vm.activeGoal.category !== 'checkout' && metric.id === 2 ? 'disabled' : '')),
          ((metric.id === 1 ? 'lend' : '') + " " + (metric.id === 2 ? 'rend' : '')) ],on:{"click":function($event){return _vm.onSelectGoalMetric(metric)}}},[_c('font-awesome-icon',{staticClass:"flip-icon",attrs:{"icon":['fas', 'check'],"size":"1x"}}),_c('div',{staticClass:"flip-text"},[_vm._v(_vm._s(metric.label))])],1)}),_c('div',{class:("" + (_vm.activeGoalMetricLabel === 'ROAS' || _vm.activeGoalMetricLabel === 'Inc. Reach %'
              ? 'percent-sign'
              : 'dollar-sign'))},[_c('b-text-input',{staticClass:"cl-subcat",attrs:{"value":_vm.goalMetricValue,"required":true,"placeholder":_vm.metricPlaceholder,"readonly":_vm.readonly},on:{"change":_vm.onChangeGoalMetricValue}})],1)],2):_vm._e(),(_vm.activeGoal.category === 'vcr')?_c('div',{staticClass:"flip-options"},[_vm._l((_vm.FLIP_CAMPAIGN_AWARENESS_METRICS),function(metric){return _c('a',{key:metric.id,staticClass:"flip-btn",class:[
          ("" + (_vm.isAwarenessMetricSelected(_vm.activeGoal, metric) ? 'flip-btn-selected' : '')),
          ((metric.id === 3 ? 'lend' : '') + " " + (metric.id === 4 ? 'rend' : '')) ],on:{"click":function($event){return _vm.onSelectGoalMetric(metric)}}},[_c('font-awesome-icon',{staticClass:"flip-icon",attrs:{"icon":['fas', 'check'],"size":"1x"}}),_c('div',{staticClass:"flip-text"},[_vm._v(_vm._s(metric.label))])],1)}),(_vm.activeGoalAwarenessMetricLabel === 'VCR')?_c('div',{class:("" + (_vm.activeGoalAwarenessMetricLabel === 'VCR' ? 'percent-sign' : ''))},[_c('b-text-input',{staticClass:"cl-subcat",attrs:{"value":_vm.goalMetricValue,"required":true,"placeholder":("Enter Goal " + (this.activeGoalAwarenessMetricLabel)),"readonly":_vm.readonly},on:{"change":_vm.onChangeGoalMetricValue}})],1):_vm._e()],2):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }