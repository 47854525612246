/**
 * TargetingMixin exports 2 mixin flavors:
 * - default: used in all target type forms
 * - targetingSelectMixin: only used in BaseTargetingForm to access helper methods
 */

import { isBlank, inert } from 'adready-api/helpers/common';

// import { TARGETING_TYPES } from '~/constant';
import TARGETING_TYPES from 'adready-api/constant-targeting';

import LineItemTargeting from 'adready-api/model/line-item-targeting';
import { TAR_DEMOGRAPHIC_TARGETING, TAR_BEHAVIOURAL_SEGMENT } from 'adready-api/constant';
import { TAR_STATE, TAR_CITY, TAR_DMA, TAR_ZIP_CODES, TAR_COUNTRY } from '~/constant';

const geoTaggingIds = [TAR_STATE, TAR_CITY, TAR_DMA, TAR_ZIP_CODES, TAR_COUNTRY];
/**
 * Note:
 * The `selectedTargeting` and `selectedTargetingId` fields are the main
 * differences between the BaseTargetForm (LineItemRow selection cell) and the
 * individual targeting forms (within modal dialog).
 *
 * In BaseTargetForm:
 *   - selectedTargeting: computed prop referencing the live (in vuex store) targeting object
 *   - selectedTargetingId: computed prop referencing `selectedTargeting` above
 *   - Both are used in a read-only context
 *
 * In Targeting forms (modals):
 *   - targetings: prop used to pass a copy of targetings array into modals
 *   - selectedTargetingId: prop used to pass selected ID for locating the current target
 *   - selectedTargeting: computed prop referencing a *copy* of the current targeting object, via ID above
 */

const props = {
  // Copy of lineitem.targetings array for read/write access in modals
  targetings: {
    required: true,
    type: Array,
  },

  // ID of targeting we are operating on (usually within a modal dialog)
  selectedTargetingId: {
    required: true,
    type: Number,
  },
};

// Computed props included into all targeting forms
const computed = {
  /**
   * The currently selected targeting from the locally-scoped targetings array (an inert copy)
   */
  selectedTargeting() {
    if (isBlank(this.targetings) || !this.selectedTargetingId) {
      return this.createSelectedTargeting(this.selectedTargetingId);
    }
    const existedTargeting = this.targetings.find(
      (te) => te.targetableElementId === this.selectedTargetingId
    );
    if (existedTargeting) {
      return existedTargeting;
    }
    return this.createSelectedTargeting(this.selectedTargetingId);
  },
};

// Computed props included into targeting forms + BaseTargetingForm
const computedAll = {
  /**
   * Computed ref to current Targeting value
   */
  targetingValue: {
    get() {
      if (this.selectedTargeting) {
        return this.selectedTargeting.value;
      }
      return undefined;
    },

    set(val) {
      if (this.selectedTargeting.value === val) {
        // don't touch if val is already the same
        return;
      }
      this.updateLineItemTargeting(this.targetingType, val);
    },
  },

  /**
   * Computed ref to current Targeting Value class impl
   */
  targetingClass() {
    if (!this.selectedTargetingId) {
      return null;
    }
    return this.getTargetingClass(this.selectedTargetingId);
  },
};

// Used by line-item-audience & base-targeting-form
// for managing modal value state.
const computedSelectOnly = {
  // Satisfies the committable API for targeting selection forms
  committableField() {
    if (this.showClickToEditCR) {
      return this.parentLineItem.targetings.map((targ) => targ.inertClone(true));
    }
    return this.lineItem.targetings;
  },

  hasValue() {
    return !!this.selectedTargetingId;
  },
};

function beforeMount() {
  this.initTargetingValue();
}

const methods = {
  createSelectedTargeting(id) {
    const sel = new LineItemTargeting();
    sel.targetableElementId = id;
    const tc = this.getTargetingClass(id);
    sel.value = tc ? tc.create() : null;
    return sel;
  },

  /**
   * Get the Targeting Value class impl for the given Targetable Element ID.
   * See `src/model/target_types/*`.
   *
   * @param {Number} id Targetable Element ID
   * @returns {TargetingValue}
   */
  getTargetingClass(id) {
    const tt = TARGETING_TYPES[id];
    if (!tt) {
      return null;
    }
    return tt.class;
  },

  /**
   * Initialize the targeting value to the correct type
   */
  initTargetingValue() {
    if (this.targetingClass && !this.targetingClass.isValidType(this.targetingValue)) {
      this.targetingValue = this.targetingClass.create();
    }
  },

  //  Update a targeting value for the current line item
  //
  // type: a valid targeting type (geoTargeting, audienceTargeting, publisherTargeting) [*not used, remove*]
  // val: the val to set on the targeting object
  updateLineItemTargeting(type, val) {
    // Set the value directly on the targeting object, which should be an inert copy.
    this.selectedTargeting.value = val;
    this.updateLineItemTargetingToStore();
  },

  //  Update a targeting value's field for the current line item
  //
  // type: a valid targeting type (geoTargeting, audienceTargeting, publisherTargeting)
  // val: the val to set on the targeting object
  updateLineItemTargetingField(type, key, val) {
    // Set the value directly on the targeting object, which should be an inert copy.
    this.selectedTargeting.value[key] = val;
    this.updateLineItemTargetingToStore();
  },

  /**
   * Update the selected targeting to the LineItem object and push it to the vuex store.
   */
  updateLineItemTargetingToStore() {
    let copyTargetings = inert(this.targetings);
    const localTargeting = copyTargetings.find(
      (t) => t.targetableElementId === this.selectedTargeting.targetableElementId
    );
    if (localTargeting) {
      localTargeting.value = inert(this.selectedTargeting.value);
    } else {
      copyTargetings.push(inert(this.selectedTargeting));
    }

    // Filter out if Demographic All targetings are blank
    if (this.selectedTargeting.targetableElementId === TAR_DEMOGRAPHIC_TARGETING) {
      const isAllBlank = !Object.values(this.selectedTargeting.value).some((v) => {
        return !isBlank(v) && v.length !== 0;
      });
      if (isAllBlank) {
        copyTargetings = copyTargetings.filter(
          (f) => f.targetableElementId !== TAR_DEMOGRAPHIC_TARGETING
        );
      }
    }
    // Filter out if Behavioural Targeting if none is selected
    if (this.selectedTargeting.targetableElementId === TAR_BEHAVIOURAL_SEGMENT) {
      if (
        (isBlank(this.selectedTargeting?.value?.dropDownValue) ||
          this.selectedTargeting?.value?.dropDownValue?.length === 0) &&
        isBlank(this.selectedTargeting?.value?.segmentRequested)
      ) {
        copyTargetings = copyTargetings.filter(
          (f) => f.targetableElementId !== TAR_BEHAVIOURAL_SEGMENT
        );
      }
    }

    this.updateLineItem({ targetings: copyTargetings });
  },

  updateGeoTaggingTargetingsToStore(id) {
    const copyTargetings = inert([
      ...this.targetings.filter((target) => !geoTaggingIds.includes(target.targetableElementId)),
    ]);
    this.updateLineItem(inert({ targetings: [...copyTargetings], geoTagElementId: id }));
  },
  getSelectedGeoTaggingId() {
    let taggingId = TAR_COUNTRY;
    const copyTargetings = this.targetings || [];
    for (let i = 0; i < copyTargetings.length; i++) {
      const id = copyTargetings[i].targetableElementId || -1;
      if (id) {
        for (let j = 0; j < geoTaggingIds.length; j++) {
          if (geoTaggingIds[j] === id && copyTargetings[i].value !== null) {
            taggingId = id;
          }
        }
      }
    }
    return taggingId;
  },
};

/**
 * targetingSelectMixin
 *
 * This is used for the targeting *selection* forms. i.e., the dropdown which
 * chooses the geo/audience/publisher targeting option at the line item row
 * level.
 */
export const targetingSelectMixin = {
  props,
  computed: {
    ...computedSelectOnly,
    ...computedAll,
  },
  methods,
};

/**
 * Modal level component for setting targeting values
 */
export default {
  props,
  computed: {
    ...computed,
    ...computedAll,
  },
  beforeMount,
  methods,
};
