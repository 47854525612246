<template>
  <div class="col-lg-12 bh-form-container" :class="`${disabled ? 'disable-field' : ''}`">
    <b-search-options-dropdown
      :search-text="searchText"
      :options="behaviouralOptions"
      :value="selectedTargeting.value.dropDownValue"
      label="Search Behavioral Targets"
      :show-option-loader="isOptionsLoading"
      :is-searchable="true"
      @select="onInput"
      @onSearchOptions="onSearchInput"
    />
  </div>
</template>

<script>
import { inert } from 'adready-api/helpers/common';
import { TAR_BEHAVIOURAL_SEGMENT } from 'adready-api/constant';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import targetingApi from '~/api/targeting-api';
import TargetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import { targetingSelectMixin } from '~/components/mixins/targeting-mixin';
import BSearchOptionsDropdown from '~/components/elements/b-search-options-dropdown.vue';

export default {
  name: 'BehavioralSegmentsForm',
  components: { BSearchOptionsDropdown },

  mixins: [
    lineItemMixin,
    validationsMixin.events,
    TargetableElementOptionsMixin,
    targetingSelectMixin,
  ],

  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      targetableElementId: TAR_BEHAVIOURAL_SEGMENT,
      targetingType: 'audienceTargeting',
      targeting: {
        dropDownValue: [],
        segmentRequested: undefined,
      },
      selectedTargeting: null,
      segmentRequested: false,
      behaviouralOptions: [],
      isOptionsLoading: false,
      searchText: '',
    };
  },
  computed: {
    label() {
      if (this.segmentRequested) {
        return 'Segment Requested';
      }
      return 'Segment not listed';
    },
    // plan: get('common/plan'),
  },

  beforeMount() {
    const selectedTargeting = this.targetings.find(
      (tp) => tp.targetableElementId === this.targetableElementId
    );
    if (selectedTargeting) {
      this.selectedTargeting = inert(selectedTargeting);
    } else {
      this.selectedTargeting = this.createSelectedTargeting(this.targetableElementId);
    }
  },

  methods: {
    onInput(val) {
      if (val?.length === 0) {
        this.searchText = '';
        this.behaviouralOptions = [];
      }
      this.updateLineItemTargetingField('audienceTargeting', 'dropDownValue', val);
      this.$emit('onChangeThirdPartyTargeting', 'behavioral', val);
    },
    async onSearchInput(val) {
      this.searchText = val;
      if (val.length < 3) {
        this.behaviouralOptions = [];
      } else {
        this.isOptionsLoading = true;
        this.behaviouralOptions = await targetingApi.getTargetableElementOptions([4, val]);
        this.isOptionsLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #cccccc;
  min-height: 20px;
  border-radius: unset;
  position: relative;
}
::v-deep .form-input .form-input-field.v-select.multiple-input-field .vs__selected-options {
  padding: 10px;
}

::v-deep .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  background: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  font-weight: 300;
  font-size: 13px;
  font-family: 'Manrope', sans-serif;
  padding: 3px 7px;
  margin: 0px 4px 1px 0px;
  border-radius: 2px;
  display: inline-block;
  border: unset;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: rgba(255, 255, 255, 0.8);
}
</style>
<style>
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--selected {
  display: none;
  background-color: #d9d8d8;
  color: #525b65;
}
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
  color: #525b65;
  background-color: #d9d8d8;
}
body .vs__dropdown-menu .vs__dropdown-option:hover {
  background: #d9d8d8;
}

body .vs__dropdown-hover:active {
  background: #717171 !important;
  box-shadow: inset 0 0 10px #000000 !important;
}
body .vs__dropdown-hover span:active {
  color: white;
}
.bh-form-container {
  padding-right: 0;
  padding-left: 0;
}
.disable-field {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
</style>
