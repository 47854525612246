<template>
  <div ref="bDropdownMultiSelect" :class="`ss-mdrop noselect ${showDropdownOptions ? 'open' : ''}`">
    <div class="ss-mdrop-btn">
      <div style="width:100%" @click="handleOpenDropdown(true)">
        <k-text-input
          v-if="isSearchable"
          class="searchable-input"
          :required="false"
          :value="searchText"
          :placeholder="label"
          type="text"
          @keyup="onInput"
        />
      </div>
    </div>
    <div :class="`ss-mdrop-content ${options.length > 0 ? '' : 'hide-shadow'}`">
      <div v-if="!showOptionLoader && showDropdownOptions">
        <div
          v-for="(option, index) in options"
          :key="`${option}_dropDown_${index}`"
          :class="`ss-mdrop-itm mitm1 ${isSelectedOption(option) ? 'selected' : ''}`"
          @click="handleOptionClick(option)"
        >
          <span :title="option.value" class="optionText">{{ option.value }}</span>
          <span v-if="option.options" class="additionalInfoText">{{ option.options }}</span>
          <font-awesome-icon
            :icon="['fal', `${isSelectedOption(option) ? 'minus' : 'plus'}`]"
            :class="`droparw supportIcon ${showDropdownOptions ? '' : 'outsideCrossIcon'}`"
          />
        </div>
      </div>
      <div v-if="showOptionLoader" class="ss-mdrop-itm mitm1"><b-loading-spinner /></div>
    </div>
    <div v-if="value.length > 0">
      <div
        v-for="(val, index) in value"
        :key="`${val}_selected_option_${index}`"
        class="ss-mdrop-itm-selected mitm1 selected outsideTag"
        @click="handleOptionClick({ value: val })"
      >
        <span :title="val" class="optionText">{{ val }}</span>
        <font-awesome-icon :icon="['fal', 'times']" class="droparw supportIcon outsideCrossIcon" />
      </div>
      <div
        v-if="value.length > 0"
        class="ss-mdrop-itm-selected mitm-removeall"
        @click="handleRemoveAllOptions"
      >
        Remove All
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';

export default {
  name: 'BSearchOptionsDropdown',
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: () => '',
    },
    hideSelectAllOption: {
      type: Boolean,
      required: false,
      deafult: () => false,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectAllInfo: {
      type: String,
      required: false,
      default: () => '',
    },
    searchText: {
      type: String,
      required: false,
      default: () => '',
    },
    showOptionLoader: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isSearchable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      showDropdownOptions: false,
    };
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener('click', this.onClickOutsideEvent);
      window.addEventListener('keydown', this.onClickOutsideEventKey);
    }, 0);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutsideEvent);
    window.removeEventListener('keydown', this.onClickOutsideEventKey);
  },
  methods: {
    handleOpenDropdown(isOpenOnly) {
      if (isOpenOnly) {
        this.showDropdownOptions = true;
      } else {
        this.showDropdownOptions = !this.showDropdownOptions;
      }
    },

    handleRemoveAllOptions() {
      this.$emit('select', []);
    },
    handleOptionClick(option) {
      if (this.value.find((val) => option.value === val)) {
        this.$emit(
          'select',
          this.value.filter((val) => val !== option.value)
        );
      } else {
        this.$emit('select', [...this.value, option.value]);
      }
    },
    isSelectedOption(option) {
      return this.value.find((val) => option.value === val);
    },
    onClickOutsideEvent(event) {
      const el = this.$refs.bDropdownMultiSelect;
      if (
        el &&
        !(el === event.target || el.contains(event.target)) &&
        !(event.target.className === 'form-input-field')
      ) {
        this.showDropdownOptions = false;
      }
    },
    onClickOutsideEventKey(event) {
      if (event.key === 'Escape') {
        if (
          !(this.$el === event.target || this.$el.contains(event.target)) &&
          !(event.target.className === 'form-input-field')
        ) {
          this.showDropdownOptions = false;
        }
      }
    },
    onInput: _.debounce(function(val) {
      if (this.searchText !== val) {
        this.$emit('onSearchOptions', val);
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.ss-mdrop {
  position: relative;
}

.ss-mdrop-btn {
  display: flex;
  padding: 11px 20px 10px 17px;
  position: relative;
  border: 1px solid #aaa;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  background-color: #fff;
}
.ss-mdrop-btn:hover {
  border: 1px solid var(--selfserveprimarycolor);
}

.fa-chevron-down:before {
  content: '\f078';
}

.open .ss-mdrop-content {
  max-height: 255px;
  overflow: auto;
  position: absolute;
  z-index: 11;
  top: 42px;
  width: 100%;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 10%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.open .additionalInfoText {
  display: inline-block;
}
.additionalInfoText {
  margin-right: 10px;
  margin-left: 5px;
  font-size: 12px;
  opacity: 0.7;
  display: none;
  position: absolute;
  right: 32px;
  top: 11px;
}
.droparw {
  position: relative;
  float: right;
  font-size: 11px;
  line-height: 12px;
  cursor: pointer;
  font-weight: 600;
  &.arrowicon {
    top: 5px;
  }
  &.supportIcon {
    font-size: 13px;
    top: 5px;
  }
}
.ss-mdrop-itm {
  display: inline-block;
  padding: 5px 25px 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  background-color: #fff;
  width: auto;
  margin: 5px 2px 0px 0px;
  color: #444;
  display: none;
  position: relative;
  cursor: pointer;
  &.no-options {
    text-align: center;
  }
}
.ss-mdrop-itm-selected {
  display: inline-block;
  padding: 5px 25px 5px 10px;
  border: 0px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  background-color: #fff;
  width: auto;
  margin: 5px 2px 0px 0px;
  color: #444;
  display: none;
  position: relative;
  cursor: pointer;
  &.no-options {
    text-align: center;
  }
}
.ss-mdrop-itm:hover {
  filter: brightness(0.93);
}
.ss-mdrop-itm-selected:hover {
  filter: brightness(0.93);
}

.open .ss-mdrop-itm {
  display: inline-block;
  width: 100%;
  padding: 9px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f7f9fb;
  font-size: 14px;
  color: #5a6269;
  margin: 0px;
  display: inline-block;
  border-radius: 0px;
}

.ss-mdrop-itm.selected {
  background-color: var(--selfserveprimarycolor);
  color: #fff;
  display: inline-block;
}
.ss-mdrop-itm-selected.selected {
  background-color: var(--selfserveprimarycolor);
  color: #fff;
  display: inline-block;
}
.ss-mdrop-itm.mitm1.outsideTag {
  border-radius: 3px;
  padding: 5px 10px 0px 10px;
}
.ss-mdrop-itm-selected.mitm1.outsideTag {
  border-radius: 3px;
  padding: 5px 10px 0px 10px;
}
.droparw.supportIcon.plusIcon {
  top: 3px;
}
.droparw.supportIcon.outsideCrossIcon {
  margin-left: 7px;
  top: 4px;
  opacity: 0.7;
  stroke: var(--selfserveprimarycolor);
  stroke-width: 1px;
}
.mitm-removeall {
  display: inline-block;
  padding: 5px 10px;
  background-color: transparent !important;
  color: #777 !important;
  bottom: 5px;
}
.mitm-removeall:hover {
  color: var(--selfserveprimarycolor) !important;
}
.ss-mdrop-itm:hover {
  filter: brightness(0.93);
}
.ss-mdrop-itm-selected:hover {
  filter: brightness(0.93);
}
.displayFlex {
  display: flex;
}
.optionText {
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.optionText.widthMaxContent {
  max-width: unset;
}
::v-deep .searchable-input.form-input label {
  margin: 11px 0 0 10px;
}
::v-deep .searchable-input.form-input .form-input-field {
  border: unset;
  padding: unset;
}
::v-deep .searchable-input.form-input.focused label {
  display: none;
}
.hidden {
  display: none;
}
.open .ss-mdrop-content.hide-shadow {
  box-shadow: unset;
}
</style>
