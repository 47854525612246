<template>
  <div :id="`left-nav-${lineItem.id}`" class="ss-nav-wrapper">
    <div class="ss-title-bar">
      <div class="ad-group-title">
        <div v-if="lineItem && lineItem.name" class="ss-nav-title">{{ lineItem.name }}</div>
        <div v-else class="ss-nav-title">New Ad Group</div>
        <div
          :class="`newgrp-clone mp-btn btn-teal ${readonly ? 'readonly-ag' : ''}`"
          @click="onCloneGroup"
        >
          <span class="intip4 intip4b" data-tool-tip="Clone this ad group">
            <font-awesome-icon :icon="['far', 'clone']" class="inline-fa" />
          </span>
        </div>
      </div>
      <div
        :class="`newgrp-plus mp-btn btn-teal ${readonly ? 'readonly-ag' : ''}`"
        @click="onAddNewGroup"
      >
        <span class="intip4 intip4b relativePos" data-tool-tip="Add an additional ad group">
          <font-awesome-icon :icon="['far', 'plus']" class="inline-fa" />
        </span>
      </div>
      <div v-if="canRemoveAdGroup" class="delete-adgrp mp-btn" @click="onRemoveGroup">
        <span class="intip4 intip4b" data-tool-tip="Delete this ad group">
          <font-awesome-icon :icon="['far', 'trash']" class="inline-fa" />
        </span>
      </div>
    </div>
    <div class="ss-lnav-links">
      <div
        v-for="(liOptions, index) in lineItemOptions"
        :key="`${selectedPage}_${selectedPageIndex}_${index}`"
        :class="
          `ss-lnav-link ${
            selectedPage === liOptions.pageName && selectedPageIndex === liOptions.pageIndex
              ? 'active'
              : ''
          }`
        "
      >
        <font-awesome-icon :icon="['far', `${liOptions.leftIcon}`]" class="lnav-icon" />
        <div
          :class="
            `ss-lnav-txt ${
              selectedPage === liOptions.pageName && selectedPageIndex === liOptions.pageIndex
                ? 'active'
                : ''
            }`
          "
          @click="openPage(liOptions.pageName, liOptions.pageIndex, lineItemIndex)"
        >
          {{ liOptions.title }}
        </div>
        <font-awesome-icon
          :icon="[
            `${liOptions.rightIcon === 'exclamation' ? 'fas' : 'far'}`,
            `${liOptions.rightIcon}`,
          ]"
          :class="
            `lnav-icon2 ${isPageIndexValid(liOptions) ? 'active' : ''} ${
              liOptions.rightIcon === 'exclamation' ? 'error' : ''
            }`
          "
        />
      </div>

      <div class="side-projection active">
        <div class="sdp-item">
          <span class="sdp-val-label">Estimated CPM: </span>
          <span class="sdp-val">{{ formatNumber(cpm, 'CURRENCY') }}</span>
        </div>
        <div class="sdp-item">
          <span class="sdp-val-label">Estimated Impressions: </span>
          <span class="sdp-val">{{ formatNumberAsFullNumber(impressions, 'VISITS') }}</span>
        </div>
        <div class="sdp-item">
          <div class="left-nav-alert">
            <span
              v-if="deliveryProbability && deliveryProbability.level === 'Low'"
              class="intip4 intip-right"
              data-tool-tip="This ad group is unlikely to deliver in full. Loosen the targeting parameters, add publishers, or decrease budget to increase the delivery probability."
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation-triangle']"
                class="inline-fa font12 low bounce"
              />
            </span>
          </div>
          <span class="sdp-val-label">Delivery Probability: </span>
          <font-awesome-icon
            v-if="deliveryProbability"
            :icon="['far', deliveryProbability.icon]"
            class="inline-fa sdp-gauge"
            :class="
              `${
                deliveryProbability && deliveryProbability.level
                  ? deliveryProbability.level.toLowerCase()
                  : ''
              }`
            "
          />
          <span v-if="deliveryProbability" class="sdp-note">
            ({{ deliveryProbability.level }})
          </span>
          <span v-else class="sdp-note"> NA</span>
        </div>
      </div>
    </div>
    <!-- <div class="displayFlex new-grp-btn-container">
      <div
        v-if="lineItemIndex === totalLineItems - 1"
        class="newgrp-plus mp-btn btn-teal"
        @click="onAddNewGroup"
      >
        <span class="intip4 intip4b relativePos" data-tool-tip="Add an additional ad group">
          <font-awesome-icon :icon="['fal', 'plus']" class="inline-fa" />
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get, sync } from 'vuex-pathify';
import { AD_GROUP_NAV_BAR_OPTIONS } from '~/constant';
import lineItemHelpers from '~/helpers/line-item-helpers';
import deliveryProbabilityHelpers from '~/helpers/delivery-probability-helpers';
import formatNumber, { formatNumberAsFullNumber } from '~/util/numeral';
import forklift from '~/components/mixins/forklift-mixin';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';

export default {
  name: 'AdGroupNavOptions',
  mixins: [forklift, campaignValidationMixin],
  props: {
    openPage: {
      type: Function,
      required: true,
      default: () => {},
    },
    selectedPage: {
      type: String,
      required: true,
      default: () => '',
    },
    lineItemStartPageIndex: {
      type: Number,
      required: true,
      default: () => 0,
    },
    lineItemIndex: {
      type: Number,
      required: true,
      default: () => 0,
    },
    lineItem: {
      type: Object,
      required: false,
      default: () => {},
    },
    totalLineItems: {
      type: Number,
      required: true,
      default: () => 0,
    },
    selectedPageIndex: {
      type: Number,
      required: true,
      default: () => 0,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    const pi =
      AD_GROUP_NAV_BAR_OPTIONS.length * this.lineItemIndex + this.lineItemStartPageIndex + 1;
    const lineItemOptions = AD_GROUP_NAV_BAR_OPTIONS.map((o, i) => ({
      ...o,
      pageIndex: pi + i,
    }));
    return {
      formatNumber,
      formatNumberAsFullNumber,
      lineItemOptions,
      cpm: 0,
      impressions: 0,
      deliveryProbability: null,
    };
  },

  computed: {
    mediaPublisherCPMData: sync('common/mediaPublisherCPMData'),
    allDeviceTypes: get('common/allDeviceTypes'),
    plan: get('common/plan'),
    lineItems: get('common/plan@lineItems'),
    changedLineItem() {
      // If user is creating a new campaign and populates multiple AdGroups without saving yet,
      // then all LIs will have id == undefined.
      if (!this.lineItem.id) {
        return this.lineItem;
      }
      const line = this.lineItems.find((l) => l.id === this.lineItem.id);
      if (line) {
        return line;
      }
      return this.lineItem;
    },
    canRemoveAdGroup() {
      return (
        isBlank(this.lineItem.id) &&
        (this.plan && this.plan.lineItems ? this.plan.lineItems.length > 1 : false)
      );
    },
  },

  watch: {
    changedLineItem: {
      deep: true,
      handler(l) {
        const result = lineItemHelpers.calculateLineItemCPMAndImpressions(
          l,
          this.mediaPublisherCPMData
        );
        this.cpm = result.cpm;
        this.impressions = result.impressions;
        const probability = deliveryProbabilityHelpers.calculateLineItemDeliveryProbability(
          l,
          this.mediaPublisherCPMData,
          this.allDeviceTypes
        );
        this.deliveryProbability = probability;
        setTimeout(() => {
          if (this.pageIndexValidationObj[this.selectedPageIndex]?.isValidated) {
            this.lineItemOptions = this.checkForPageIndexValidation(
              this.lineItemOptions,
              this.plan
            );
          }
        }, 0);
      },
    },
    selectedPageIndex: {
      immediate: true,
      deep: true,
      handler() {
        this.lineItemOptions = this.checkForPageIndexValidation(this.lineItemOptions, this.plan);
      },
    },
  },

  async mounted() {
    await this.loadMediaPublishers();
    await this.loadDeviceTypes();
    const result = lineItemHelpers.calculateLineItemCPMAndImpressions(
      this.changedLineItem,
      this.mediaPublisherCPMData
    );
    this.cpm = result.cpm;
    this.impressions = result.impressions;
    const probability = deliveryProbabilityHelpers.calculateLineItemDeliveryProbability(
      this.changedLineItem,
      this.mediaPublisherCPMData,
      this.allDeviceTypes
    );
    this.deliveryProbability = probability;
  },

  methods: {
    onRemoveGroup() {
      this.$emit('removeGroup', this.lineItemIndex);
    },
    onAddNewGroup() {
      this.$emit('addNewGroup');
    },
    onCloneGroup() {
      this.$emit('cloneAdGroup', this.lineItem, this.lineItemIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.ss-nav-wrapper {
  padding-top: 40px;
  .ss-title-bar {
    display: inline-flex;
    .ad-group-title {
      display: flex;
      flex-direction: row;
    }
  }
  .ss-lnav-link {
    margin-left: 12px;
  }
  .ss-nav-title {
    text-transform: uppercase;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.05em;
    font-weight: 700;
  }
  .ss-lnav-links {
    margin-top: 20px;
    padding-left: 12px;
    position: relative;
  }
}
.side-projection {
  border-bottom: 1px solid #cbd6e1;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: none;
  &.active {
    display: block;
  }
}
.sdp-item {
  font-size: 14px;
}
.left-nav-alert {
  position: absolute;
  margin-left: -20px;
}
.sdp-gauge {
  font-size: 17px;
  position: relative;
  top: 1px;
  color: var(--selfserveprimarycolor);
}
.low {
  color: #de284a !important;
}
.font12 {
  font-size: 12px !important;
}
.sdp-val {
  color: var(--selfserveprimarycolor);
  font-weight: 600;
}
.sdp-note {
  opacity: 0.5;
}
.newgrp-plus,
.newgrp-plus:active,
.newgrp-plus:visited,
.delete-adgrp,
.delete-adgrp:active,
.delete-adgrp:visited,
.newgrp-clone,
.newgrp-clone:active,
.newgrp-clone:visited {
  right: 30px;
  padding: 0px 5px;
  font-size: 16px;
  border-radius: 2px;
  height: 18px;
  width: 18px;
  vertical-align: top;
  line-height: 24px;
}
.delete-adgrp,
.delete-adgrp:active,
.delete-adgrp:visited,
.newgrp-clone,
.newgrp-clone:active,
.newgrp-clone:visited,
.newgrp-plus,
.newgrp-plus:active,
.newgrp-plus:visited {
  font-size: 16px;
  height: 18px;
  width: 18px;
  line-height: 28px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.intip2[data-tool-tip]::after,
.intip4[data-tool-tip]::after {
  content: attr(data-tool-tip);
  display: block;
  position: absolute;
  width: 220px;
  font-size: 12px;
  line-height: 1.2em;
  background-color: #fdea85;
  color: #5b5b5b;
  padding: 10px 12px;
  border-radius: 3px;
  left: 0;
  margin-left: -113px;
  bottom: 100%;
  transform: scale(0);
  transition: ease 0.3s;
  transform-origin: bottom center;
  z-index: 99;
  pointer-events: none;
}
.btn-teal {
  background-color: #fff !important;
  color: var(--selfserveprimarycolor) !important;
  &:hover {
    background-color: var(--selfserveprimarycolor) !important;
    color: #fff !important;
  }
}
.mp-btn:hover {
  background-color: #444;
  color: #fff;
}

.intip4 {
  position: relative;
  top: 0px;
}
.intip4[data-tool-tip]::after {
  background-color: #464646;
  color: #fff;
  width: 160px;
  margin-left: -80px;
  font-size: 13px;
  pointer-events: none;
}
.intip-right[data-tool-tip]::after {
  text-transform: none;
  width: 245px;
  margin-left: -20px;
  text-align: left;
  bottom: 150% !important;
}
.intip4b[data-tool-tip]::after {
  text-align: center;
  width: 120px;
  margin-left: -50px;
  text-transform: none;
  font-size: 12px;
  letter-spacing: 0em;
}

.intip4b[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 185%;
}
.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 250%;
  bottom: 10px;
  right: 30px;
}
.displayFlex {
  display: flex;
}
.relativePos {
  //left: 2px;
  position: relative;
}
.delete-adgrp {
  border: 0px;
  padding: 0px 7px;
  background-color: #fff !important;
  color: var(--selfserveprimarycolor) !important;
  &:hover {
    background-color: var(--selfserveprimarycolor) !important;
    color: #fff !important;
  }
}
::v-deep .icon-wrap {
  text-align: center;
}
.bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 0.5s infinite linear;
  -o-animation: bounce 0.5s infinite linear;
  -webkit-animation: bounce 0.5s infinite linear;
  animation: bounce 0.5s infinite linear;
}
@-webkit-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@-ms-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
.error {
  color: #ff0202;
}
.new-grp-btn-container {
  margin-top: 24px;
  .newgrp-plus {
    font-size: 21px;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.readonly-ag {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
