// targetableElementOptionsMixin
//
// Loads option lists for the currently selected TargetableElement.
// Sets this.options for use by k-select.
import masterDataApi from 'adready-api/api/masterData';
import { isBlank } from 'adready-api/helpers/common';
import {
  TEOB_HOMEOWNERS,
  TEOB_AUTO_INTENDERSS,
  PROD_CTV,
  TAR_INCOME,
  TAR_BEHAVIOURAL_SEGMENT,
} from 'adready-api/constant';

const computed = {
  id() {
    // id can be set directly on the component (e.g., in StateSelect). If so, use it.
    // Otherwise try to load from line item via currently selectly targeting option.
    let id = this.targetableElementId;
    if (!id && this.selectedTargeting) {
      id = this.selectedTargeting.targetableElementId;
    }
    return id;
  },

  options() {
    if (isBlank(this.optionsData)) {
      return [];
    }
    const { id } = this;
    const options = this.optionsData;
    const ctvProduct = this.lineItem.hasProductTypeId(PROD_CTV);
    if (!(ctvProduct && id === TAR_BEHAVIOURAL_SEGMENT)) {
      // return if any other type
      if (id === TAR_INCOME) {
        options.sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        });
      }
      return options.map((item) => item.value);
    }

    // Filter out behavioral segments for CTV
    return options
      .filter((opt) => opt.id === TEOB_HOMEOWNERS || opt.id === TEOB_AUTO_INTENDERSS)
      .map((item) => item.value);
  },
};

const asyncComputed = {
  optionsData: {
    default: [],
    async get() {
      if (!this.id) {
        return [];
      }
      return masterDataApi.targetingTypeOptions(this.id).then((data) => {
        return data;
      });
    },
  },
};

export default {
  computed,
  asyncComputed,
};
