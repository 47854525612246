<template>
  <div class="ss-third" :class="`${disabled ? 'disable-field' : ''}`">
    <gender-select
      :line-item="lineItem"
      :plan="plan"
      :targeting-value="targetingValue"
      :required="true"
      :valid="isValid"
      @onInput="onInput"
      @validation="(errors) => onValidation(errors, 'gender', true)"
    />
    <age-select
      :line-item="lineItem"
      :plan="plan"
      :targeting-value="targetingValue"
      :required="true"
      :valid="isValid"
      @onInput="onInput"
      @validation="(errors) => onValidation(errors, 'age', true)"
    />
    <income-select
      :line-item="lineItem"
      :plan="plan"
      :targeting-value="targetingValue"
      :required="true"
      :valid="isValid"
      @onInput="onInput"
      @validation="(errors) => onValidation(errors, 'income', true)"
    />
  </div>
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import { inert } from 'adready-api/helpers/common';
import { TAR_DEMOGRAPHIC_TARGETING } from 'adready-api/constant';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import { targetingSelectMixin } from '~/components/mixins/targeting-mixin';

import committableMixin from '~/components/mixins/committable-mixin';

import AgeSelect from './age-select.vue';
import GenderSelect from './gender-select.vue';
import IncomeSelect from './income-select.vue';

export default {
  name: 'AudienceDemographicForm',

  components: {
    AgeSelect,
    GenderSelect,
    IncomeSelect,
  },

  mixins: [lineItemMixin, validationsMixin.events, targetingSelectMixin, committableMixin],
  props: {
    targetings: {
      required: true,
      type: Array,
      default: () => [],
    },

    selectedTargetingId: {
      required: true,
      type: Number,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      targetingType: 'audienceTargeting',
      // TODO: move to some common location
      errorMessage: 'Must select at least Age, Gender or Income',
      selectedTargeting: null,
      targetableElementId: TAR_DEMOGRAPHIC_TARGETING,
    };
  },

  computed: {
    isValid() {
      if (this.errors.numKeys === 3 && this.errors.length < 3) {
        // valid if we have reports for all 3 children but less than 3 errors
        // (which means at least one child is valid)
        return true;
      }
      return null;
    },
  },

  beforeMount() {
    const selectedTargeting = this.targetings.find(
      (tp) => tp.targetableElementId === this.targetableElementId
    );
    if (selectedTargeting) {
      this.selectedTargeting = inert(selectedTargeting);
    } else {
      this.selectedTargeting = this.createSelectedTargeting(this.targetableElementId);
    }
  },

  methods: {
    onInput(val, type) {
      this.updateLineItemTargetingField('audienceTargeting', type, val);
      this.$emit('onChangeThirdPartyTargeting', type, val);
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-form-section {
  .form-input-wrap {
    width: 200px;
    margin: 0 10px 0 0;
    &:last-child {
      margin: 0;
    }
  }
}
.ss-third {
  display: flex;
  vertical-align: top;
  justify-content: space-between;
  margin-bottom: 30px;
  font-family: 'Manrope';
  width: 100%;
  & .ss-mdrop {
    /* width: 267px; */
    width: 31%;
    margin-right: unset;
  }
}
.disable-field {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
</style>
