<template>
  <div :class="` ${readonly ? 'readonly-bp' : ''}`">
    <div class="flip-title" :class="goalBrandLift ? 'disabled' : ''">
      Preferred Attribution Methodology
    </div>
    <div class="bl-options" :class="goalBrandLift ? 'disabled' : ''">
      <a
        v-for="(methodology, index) in methodologies"
        :key="methodology.id"
        class="bl-btn"
        :class="[
          `${methodology.id === activeAttribution.id ? 'bl-btn-selected' : ''}`,
          `${index === 0 ? 'lend' : ''} ${index === methodologies.length - 1 ? 'rend' : ''}`,
        ]"
        @click="onSelectAttribution(methodology)"
      >
        <font-awesome-icon class="bl-icon" :icon="['fas', 'check']" size="1x" />
        <div class="bl-text">{{ methodology.value }}</div>
        <span v-if="methodology.desc" :data-tool-tip="methodology.desc" class="intip4">
          <font-awesome-icon :icon="['fas', 'info-circle']" />
        </span>
      </a>
    </div>
    <b-error-msg v-if="errorObj.methodology" :error-msg="errorObj.methodology" />
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get, sync } from 'vuex-pathify';
import bErrorMsg from '~/components/elements/b-error-msg.vue';

export default {
  name: 'BPlanFlipCampaignAttribution',
  components: { bErrorMsg },
  props: {
    errorObj: { required: false, type: Object, default: () => {} },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      methodologies: [
        {
          id: 1,
          value: 'last touch',
          desc:
            'The last exposure gets all the credit. For example, if someone is exposed to ads on Sling, Hulu, and NBCU, in that order, and then converts, NBCU would be credited for the conversion.',
        },
        {
          id: 2,
          value: 'first touch',
          desc:
            'The first exposure gets all the credit. For example, if someone is exposed to ads on Sling, Hulu, and NBCU, in that order, and then converts, Sling would be credited for the conversion.',
        },
        {
          id: 3,
          value: 'linear',
          desc:
            'Each exposure gets equal credit. For example, if someone is exposed to ads on Sling, Hulu, and NBCU, in that order, and then converts, each of the three publishers would be assigned 33% of the credit.',
        },
        {
          id: 4,
          value: 'time decay',
          desc:
            'The closer the exposure is to the conversion, the more credit assigned. For example, if someone is exposed to ads on Sling, Hulu, and NBCU, in that order, and then converts, Sling might receive 20% of the credit, Hulu might receive 30% of the credit, and NBCU might receive 50% of the credit.',
        },
      ],
    };
  },
  computed: {
    account: get('common/account'),
    plan: get('common/plan'),
    flipCampaignInfoMethodology: sync('common/plan@flipCampaignInfo.methodology'),
    flipCampaignInfoGoal: get('common/plan@flipCampaignInfo.goal'),

    goalBrandLift() {
      if (!isBlank(this.flipCampaignInfoGoal)) {
        return this.flipCampaignInfoGoal.category === 'brandlift';
      }
      return false;
    },
    activeAttribution() {
      if (
        !isBlank(this.plan.flipCampaignInfo) &&
        !isBlank(this.plan.flipCampaignInfo.methodology)
      ) {
        return this.plan.flipCampaignInfo.methodology;
      }
      return { value: null, id: null };
    },
  },
  methods: {
    onSelectAttribution(val) {
      this.flipCampaignInfoMethodology = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.bl-options {
  display: flex;
  .bl-icon {
    display: none;
  }
  .bl-text {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 5px;
  }
  .bl-btn {
    color: #7f7f7f !important;
    border: 1px solid #b3b3b3 !important;
    padding: 9px 12px !important;
    margin: 10px 0px;
    text-align: center;
    letter-spacing: 0.02em;
    height: 40px;
    display: flex;
    align-items: center;
    border-right-width: 0.5px !important;
    border-left-width: 0.5px !important;
    &.lend {
      border-left-width: 1px !important;
      border-radius: 3px 0px 0px 3px;
    }
    &.rend {
      border-right-width: 1px !important;
      border-radius: 0px 3px 3px 0px;
    }
    &:hover {
      background-color: #eee !important;
      color: #222 !important;
    }
    &.bl-btn-selected:hover {
      background-color: var(--selfserveprimarycolor) !important;
      color: #fff !important;
    }
  }
  .bl-btn-selected {
    background-color: var(--selfserveprimarycolor) !important;
    color: #fff !important;
    border: 1px solid var(--selfserveprimarycolor) !important;
    .bl-icon {
      display: block;
      color: #fff !important;
      transform: scale(0.8);
      margin-right: 5px;
    }
    &:hover {
      background-color: #fff;
    }
  }
}
.flip-title {
  margin-bottom: 20px;
  color: #444;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.02em;
}
.disabled {
  pointer-events: none;
  opacity: 0.25;
}
::v-deep .fa-info-circle {
  transform: scale(0.8);
}
.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  padding: 10px 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: left;
  text-transform: none;
  content: attr(data-tool-tip);
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
  background-color: #464646;
  color: #fff;
  font-size: 13px;
  pointer-events: none;
  text-transform: none;
  max-width: 245px;
  margin-left: -120px;
  width: max-content;
}

.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 170%;
}
.readonly-bp {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.25;
}
</style>
