<template>
  <div>
    <div :class="['center-xs']">
      <BPlanFlipCampaignGoal
        :goal-data="goalData"
        :error-obj="errorObj"
        :readonly="readonly"
        @onChangeGoal="onChangeGoal"
      />
    </div>
    <div class="sec-spacer"></div>
    <div :class="['row']">
      <div class="col-xs-6">
        <b-plan-flip-campaign-optimization
          :error-obj="errorObj"
          :readonly="readonly || disableCampaignOptimization"
        />
      </div>
      <div class="col-xs-6">
        <BPlanFlipCampaignConversion
          :error-obj="errorObj"
          :readonly="readonly || disableCampaignConversion"
          @checkForError="onCheckForError"
        />
      </div>
    </div>
    <div class="sec-spacer"></div>
    <div :class="['row']">
      <div class="col-xs-12">
        <BPlanFlipCampaignAttribution
          :error-obj="errorObj"
          :readonly="readonly || disableCampaignAttribution"
        />
      </div>
    </div>
    <div class="sec-spacer"></div>
  </div>
</template>

<script>
import BPlanFlipCampaignGoal from './b-plan-flip-campaign-goal.vue';
import BPlanFlipCampaignOptimization from './b-plan-flip-campaign-optimization.vue';
import BPlanFlipCampaignConversion from './b-plan-flip-campaign-conversion.vue';
import BPlanFlipCampaignAttribution from './b-plan-flip-campaign-attribution.vue';

export default {
  name: 'BPlanFlipCampaignInfo',

  components: {
    BPlanFlipCampaignGoal,
    BPlanFlipCampaignOptimization,
    BPlanFlipCampaignConversion,
    BPlanFlipCampaignAttribution,
  },
  props: {
    goalData: { required: true, type: Array },
    errorObj: { required: false, type: Object, default: () => {} },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disableCampaignOptimization: false,
      disableCampaignConversion: false,
      disableCampaignAttribution: false,
    };
  },

  methods: {
    onCheckForError(val, fieldName) {
      this.$emit('checkForError', val, fieldName);
    },
    onChangeGoal(selectedGoal) {
      const isVCR = selectedGoal === 'vcr';
      this.disableCampaignOptimization = isVCR;
      this.disableCampaignConversion = isVCR;
      this.disableCampaignAttribution = isVCR;
    },
  },
};
</script>

<style lang="scss" scoped>
.sec-spacer {
  padding-top: 40px;
  border-bottom: 1px dashed #ddd;
  margin-bottom: 40px;
}
</style>
