<template>
  <div
    id="REVIEW_CAMPAIGN"
    class="mvc-form-wrap3 s3-wrap"
    style="padding:0px 50px 40px 50px;text-align: left;"
  >
    <div style="height: 5px;"></div>
    <div class="body-sec-title" style="margin-top: 30px;">Review</div>
    <div v-if="errorMsg" class="error">{{ errorMsg }}</div>
    <div class="sec-title block">
      Campaign Detail
    </div>
    <div
      class="mvc-input mvc-half mvc-half-left vtop rsedit sl1"
      style="margin-bottom: 20px;"
      @click="jumpToPage(CAMPAIGN_INFO)"
    >
      <div class="edit-blk-section">
        <font-awesome-icon :icon="['fas', 'pen']" class="inline-fa" />
      </div>
      <review-campaign-display-field :field="reviewPlan.campaignName" label="Campaign Name" />
      <div class="ss-break"></div>
      <review-campaign-display-field
        :field="reviewPlan.flightStartDate"
        label="Start Date"
        data-type="date"
      />
      <div class="ss-break"></div>
      <review-campaign-display-field
        :field="reviewPlan.flightEndDate"
        label="End Date"
        data-type="date"
      />
      <div class="ss-break"></div>
      <review-campaign-display-field
        :field="reviewPlan.totalBudget"
        label="Budget"
        data-type="currency"
      />
      <div class="ss-break"></div>
      <review-campaign-display-field
        :field="reviewPlan.overallCPM"
        label="eCPM"
        data-type="currency"
      />
      <div class="ss-break"></div>
      <review-campaign-display-field
        :field="reviewPlan.overallImpressions"
        label="IMPs"
        data-type="visits"
      />
      <div class="ss-break"></div>
    </div>
    <div
      class="mvc-input mvc-half mvc-half-right vtop"
      style="margin-bottom: 20px;"
      @click="jumpToPage(CAMPAIGN_INFO)"
    >
      <div class="rsedit sl1">
        <div class="edit-blk-section">
          <font-awesome-icon :icon="['fas', 'pen']" class="inline-fa" />
        </div>
        <review-campaign-display-field :field="reviewPlan.goal" label="Campaign Goal" />
        <div class="ss-break"></div>
        <review-campaign-display-field
          :field="reviewPlan.goal_subcategories"
          label="Goal Subcategories"
        />
        <div class="ss-break"></div>
        <review-campaign-display-field :field="reviewPlan.goal_metric" label="Goal Metric" />
        <div class="ss-break"></div>
        <review-campaign-display-field :field="reviewPlan.optimization" label="Optimization" />
        <div class="ss-break"></div>
        <review-campaign-display-field
          :field="reviewPlan.conversionWindow"
          label="Conversion Window"
        />
        <div class="ss-break"></div>
        <review-campaign-display-field :field="reviewPlan.methodology" label="Methodology" />
        <div class="ss-break"></div>
      </div>
    </div>
    <div class="ss-review-div"></div>
    <div class="mvc-half mvc-half-left">
      <div class="sec-title block" :class="`${isLucidViable ? '' : 'disable-field'}`">
        Brand Lift
      </div>
      <div class="mvc-input vtop" style="margin-bottom: 20px;">
        <div
          class="rsedit sl3"
          :class="`${isLucidViable ? '' : 'disable-field'}`"
          @click="jumpToPage(LUCID)"
        >
          <div class="edit-blk-section">
            <font-awesome-icon :icon="['fas', 'pen']" class="inline-fa" />
          </div>
          <review-campaign-display-field
            :field="reviewPlan.competitors"
            label="Competitors"
            :highlight-error-field="isLucidViable"
          />
          <div class="ss-break"></div>
          <review-campaign-display-field
            :field="reviewPlan.blCategory"
            label="Category"
            :highlight-error-field="isLucidViable"
          />
          <div class="ss-break"></div>
        </div>
      </div>
    </div>
    <div class="mvc-half mvc-half-right">
      <div class="sec-title block">
        Brand Safety &amp; Verification
      </div>
      <div class="mvc-input vtop" style="margin-bottom: 20px;">
        <div class="rsedit sl3" @click="jumpToPage(BRAND_SAFETY)">
          <div class="edit-blk-section">
            <font-awesome-icon :icon="['fas', 'pen']" class="inline-fa" />
          </div>
          <review-campaign-display-field
            :field="reviewPlan.brandSafetyLevelId"
            label="Brand Safety"
            :formatter="formatBrandSafetyLevel"
          />
          <div class="ss-break"></div>
          <review-campaign-display-field
            :field="reviewPlan.verificationPartners"
            label="Verification"
          />
          <div class="ss-break"></div>
        </div>
      </div>
    </div>
    <div v-for="(lineItem, index) in reviewLineItems" :key="index">
      <div :id="lineItem.id.originalValue" class="ss-review-div"></div>
      <div
        class="adgrouping"
        style="position: relative;"
        :data-label="
          lineItem.name.changedValue ? lineItem.name.changedValue : lineItem.name.originalValue
        "
      >
        <div class="adgrouping-label">
          <div class="content">
            {{
              lineItem.name.changedValue ? lineItem.name.changedValue : lineItem.name.originalValue
            }}
          </div>
        </div>
        <div>
          <div class="sec-title block">
            Ad Group Information
          </div>
          <div class="rsedit sl4" @click="jumpToPage(AD_GROUP_INFO, index)">
            <div class="edit-blk-section">
              <font-awesome-icon :icon="['fas', 'pen']" class="inline-fa" />
            </div>
            <div class="mvc-input mvc-half mvc-half-left vtop" style="margin-bottom: 10px;">
              <review-campaign-display-field :field="lineItem.name" label="Ad Group Name" />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="
                  lineItem.lineItemBudget.originalValue > 0
                    ? lineItem.lineItemBudget
                    : lineItem.budgetGroup
                "
                label="Budget"
                data-type="currency"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.cpm"
                label="Estimated CPM"
                data-type="currency"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.impressions"
                label="Estimated IMPs"
                data-type="visits"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.deliveryProbability"
                label="Delivery Projection"
              />
              <div class="ss-break"></div>
            </div>
            <div class="mvc-input mvc-half mvc-half-right vtop" style="margin-bottom: 10px;">
              <review-campaign-display-field
                :field="lineItem.flightStartDate"
                label="Start Date"
                data-type="date"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.flightEndDate"
                label="End Date"
                data-type="date"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field :field="lineItem.publishers" label="Media" />
              <div class="ss-break"></div>
            </div>
          </div>
          <div class="sec-title block">
            Targeting
          </div>
          <div class="rsedit sl6 sltarget" @click="jumpToPage(AD_GROUP_TARGETING, index)">
            <div class="edit-blk-section">
              <font-awesome-icon :icon="['fas', 'pen']" class="inline-fa" />
            </div>
            <div class="mvc-input mvc-half mvc-half-left vtop" style="margin-bottom: 20px;">
              <review-campaign-display-field :field="lineItem.audienceName" label="Audience Name" />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.targetings_demo_gender"
                label="Gender"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field :field="lineItem.targetings_demo_age" label="Age" />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.targetings_demo_income"
                label="Income"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.targetings_behavioral"
                label="Behavioral"
                :restrict-height="true"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.targetings_first_party"
                label="First Party Targeting"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.targetings_contextual"
                label="Categories"
              />
              <div class="ss-break"></div>
            </div>
            <div class="mvc-input mvc-half mvc-half-right vtop" style="margin-bottom: 20px;">
              <review-campaign-display-field :field="lineItem.deviceTypes" label="Devices" />
              <div class="ss-break"></div>
              <review-campaign-display-field
                :field="lineItem.targetings_geo"
                label="Geo Targeting"
                :restrict-height="true"
              />
              <div class="ss-break"></div>
              <review-campaign-display-field :field="lineItem.frequencyCap" label="Frequency Cap" />
              <div class="ss-break"></div>
              <review-campaign-display-field :field="lineItem.recencyCap" label="Recency Cap" />
              <div class="ss-break"></div>
              <review-campaign-display-field :field="lineItem.dayParting" label="Day Parting" />
              <div class="ss-break"></div>
            </div>
          </div>
          <div class="sec-title block" :class="[lineItem.creatives.errorMessage ? `req` : ``]">
            <span
              v-if="lineItem.creatives.errorMessage"
              class="ss-alert alert-r intip4 intip5 intip4bx"
              :data-tool-tip="lineItem.creatives.errorMessage"
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation-triangle']"
                class="inline-fa fa-w-18"
              />
            </span>
            Ads
          </div>
          <div
            class="rsedit sl7"
            style="padding-bottom: 10px;"
            @click="jumpToPage(AD_GROUP_ADS, index)"
          >
            <div class="edit-blk-section">
              <font-awesome-icon :icon="['fas', 'pen']" class="inline-fa" />
            </div>
            <div v-for="(creative, ci) in lineItem.creatives.originalValue" :key="ci">
              <div
                class="ss-form-rtitle"
                :class="[
                  lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                    ? `old`
                    : ``,
                ]"
              >
                Creative {{ ci + 1 }} Name:
              </div>
              <div
                class="ss-form-result"
                :class="[
                  lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                    ? `old`
                    : ``,
                ]"
              >
                {{ creative.name }}
              </div>
              <div class="ss-break"></div>
              <div
                class="ss-form-rtitle"
                :class="[
                  lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                    ? `old`
                    : ``,
                ]"
              >
                Creative {{ ci + 1 }} File:
              </div>
              <div class="ss-form-result">
                <span v-for="(file, fi) in creative.files" :key="fi">
                  <div
                    class="ss-form-result-file"
                    :class="[
                      lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                        ? `old`
                        : ``,
                    ]"
                  >
                    {{ fetchFileInfo(file) }}
                  </div>
                  <div class="ss-break"></div>
                </span>
              </div>
              <div class="ss-break"></div>
            </div>
            <div v-for="(ccreative, cci) in lineItem.creatives.changedValue" :key="cci">
              <div
                class="ss-form-rtitle"
                :class="[
                  lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                    ? `new`
                    : ``,
                ]"
              >
                Creative {{ cci + 1 }} Name:
              </div>
              <div
                class="ss-form-result"
                :class="[
                  lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                    ? `new`
                    : ``,
                ]"
              >
                {{ ccreative.name }}
              </div>
              <div class="ss-break"></div>
              <div
                class="ss-form-rtitle"
                :class="[
                  lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                    ? `new`
                    : ``,
                ]"
              >
                Creative {{ cci + 1 }} File:
              </div>
              <div class="ss-form-result">
                <span v-for="(file, fi) in ccreative.files" :key="fi">
                  <div
                    class="ss-form-result-file"
                    :class="[
                      lineItem.creatives.changedValue && lineItem.creatives.changedValue.length > 0
                        ? `new`
                        : ``,
                    ]"
                  >
                    {{ fetchFileInfo(file) }}
                  </div>
                  <div class="ss-break"></div>
                </span>
              </div>
              <div class="ss-break"></div>
            </div>
            <review-campaign-display-field
              :field="lineItem.tagsTrackers"
              label="Tags and Trackers"
            />
            <div class="ss-break"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ss-review-div" style="margin-top: 40px;"></div>
  </div>
</template>
<script>
import { get } from 'vuex-pathify';
import _ from 'underscore';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  TAR_CONTEXTUAL_TARGETING,
  LIS_CHANGE_NEW,
  LIS_CHANGE_PENDING_ACTIVATION,
  LIS_CHANGE_DRAFT,
  IO_STATE_CHANGE,
  LIT_CHANGE,
  IOS_DRAFT,
} from 'adready-api/constant';
import { isBlank } from 'adready-api/helpers/common';
import lucidMixin from '~/components/mixins/lucid-mixin';
import * as util from '~/util/utility-functions';
import {
  NEW_CAMPAIGN_PAGES,
  CAMPAIGN_NAV_BAR_OPTIONS,
  AD_GROUP_NAV_BAR_OPTIONS,
  TAR_CITY,
  TAR_COUNTRY,
  TAR_DMA,
  TAR_STATE,
  TAR_ZIP_CODES,
} from '~/constant';
import { removeKeys } from '~/helpers/global/misc-helpers';
import campaignMixin from '~/components/mixins/campaign-mixin';
import forklift from '~/components/mixins/forklift-mixin';
import lineItemHelpers from '~/helpers/line-item-helpers';
import ioHelpers from '~/helpers/io-helpers';
import deliveryProbabilityHelpers from '~/helpers/delivery-probability-helpers';
import { eBus } from '~/main';

const {
  CAMPAIGN_INFO,
  BRAND_SAFETY,
  LUCID,
  AD_GROUP_INFO,
  AD_GROUP_TARGETING,
  AD_GROUP_ADS,
  REVIEW_CAMPAIGN,
} = NEW_CAMPAIGN_PAGES;

export default {
  name: 'ReviewCampaign',
  components: {
    ReviewCampaignDisplayField: () =>
      import(
        /* webpackChunkName: "review-campaign-display-field" */ '~/components/campaigns/review-campaign-display-field.vue'
      ),
    // BLoadingSpinner: () =>
    //   import(
    //     /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
    //   ),
  },
  mixins: [forklift, campaignMixin, lucidMixin],
  props: {
    lineItemRef: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formatBrandSafetyLevel: this.fetchBrandSafetyLevel,
      CAMPAIGN_INFO,
      BRAND_SAFETY,
      LUCID,
      AD_GROUP_INFO,
      AD_GROUP_TARGETING,
      AD_GROUP_ADS,
      REVIEW_CAMPAIGN,
      errorMsg: null,
      // loader: false,
      validationErrorMessages: [],
      reviewLineItems: [],
      reviewPlan: {
        brandSafetyLevelId: {},
        campaignName: {},
        flightStartDate: {},
        flightEndDate: {},
        competitors: {},
        blCategory: {},
        conversionWindow: {},
        goal: {},
        methodology: {},
        optimization: {},
        verificationPartners: {},
        overallBudget: {},
        overallBudgetGroup: {},
        totalBudget: { originalValue: 0 },
        overallCPM: {},
        overallImpressions: {},
        overallDeliveryProbability: {},
      },
    };
  },

  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    plan: get('common/plan'),
    orgLineItems: get('common/orgLineItems'),
    plans: get('common/plans'),
    lineItems: get('common/plan@lineItems'),
    allDeviceTypes: get('common/allDeviceTypes'),
    allBrandSafetyLevels: get('common/allBrandSafetyLevels'),
    allVerificationPartners: get('common/allVerificationPartners'),
    mediaPublisherCPMData: get('common/mediaPublisherCPMData'),
    isLucidViable() {
      const impressions = ioHelpers.calculateImpressionCount(this.plan, this.mediaPublisherCPMData);
      return ioHelpers.isLucidViable(impressions);
    },
  },
  watch: {
    plan: {
      deep: true,
      handler(p) {
        this.$store.dispatch('common/setTransformPlanLoading', true);
        this.reviewPlan = this.transformPlan(p);
        this.$store.dispatch('common/setTransformPlanLoading', false);
      },
    },
    lineItems: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n?.length > 0) {
          const list = n.map((li) => {
            const output = {};
            const l = this.getLineItem(li);

            // fetch fields with literal values
            this.fetchLiteralFieldData(l, output);

            // fetch mediaData field data
            this.fetchMediaPublisherData(l, output);

            // fetch deviceTypes field data
            this.fetchComplexFieldData(l, output, 'deviceTypes', this.fetchDevices);

            // fetch frequency & recency field data
            this.fetchFrequencyRecencyData(l, output);

            // fetch targetings field data
            this.fetchTargetingsData(l, output);

            // fetch dayParting field data
            this.fetchComplexFieldData(l, output, 'dayParting', this.fetchDayParting);

            // fetch creatives field data
            this.fetchComplexFieldData(l, output, 'creatives', this.fetchCreativeList);

            // fetch tags & trackers field data
            this.fetchComplexFieldData(l, output, 'tagsTrackers', this.fetchTagsTrackers);

            // fetch delivery probability
            this.fetchDeliveryProbabilityData(l, output);

            // perform ad group validations
            const validationErrors = lineItemHelpers.validateEntireAdGroup(l);

            // remove changedValue if both originalValue & changedValue are same
            const orgPlan = this.plans.find((p) => p.id === this.plan.id);
            Object.keys(output).forEach((k) => {
              if (orgPlan && orgPlan.statusId === IOS_DRAFT) {
                if (!isBlank(output[k].changedValue)) {
                  output[k].originalValue = output[k].changedValue;
                }
                delete output[k].changedValue;
              } else {
                if (['flightStartDate', 'flightEndDate'].includes(k)) {
                  if (moment(output[k].originalValue).isSame(output[k].changedValue, 'day')) {
                    delete output[k].changedValue;
                  }
                } else if (output[k].originalValue === output[k].changedValue) {
                  delete output[k].changedValue;
                }
                if (k === 'creatives') {
                  if (isEqual(output[k].originalValue, output[k].changedValue)) {
                    delete output[k].changedValue;
                  }
                }
              }
              if (validationErrors[k]) {
                output[k].errorMessage = validationErrors[k];
              }
            });

            return output;
          });
          this.reviewLineItems = list;
          this.$emit('checkForPagesValidation');
          if (this.lineItemRef) {
            setTimeout(() => {
              util.scrollToElement(this.lineItemRef, '');
              util.scrollToElement(`left-nav-${this.lineItemRef}`, '');
            }, 0);
          }
        }
      },
    },
  },

  async mounted() {
    // this.loader = true;
    this.$emit('checkForPagesValidation');
    this.$store.dispatch('common/setTransformPlanLoading', true);
    await this.loadBrandSafetyLevels();
    await this.loadVerificationPartners();
    await this.loadDeviceTypes();
    await this.loadProductCategories();
    await this.loadMediaPublishers();
    this.reviewPlan = await this.transformPlan();
    // this.loader = false;
    if (this.lineItemRef) {
      setTimeout(() => {
        util.scrollToElement(this.lineItemRef, '');
        util.scrollToElement(`left-nav-${this.lineItemRef}`, '');
      }, 0);
    } else {
      setTimeout(() => {
        util.scrollToElement('REVIEW_CAMPAIGN');
      }, 0);
    }
    this.$store.dispatch('common/setTransformPlanLoading', false);
  },

  methods: {
    getLineItem(lineItem) {
      const inertLi = lineItem.inertClone();
      const { crLineItems } = lineItem;
      if (!isBlank(crLineItems)) {
        const crLineItem = crLineItems.find((l1) =>
          [LIS_CHANGE_NEW, LIS_CHANGE_PENDING_ACTIVATION].includes(l1.statusId)
        );
        if (crLineItem) {
          inertLi.parentId = lineItem.id;
          inertLi.statusId = crLineItem.statusId;
          inertLi.type = LIT_CHANGE;
        }
        if (this.plan.state === IO_STATE_CHANGE) {
          inertLi.statusId = LIS_CHANGE_DRAFT;
          inertLi.parentId = lineItem.id;
          inertLi.type = LIT_CHANGE;
        }
      }
      return inertLi;
    },

    onError(errorMsg) {
      this.errorMsg = errorMsg;
    },

    handleCampaignInfo() {
      this.$emit('pageSelected', CAMPAIGN_INFO, 1);
    },
    jumpToPage(page, index) {
      const campaignPageNames = CAMPAIGN_NAV_BAR_OPTIONS.map((o) => o.pageName);
      const adGroupPageNames = AD_GROUP_NAV_BAR_OPTIONS.map((o) => o.pageName);
      const isCampaignPage = campaignPageNames.includes(page);
      const isAdGroupPage = adGroupPageNames.includes(page);

      let pageIndex = index;
      let lineItemIndex = 0;

      if (isCampaignPage) {
        const option = CAMPAIGN_NAV_BAR_OPTIONS.find((o) => o.pageName === page);
        pageIndex = option.pageIndex;
      } else if (isAdGroupPage) {
        lineItemIndex = index;
        const optionIndex = AD_GROUP_NAV_BAR_OPTIONS.findIndex((o) => o.pageName === page);
        pageIndex =
          AD_GROUP_NAV_BAR_OPTIONS.length * lineItemIndex +
          CAMPAIGN_NAV_BAR_OPTIONS.length +
          1 +
          optionIndex;
      }

      this.$emit('pageSelected', page, pageIndex, lineItemIndex);
    },
    transformComplexData(inputData, output, outputField, fn) {
      output[outputField] = {
        originalValue: fn ? fn(inputData.originalValue) : inputData.originalValue,
      };
      if (inputData.changedValue) {
        output[outputField].changedValue = fn ? fn(inputData.changedValue) : inputData.changedValue;
      }
    },
    fetchPlanLiteralFieldData(inputPlan, output) {
      const fields = ['campaignName', 'brandSafetyLevelId', 'flightStartDate', 'flightEndDate'];
      fields.forEach((f) => {
        output[f] = ioHelpers.getPlanFieldBothValues(inputPlan, f, this.plans);
      });
    },
    fetchFlipCampaignInfoData(inputPlan, output) {
      const flipCampaignInfo = ioHelpers.getPlanFieldBothValues(
        inputPlan,
        'flipCampaignInfo',
        this.plans
      );

      this.transformComplexData(flipCampaignInfo, output, 'goal', this.fetchGoal);
      this.transformComplexData(
        flipCampaignInfo,
        output,
        'goal_subcategories',
        this.fetchGoalSubcategories
      );
      this.transformComplexData(flipCampaignInfo, output, 'goal_metric', this.fetchGoalMetric);
      this.transformComplexData(flipCampaignInfo, output, 'optimization', this.fetchOptimization);
      this.transformComplexData(
        flipCampaignInfo,
        output,
        'conversionWindow',
        this.fetchConversionWindow
      );
      this.transformComplexData(flipCampaignInfo, output, 'methodology', this.fetchMethodology);
    },
    fetchLucidCampaignInfoData(inputPlan, output) {
      const lucidCampaignInfo = ioHelpers.getPlanFieldBothValues(
        inputPlan,
        'lucidCampaignInfo',
        this.plans
      );
      this.transformComplexData(lucidCampaignInfo, output, 'competitors', this.fetchBLCompetitors);
      this.transformComplexData(lucidCampaignInfo, output, 'blCategory', this.fetchBLCategory);
    },
    fetchVerificationPartnersData(inputPlan, output) {
      const lucidCampaignInfo = ioHelpers.getPlanFieldBothValues(
        inputPlan,
        'verificationPartners',
        this.plans
      );
      this.transformComplexData(
        lucidCampaignInfo,
        output,
        'verificationPartners',
        this.fetchVerificationPartners
      );
    },
    fetchCampaignTotals(output) {
      const sums = {};
      const fieldsToSum = ['budgetGroup', 'lineItemBudget', 'deliveryScale', 'impressions'];
      fieldsToSum.forEach((f) => {
        if (!sums[f]) {
          sums[f] = {};
          sums[f].originalValue = 0;
          sums[f].changedValue = 0;
          sums[f].originalBudgetValue = 0;
          sums[f].originalBudgetGroupId = [];
        }
      });
      if (!sums.variableCPM) {
        sums.variableCPM = {};
        sums.variableCPM.originalValue = 0;
        sums.variableCPM.changedValue = 0;
      }
      this.reviewLineItems.forEach((l) => {
        // sum values for fields
        this.doSumForLineItemFields(sums, l, fieldsToSum);
      });

      this.transformComplexData(sums.lineItemBudget, output, 'overallBudget');
      this.transformComplexData(sums.budgetGroup, output, 'overallBudgetGroup');
      this.calculateTotalBudget(output);
      this.transformComplexData(
        sums.deliveryScale,
        output,
        'overallDeliveryProbabilityLevel',
        this.fetchOverallDeliveryProbabilityLevel
      );
      this.transformComplexData(
        sums.deliveryScale,
        output,
        'overallDeliveryProbability',
        this.fetchOverallDeliveryProbabilityText
      );
      this.calculateWeightedAverageCPM(sums, output);
      this.transformComplexData(sums.impressions, output, 'overallImpressions');
    },
    transformPlan() {
      const output = {};

      // fetch fields with literal values
      this.fetchPlanLiteralFieldData(this.plan, output);

      // fetch verification partners field data
      this.fetchVerificationPartnersData(this.plan, output);

      // fetch flip campaign field data
      this.fetchFlipCampaignInfoData(this.plan, output);

      // fetch lucid campaign field data
      this.fetchLucidCampaignInfoData(this.plan, output);

      // fetch campaign totals data
      this.fetchCampaignTotals(output);

      // perform plan validations
      const validationPlanErrors = ioHelpers.validateCampaign(this.plan);
      const validationLucidErrors = this.isLucidViable
        ? ioHelpers.validateLucidData(this.plan, this.activeBrandLiftInfo)
        : {};

      // remove changedValue if both originalValue & changedValue are same
      const orgPlan = this.plans.find((p) => p.id === this.plan.id);
      Object.keys(output).forEach((k) => {
        if (orgPlan && orgPlan.statusId === IOS_DRAFT) {
          if (!isBlank(output[k].changedValue)) {
            output[k].originalValue = output[k].changedValue;
          }
          delete output[k].changedValue;
        } else if (['flightStartDate', 'flightEndDate'].includes(k)) {
          if (moment(output[k].originalValue).isSame(output[k].changedValue, 'day')) {
            delete output[k].changedValue;
          }
        } else if (output[k].originalValue === output[k].changedValue) {
          delete output[k].changedValue;
        }

        if (validationPlanErrors[k]) {
          this.validationErrorMessages.push(validationPlanErrors[k]);
          output[k].errorMessage = validationPlanErrors[k];
        }
        if (validationLucidErrors[k]) {
          this.validationErrorMessages.push(validationLucidErrors[k]);
          output[k].errorMessage = validationLucidErrors[k];
        }
      });

      // collect all ad group msgs
      this.reviewLineItems.forEach((l) => {
        Object.keys(l).forEach((k) => {
          if (l[k].errorMessage) {
            this.validationErrorMessages.push(l[k].errorMessage);
          }
        });
      });

      eBus.$emit('validationChecksPassed', !this.validationErrorMessages.length);

      return output;
    },
    fetchGoal(flipCampaignInfo) {
      return flipCampaignInfo && flipCampaignInfo.goal ? flipCampaignInfo.goal.name : '';
    },
    fetchGoalSubcategories(flipCampaignInfo) {
      const subCategories = flipCampaignInfo?.goal?.subCategories || [];
      return !isBlank(subCategories) && _.isArray(subCategories) ? subCategories.join(', ') : '';
    },
    fetchGoalMetric(flipCampaignInfo) {
      if (!flipCampaignInfo || !flipCampaignInfo.goal) {
        return '';
      }
      const { label, value } = flipCampaignInfo.goal.metric;
      return util.formatMetricValue(label, value, true);
    },
    fetchOptimization(flipCampaignInfo) {
      const values = [];
      if (flipCampaignInfo.goal.name === 'Awareness') {
        return 'NA';
      }
      const keys = ['attribution', 'impressions', 'weightage'];
      if (flipCampaignInfo && flipCampaignInfo.optimization) {
        keys.forEach((k) => {
          const obj = flipCampaignInfo.optimization[k];
          if (obj && !isBlank(obj.value)) {
            values.push(util.capitalizeStringSentence(obj.value));
          }
        });
      }
      return values.join(', ');
    },
    fetchConversionWindow(flipCampaignInfo) {
      if (flipCampaignInfo.goal.name === 'Awareness') {
        return 'NA';
      }
      if (flipCampaignInfo && flipCampaignInfo.conversionWindow) {
        return `${flipCampaignInfo.conversionWindow.value} ${flipCampaignInfo.conversionWindow.unit.value}`;
      }
      return '';
    },
    fetchMethodology(flipCampaignInfo) {
      if (flipCampaignInfo.goal.name === 'Awareness') {
        return 'NA';
      }
      if (flipCampaignInfo && flipCampaignInfo.methodology) {
        return util.capitalizeStringSentence(flipCampaignInfo.methodology.value) || '';
      }
      return '';
    },
    fetchBLCompetitors(lucidCampaignInfo) {
      const { competitors } = !isBlank(lucidCampaignInfo) ? lucidCampaignInfo.brandLiftInfo : [];
      return !isBlank(competitors) && _.isArray(competitors) ? competitors.join(', ') : '';
    },
    fetchBLCategory(lucidCampaignInfo) {
      if (isBlank(lucidCampaignInfo) || isBlank(lucidCampaignInfo.brandLiftInfo)) {
        return '';
      }
      const { category } = lucidCampaignInfo.brandLiftInfo;
      const { type } = lucidCampaignInfo.brandLiftInfo;
      if (isBlank(category) || isBlank(type) || isBlank(type.value)) {
        return '';
      }

      return `${category} ${type.value}`;
    },
    fetchBrandSafetyLevel(brandSafetyLevelId) {
      const brandSafetyLevel = this.allBrandSafetyLevels.find((o) => o.id === brandSafetyLevelId);
      if (!brandSafetyLevel) {
        return '';
      }

      return `Level ${brandSafetyLevel.id} (${brandSafetyLevel.levelText})`;
    },
    fetchVerificationPartners(verificationPartners) {
      const vp = isBlank(verificationPartners)
        ? {}
        : JSON.parse(JSON.stringify(verificationPartners));
      if (!vp.value) {
        vp.value = [];
      }
      if (isBlank(vp)) {
        return '';
      }

      const verificationPartnerNames = this.allVerificationPartners
        .filter((o) => vp.value.includes(o.id))
        .map((o) => o.name);

      return verificationPartnerNames.join(', ');
    },
    calculateWeightedAverageCPM(sums, output) {
      if (!output.overallCPM) {
        output.overallCPM = {};
        output.overallCPM.originalValue = 0;
        output.overallCPM.changedValue = 0;
      }
      const originalBudget =
        sums.lineItemBudget.originalValue + sums.budgetGroup.originalBudgetValue;
      const changedBudget = sums.lineItemBudget.changedValue + sums.budgetGroup.originalBudgetValue;

      output.overallCPM.originalValue = originalBudget
        ? sums.variableCPM.originalValue / originalBudget
        : 0;
      output.overallCPM.changedValue = changedBudget
        ? sums.variableCPM.changedValue / changedBudget
        : 0;
    },
    calculateAveragePerLineItem(total) {
      const items = this.reviewLineItems;
      if (!total || !items || !items.length) {
        return 0;
      }
      return total / items.length;
    },
    fetchOverallDeliveryProbability(total) {
      const averageScale = this.calculateAveragePerLineItem(total);
      return this.fetchDeliveryProbability(averageScale);
    },
    fetchOverallDeliveryProbabilityText(total) {
      const probability = this.fetchOverallDeliveryProbability(total);
      return this.fetchDeliveryProbabilityText(probability, true);
    },
    fetchOverallDeliveryProbabilityLevel(total) {
      const probability = this.fetchOverallDeliveryProbability(total);
      return this.fetchDeliveryProbabilityLevel(probability, true);
    },
    doSumForLineItemFields(sums, lineItem, fieldsToSum) {
      fieldsToSum.forEach((f) => {
        if (!sums[f]) {
          sums[f] = {};
          sums[f].originalValue = 0;
          sums[f].changedValue = 0;
          sums[f].originalBudgetValue = 0;
          sums[f].originalBudgetGroupId = [];
        }
        if (f === 'budgetGroup' && lineItem[f]?.originalBudgetGroupId) {
          if (!sums[f].originalBudgetGroupId.includes(lineItem[f]?.originalBudgetGroupId[0])) {
            sums[f].originalBudgetGroupId.push(lineItem[f].originalBudgetGroupId[0]);
            sums[f].originalBudgetValue += +lineItem[f].originalBudgetValue || 0;
            sums[f].originalValue += +lineItem[f].originalBudgetValue || 0;
            sums[f].changedValue += lineItem[f].originalBudgetValue
              ? +lineItem[f].originalBudgetValue
              : +lineItem[f].originalBudgetValue || 0;
          }
        } else {
          sums[f].originalValue += +lineItem[f].originalValue || 0;
          sums[f].changedValue += lineItem[f].changedValue
            ? +lineItem[f].changedValue
            : +lineItem[f].originalValue || 0;
        }
      });
      this.doSumForLineItemVariableCPM(sums, lineItem);
    },
    doSumForLineItemVariableCPM(sums, lineItem) {
      if (!sums.variableCPM) {
        sums.variableCPM = {};
        sums.variableCPM.originalValue = 0;
        sums.variableCPM.changedValue = 0;
      }

      sums.variableCPM.originalValue += this.calculateVariableCPM(lineItem);
      sums.variableCPM.changedValue += this.calculateVariableCPM(lineItem, true);
    },
    calculateVariableCPM(lineItem, useChangedValue = false) {
      const budget =
        useChangedValue && lineItem.lineItemBudget.changedValue
          ? lineItem.lineItemBudget.changedValue
          : lineItem.lineItemBudget.originalValue || 0;
      const cpm =
        useChangedValue && lineItem.cpm.changedValue
          ? lineItem.cpm.changedValue
          : lineItem.cpm.originalValue || 0;
      return +budget * +cpm;
    },
    calculateTotalBudget(output) {
      let orgValue =
        output?.overallBudget?.originalValue + output?.overallBudgetGroup?.originalValue;
      let chgValue =
        output?.overallBudget?.changedValue + output?.overallBudgetGroup?.originalValue;
      if (Number.isNaN(Number(orgValue))) {
        orgValue = 0;
      }
      if (Number.isNaN(Number(chgValue))) {
        chgValue = 0;
      }
      output.totalBudget = { originalValue: orgValue, changedValue: chgValue };
    },
    fetchLiteralFieldData(inputLineItem, output) {
      const fields = [
        'name',
        'lineItemBudget',
        'budgetGroup',
        'flightStartDate',
        'flightEndDate',
        'audienceName',
        'id',
      ];
      fields.forEach((f) => {
        output[f] = lineItemHelpers.getLineItemFieldBothValues(inputLineItem, f, this.orgLineItems);
      });
    },
    fetchComplexFieldData(inputLineItem, output, field, fn) {
      const data = lineItemHelpers.getLineItemFieldBothValues(
        inputLineItem,
        field,
        this.orgLineItems
      );
      this.transformComplexData(data, output, field, fn);
    },
    fetchMediaPublisherData(inputLineItem, output) {
      const targetings = lineItemHelpers.getLineItemFieldBothValues(
        inputLineItem,
        'targetings',
        this.orgLineItems
      );
      const deviceTypes = lineItemHelpers.getLineItemFieldBothValues(
        inputLineItem,
        'deviceTypes',
        this.orgLineItems
      );
      const lineItemBudget = lineItemHelpers.getLineItemFieldBothValues(
        inputLineItem,
        'lineItemBudget',
        this.orgLineItems
      );

      const lineItemBudgetGroup = lineItemHelpers.getLineItemFieldBothValues(
        inputLineItem,
        'budgetGroup',
        this.orgLineItems
      );
      const publisherNames = {};
      publisherNames.originalValue = lineItemHelpers.fetchPublisherTargeting(
        targetings.originalValue
      );
      output.publishers = {
        originalValue: lineItemHelpers.fetchPublisherTargetingValue(targetings.originalValue),
      };
      if (targetings.changedValue) {
        publisherNames.changedValue = lineItemHelpers.fetchPublisherTargeting(
          targetings.changedValue
        );
        output.publishers.changedValue = lineItemHelpers.fetchPublisherTargetingValue(
          targetings.changedValue
        );
      }
      output.cpm = {
        originalValue: lineItemHelpers.calculateCPM(
          publisherNames.originalValue,
          this.mediaPublisherCPMData
        ),
      };
      output.cpm.originalValue += lineItemHelpers.calculateCPMAddOns(
        deviceTypes.originalValue,
        targetings.originalValue
      );
      output.impressions = {
        originalValue: lineItemHelpers.calculateImpressions(
          output.cpm.originalValue,
          lineItemBudget.originalValue > 0
            ? lineItemBudget.originalValue
            : lineItemBudgetGroup?.originalBudgetValue
        ),
      };
      if (publisherNames.changedValue || targetings.changedValue || deviceTypes.changedValue) {
        const localPublisherNames = publisherNames.changedValue
          ? publisherNames.changedValue
          : publisherNames.originalValue;
        const localDeviceTypes = deviceTypes.changedValue
          ? deviceTypes.changedValue
          : deviceTypes.originalValue;
        const localTargetings = targetings.changedValue
          ? targetings.changedValue
          : targetings.originalValue;
        output.cpm.changedValue = lineItemHelpers.calculateCPM(
          localPublisherNames,
          this.mediaPublisherCPMData
        );
        output.cpm.changedValue += lineItemHelpers.calculateCPMAddOns(
          localDeviceTypes,
          localTargetings
        );
      }
      if (!isBlank(lineItemBudgetGroup?.changedBudgetValue) && output.cpm.changedValue) {
        const cpm = output.cpm.changedValue ? output.cpm.changedValue : output.cpm.originalValue;
        const budget = lineItemBudgetGroup.changedBudgetValue
          ? lineItemBudgetGroup.changedBudgetValue
          : lineItemBudgetGroup.originalBudgetValue;

        output.impressions.changedValue = lineItemHelpers.calculateImpressions(cpm, budget);
      } else if (lineItemBudget.changedValue || output.cpm.changedValue) {
        const cpm = output.cpm.changedValue ? output.cpm.changedValue : output.cpm.originalValue;
        const budget = lineItemBudget.changedValue
          ? lineItemBudget.changedValue
          : lineItemBudget.originalValue;

        output.impressions.changedValue = lineItemHelpers.calculateImpressions(cpm, budget);
      }
    },
    fetchFrequencyRecencyData(inputLineItem, output) {
      const frequencyRecencyData = lineItemHelpers.getLineItemFieldBothValues(
        inputLineItem,
        'frequencyRecency',
        this.orgLineItems
      );

      this.transformComplexData(
        frequencyRecencyData,
        output,
        'frequencyCap',
        this.fetchFrequencyCap
      );
      this.transformComplexData(frequencyRecencyData, output, 'recencyCap', this.fetchRecencyCap);
    },
    fetchTargetingsData(inputLineItem, output) {
      const targetings = lineItemHelpers.getLineItemFieldBothValues(
        inputLineItem,
        'targetings',
        this.orgLineItems
      );

      // Demographich targetings
      const demographicKeys = ['gender', 'age', 'income'];
      demographicKeys.forEach((k) => {
        output[`targetings_demo_${k}`] = {
          originalValue: this.fetchDemographicTargeting(targetings.originalValue, k),
        };
        if (targetings.changedValue) {
          output[`targetings_demo_${k}`].changedValue = this.fetchDemographicTargeting(
            targetings.changedValue,
            k
          );
        }
      });

      // Other targetings
      const targetingTypes = {
        behavioral: this.fetchBehavioralTargeting,
        first_party: this.fetchFirstPartyTargeting,
        contextual: this.fetchContextualTargeting,
        geo: this.fetchGeoTargeting,
      };

      Object.keys(targetingTypes).forEach((tt) => {
        this.transformComplexData(targetings, output, `targetings_${tt}`, targetingTypes[tt]);
      });
    },
    fetchDeliveryProbabilityData(inputLineItem, output) {
      output.deliveryScale = {};
      output.deliveryScale.originalValue = this.fetchDeliveryScalability(inputLineItem, true);
      output.deliveryScale.changedValue = this.fetchDeliveryScalability(inputLineItem, false);

      const probabilityOriginal = this.fetchDeliveryProbability(output.deliveryScale.originalValue);
      const probabilityChanged = this.fetchDeliveryProbability(output.deliveryScale.changedValue);

      output.deliveryProbabilityLevel = {
        originalValue: this.fetchDeliveryProbabilityLevel(probabilityOriginal),
        changedValue: this.fetchDeliveryProbabilityLevel(probabilityChanged),
      };

      output.deliveryProbability = {
        originalValue: this.fetchDeliveryProbabilityText(probabilityOriginal),
        changedValue: this.fetchDeliveryProbabilityText(probabilityChanged),
      };
    },
    fetchDeliveryScalability(lineItem, ignoreChanges) {
      return deliveryProbabilityHelpers.calculateLineItemScalability(
        lineItem,
        this.mediaPublisherCPMData,
        this.allDeviceTypes,
        ignoreChanges
      );
    },
    fetchDeliveryProbability(scale) {
      return deliveryProbabilityHelpers.convertScalabilityToProbability(scale);
    },
    fetchDeliveryProbabilityText(probability, returnShort) {
      if (probability) {
        return returnShort
          ? `${probability.shortText}`
          : `${probability.level} - ${probability.text}`;
      }
      return '';
    },
    fetchDeliveryProbabilityLevel(probability) {
      if (probability) {
        return probability.level;
      }
      return '';
    },
    isDeliveryProbabilityLow(deliveryProbabilityLevel) {
      if (!deliveryProbabilityLevel) {
        return false;
      }
      const deliveryProbability = deliveryProbabilityLevel.changedValue
        ? deliveryProbabilityLevel.changedValue
        : deliveryProbabilityLevel.originalValue;
      return deliveryProbability === 'Low';
    },
    fetchDemographicTargeting(targetings, key) {
      return lineItemHelpers.fetchDemographicTargetingValue(targetings, key);
    },
    fetchBehavioralTargeting(targetings) {
      return lineItemHelpers.fetchBehavioralTargetingValue(targetings);
    },
    fetchFirstPartyTargeting(targetings) {
      return lineItemHelpers.fetchFirstPartyTargetingValue(targetings);
    },
    fetchContextualTargeting(targetings) {
      if (isBlank(targetings)) {
        return '';
      }

      // targetableElementId for Contextual targeting = 41
      const targeting = targetings.find((t) => t.targetableElementId === TAR_CONTEXTUAL_TARGETING);
      if (!targeting) {
        return '';
      }

      if (targeting.value && _.isArray(targeting.value)) {
        return targeting.value.join(', ');
      }

      return targeting.value;
    },
    fetchDevices(deviceTypes) {
      if (isBlank(deviceTypes)) {
        return '';
      }

      const deviceTypeIds = deviceTypes.map((d) => d.deviceTypeId);
      const deviceTypeNames = this.allDeviceTypes
        .filter((d) => deviceTypeIds && deviceTypeIds.includes(d.id))
        .map((d) => d.deviceType);

      return deviceTypeNames.join(', ');
    },
    fetchGeoTargeting(targetings) {
      if (isBlank(targetings)) {
        return '';
      }

      const geoTargetingValues = [];
      const geoElements = {};
      geoElements[TAR_COUNTRY] = 'Country';
      geoElements[TAR_DMA] = 'DMA';
      geoElements[TAR_STATE] = 'State';
      geoElements[TAR_ZIP_CODES] = 'Zip Codes';
      geoElements[TAR_CITY] = 'City';

      Object.keys(geoElements).forEach((key) => {
        const geoTargeting = targetings.find((t) => `${t.targetableElementId}` === key);
        if (!geoTargeting || !geoTargeting.value) {
          return;
        }
        let valueArray = [];
        if (key === `${TAR_ZIP_CODES}`) {
          if (geoTargeting.value.zipCodes && _.isArray(geoTargeting.value.zipCodes)) {
            valueArray = [...geoTargeting.value.zipCodes];
          }
        } else if (key === `${TAR_CITY}`) {
          valueArray.push(geoTargeting.value);
        } else if (_.isArray(geoTargeting.value)) {
          valueArray = [...geoTargeting.value];
        }

        if (isBlank(valueArray)) {
          return;
        }
        geoTargetingValues.push(`${geoElements[key]}: ${valueArray.join(',')}`);
      });
      return geoTargetingValues.join('<br />');
    },
    fetchFrequencyCap(frequencyRecency) {
      if (!(frequencyRecency && frequencyRecency.enabled)) {
        return '';
      }
      if (!(frequencyRecency.impression && frequencyRecency.timePeriod)) {
        return '';
      }

      return `${frequencyRecency.impression} / ${frequencyRecency.timePeriod}`;
    },
    fetchRecencyCap(frequencyRecency) {
      if (!(frequencyRecency && frequencyRecency.enabled)) {
        return '';
      }
      if (!(frequencyRecency.recencyCapValue && frequencyRecency.recencyCapTimePeriod)) {
        return '';
      }

      return `${frequencyRecency.recencyCapValue} ${frequencyRecency.recencyCapTimePeriod}`;
    },
    fetchDayParting(dayParting) {
      if (!(dayParting && dayParting.enabled)) {
        return '';
      }

      const dayPartingValues = [];
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      days.forEach((d) => {
        const timeSlots = dayParting[d];
        if (!timeSlots) {
          return;
        }

        const formattedSlots = this.formatTimeSlots(timeSlots);
        dayPartingValues.push(`${d}: ${formattedSlots}`);
      });

      return dayPartingValues.join('<br />');
    },
    formatTimeSlots(numArray) {
      let hours = [...numArray];
      hours = hours.sort((a, b) => {
        return a - b;
      });
      const max = Math.max(...hours);
      const min = Math.min(...hours);
      let slot = {};
      const slots = [];

      for (let hour = min; hour <= max; hour++) {
        const found = hours.includes(hour);
        if (found) {
          if (slot.start >= 0) {
            slot.end = hour;
          } else {
            slot = { start: hour };
          }
        } else {
          if (slot.start >= 0) {
            slots.push(slot);
          }
          slot = {};
        }
      }
      if (slot.start >= 0) {
        slots.push(slot);
      }

      return slots
        .map((s) => {
          const start = `${s.start}`;
          const end = `${s.end}`;
          return s.end
            ? `${util.formatHour(start)} - ${util.formatHour(end)}`
            : util.formatHour(start);
        })
        .join(', ');
    },
    fetchCreativeList(creatives) {
      if (isBlank(creatives)) {
        return [];
      }
      const clone = JSON.parse(JSON.stringify(creatives));
      removeKeys(clone, ['_uuid']);
      return clone;
    },
    fetchFileInfo(file) {
      if (!file || isBlank(file.type)) {
        return '';
      }

      if (file.type.toLowerCase() === 'link') {
        if (isBlank(file.path)) {
          return '';
        }
        return `${file.path}`;
      }
      if (file.type.toLowerCase() === 'creative') {
        if (isBlank(file.name) || isBlank(file.size)) {
          return '';
        }
        return `${file.name} (${util.formatFileSize(file.size)})`;
      }
      return file.type;
    },
    fetchTagsTrackers(tagsTrackers) {
      return tagsTrackers ? 'Yes' : 'No';
    },
  },
};
</script>

<style lang="scss">
.ss-right-content {
  padding: 48px 45px;
  overflow-y: scroll;
  max-height: calc(100vh - 130px);
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5) !important;
  }
}
.ss-result-xl {
  overflow: scroll;
  max-height: 110px;
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5) !important;
  }
}
</style>

<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';

.mvc-form-wrap3 {
  margin: 0;
  margin-right: 105px;
  font-family: 'Manrope', sans-serif;
  line-height: 1.2em;
  line-height: 1.7;
  font-weight: 500;
  color: #48525c;
  min-height: 900px;
  background: none;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.ss-right-content {
  padding: 48px 45px;
  overflow-y: scroll;
  max-height: calc(100vh - 130px);
  &::-webkit-scrollbar {
    width: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8) !important;
  }
}
.body-sec-title {
  color: var(--selfserveprimarycolor);
  font-weight: 500;
  font-size: 1.6em;
}
.sec-title {
  display: block;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-right: 30px;
  color: #444;
  font-size: 20px;
  letter-spacing: 0.02em;
  .intip5[data-tool-tip] {
    &::after {
      margin-left: -35px;
    }
  }
  &.req {
    .svg-inline--fa {
      vertical-align: 0.1em;
    }
  }
}
.mvc-half {
  width: 48%;
  display: inline-block;
}
.mvc-half-left {
  margin-right: 3%;
}
.mvc-half-right {
  float: right;
}
.vtop {
  vertical-align: top;
}
.rsedit {
  position: relative;
  padding: 10px 10px 10px 20px;
  border: 2px solid transparent;
  left: -10px;
  top: -16px;
  margin-left: -10px;
  &.sltarget {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  svg {
    display: none;
  }
  &:hover {
    svg {
      display: block;
      position: relative;
      right: 10px;
    }
  }
}
.rsedit:hover::before {
  content: '\f304';
  font-weight: 900;
  font-size: 0;
  position: absolute;
  color: var(--selfserveprimarycolor);
  /*z-index: -1;*/
  text-align: right;
}
.rsedit:hover {
  border: 2px dashed var(--selfserveprimarycolor);
  border-radius: 9px;
}
.ss-form-rtitle {
  display: inline-block;
  margin-right: 10px;
  width: 150px;
  font-size: 15px;
  vertical-align: top;
  position: relative;
}
.sec-title.req,
.ss-form-rtitle.req {
  color: #de284a;
}
.ss-form-result {
  display: inline-block;
  color: #777;
  font-size: 15px;
  max-width: calc(100% - 186px);
  vertical-align: top;
}
.ss-result-xl {
  overflow: scroll;
  max-height: 110px;
}
.mvc-half .ss-form-result {
  max-width: calc(100% - 186px);
}
.ss-form-result-file {
  display: inline-block;
  color: #777;
  font-size: 15px;
  vertical-align: top;
}
.ss-break {
  display: block;
  height: 13px;
}
.ss-review-div {
  border-top: 1px dashed #ddd;
  margin: 20px 0px;
  clear: both;
}
.new {
  background-color: #ffe578;
  padding: 0px 3px;
  margin-left: 4px;
}
.old {
  text-decoration: line-through;
  opacity: 0.7;
}
.alert-y {
  color: #eda705;
}
.alert-r {
  color: #de284a;
}
.error {
  color: #ff0202;
}
.ss-alert {
  position: absolute;
  margin: 1px 0px 0px -18px;
  font-size: 13px;
}
.intip4 {
  position: relative;
  top: 0px;
}
.intip4[data-tool-tip]::after {
  content: attr(data-tool-tip);
  display: block;
  position: absolute;
  width: 220px;
  font-size: 12px;
  line-height: 1.2em;
  background-color: #fdea85;
  color: #5b5b5b;
  padding: 10px 12px;
  border-radius: 3px;
  left: 0;
  margin-left: -113px;
  bottom: 100%;
  transform: scale(0);
  transition: ease 0.3s;
  transform-origin: bottom center;
  z-index: 99;
  pointer-events: none;
}

.intip4[data-tool-tip]::after {
  background-color: #464646;
  color: #fff;
  width: 160px;
  margin-left: -80px;
  font-size: 13px;
  pointer-events: none;
}
.intip5[data-tool-tip]::after {
  text-transform: none;
  width: 245px;
  margin-left: -120px;
  text-align: left;
}
.intip6[data-tool-tip]::after {
  text-transform: none;
  width: 345px;
  margin-left: -35px;
  text-align: left;
}
.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 250%;
}
.adgrouping,
.adgrouping-two-digit {
  padding-left: 30px;
  border-left: 2px solid var(--selfserveprimarycolor);
  margin-left: 5px;
}

.adgrouping-two-digit::before {
  display: inline-block;
  content: attr(data-label);
  position: absolute;
  transform: rotate(270deg);
  background-color: var(--selfserveprimarycolor);
  color: #fff;
  padding: 1px 8px;
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  border-radius: 5px 5px 0px 0px;
  min-width: 80px;
  text-align: center;
}
.adgrouping-label {
  display: inline-block;
  position: absolute;
  transform: rotate(-90deg) scaleX(-1);
  transform-origin: top left;
  background-color: var(--selfserveprimarycolor);
  color: #fff;
  padding: 1px 8px;
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  border-radius: 5px 5px 0px 0px;
  min-width: 80px;
  text-align: center;
  left: -24px;
  .content {
    transform: scaleX(-1);
  }
}

.adgrouping-two-digit::before {
  margin: 31px 0px 0px -84px;
}
.edit-blk-section {
  position: absolute;
  right: 0;
  font-size: 15px;
  color: var(--selfserveprimarycolor);
  cursor: pointer;
}
.disable-field {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
.p-l10 {
  padding-left: 12px;
}
.ml-9 {
  margin-left: 9px;
}
.ml-10 {
  margin-left: 10px;
}

::v-deep .rsedit {
  .icn-txt-blk {
    // padding-top: 12px;
    // padding-left: 32px;
    .intip4 {
      display: inline-flex;

      svg {
        display: block;
        position: relative;
        right: 1px;
      }
    }
    .intip5[data-tool-tip] {
      &::after {
        margin-left: -35px;
      }
    }
  }
}
</style>
