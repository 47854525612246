<template>
  <div
    v-if="!campaignsLoading"
    id="LUCID_BRAND_LIFT_CAMPAIGN"
    :class="`mvc-form-wrap3 s3-wrap ${isReadonly ? 'readonly-bp' : ''}`"
    style="padding: 40px 50px; text-align: left;"
  >
    <div style="height: 0px;"></div>
    <div class="body-sec-title" style="margin-bottom: 30px;">
      Brand Lift Information
      <span class="">(for brand lift study)</span>
      <b-error-msg
        v-if="validationMap.impressionCount"
        :error-msg="validationMap.impressionCount"
      />
      <b-error-msg
        v-if="validationMap.flipCampaignInfo"
        :error-msg="validationMap.flipCampaignInfo"
      />
      <b-error-msg
        v-if="validationMap.conversionWindow"
        :error-msg="validationMap.conversionWindow"
      />
    </div>

    <div class="ss-form-label2">Please list 4 competitors</div>
    <b-add-tag
      :class="
        `lucid-tags ${isLucidEditable ? '' : 'disable-field'} ${
          validationMap.competitors ? 'error-msg-field' : ''
        }`
      "
      :selected-competitors="activeBrandLiftInfo.competitors"
      :readonly="isReadonly"
      @addTags="onChangeCompetitors"
    />
    <b-error-msg v-if="validationMap.competitors" :error-msg="validationMap.competitors" />

    <div style="margin-bottom: 30px;"></div>
    <div class="sec-grp" :class="[isLucidEditable ? '' : 'disable-field']">
      <div
        :class="
          `mvc-input mvc-half mvc-half-left ${validationMap.blCategory ? 'error-msg-field' : ''}`
        "
        style="margin-bottom: 20px;position: relative;"
      >
        <div class="ss-form-label2">Category</div>
        <k-text-input
          class="category-input"
          placeholder="i.e health and wellness, apparel etc"
          :required="true"
          :disabled="isLucidEditable"
          :value="activeBrandLiftInfo.category"
          :readonly="isReadonly"
          @change="onChangeCategory"
        />
        <b-error-msg v-if="validationMap.blCategory" :error-msg="validationMap.blCategory" />
      </div>
      <div class="mvc-input mvc-half mvc-half-right" style="margin-bottom: 20px;">
        <div class="ss-form-label2" style="margin-bottom: 3px;">Select One</div>
        <div :class="`bl-options ${validationMap.typeId ? 'error-msg-field' : ''}`">
          <a
            v-for="(t, index) in types"
            :key="t.id"
            class="bl-btn"
            :class="[
              `${
                activeBrandLiftInfo.type && t.id === activeBrandLiftInfo.type.id
                  ? 'bl-btn-selected'
                  : ''
              }`,
              `${index === 0 ? 'lend' : ''} ${index === types.length - 1 ? 'rend' : ''}`,
            ]"
            @click="onSelectType(t)"
          >
            <font-awesome-icon class="bl-icon" :icon="['fas', 'check']" size="1x" />
            <div class="bl-text">{{ t.value }}</div>
          </a>
        </div>
        <b-error-msg v-if="validationMap.typeId" :error-msg="validationMap.typeId" />
      </div>
    </div>
    <!--
    Do not use a skipLucid flag or checkbox. Use the ioHelpers.isLucidFormDataProvided
    function instead.
    <div class="form-group form-check">
      <label class="form-check-label" for="skipLucid">Skip</label>
      <k-checkbox :checked="skipLucid" name="skipLucid" @change="setSkipLucid" />
    </div>
    -->
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import BAddTag from './b-add-tag.vue';
import { LUCID_CAMPAIGN_INFO_DEFAULT } from '~/constant';
import ioHelpers from '~/helpers/io-helpers';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';
import BErrorMsg from '../../elements/b-error-msg.vue';
import * as util from '~/util/utility-functions';

export default {
  name: 'PlanLucidCampaignBrandLift',
  components: {
    BAddTag,
    BErrorMsg,
  },
  mixins: [campaignValidationMixin],
  props: {
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeType: null,
      campaignUrl:
        'https://tracker.samplicio.us/tracker/CAMPAIGN_ID/pixel.gif?sid=Xandr&pid=${CPG_ID}&crid=${CREATIVE_ID}&device_id=${DEVICE_APPLE_IDA}&cachebuster=${CACHEBUSTER}',
      types: [
        {
          id: 1,
          value: 'products',
        },
        {
          id: 2,
          value: 'services',
        },
      ],
      copyText: 'Copy to Clipboard',
      campaignsLoading: false,
      questions: [],
      baseline_questions: [],
      type: {},
      loadingQuestions: false,
      activeTab: 'SURVEY',
      errorMsg: null,
      errorBrandCompetitorMsg: null,
      saveAndLaunch: false,
      deleteCampaign: false,
      campaign: null,
      validationMap: this?.pageIndexValidationObj[this?.selectedPageIndex] || {},
      menuItems: [
        {
          name: 'Survey',
          to: '',
        },
      ],
    };
  },
  asyncComputed: {
    isLucidCampaignLaunched: {
      default: false,
      async get() {
        const isLucidCampaignLaunched = await ioHelpers.isLucidCampaignLaunched(
          this.plan,
          this.account
        );
        return isLucidCampaignLaunched;
      },
    },
  },

  watch: {
    plan: {
      handler() {
        if (this.pageIndexVisited[3] > 1) {
          this.pageIndexValidationObj[this.selectedPageIndex] = this.validateBlCampaign(this.plan);
          this.validationMap = this.pageIndexValidationObj[this.selectedPageIndex] || {};
        }
      },
    },
  },
  mounted() {
    util.scrollToElement('LUCID_BRAND_LIFT_CAMPAIGN');
    setTimeout(() => {
      this.pageIndexVisited[3] = this.pageIndexVisited[3] ? this.pageIndexVisited[3] + 1 : 1;
    }, 0);
  },

  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    lucidCampaignInfo: sync('common/plan@lucidCampaignInfo'),
    activeBrandLiftInfo() {
      if (!isBlank(this.lucidCampaignInfo) && !isBlank(this.lucidCampaignInfo.brandLiftInfo)) {
        return this.lucidCampaignInfo.brandLiftInfo;
      }
      return LUCID_CAMPAIGN_INFO_DEFAULT;
    },
    plan: sync('common/plan'),
    mediaPublisherCPMData: get('common/mediaPublisherCPMData'),
    impression() {
      return ioHelpers.calculateImpressionCount(this.plan, this.mediaPublisherCPMData);
    },
    isLucidEditable() {
      return ioHelpers.isLucidViable(this.impression) && !this.isLucidCampaignLaunched;
    },
  },

  methods: {
    onChangeCompetitors(val) {
      const competitors = val.map((v) => {
        return v.value ? v.value : v;
      });
      this.lucidCampaignInfo = {
        ...this.lucidCampaignInfo,
        brandLiftInfo: {
          ...this.lucidCampaignInfo.brandLiftInfo,
          competitors,
        },
      };
    },
    onChangeCategory(val) {
      this.lucidCampaignInfo = {
        ...this.lucidCampaignInfo,
        brandLiftInfo: {
          ...this.lucidCampaignInfo?.brandLiftInfo,
          category: val,
        },
      };
    },
    onSelectType(val) {
      this.lucidCampaignInfo = {
        ...this.lucidCampaignInfo,
        brandLiftInfo: {
          ...this.lucidCampaignInfo?.brandLiftInfo,
          type: val,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mvc-form-wrap3 {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  color: #48525c;
  width: 860px;
}
.bl-options {
  display: flex;
  .bl-icon {
    display: none;
  }
  .bl-text {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 5px;
  }
  .bl-btn {
    color: #7f7f7f !important;
    border: 1px solid #b3b3b3 !important;
    padding: 9px 12px !important;
    margin: 10px 0px;
    text-align: center;
    letter-spacing: 0.02em;
    height: 40px;
    display: flex;
    align-items: center;
    border-right-width: 0.5px !important;
    border-left-width: 0.5px !important;
    &.lend {
      border-left-width: 1px !important;
      border-radius: 3px 0px 0px 3px;
    }
    &.rend {
      border-right-width: 1px !important;
      border-radius: 0px 3px 3px 0px;
    }
    &:hover {
      background-color: #eee !important;
      color: #222 !important;
    }
    &.bl-btn-selected:hover {
      background-color: var(--selfserveprimarycolor) !important;
      color: #fff !important;
    }
  }
  .bl-btn-selected {
    background-color: var(--selfserveprimarycolor) !important;
    color: #fff !important;
    border: 1px solid var(--selfserveprimarycolor) !important;
    .bl-icon {
      display: block;
      color: #fff !important;
      transform: scale(0.8);
      margin-right: 5px;
    }
    &:hover {
      background-color: #fff;
    }
  }
}
.error-text {
  color: #eb2026;
  margin-bottom: 10px;
}
::v-deep .actions-btn {
  display: inline-flex;
  margin: 20px 0;
  & span {
    padding-right: 10px;
  }
}
::v-deep .k-btn.btn-size-3 {
  margin-right: 4px;
  margin-bottom: 10px;
}
.disable-field {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
#hide-success {
  -moz-animation: cssAnimation 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 5s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
.success-txt {
  text-align: center;
  font-size: 15px;
  color: #666666;
  text-align: center;
}

.load-row {
  min-height: unset !important;
  padding: 20px 0 !important;
  .spinner {
    // margin-top: 20% !important;
    margin-left: 50% !important;
  }
}
::v-deep .lucid-campaign-info .modal-container {
  width: 665px !important;
}
.body-sec-title {
  color: var(--selfserveprimarycolor);
  font-weight: 500;
  font-size: 1.6em;
}
.body-sec-title span {
  color: #a4abc3;
  font-size: 14px;
  margin-left: 10px;
}
.category-input {
  position: relative;
  margin-top: 11px;
}
.mvc-half-left {
  margin-right: 3%;
}

.mvc-half-right {
  float: right;
}

.mvc-half {
  width: 48%;
  display: inline-block;
}
::v-deep .error-msg-field .tagsinput {
  border: 2px solid #ff0202;
}
::v-deep .error-msg-field .form-input .form-input-field {
  border: 2px solid #ff0202;
}
::v-deep .error-msg-field .bl-btn {
  border: 2px solid #ff0202 !important;
}
.readonly-bp {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
