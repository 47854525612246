<template>
  <div class="sec-first-party-targeting">
    <div class="ss-half">
      <div class="sec-title">First Party Targeting</div>
      <div v-if="errorFileUpload" class="error">{{ errorFileUpload }}</div>
      <div class="upload-container" :class="`${disabled || readonly ? 'disable-field' : ''}`">
        <div class="upload-box">
          <b-file-upload
            ref="fileUpload"
            name="FirstPartyDataUpload"
            label="Browse"
            :required="true"
            class="browse-file"
            prompt="no file selected"
            :value="uploadValue"
            :line-item-id="lineItem.id"
            :plan-id="lineItem.planId"
            @upload="onFileUpload"
            @onDeleteFile="onDeleteFile"
          />
        </div>
      </div>
    </div>
    <div class="ss-half last" style="margin-top: 52px;">
      <div class="lf-txt">
        <div :class="`ott-retargeting ${readonly ? 'disable-field' : ''}`">
          Include OTT Retargeting
          <span data-tool-tip="Retarget site visitors with OTT ads" class="intip4 info-icon">
            <font-awesome-icon :icon="['far', 'info-circle']" />
          </span>
        </div>
        <div
          :class="isFirstPartyFileUploaded() ? '' : 'disable-field'"
          class="lookalike-targeting"
          style="width: 250px;"
        >
          Include Lookalike Targeting
          <span data-tool-tip="Create Look-a-Likes for Targeting" class="intip4 info-icon">
            <font-awesome-icon :icon="['far', 'info-circle']" />
          </span>
        </div>
      </div>
      <div class="rt-txt">
        <div :class="`${readonly ? 'disable-field' : ''}`">
          <b-campaign-toggle
            class-prefix="ott-retargeting"
            :checked="siteRetargetingEnabled"
            :change="onChangeSiteRetargeting"
          />
        </div>
        <div :class="isFirstPartyFileUploaded() ? '' : 'disable-field'">
          <b-campaign-toggle
            class-prefix="lookalike-targeting"
            :checked="lookALikeRetargetingEnabled"
            :change="onChangeLookALikeRetargeting"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import { isBlank, inert } from 'adready-api/helpers/common';
import { TAR_AUDIENCE_FIRST_PARTY_TARGETING } from 'adready-api/constant';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import { targetingSelectMixin } from '~/components/mixins/targeting-mixin';
import BFileUpload from '~/components/elements/b-file-upload.vue';
import BCampaignToggle from '~/components/elements/b-campaign-toggle.vue';

export default {
  name: 'FirstPartyDataForm',

  components: { BFileUpload, BCampaignToggle },

  mixins: [lineItemMixin, targetingSelectMixin, validationsMixin.events],

  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      targetingType: 'audienceTargeting',
      selectedTargeting: null,
      targetableElementId: TAR_AUDIENCE_FIRST_PARTY_TARGETING,
      selectedUploaded: null,
      selectedLookALikeRetargeting: false,
      errorMessage: null,
    };
  },

  computed: {
    uploadValue() {
      if (this?.selectedTargeting?.value?.uploaded) {
        return this.selectedTargeting.value.uploaded;
      }
      return null;
    },
    lookALikeRetargetingEnabled() {
      return this.selectedLookALikeRetargeting;
    },
    siteRetargetingEnabled() {
      if (this?.lineItem?.siteRetargeting) {
        return true;
      }
      return false;
    },
    errorFileUpload() {
      if (!isBlank(this.errorMessage)) {
        return this.errorMessage;
      }
      return null;
    },
  },

  beforeMount() {
    const selectedTargeting = this.targetings.find(
      (tp) => tp.targetableElementId === this.targetableElementId
    );
    if (selectedTargeting) {
      this.selectedTargeting = inert(selectedTargeting);
    } else {
      this.selectedTargeting = this.createSelectedTargeting(this.targetableElementId);
      if (this.selectedTargeting.value) {
        this.selectedTargeting.value.lookALikeRetargeting = false;
      }
    }
    this.selectedUploaded = this?.selectedTargeting?.value?.uploaded;
    this.selectedLookALikeRetargeting = this?.selectedTargeting?.value?.lookALikeRetargeting;
  },

  methods: {
    clearMsg() {
      this.errorMessage = null;
    },
    isFirstPartyFileUploaded() {
      if (this.disabled) {
        return false;
      }
      return this?.selectedUploaded?.size > 0;
    },
    onFileUpload(fileData) {
      this.errorMessage = null;
      if (!this.isValidForUpload(fileData)) {
        this.$refs.fileUpload.reset();
        this.errorMessage = 'The File type is not csv/xls';
        return;
      }
      this.selectedUploaded = fileData;
      this.selectedLookALikeRetargeting = true;
      this.updateLineItemTargetingField('audienceTargeting', 'uploaded', fileData);
      this.updateLineItemTargetingField(
        'audienceTargeting',
        'lookALikeRetargeting',
        this.selectedLookALikeRetargeting
      );
      this.updateLineItemTargetingToStore();
      this.$emit('onChangeFirstPartyTargeting', fileData);
    },

    onDeleteFile() {
      this.errorMessage = null;
      this.selectedUploaded = null;
      this.selectedLookALikeRetargeting = false;
      this.updateLineItemTargetingField('audienceTargeting', 'uploaded', null);
      this.updateLineItemTargetingField(
        'audienceTargeting',
        'lookALikeRetargeting',
        this.selectedLookALikeRetargeting
      );
      this.updateLineItemTargetingToStore();
      this.$emit('onChangeFirstPartyTargeting', null);
    },
    onChangeLookALikeRetargeting(val) {
      this.selectedLookALikeRetargeting = val;
      this.updateLineItemTargetingField('audienceTargeting', 'uploaded', this.selectedUploaded);
      this.updateLineItemTargetingField('audienceTargeting', 'lookALikeRetargeting', val);
      this.updateLineItemTargetingToStore();
    },
    onChangeSiteRetargeting(val) {
      const updates = {
        siteRetargeting: val,
      };
      this.updateLineItem(updates);
    },
    isValidForUpload(fileData) {
      return !isBlank(fileData.name) && this.isFileType(fileData.name);
    },
    isFileType(fileName) {
      let fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
      fileType = fileType.toLowerCase();
      return fileType === 'csv' || fileType === 'xls';
    },
  },
};
</script>
<style lang="scss" scoped>
.sec-title {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #444;
  letter-spacing: 0.02em;
}
.info-icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  margin-left: 5px;
}
.ss-half {
  display: inline-block;
  width: 48%;
  vertical-align: top;
  /* min-width: 500px; */
}
.ss-half.last {
  margin-right: 0%;
  vertical-align: top;
  right: 0;
}
.lf-txt {
  display: inline-block;
  /* margin-right: 30px; */
  vertical-align: top;
}
.rt-txt {
  display: inline-block;
  vertical-align: top;
}
.upload-container {
  display: inline-block;
  width: 350px;
  border: 1px solid #ddd;
  padding: unset;
  position: relative;
  .upload-box {
    position: relative;
    .browse-file {
      position: relative;
    }
    .upload-btn-box {
      position: absolute;
      width: 61px;
      height: 49px;
      top: -1px;
      text-align: center;
      .upload-icn-box {
        opacity: 0;
        position: absolute;
        inset: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
::v-deep .upload-wrap {
  background-color: #fff;
  padding: unset;
  position: relative;
}
::v-deep .uploaded-container .uploaded-wrap {
  padding: unset;
  margin: unset;
  text-align: unset;
  border-bottom: unset;
  width: 300px;
  position: relative;
  left: 20px;
}
::v-deep .uploaded-container .uploaded-wrap .row.between-xs.top-xs a {
  position: relative;
  bottom: 5px;
  left: 2px;
}
::v-deep .upload-section {
  & .upload-required {
    display: inline-block;
    padding: 2px 6px !important;
    margin: 11px 0px 0px 10px;
    border: 1px solid #b9b9b9;
    background: #fff;
    min-width: 66px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: 'Museo Sans 500', Arial, Helvetica, sans-serif;
    font-size: 12px;
    &::after {
      display: none;
    }
  }
}
::v-deep .upload-wrap .upload-section .progess-bar-wrap {
  display: block !important;
}
::v-deep .upload-wrap .progess-bar-wrap .progess-bar {
  width: 100%;
}
::v-deep .upload-wrap .upload-section .upload-required:hover {
  background-color: #565656;
}
::v-deep .drag-box {
  position: absolute;
  left: 90px;
  top: 12px;
}
::v-deep .upload-section .drag-box .drag-text {
  display: inline-block;
  font-size: 13px;
  color: #a1a6ad;
}
::v-deep .draghere-wrap {
  display: none;
}
::v-deep .progess-bar-wrap {
  display: block;
}
::v-deep .upload-section .drag-text {
  display: none;
}
::v-deep .uploaded-container .uploaded-info {
  font-size: 11px;
  padding-bottom: 5px;
}
::v-deep .uploaded-container .uploaded-img {
  width: 30px;
  margin: unset;
}
::v-deep .uploaded-icon {
  position: relative;
  top: 5px;
}
.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  padding: 10px 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: left;
  text-transform: none;
  content: attr(data-tool-tip);
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
  background-color: #464646;
  color: #fff;
  font-size: 13px;
  pointer-events: none;
  text-transform: none;
  max-width: 245px;
  margin-left: -120px;
  width: max-content;
}

.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 170%;
}
.sec-first-party-targeting {
  display: flex;
  justify-content: space-between;
}
.disable-field {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: #fc4242;
  letter-spacing: 0.9px;
}
</style>
