<template>
  <b-dropdown-multiselect-v2
    class-prefix="income"
    :options="incomeOptions"
    :label="'Select Income'"
    :value="incomeValues"
    @select="onInput"
  />
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
// import targetingMixin from '~/components/mixins/targeting-mixin';
import targetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
// import authzMixin from '~/components/mixins/authz-mixin';
import { incomeOptionsMNI } from '~/constant';
import bDropdownMultiselectV2 from '~/components/elements/b-dropdown-multiselect-v2.vue';

export default {
  name: 'IncomeSelect',
  components: { bDropdownMultiselectV2 },

  mixins: [lineItemMixin, targetableElementOptionsMixin, validationsMixin],

  props: {
    targetingValue: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      targetingType: 'audienceTargeting',
      targetableElementId: 2,
      counter: 0,
    };
  },
  computed: {
    incomeOptions() {
      let value = [];
      if (this.isMni) {
        value = incomeOptionsMNI;
      } else {
        value = this.options;
      }
      return value.map((option) => {
        return { text: option };
      });
    },
    incomeValues() {
      let values = [];
      if (this.counter >= 0 && this?.targetingValue?.income) {
        values = this.targetingValue.income.map((income) => {
          return { text: income };
        });
      }
      return values;
    },
  },
  methods: {
    onInput(val) {
      this.counter += 1;
      const valString = val.map((v) => v.text);
      this.$emit('onInput', valString, 'income');
    },
  },
};
</script>
