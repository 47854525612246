<template>
  <div>
    <div v-if="loader" class="loading-spinner">
      <b-loading-spinner />
    </div>
    <div v-else>
      <IntermediateScreen
        v-if="showIntermediateScreen"
        :warning-message="warningMessage"
        :warning-action-button-title="warningActionButtonTitle"
        :page-title="pageTitle"
        :empty-message="emptyMessage"
        :page-action-button1-title="pageActionButton1Title"
        :page-action-button2-title="pageActionButton2Title"
        @warning-action-button-click="warningActionButtonClick"
        @page-action-button1-click="pageActionButton1Click"
        @page-action-button2-click="pageActionButton2Click"
      />
      <div v-else class="campaign-base">
        <div class="campaignContainer trans-effect mvc-io-wrap export-view">
          <div class="mvc-body-wrap body645 width100 ordermin">
            <div id="header" class="mvc-body">
              <div id="book-campaign-page" class="mvc-body">
                <div class="mvc-io-wrap title-section">
                  <div class="flp-top-panels-title">
                    <div class="flp-top-panels-title-txt">Reporting Summary</div>
                    <div class="flp-top-right2" style="right: 33px;">
                      <b-campaign-datepicker @change="bCampaignDatepicker" />
                    </div>
                  </div>
                  <campaign-stats />
                </div>
                <div class="campaignBtnSection">
                  <div class="flp-top-panels-title-txt campaign-text">
                    Campaigns
                  </div>
                  <div class="quicklinks">
                    <lineItem-status-filters
                      v-if="lineItemStatusCountForPlans.length"
                      :is-loading="isTableDataLoading"
                      @applyLineItemStatusFilter="applyLineItemStatusFilter"
                    />
                  </div>
                  <div class="add-new-btn-section-1">
                    <button
                      class="mvc-btn btn-size3 add-io-btnx intip4x intip-btnx add-new-btn"
                      style="min-width: 100px;"
                      @click="addNewCampaignButtonClick"
                    >
                      New Campaign
                    </button>
                  </div>
                  <div class="add-new-btn-section">
                    <div class="iosearch-wrap">
                      <k-text-input
                        class="col-xs io-search-box"
                        :class="['search-input']"
                        :icon="['far', 'search']"
                        :value="searchPlanText"
                        @keyup="onPlanSearchKey"
                      />
                    </div>
                  </div>
                </div>
                <div class="section-top-io2 middle-xs">
                  <div class="col-xs-12">
                    <plan-table
                      ref="planTable"
                      class="plans"
                      :loader="loader"
                      :expand-plan-id="expandPlanId"
                      :selected-status-ids="selectedWorkflowIds"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 pagination-alignment">
            <pagination
              :pages="pages"
              :total-count="totalCount"
              :result-count="resultCount"
              :loader="loader"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sync, get } from 'vuex-pathify';
import _ from 'underscore';
import { isBlank } from 'adready-api/helpers/common';
import { IO_STATE_CHANGE } from 'adready-api/constant';
import PlanTable from '~/components/campaigns/plan-table.vue';
import CampaignStats from '~/components/campaigns/campaign-stats.vue';
import { ENUM_IS_WATCH_STATS, LAYOUT_TYPES } from '~/constant';
import { eBus } from '~/main';
import lineItemStatusFilters from '~/components/lineItem-status-filters.vue';
import { stock } from '~/components/mixins/forklift-mixin';
import Pagination from '~/components/campaigns/pagination.vue';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import campaignMixin from '~/components/mixins/campaign-mixin';
import fetchIosMethodMixin from '~/components/mixins/fetch-ios-method-mixin';
import IntermediateScreen from '~/components/intermediate-screen.vue';
import authzMixin from '~/components/mixins/authz-mixin';

export default {
  components: {
    PlanTable,
    lineItemStatusFilters,
    CampaignStats,
    Pagination,
    IntermediateScreen,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BCampaignDatepicker: () =>
      import(
        /* webpackChunkName: "b-campaign-datepicker" */ '~/components/elements/b-campaign-datepicker.vue'
      ),
  },
  mixins: [
    stock('liStatus'),
    stock('mediaPublishers'),
    gaEventsMixin,
    campaignMixin,
    authzMixin,
    fetchIosMethodMixin,
  ],

  props: {
    expandPlanId: { require: false, type: Number, default: 0 },
  },

  data() {
    return {
      selectedWorkflowIds: [],
      isFlip: true,
      loader: false,
      includePacing: true,
      searchPlanText: '',
      plansIds: '',
      warningMessage: '',
      warningActionButtonTitle: '',
      pageTitle: '',
      emptyMessage: '',
      pageActionButton1Title: '',
      pageActionButton2Title: '',
      pageActionButton2Click: () => {},
      showIntermediateScreen: false,
      isTableDataLoading: false,
    };
  },

  computed: {
    advertiser: get('common/advertiser'),
    account: get('common/account'),
    lineItemStatusCount: get('common/liStatusCount'),
    lineItemStatusCountForPlans: get('common/liStatusCountForPlans'),
    plans: sync('common/plans'),
    totalPlansPages: get('common/totalPlansPages'),
    totalPlansCount: get('common/totalPlansCount'),
    plansQuery: sync('common/plansQuery'),
    orderCriteria: sync('common/orderCriteria'),
    ioOptions: get('dashboard/filters@ioOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    universalPixelId: get('common/universalPixelId'),

    pages() {
      return this.totalPlansPages;
    },

    resultCount() {
      return this.plans.length;
    },

    totalCount() {
      return this.totalPlansCount;
    },
  },

  watch: {
    orderCriteria: {
      deep: true,
      handler: _.debounce(function handler() {
        this.loadDataForCampaignDashboard(
          this.account,
          this.advertiser,
          this.isFlip,
          this.includePacing
        );
      }, 100),
    },
    advertiser(n) {
      this.validateAdvertiserSetup(n);
    },
  },
  created() {
    document.body.style.setProperty('background-color', '#fff', 'important');
    document.body.style.margin = '0px';
    eBus.$on('on-show-intermediate-screen', this.onShowIntermediateScreen);
  },

  destroyed() {
    document.body.style.backgroundColor = null;
    document.body.style.margin = null;
    eBus.$off('on-show-intermediate-screen', this.onShowIntermediateScreen);
  },
  mounted() {
    this.loader = true;
    const prevDatePickerElements = document.querySelectorAll('[id*="datepicker_"]');
    if (prevDatePickerElements?.length > 0) {
      prevDatePickerElements.forEach((elm) => elm.remove());
    }
    this.loadDataForCampaignDashboard();
    this.loadMediaPublishers();
    if (this.advertiser?.selfServe) {
      this.showIntermediateScreen = this.doesAdvertiserHaveNoCampaigns();
    }
  },
  methods: {
    bCampaignDatepicker(data) {
      const datePickerPayload = {
        startDate: data.dates[0],
        endDate: data.dates[1],
        dateRangeOption: data.dateRangeOption,
      };
      this.$store.set('dashboard/isCampaignDatesChanged', true);
      this.$store.set('dashboard/campaignDates', datePickerPayload);
    },
    validateAdvertiserSetup(n) {
      if (n?.selfServe) {
        this.showIntermediateScreen = !this.loader && this.doesAdvertiserHaveNoCampaigns();
        return;
      }
      this.showIntermediateScreen = false;
    },
    onShowIntermediateScreen() {
      this.warningMessage = 'Your campaign has to start before you can view reporting';
      this.warningActionButtonTitle = 'Manage Campaigns';
      this.warningActionButtonClick = this.openManageCampaigns;
      this.pageTitle = 'Reporting';
      this.emptyMessage = "This campaign hasn't started yet.";
      this.pageActionButton1Title = 'Manage Campaigns';
      this.pageActionButton1Click = this.openManageCampaigns;
      this.pageActionButton2Title = 'All Reporting';
      this.pageActionButton2Click = this.dashboardButtonClick;
      this.showIntermediateScreen = true;
    },
    doesAdvertiserHaveNoCampaigns() {
      const hasNoCampaigns = !(this.plans && this.plans.length > 0);
      if (hasNoCampaigns) {
        this.warningMessage =
          'You have to build your first campaign before you can manage campaigns';
        this.warningActionButtonTitle = 'Build Campaign';
        this.warningActionButtonClick = this.addNewCampaignButtonClick;
        this.pageTitle = 'Campaigns';
        this.emptyMessage = "You haven't built a campaign yet.";
        this.pageActionButton1Title = 'Build Campaign';
        this.pageActionButton1Click = this.addNewCampaignButtonClick;
        this.pageActionButton2Title = '';
        this.pageActionButton2Click = () => {};
      }
      return hasNoCampaigns;
    },
    openAccountSetup() {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.ACCOUNT_SETUP);
    },
    openManageCampaigns() {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.CAMPAIGNS);
      this.showIntermediateScreen = false;
    },
    async loadDataForCampaignDashboard() {
      await this.loadLineItemStatusCount(this.account.id, this.advertiser.id, true, true);
      await this.loadLineItemStatusCountForPlans(this.account.id, this.advertiser.id, true, true);
      this.loadData();
    },
    async dashboardButtonClick() {
      const initialIoOptions = JSON.parse(JSON.stringify(this.ioOptions));
      initialIoOptions.forEach((ioOption) => {
        ioOption.checked = true;
      });

      const initialAdGroupOptions = JSON.parse(JSON.stringify(this.adGroupOptions));
      initialAdGroupOptions.forEach((adGroupOption) => {
        adGroupOption.checked = true;
      });

      this.$store.set('dashboard/filters@ioOptions', initialIoOptions);
      this.$store.set('dashboard/filters@adGroupOptions', initialAdGroupOptions);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.DASHBOARD);
    },

    applyLineItemStatusFilter(workflowIds) {
      const filteredPlansIds = [];
      this.lineItemStatusCountForPlans
        .filter((w) => _.contains(workflowIds, w.id))
        .forEach((w) => {
          let id = w.planId;
          if (w.parentId) {
            id = w.parentId;
          }
          if (!filteredPlansIds.some((wd) => wd === id)) {
            filteredPlansIds.push(id);
          }
        });
      this.selectedWorkflowIds = workflowIds;
      this.plansIds = filteredPlansIds.join();
      this.loadData();
    },
    handleSearchChange: _.debounce(function handler() {
      if (this.plans.length) {
        this.loadData();
      }
    }, 1000),

    /**
     * Incremental plan search as user types
     */
    onPlanSearchKey(val) {
      // value changed or both are not blank
      // handles case when val = "" and current val is null/undefined
      if (this.searchPlanText !== val && !(isBlank(val) && isBlank(this.searchPlanText))) {
        // react on actual changes, so we don't needlessly trigger changes on
        // random keys (like control, tab, etc)
        this.searchPlanText = val;
        this.handleSearchChange();
      }
    },

    loadData() {
      const rsqlFilters = [];

      if (!isBlank(this.searchPlanText)) {
        rsqlFilters.push(`campaignName==(*${this.searchPlanText.trim()}*)`);
      }
      if (!isBlank(this.plansIds)) {
        rsqlFilters.push(`id=in=(${this.plansIds})`);
      }
      const filterString = rsqlFilters.join(';');
      let q = `accountId==${this.account.id};advertiserId==${this.advertiser.id};state!=${IO_STATE_CHANGE}`;

      if (!isBlank(filterString)) {
        q = `${q};${filterString}`;
      }
      this.isTableDataLoading = true;
      this.loadPlansData(
        q,
        {},
        this.isFlip,
        true,
        this.includePacing,
        this.advertiser ? this.advertiser.name : '',
        this.account ? this.account.name : '',
        this.advertiser ? this.advertiser.xandrAdvertiserId : '',
        this.universalPixelId
      ).then(() => {
        this.loader = false;
        this.isTableDataLoading = false;
        if (isBlank(filterString)) {
          this.validateAdvertiserSetup(this.advertiser);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';
@import 'https://fonts.googleapis.com/css2?family=Bitter:wght@100;200;300;400;500;600;700;800;900&display=swap';

.export-view {
  font-family: 'Manrope', sans-serif;
  line-height: 1.2em;
}
.add-new-btn-section-1 {
  position: absolute;
  right: 325px;
  top: 30px;
  .mvc-btn {
    height: 42px;
  }
}

.bg1 {
  margin: 75px 0px 0px 0px;
}

* {
  box-sizing: border-box;
}

.campaign-base {
  height: calc(100vh - 65px);
  background-color: #fff;
}

.mvc-body-wrap {
  background-color: #fff;
  display: block;
  width: 100%;
  margin: auto;
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -ms-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
  padding-bottom: 50px;
}

/*After added to auto expand the body background*/
.mvc-body-wrap::after {
  clear: both;
  content: '';
  width: 100%;
  height: 1px;
  display: block;
}

.body645 {
  width: 645px;
  padding-bottom: 0px;
}
.body645 .mvc-body {
  padding: 0px 0px;
}
.width100 {
  width: 100%;
}

.mvc-body {
  width: 100%;
  margin: auto;
  padding: 0px 44px;
  -webkit-transition: width, padding 0.3s ease;
  -moz-transition: width, padding 0.3s ease;
  -ms-transition: width, padding 0.3s ease;
  -o-transition: width, padding 0.3s ease;
  transition: width, padding 0.3s ease;
}

.flp-top-panels-title {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 60px;
  margin-top: -15px;
  padding-bottom: 25px;
}
.flp-top-panels-title-txt {
  display: inline-block;
  padding-top: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 16px;
  color: #2d313a;
}
.flp-top-right2 {
  display: inline-block;
  position: absolute;
  right: 0;
}
.mvc-btn .add-new-btn {
  min-width: 100px;
  margin-right: 6px;
  height: 41px;
  position: relative;
  bottom: 1px;
}
.mvc-btn {
  color: var(--selfserveovertext) !important;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  display: inline-block;
  font-weight: 500;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 170px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.btn-outline {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #3b3d48 !important;
    padding: 10px 18px;
  }
  &:hover {
    color: #fff;
    background-color: #555 !important;
  }
}

@media screen and (max-width: 1025px) {
  .bg1 {
    background: #fff;
    margin: 0px;
  }
}

@media screen {
  .bg1 {
    background: #fff;
    margin: 0px;
  }
  .mvc-io-wrap {
    margin-top: 0px !important;
  }
}
</style>

<style lang="scss" scoped>
.mvc-io-wrap {
  background-color: #fff;
  &.title-section {
    padding: 0px 40px;
    margin-top: 20px !important;
    box-shadow: 0px 30px 40px -20px rgb(0 0 0 / 7%);
  }
  table {
    td {
      &:first-child {
        padding: 0 0.3em 0.6em 0.3em !important;
      }
    }
    th {
      height: 59px !important;
    }
  }
}
.campaignContainer {
  padding-top: 65px;
  //box-shadow: 0px 30px 40px -20px rgb(0 0 0 / 7%);
}
.campaignBtnSection {
  padding: 38px 0 25px 0;
  display: flex;
  align-items: center;
  transform: scale(0.95);
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  ::v-deep {
    .form-input {
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #485257;
      &.focused,
      &.filled {
        label {
          background-color: #fff;
        }
      }
      .form-input-field {
        border-radius: 5px;
        background-color: #f7f9fb;
        border: 1px solid #d3d6dd;
        box-shadow: none;
      }
    }
  }
}
.section-top-io2 {
  border-right: 1px solid #e3e6eb;
}
.section-top-io {
  margin-bottom: 20px;
  padding: 0px 40px;
}
.quicklinks {
  display: inline-flex;
  max-width: 945px;
  position: relative;
  right: 3px;
  left: 15px;
}
.btn-size3 {
  font-size: 12px;
  padding: 10px 18px;
  background-color: var(--selfserveprimarycolor) !important;
}
.mvc-btn {
  background-color: #54a8c7;
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  padding: 10px 18px;
  vertical-align: top;
  margin-right: 7px;
  color: var(--selfserveovertext) !important;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  display: inline-block;
  font-weight: 500;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 170px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.iosearch-wrap {
  display: inline-block;
  // & .io-search-box {
  //   width: 315px;
  // }
}
.campaign-text {
  margin-bottom: 6px;
  padding: 0px 10px 0px 10px;
  position: relative;
  top: 2px;
}
::v-deep .iosearch-wrap .form-input.search-input svg {
  position: absolute;
  top: 15px;
  right: 10px;
}
.add-new-btn-section {
  display: inline-block;
  float: right;
  position: relative;
  top: 27px;
  right: 5px;
  position: absolute;
  width: 315px;
}
::v-deep .iosearch-wrap .form-input .form-input-field {
  width: 315px;
}
::v-deep .form-input.search-input .form-input-field {
  padding: 12px 34px 12px 10px !important;
}
.campaignContainer .pagination {
  margin-top: unset;
  margin-right: 30px;
  margin-top: 10px;
}
.section-top-io2.middle-xs .col-xs-12 {
  padding-right: unset;
  padding-left: unset;
}
.pagination-alignment {
  height: 0px;
  bottom: 9px;
  position: relative;
}
@media screen and (max-width: 1080px) {
  ::v-deep .add-new-btn-section {
    width: 100%;
    left: 80%;
  }
  ::v-deep .iosearch-wrap .form-input .form-input-field {
    width: 100%;
  }
}
@media screen and (max-width: 970px) {
  ::v-deep .add-new-btn-section {
    width: 14%;
    left: auto;
  }
  ::v-deep .iosearch-wrap .form-input .form-input-field {
    width: 100%;
  }
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b1d21;
}
</style>
