import {
  TAR_BEHAVIOURAL_SEGMENT,
  TAR_DEMOGRAPHIC_TARGETING,
  TAR_CONTEXTUAL_TARGETING,
  TAR_AUDIENCE_FIRST_PARTY_TARGETING,
  DT_CTV,
} from 'adready-api/constant';
import { isBlank } from 'adready-api/helpers/common';
import _ from 'underscore';
import {
  DELIVERY_PROBABILITY_OPTIONS,
  TAR_CITY,
  TAR_COUNTRY,
  TAR_DMA,
  TAR_STATE,
  TAR_ZIP_CODES,
} from '~/constant';
import lineItemHelpers from '~/helpers/line-item-helpers';
import { toFixedNumber, dateDiff } from '~/util/utility-functions';

function calculateGeoScalability(targetings) {
  const geoElements = {};
  geoElements[TAR_COUNTRY] = 0;
  geoElements[TAR_DMA] = -3;
  geoElements[TAR_STATE] = -1;
  geoElements[TAR_ZIP_CODES] = -5;
  geoElements[TAR_CITY] = 0;

  const keys = Object.keys(geoElements);
  const geoTargeting = targetings.find((t) => keys.includes(`${t.targetableElementId}`));
  // console.log('geoTargeting', geoTargeting);

  return geoTargeting ? geoElements[`${geoTargeting.targetableElementId}`] : 0;
}
function calculateTargetingScalability(targetings, targetableElementId, defaultValue) {
  let returnValue = defaultValue;
  const targeting = targetings.find((t) => t.targetableElementId === targetableElementId);
  if (!targeting) {
    return 0;
  }

  let value;
  let lookALikeRetargeting;

  switch (targetableElementId) {
    case TAR_BEHAVIOURAL_SEGMENT:
      value =
        targeting.value && targeting.value.dropDownValue && _.isArray(targeting.value.dropDownValue)
          ? targeting.value.dropDownValue.join(', ')
          : '';
      break;
    case TAR_CONTEXTUAL_TARGETING:
      if (targeting.value && _.isArray(targeting.value)) {
        value = targeting.value.join(', ');
      } else {
        value = targeting.value;
      }
      break;
    case TAR_DEMOGRAPHIC_TARGETING:
      if (targeting.value) {
        const keys = ['gender', 'age', 'income'];
        const filteredKeys = Object.keys(targeting.value).filter((k) => keys.includes(k));
        const data = [];
        filteredKeys.forEach((key) => {
          if (targeting.value[key] && _.isArray(targeting.value[key])) {
            data.push(targeting.value[key].join(', '));
          }
        });
        value = data.join(',');
      }
      break;
    case TAR_AUDIENCE_FIRST_PARTY_TARGETING:
      value = targeting.value && targeting.value.uploaded ? targeting.value.uploaded.name : '';
      lookALikeRetargeting = targeting.value && targeting.value.lookALikeRetargeting;
      returnValue = lookALikeRetargeting ? -3 : -7;
      break;
    default:
      break;
  }

  return !isBlank(value) ? returnValue : 0;
}
function calculateDeviceScalability(lineItem, allDevices, ignoreChanges) {
  const deviceTypes = lineItemHelpers.getLineItemFieldValue(lineItem, 'deviceTypes', ignoreChanges);
  if (deviceTypes && deviceTypes.length === allDevices.length) {
    return 0;
  }
  const deviceTypeIds = deviceTypes.map((d) => d.deviceTypeId);
  const unselectedDeviceIds = allDevices
    .filter((d) => !deviceTypeIds.includes(d.id))
    .map((d) => d.id);
  const scalability = [];
  unselectedDeviceIds.forEach((id) => scalability.push(id === DT_CTV ? -4 : -1));
  // console.log('unselectedDeviceIds', unselectedDeviceIds);
  return scalability.reduce((s, scale) => {
    return s + scale;
  }, 0);
}
function calculateOverallScalability(lineItem, selectedMediaPublishers, allDevices, ignoreChanges) {
  const scalability = [];

  // publisher scalability
  const publisherScalability = selectedMediaPublishers.map((p) => p.scalability);
  scalability.push(...publisherScalability);

  const targetings = lineItemHelpers.getLineItemFieldValue(lineItem, 'targetings', ignoreChanges);
  if (!isBlank(targetings)) {
    // geo targeting scalability
    scalability.push(calculateGeoScalability(targetings));
    // behavioral targeting scalability
    scalability.push(calculateTargetingScalability(targetings, TAR_BEHAVIOURAL_SEGMENT, -3));
    // contextual targeting scalability
    scalability.push(calculateTargetingScalability(targetings, TAR_CONTEXTUAL_TARGETING, -5));
    // demographic targeting scalability
    scalability.push(calculateTargetingScalability(targetings, TAR_DEMOGRAPHIC_TARGETING, -1));
    // first party targeting scalability
    scalability.push(calculateTargetingScalability(targetings, TAR_AUDIENCE_FIRST_PARTY_TARGETING));
  }

  // device scalability
  scalability.push(calculateDeviceScalability(lineItem, allDevices, ignoreChanges));

  // console.log('overall', scalability);

  return scalability.reduce((s, scale) => {
    return s + scale;
  }, 0);
}
function calculateBudgetScalability(lineItem, ignoreChanges) {
  // manual
  const db = lineItemHelpers.getLineItemFieldValue(lineItem, 'lineItemBudget', ignoreChanges) || 0;
  // console.log('db', db);
  const s = lineItemHelpers.getLineItemFieldValue(lineItem, 'flightStartDate', ignoreChanges);
  const e = lineItemHelpers.getLineItemFieldValue(lineItem, 'flightEndDate', ignoreChanges);

  // derived
  // Campaign length in days (d)
  const d = dateDiff(s, e);
  // console.log('d', d);
  // Monthly Budget (mb)
  const mb = (db / d) * 30;
  // console.log('mb', mb);
  const scalability = (mb / 50000) * -2;

  return toFixedNumber(scalability, 2);
}
function convertScalabilityToProbability(scale) {
  if (isBlank(scale)) {
    return null;
  }
  return DELIVERY_PROBABILITY_OPTIONS.find((o) => scale >= o.minScale && scale <= o.maxScale);
}
function calculateLineItemScalability(lineItem, mediaPublisherCPMData, allDevices, ignoreChanges) {
  // console.log('lineItem', lineItem);
  const targetings = lineItemHelpers.getLineItemFieldValue(lineItem, 'targetings', ignoreChanges);
  const publisherNames = lineItemHelpers.fetchPublisherTargeting(targetings);
  const selectedMediaPublishers = lineItemHelpers.getSelectedMediaPublishers(
    publisherNames,
    mediaPublisherCPMData
  );
  const totalPublishers = selectedMediaPublishers ? selectedMediaPublishers.length : 0;
  // console.log('totalPublishers', selectedMediaPublishers, totalPublishers);
  if (totalPublishers < 6 || totalPublishers > 12) {
    return null;
  }

  const overallScale = calculateOverallScalability(
    lineItem,
    selectedMediaPublishers,
    allDevices,
    ignoreChanges
  );
  const budgetScale = calculateBudgetScalability(lineItem, ignoreChanges);
  const totalScale = overallScale + budgetScale;
  // console.log('overallScale', overallScale);
  // console.log('budgetScale', budgetScale);
  // console.log('total', totalScale);

  // return convertScaleToProbability(totalScale);
  return totalScale;
}
function calculateLineItemDeliveryProbability(
  lineItem,
  mediaPublisherCPMData,
  allDevices,
  ignoreChanges
) {
  const totalScale = calculateLineItemScalability(
    lineItem,
    mediaPublisherCPMData,
    allDevices,
    ignoreChanges
  );
  return convertScalabilityToProbability(totalScale);
}
export default {
  calculateLineItemScalability,
  convertScalabilityToProbability,
  calculateLineItemDeliveryProbability,
};
