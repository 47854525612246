<template>
  <div class="fhide">
    <div class="rt-txt">
      <div class="lf-txt">
        <div class="sec-title">Frequency &amp; Recency Capping</div>
      </div>
      <div class="switch ">
        <div :class="`toggle-btn-cont ${readonly ? 'readonly-fr' : ''}`">
          <b-campaign-toggle
            class-prefix="freq-recency"
            :checked="frequencyEnabled"
            :change="onChangeFrequencyToggle"
            :readonly="readonly"
          />
        </div>
      </div>
    </div>
    <div v-if="frequencyEnabled">
      <div class="formclear" style="height:10px;"></div>
      <div class="formclear" style="height:8px;"></div>
      <div class="ss-half ">
        <div class="inline">
          <div class="ss-form-label">Impressions</div>
          <k-select
            :required="true"
            :valid="isValid"
            :options="impressionOptions"
            label-key="value"
            :value="frequencyRecencyImpression"
            :readonly="readonly"
            style="width: 95px;"
            @input="onInputImpression"
            @validation="onValidation"
          />
        </div>
        <span
          style="padding-right: 8px;display: inline-block;margin-left: 16px;margin-right: 10px;"
        >
          per
        </span>
        <div class="inline">
          <div class="ss-form-label">Time Period</div>
          <k-select
            :required="true"
            :valid="isValid"
            :options="timePeriodOptions"
            label-key="value"
            :value="frequencyRecencyTimePeriod"
            :readonly="readonly"
            style="width: 95px;"
            @input="onInputTimePeriod"
            @validation="onValidation"
          />
        </div>
      </div>
      <div class="ss-half last" style="vertical-align: bottom;">
        <div class="inline">
          <div class="ss-form-label">Recency Cap</div>
          <k-select
            :required="true"
            :valid="isValid"
            :options="recencyOptions"
            label-key="value"
            :value="frequencyRecencyCap"
            :readonly="readonly"
            style="width: 200px;"
            @input="onInputRecencyCap"
            @validation="onValidation"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import { isBlank } from 'adready-api/helpers/common';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import BCampaignToggle from '~/components/elements/b-campaign-toggle.vue';
import { DEFAULT_FREQUENCY_RECENCY } from '~/constant';

export default {
  name: 'LineItemFrequencyRecency',

  components: {
    BCampaignToggle,
  },

  mixins: [validationsMixin.events, lineItemMixin],
  props: {
    lineItem: {
      required: true,
      type: Object,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      frequencyRecencyData: { ...this.lineItem.frequencyRecency },
      impressionOptions: [
        {
          id: 0,
          value: 0,
        },
        {
          id: 1,
          value: 1,
        },
        {
          id: 2,
          value: 2,
        },
        {
          id: 3,
          value: 3,
        },
        {
          id: 4,
          value: 4,
        },
        {
          id: 5,
          value: 5,
        },
        {
          id: 6,
          value: 6,
        },
      ],
      timePeriodOptions: [
        {
          id: 'Hour',
          value: 'Hour',
        },
        {
          id: 'Day',
          value: 'Day',
        },
        {
          id: 'Week',
          value: 'Week',
        },
        {
          id: 'Month',
          value: 'Month',
        },
      ],
      recencyOptions: [
        {
          id: 'None',
          value: 'None',
        },
        {
          id: '1 Minute',
          value: '1 Minute',
        },
        {
          id: '2 Minutes',
          value: '2 Minutes',
        },
        {
          id: '30 Minutes',
          value: '30 Minutes',
        },
        {
          id: '60 Minutes',
          value: '60 Minutes',
        },
        {
          id: '120 Minutes',
          value: '120 Minutes',
        },
      ],
      frequencyEnabled: this?.lineItem?.frequencyRecency?.enabled || false,
    };
  },
  computed: {
    frequencyRecencyCap() {
      if (
        this.lineItem &&
        this.lineItem.frequencyRecency &&
        this.lineItem.frequencyRecency.recencyCapValue
      ) {
        return `${this.lineItem.frequencyRecency.recencyCapValue} ${this.lineItem.frequencyRecency.recencyCapTimePeriod}`;
      }
      return 'None';
    },
    frequencyRecencyTimePeriod() {
      if (
        this.lineItem &&
        this.lineItem.frequencyRecency &&
        this.lineItem.frequencyRecency.timePeriod
      ) {
        return this.lineItem.frequencyRecency.timePeriod;
      }
      return 'Hour';
    },
    frequencyRecencyImpression() {
      if (
        this.lineItem &&
        this.lineItem.frequencyRecency &&
        this.lineItem.frequencyRecency.impression
      ) {
        return this.lineItem.frequencyRecency.impression;
      }
      return 0;
    },
    isValid() {
      return true;
    },
  },

  methods: {
    onChangeFrequencyToggle(val) {
      this.frequencyEnabled = val;
      if (val) {
        this.frequencyRecencyData = { enabled: true, ...DEFAULT_FREQUENCY_RECENCY };
      } else {
        this.frequencyRecencyData = { enabled: false };
      }
      this.updateLineItem({ frequencyRecency: { ...this.frequencyRecencyData } });
    },
    onInputImpression(val) {
      this.frequencyRecencyData.impression = val;
      this.updateLineItem({ frequencyRecency: { ...this.frequencyRecencyData } });
    },
    onInputTimePeriod(val) {
      this.frequencyRecencyData.timePeriod = val;
      this.updateLineItem({ frequencyRecency: { ...this.frequencyRecencyData } });
    },
    onInputRecencyCap(val) {
      if (!isBlank(val)) {
        const recencyCap = val.split(' ');
        // eslint-disable-next-line prefer-destructuring
        this.frequencyRecencyData.recencyCapValue = recencyCap[0];
        // eslint-disable-next-line prefer-destructuring
        this.frequencyRecencyData.recencyCapTimePeriod = recencyCap.length > 1 ? recencyCap[1] : '';
        this.updateLineItem({ frequencyRecency: { ...this.frequencyRecencyData } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rt-txt {
  display: flex;
}
.sec-title {
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 60px;
  color: #444;
  font-size: 20px;
  letter-spacing: 0.02em;
}

.ss-half {
  display: inline-block;
  width: 48%;
  margin-right: 3%;
  vertical-align: top;
}
.ss-half.last {
  margin-right: 0%;
  vertical-align: top;
}
.inline {
  display: inline-block;
}
.ss-form-label {
  font-size: 12px;
  text-transform: uppercase;
  color: #666;
  margin-bottom: 4px;
  letter-spacing: 0.02em;
}
.toggle-btn-cont {
  margin-top: 3px;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #fff;
  border-radius: unset;
  border: 1px solid #aaa;
}
::v-deep .form-input-wrap {
  border: 1px solid #aaa;
}
.readonly-fr {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.25;
}
</style>
