<template>
  <div ref="bListboxMultiSelect" :class="`ss-mdrop open ${readonly ? 'readonly-bl' : ''}`">
    <div class="ss-half ">
      <div style="margin-bottom: 10px;">
        <div class="ss-half " style="top: 3px; font-size: 14px; width: 35%;">
          <span>Available ({{ availableOptions.length }})</span>
        </div>
        <div class="ss-half last" style="width: 62%;">
          <div class="list-search-wrap">
            <k-text-input
              label="Search"
              :class="['search-input']"
              :icon="['far', 'search']"
              @keyup="onSearchTextKeyUp"
            />
          </div>
        </div>
      </div>
      <div class="ss-mdrop-content">
        <div
          v-for="(option, index) in availableOptions"
          :key="`${option}_dropDown_${index}`"
          class="ss-mdrop-itm mitm1"
          @click="handleAvailableOptionClick(option)"
        >
          <span class="optionText">
            <img :src="option.logo || DEFAULT_PUBLISHER_LOGO" class="optionLogo" />
          </span>
          <div :class="`optionText ${option.additionalInfo ? '' : 'widthMaxContent'}`">
            <div class="line1">
              {{ option.text }}
              <div
                v-if="option[infoIconTargetNode]"
                class="info-icon"
                @click="(e) => handleInfoIconClick(e, option)"
              >
                <font-awesome-icon :icon="['fas', 'info-circle']" />
              </div>
            </div>
            <div class="line2">{{ option.category }}</div>
          </div>
          <font-awesome-icon :icon="['fas', 'plus']" class="droparw supportIcon" />
        </div>
      </div>
    </div>
    <div class="ss-half last">
      <div style="margin-bottom: 10px;">
        <div class="ss-half " style="top: 3px; font-size: 14px; width: 35%;">
          <span>Selected ({{ selectedOptions.length }})</span>
        </div>
        <div class="ss-half last" style="width: 62%; top: 6px;">
          <div class="mitm-removeall" @click="handleUnselectAll()">
            Unselect All
          </div>
        </div>
      </div>
      <div class="ss-mdrop-content">
        <div
          v-for="(option, index) in selectedOptions"
          :key="`${option}_dropDown_${index}`"
          class="ss-mdrop-itm mitm1"
          @click="handleSelectedOptionClick(option)"
        >
          <span class="optionText">
            <img :src="option.logo || DEFAULT_PUBLISHER_LOGO" class="optionLogo" />
          </span>
          <div :class="`optionText ${option.additionalInfo ? '' : 'widthMaxContent'}`">
            <div class="line1">{{ option.text }}</div>
            <div class="line2">{{ option.category }}</div>
          </div>
          <font-awesome-icon :icon="['fas', 'minus']" class="droparw supportIcon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';

export default {
  name: 'BListboxMultiselect',
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    infoIconTargetNode: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      searchText: '',
      DEFAULT_PUBLISHER_LOGO:
        'https://storage.googleapis.com/flip-assets/publisher_logos/color/otherhub_80x80.png',
    };
  },
  computed: {
    availableOptions() {
      const selectedValues = this.selectedOptions.map((o) => o.text);
      const availableValues = this.options.filter((o) => !selectedValues.includes(o.text));
      if (isBlank(this.searchText)) {
        return availableValues;
      }
      const filteredValues = availableValues.filter((o) =>
        o.text.toLowerCase().includes(this.searchText.toLowerCase())
      );
      return filteredValues;
    },
    selectedOptions() {
      return this.value;
    },
  },
  methods: {
    onSearchTextKeyUp(val) {
      this.searchText = val;
    },
    handleUnselectAll() {
      if (!this.value.length) {
        return;
      }
      this.$emit('select', []);
    },
    handleInfoIconClick(e, payload) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('onInfoIconClick', payload);
    },
    handleAvailableOptionClick(option) {
      this.$emit('select', [...this.value, option]);
    },
    handleSelectedOptionClick(option) {
      this.$emit(
        'select',
        this.value.filter((val) => val.text !== option.text)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ss-mdrop {
  position: relative;
}

.ss-half {
  position: relative;
  display: inline-block;
  width: 48%;
  margin-right: 3%;
  vertical-align: top;
}
.ss-half.last {
  margin-right: 0%;
  vertical-align: top;
}

.open .ss-mdrop-content {
  height: 440px;
  max-height: 440px;
  overflow-x: scroll;
  position: absolute;
  z-index: 11;
  border: 1px solid #aaaaaa;
  width: 100%;
}
.open .additionalInfoText {
  display: inline-block;
}
.additionalInfoText {
  margin-right: 10px;
  margin-left: 5px;
  font-size: 12px;
  opacity: 0.7;
  display: none;
  position: absolute;
  right: 32px;
  top: 11px;
}
.droparw {
  position: relative;
  float: right;
  font-size: 11px;
  line-height: 12px;
  cursor: pointer;
  font-weight: 600;
  &.arrowicon {
    top: 8px;
  }
  &.supportIcon {
    font-size: 13px;
    top: 16px;
    color: #afb5bc;
    &:hover {
      color: var(--selfserveprimarycolor);
    }
  }
}
.ss-mdrop-itm {
  display: inline-block;
  padding: 5px 25px 5px 10px;
  border: 0px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  background-color: #fff;
  width: auto;
  margin: 5px 2px 0px 0px;
  color: #444;
  display: none;
  position: relative;
  cursor: pointer;
}

.open .ss-mdrop-itm {
  display: inline-block;
  width: 100%;
  padding: 12.5px 30px;
  font-size: 14px;
  color: #5a6269;
  margin: 0px;
  display: inline-block;
  &:first-child {
    padding-top: 30px !important;
  }
  &:hover {
    background-color: #ebf0f4;
  }
}

.mitm-removeall {
  display: inline-block;
  background-color: transparent !important;
  color: #777 !important;
  margin-bottom: 15px;
  float: right;
  font-size: 12px;
}
.mitm-removeall:hover {
  color: var(--selfserveprimarycolor) !important;
  cursor: pointer;
}
.optionText {
  display: inline-block;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.optionText.widthMaxContent {
  max-width: unset;
}
.optionLogo {
  height: 40px;
  width: 40px;
  margin-right: 25px;
}
.line1 {
  font-size: 16px;
  color: #48525c;
  display: flex;
  align-items: center;
  .info-icon {
    margin-left: 5px;
    color: #7f7f7f;
  }
}
.line2 {
  font-size: 12px;
  color: #929fb2;
}
::v-deep .list-search-wrap .form-input.search-input svg {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #bdbdbd;
}
::v-deep .list-search-wrap .form-input .form-input-field {
  height: 35px;
  font-size: 15px;
}
::v-deep .list-search-wrap .form-input label {
  margin: 18px 0 0 13px !important;
  font-size: 13px !important;
}
::v-deep .list-search-wrap .form-input.filled label,
::v-deep .list-search-wrap .form-input.focused label {
  display: none;
}
.readonly-bl {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
