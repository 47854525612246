<template>
  <div class="ss-left-nav-content">
    <div class="ss-nav-title">Campaign</div>
    <div class="ss-lnav-links">
      <div
        v-for="(cmpOption, index) in campaignOptions"
        :key="`cmpOption_${selectedPage}_${selectedPageIndex}_${index}`"
        class="ss-lnav-link"
      >
        <div :class="isLucidEnabled(cmpOption) ? '' : 'disabled'">
          <font-awesome-icon :icon="['far', `${cmpOption.leftIcon}`]" class="lnav-icon" />
          <div
            :class="
              `ss-lnav-txt ${
                selectedPage === cmpOption.pageName && selectedPageIndex === cmpOption.pageIndex
                  ? 'active'
                  : ''
              }`
            "
            @click="openPage(cmpOption.pageName, cmpOption.pageIndex)"
          >
            <span>
              {{ cmpOption.title }}
            </span>
          </div>
          <font-awesome-icon
            :icon="[
              `${cmpOption.rightIcon === 'exclamation' ? 'fas' : 'far'}`,
              `${cmpOption.rightIcon}`,
            ]"
            :class="
              `lnav-icon2 ${isPageIndexValid(cmpOption) ? 'active' : ''} ${
                cmpOption.rightIcon === 'exclamation' ? 'error' : ''
              }`
            "
          />
        </div>
      </div>
    </div>
    <div v-if="lineItems" style="margin-top: 100px;">
      <ad-group-nav-options
        v-for="(lineItem, index) in lineItems"
        :key="`lineItem_${index}`"
        :open-page="openPage"
        :line-item-index="index"
        :line-item-start-page-index="campaignOptions.length"
        :line-item="lineItem"
        :total-line-items="lineItems.length"
        :selected-page-index="selectedPageIndex"
        :page-index-validation-obj="pageIndexValidationObj"
        :page-index-visited="pageIndexVisited"
        :selected-page="selectedPage"
        :plan="plan"
        :readonly="isAdGroupReadonly(lineItem)"
        @addNewGroup="addNewGroup"
        @removeGroup="removeGroup"
        @cloneAdGroup="cloneAdGroup"
      />
    </div>
    <div class="ss-lnav-review">
      <div :key="`reviewPage_${selectedPage}`" class="ss-lnav-link">
        <font-awesome-icon
          :icon="['far', 'clipboard-check']"
          class="lnav-icon"
          style="font-size: 21px;"
        />
        <div
          :class="`ss-lnav-txt ${selectedPage === REVIEW_CAMPAIGN ? 'active' : ''}`"
          @click="openPage(REVIEW_CAMPAIGN, reviewPageIndex)"
        >
          Review
        </div>
        <font-awesome-icon :icon="['far', 'check']" class="lnav-icon2" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { get, sync } from 'vuex-pathify';
import { inert } from 'adready-api/helpers/common';
import { NEW_CAMPAIGN_PAGES, CAMPAIGN_NAV_BAR_OPTIONS, AD_GROUP_NAV_BAR_OPTIONS } from '~/constant';
import adGroupNavOptions from './ad-group-nav-options.vue';
import ioHelpers from '~/helpers/io-helpers';
import lineItemHelpers from '~/helpers/line-item-helpers';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';
import { IOS_DRAFT, LIS_DRAFT, LIS_NEW } from 'adready-api/constant';

const { REVIEW_CAMPAIGN } = NEW_CAMPAIGN_PAGES;

export default {
  name: 'CampaignLeftNavbar',
  components: { adGroupNavOptions },
  mixins: [campaignValidationMixin],

  props: {
    selectedPage: {
      required: true,
      type: String,
      default: () => '',
    },
    selectedPageIndex: {
      required: true,
      type: Number,
      default: () => 0,
    },
    visitedIndexes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      REVIEW_CAMPAIGN,
      isAdGroupReadonly: lineItemHelpers.isAdGroupReadonly,
      reviewPageIndex: CAMPAIGN_NAV_BAR_OPTIONS.length + 1,
      campaignOptions: inert(CAMPAIGN_NAV_BAR_OPTIONS),
    };
  },

  computed: {
    plan: sync('common/plan'),
    lineItems: get('common/plan@lineItems'),
    mediaPublisherCPMData: sync('common/mediaPublisherCPMData'),
    refreshCampaignValidation: get('common/refreshCampaignValidation'),
    impression() {
      return ioHelpers.calculateImpressionCount(this.plan, this.mediaPublisherCPMData);
    },
  },
  watch: {
    refreshCampaignValidation: {
      immediate: true,
      handler(n) {
        if (n) {
          this.campaignOptions = inert(CAMPAIGN_NAV_BAR_OPTIONS);
          setTimeout(() => {
            this.$store.dispatch('common/setRefreshCampaignValidation', false);
          }, 0);
        }
      },
    },
    plan: {
      handler(n) {
        this.setReviewPageIndex(n?.lineItems?.length || 0);
        // this is added to check for the page is valid when values entered to change the sign to success
        setTimeout(() => {
          if (this.pageIndexValidationObj[this.selectedPageIndex]?.isValidated) {
            this.campaignOptions = this.checkForPageIndexValidation(
              this.campaignOptions,
              this.plan
            );
          }
        }, 0);
      },
    },
    selectedPageIndex: {
      handler(nv, ov) {
        if (!this.refreshCampaignValidation) {
          if (!(this.selectedPageIndex === 1 && this.pageIndexVisited[1] <= 1)) {
            this.campaignOptions = this.checkForPageIndexValidation(
              this.campaignOptions,
              this.plan
            );
          }
        }
      },
    },
  },
  methods: {
    isLucidEnabled(campaignOption) {
      if (campaignOption.pageName === NEW_CAMPAIGN_PAGES.LUCID) {
        return ioHelpers.isLucidViable(this.impression);
      }
      return true;
    },
    setReviewPageIndex(lineItemsLength) {
      if (lineItemsLength) {
        this.reviewPageIndex =
          lineItemsLength * AD_GROUP_NAV_BAR_OPTIONS.length + CAMPAIGN_NAV_BAR_OPTIONS.length + 1;
      }
    },
    openPage(page, pageIndex, liIndex) {
      // The review page index always says 4. Not sure why. So calculating the right index here.
      if (page === 'review_campaign') {
        pageIndex =
          CAMPAIGN_NAV_BAR_OPTIONS.length +
          this.lineItems.length * AD_GROUP_NAV_BAR_OPTIONS.length +
          1;
      }
      this.$emit('pageSelected', page, pageIndex, liIndex);
      this.visitedIndexes.push({ name: page, pIndex: pageIndex, li: liIndex });
    },
    removeGroup(idx) {
      this.plan = {
        ...this.plan,
        lineItems: [...this.plan.lineItems.filter((fil, index) => index !== idx)],
      };
      this.$emit('pageSelected', REVIEW_CAMPAIGN, this.totalSelectableOptions);
    },
    cloneAdGroup(lineItem) {
      const clonedLineItem = lineItemHelpers.createCloneLineItem(lineItem);
      if (this.plan.statusId === IOS_DRAFT) {
        clonedLineItem.statusId = LIS_DRAFT;
      } else {
        clonedLineItem.statusId = LIS_NEW;
      }

      this.$store.dispatch('common/addLineItem', clonedLineItem);
      this.openAddNewAdGroupPage();
    },
    openAddNewAdGroupPage() {
      const page = 'ad_group_info';
      const pageIndex = this.lineItems.length * 3 + 1;
      const liIndex = this.lineItems.length - 1;
      this.openPage(page, pageIndex, liIndex);
    },

    addNewGroup() {
      const newAdGroup = lineItemHelpers.createDefaultAdGroup(
        // `Ad Group ${this.plan.lineItems.length + 1}`,
        undefined,
        this.plan
      );
      if (this.plan.statusId === IOS_DRAFT) {
        newAdGroup.statusId = LIS_DRAFT;
      } else {
        newAdGroup.statusId = LIS_NEW;
      }
      this.$store.dispatch('common/addLineItem', newAdGroup);
      this.openAddNewAdGroupPage();
    },
  },
};
</script>

<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';

.ss-left-nav-content {
  max-height: calc(100vh - 120px);
  padding: 48px 45px;
  overflow-y: scroll;
  font-family: 'Manrope', sans-serif;
}
.ss-lnav-txt {
  display: inline-block;
  margin: 0px 8px;
  font-size: 15px;
  color: #1e2124;
  cursor: pointer;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
  &.active {
    color: var(--selfserveprimarycolor) !important;
  }
  &:hover {
    color: var(--selfserveprimarycolor);
    cursor: pointer;
    .lnav-icon2 {
      color: var(--selfserveprimarycolor);
    }
  }
}
.ss-nav-title {
  display: inline-block;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.ss-lnav-links {
  position: relative;
  padding-left: 12px;
  margin-top: 20px;
  .ss-lnav-link {
    grid-gap: 14px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    &.active {
      color: var(--selfserveprimarycolor);
    }
    &:last-child {
      position: absolute;
      top: 100%;
    }
  }
}
.ss-lnav-review {
  margin-top: 60px;
  .ss-lnav-link {
    margin-bottom: 20px;
  }
  & .ss-lnav-txt {
    font-size: 16px;
  }
  & .lnav-icon {
    width: 16px;
    height: 21px;
  }
}
.lnav-icon {
  font-size: 16px;
  color: var(--selfserveprimarycolor);
}
.lnav-icon2 {
  font-size: 16px;
  color: #d6d8d9;
  &.active {
    color: var(--selfserveprimarycolor);
  }
  &.error {
    color: #ff0202;
  }
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.25;
}
</style>
