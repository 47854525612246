<template>
  <tr class="intr">
    <td class="tleft lineItem-name">
      <div
        v-if="!isLineItemEditable"
        class="lineItem_name_edit"
        :class="[mergedLineItem.name.changedValue ? `old` : ``]"
        @click="onClickLineItemName()"
      >
        {{ mergedLineItem.name.originalValue }}
      </div>
      <div v-else>
        <k-text-input
          ref="LineItemName"
          :range="true"
          icon-pos="right"
          :value="tempLineItemName"
          @change="onChangeLineItemName"
        />
      </div>
      <div v-if="mergedLineItem.name.changedValue">
        <span class="new">
          {{ mergedLineItem.name.changedValue }}
        </span>
      </div>
    </td>
    <td class="lineItem-td">
      <div v-if="!isLineItemEditable">
        <div :class="[mergedLineItem.flightStartDate.changedValue ? `old` : ``]">
          {{ formatDateInEST(mergedLineItem.flightStartDate.originalValue, DATE_FORMAT_MM_DD_YY) }}
        </div>
        <div v-if="mergedLineItem.flightStartDate.changedValue">
          <span class="new">
            {{ formatDateInEST(mergedLineItem.flightStartDate.changedValue, DATE_FORMAT_MM_DD_YY) }}
          </span>
        </div>
      </div>
      <div v-else>
        <k-date-picker
          :range="false"
          icon-pos="right"
          class="temp-line-item-date"
          :lower-bound="getStartLowerBound()"
          :upper-bound="getStartDateUpperBound()"
          :value-start="tempLineItemStartDate"
          :allow-dates-in-past="false"
          @change="onChangeLineItemStartDate"
        />
      </div>
    </td>
    <td class="lineItem-td">
      <div v-if="!isLineItemEditable">
        <div :class="[mergedLineItem.flightEndDate.changedValue ? `old` : ``]">
          {{ formatDateInEST(mergedLineItem.flightEndDate.originalValue, DATE_FORMAT_MM_DD_YY) }}
        </div>
        <div v-if="mergedLineItem.flightEndDate.changedValue">
          <span class="new">
            {{
              formatDateInEST(
                mergedLineItem.flightEndDate.chaonChangeLineItemNamengedValue,
                DATE_FORMAT_MM_DD_YY
              )
            }}
          </span>
        </div>
      </div>
      <div v-else>
        <k-date-picker
          :range="false"
          icon-pos="right"
          class="temp-line-item-date"
          :lower-bound="getEndDateLowerBound()"
          :upper-bound="getEndDateUpperBound()"
          :value-start="tempLineItemEndDate"
          :allow-dates-in-past="false"
          @change="onChangeLineItemEndDate"
        />
      </div>
    </td>
    <td class="lineItem-td">
      <div v-if="!isLineItemEditable">
        <div :class="[mergedLineItem.lineItemBudget.changedValue ? `old` : ``]">
          {{
            formatNumberAsFullNumber(
              mergedLineItem.lineItemBudget.originalValue > 0
                ? mergedLineItem.lineItemBudget.originalValue
                : 'Flexible',
              'CURRENCY'
            )
          }}
        </div>
        <div v-if="mergedLineItem.lineItemBudget.changedValue">
          <span class="new">
            {{
              formatNumberAsFullNumber(
                mergedLineItem.lineItemBudget.changedValue > 0
                  ? mergedLineItem.lineItemBudget.changedValue
                  : 'Flexible',
                'CURRENCY'
              )
            }}
          </span>
        </div>
      </div>
      <div v-else>
        <k-text-input
          :value="tempLineItemBudget"
          class="temp-line-item-date"
          :readonly="readOnly"
          :max-length="14"
          @change="onChangeLineItemBudget"
        />
      </div>
    </td>
    <td class="lineItem-td">{{ formatNumberAsFullNumber(lineItem.spend, 'CURRENCY') }}</td>
    <td
      class="lineItem-td"
      :class="`pacing-column ${lineItem.pace ? '' : 'disableTooltip'}`"
      :data-tool-tip="
        `Campaign pacing at ${Math.round(Number(lineItem.pace))}% of where expected to date.`
      "
    >
      <plan-pacing-chart :pacing="Math.round(Number(lineItem.pace))" />
    </td>

    <td
      class="lineItem-td goal-column"
      :class="[showGoalTooltip(plan.flipCampaignInfo) ? '' : 'disableTooltip']"
      :data-tool-tip="goalTooltip(plan.flipCampaignInfo)"
    >
      <span v-if="plan.flipCampaignInfo && plan.flipCampaignInfo.goal">
        {{ plan.flipCampaignInfo.goal.name }}
      </span>
    </td>
    <td class="lineItem-td">
      <span
        v-if="
          plan.flipCampaignInfo && plan.flipCampaignInfo.goal && plan.flipCampaignInfo.goal.metric
        "
      >
        {{ formatMetricValue(plan.flipCampaignInfo.goal.metric.label, lineItem.goalMetric) }}
      </span>
    </td>
    <td class="lineItem-td">
      <div
        class="stat-bubble st-activated"
        :style="workFlowColor(displayLIStatusNames(liStatus.id))"
      >
        {{ displayLIStatusNames(liStatus.id) }}
      </div>
    </td>
    <td :class="`col-activation-actions lineItem-action ${disableActionBtn ? 'disable-btn' : ''}`">
      <div v-if="plan.id">
        <div v-if="lineItem.id && !isLineItemEditable">
          <div v-if="canPlayLineItem" class="mvc-btn btn-size2 ply-btn">
            <k-icon-tooltip
              tooltip="Play"
              :icon="['fas', 'play']"
              class="inline-fa fa-w-16"
              @click="onClickPlay"
            />
          </div>
          <div v-else-if="canPauseLineItem" class="mvc-btn btn-size2 pause-btn">
            <k-icon-tooltip
              tooltip="Pause"
              :icon="['fas', 'pause']"
              class="inline-fa fa-w-16"
              @click="onClickPause"
            />
          </div>
          <div v-else class="mvc-btn btn-size2 pause-btn disabled">
            <k-icon-tooltip
              tooltip="Play"
              :icon="['fas', 'play']"
              class="inline-fa fa-w-16"
              :disabled="true"
            />
          </div>

          <div
            :class="`mvc-btn btn-size2 pen-btn ${isEndedOrCanceled ? 'disabled' : ''}`"
            @click="onEditLineItem(lineItem)"
          >
            <k-icon-tooltip
              tooltip="Edit"
              :icon="['fas', 'pen']"
              class="inline-fa fa-w-16"
              :disabled="isEndedOrCanceled"
            />
          </div>

          <div class="mvc-btn btn-size2 clone-btn" @click="onCloneLineItem(plan, lineItem)">
            <k-icon-tooltip tooltip="Clone" :icon="['fas', 'clone']" class="inline-fa fa-w-16" />
          </div>

          <div class="mvc-btn btn-size2 report-btn" @click="handleDashBoardSwitch">
            <k-icon-tooltip
              tooltip="Dashboard"
              :icon="['fas', 'chart-pie']"
              class="inline-fa fa-w-16"
            />
          </div>

          <div class="mvc-btn btn-size2 trash-btn">
            <k-icon-tooltip
              tooltip="Cancel"
              :icon="['fas', 'trash']"
              class="inline-fa fa-w-16"
              @click="$emit('onClickDeleteLineItem', lineItem)"
            />
          </div>
        </div>
        <div v-else-if="(!lineItem.id || isLineItemEditable) && onEditClonedLineItem(lineItem)">
          <div
            v-if="!lineItem.id"
            class="mvc-btn btn-size2 cancel-btn ft-12"
            @click="$emit('onClickDeleteLineItem', lineItem)"
          >
            <k-icon-tooltip tooltip="Cancel" :icon="['fas', 'times']" class="inline-fa fa-w-16" />
          </div>
          <div v-else class="mvc-btn btn-size2 cancel-btn ft-12" @click="handleDiscardChanges()">
            <k-icon-tooltip tooltip="Cancel" :icon="['fas', 'times']" class="inline-fa fa-w-16" />
          </div>
          <div class="mvc-btn btn-size2 save-btn ft-12" @click="onClickSaveLineItem(lineItem)">
            <k-icon-tooltip tooltip="Save" :icon="['fas', 'save']" class="inline-fa fa-w-16" />
          </div>
        </div>
      </div>
    </td>
    <ConfirmationModal
      v-if="showLineItemWarning"
      :message="warningTitle"
      :show-confirm-btn="showConfirmBtn"
      :cancel-btn-text="cancelBtnText"
      :header-text="headerText"
      :positive-button-label="labelYes"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      @close="handleCancelChanges"
      @onNegativeButtonClick="handleConfirmLineItemChanges"
      @onPositiveButtonClick="handleCancelChanges"
    />
    <ConfirmationModal
      v-if="showLineItemConflictWarning"
      :message="warningTitle"
      :show-confirm-btn="showConfirmBtn"
      header-text="Cancel Campaign Changes"
      positive-button-label="YES"
      negative-button-label="NO"
      :is-alert="isErrorWarning"
      :cancel-btn-text="cancelBtnText"
      @close="handleCancelChanges"
      @onNegativeButtonClick="handleCancelChanges"
      @onPositiveButtonClick="adjustPlanEndDate"
    />
  </tr>
</template>

<script>
import _ from 'underscore';
import { isBlank, inert } from 'adready-api/helpers/common';
import { get, sync } from 'vuex-pathify';
import moment from 'moment';
import planApi from 'adready-api/api/plans';
import lineItemsApi from 'adready-api/api/line-items';
import { LineItem } from 'adready-api/model/line-item';
import KDatePicker from 'adready-vue/components/elements/k-date-picker.vue';
import {
  IOS_DRAFT,
  LIS_PENDING_ACTIVATION,
  LIW_PENDING_AM,
  LI_STATUS_FOR_CR_ENABLED,
  LIS_CHANGE_PENDING_ACTIVATION,
  LIT_CHANGE,
  LIS_NEW,
  LIS_PENDING_PAUSED,
  LIS_DRAFT,
} from 'adready-api/constant';
import KIconTooltip from 'adready-vue/components/elements/k-icon-tooltip.vue';
import { formatMetricValue, addDaysToDate, isFlamingoInstance } from '~/util/utility-functions';
import { eBus } from '~/main';
import ConfirmationModal from '~/components/campaigns/confirmation-modal.vue';
import forklift from '~/components/mixins/forklift-mixin';
import { formatNumberAsFullNumber } from '~/util/numeral';
import { formatDateInEST, formatDate } from '~/util/apiDateFormat';
import lineItemHelpers from '~/helpers/line-item-helpers';
import { formatDateString } from '~/helpers/global/misc-helpers';
import { buildQueryString } from '~/helpers/global/url-helpers';
import advertiserReportsAPI from '~/api/advertiser-reports';
import fetchMediaTypesMethodMixin from '~/components/mixins/fetch-mediatypes-method-mixin';
import ioHelpers from '~/helpers/io-helpers';

import {
  DATE_FORMAT_MM_DD_YY,
  LAYOUT_TYPES,
  NEW_CAMPAIGN_PAGES,
  CR_LINEITEM_STATUS,
} from '~/constant';

import emailMixin from '~/components/mixins/email-mixin';
import {
  canPauseLineItem,
  canPlayLineItem,
  displayLIStatusNames,
  LI_DISPLAY_STATUS_COLOR_CODES,
  pausedStatusAndWorkflowChange,
  playStatusAndWorkflowChange,
} from '~/helpers/line-item-status-helpers';

export default {
  name: 'LineItemRow',
  components: {
    PlanPacingChart: () =>
      import(
        /* webpackChunkName: "plan-pacing-chart" */ '~/components/charts/plan-pacing-chart.vue'
      ),
    KIconTooltip,
    KDatePicker,
    ConfirmationModal,
  },
  mixins: [forklift, emailMixin, fetchMediaTypesMethodMixin],

  props: {
    lineItem: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formatDateInEST,
      formatDate,
      formatNumberAsFullNumber,
      addDaysToDate,
      formatMetricValue,
      DATE_FORMAT_MM_DD_YY,
      IOS_DRAFT,
      LIS_PENDING_ACTIVATION,
      LIS_PENDING_PAUSED,
      mergedLineItem: {
        name: {},
        flightStartDate: {},
        flightEndDate: {},
        lineItemBudget: {},
      },
      isLineItemEditable: false,
      tempLineItemStartDate: '',
      tempLineItemEndDate: '',
      tempLineItemName: '',
      tempLineItemBudget: null,
      showLineItemWarning: false,
      showLineItemConflictWarning: false,
      warningTitle: '',
      showConfirmBtn: true,
      cancelBtnText: 'NO',
      isSaveLoading: false,
      isErrorWarning: false,
      isValidation: false,
      checkValidEndDateForLineItem: false,
      headerText: 'Cancel Ad Group Changes',
      labelYes: 'YES',
      isLineNameChanged: false,
      readOnly: false,
    };
  },
  computed: {
    account: get('common/account'),
    planIdForLineItemStatusCountLoading: sync('common/planIdForLineItemStatusCountLoading'),
    advertiser: get('common/advertiser'),
    liStatusOptions: get('common/liStatusOptions'),
    activePlan: sync('common/plan'),
    expandedPlanId: sync('common/expandedPlanId'),
    ioOptions: get('dashboard/filters@ioOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    allUsers: get('common/allUsers'),
    eventOptions: get('dashboard/filters@eventOptions'),
    disableActionBtn() {
      if (isBlank(this.planIdForLineItemStatusCountLoading)) {
        return false;
      }
      return this.planIdForLineItemStatusCountLoading === this.plan.id;
    },
    isEndedOrCanceled() {
      return lineItemHelpers.isAdGroupReadonly(this.lineItem);
    },
    liStatus() {
      const liStatus = this.liStatusOptions.find((so) => so.id === this.lineItem.statusId);
      return liStatus ? { status: liStatus?.status, id: liStatus.id } : { status: '', id: '' };
    },
    canPauseLineItem() {
      return canPauseLineItem(this.lineItem.statusId);
    },
    canPlayLineItem() {
      return canPlayLineItem(this.lineItem.statusId);
    },
    currentDate() {
      return moment(formatDateString(''), 'YYYY-MM-DD');
    },
    nextDayDate() {
      return addDaysToDate(new Date(), 1);
    },
  },
  watch: {
    lineItem: {
      deep: true,
      handler(l) {
        this.mergedLineItem = this.transformLineItem(l);
        this.setTempValuesForLineItems();
      },
    },
  },
  mounted() {
    this.mergedLineItem = this.transformLineItem();
    this.setTempValuesForLineItems();
  },

  methods: {
    displayLIStatusNames,
    async fetchAdGroupData(planId) {
      let adGroupData = [];
      let response = '';
      if (planId) {
        response = await advertiserReportsAPI.adGroupOptions(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString({ planIds: planId, includeNonFlip: isFlamingoInstance() })
        );
        if (response?.data?.length > 0) {
          adGroupData = response?.data;
        }
        if (adGroupData.length > 0) {
          adGroupData = adGroupData.map((agd) => {
            return { ...agd, value: agd?.lineItemName || '', checked: true };
          });
        }
      }
      return adGroupData;
    },

    async handleDashBoardSwitch() {
      const { flightStartDate, planId } = this.lineItem;
      const currentDate = moment(formatDateString(''), 'YYYY-MM-DD');
      const isCampaignStarted = moment(flightStartDate).isBefore(currentDate);

      if (!isCampaignStarted) {
        eBus.$emit('on-show-intermediate-screen');
        return;
      }
      const initialIoOptions = JSON.parse(JSON.stringify(this.ioOptions));
      const adGroupData = await this.fetchAdGroupData(planId);
      let initialAdGroupOptions = JSON.parse(JSON.stringify(adGroupData));
      const selectedIoOptionIndex = this.ioOptions.findIndex(
        (ioOption) => ioOption?.campaignId === planId
      );
      if (selectedIoOptionIndex !== -1) {
        initialIoOptions.forEach((ioOption, index) => {
          if (selectedIoOptionIndex === index) {
            ioOption.checked = true;
            initialAdGroupOptions.forEach((adGroupOption) => {
              if (adGroupOption.lineItemName === this.lineItem.name) {
                adGroupOption.checked = true;
              } else {
                adGroupOption.checked = false;
              }
            });
          } else {
            ioOption.checked = false;
          }
        });
      } else {
        initialIoOptions.forEach((ioOption) => {
          ioOption.checked = true;
        });
        initialAdGroupOptions = JSON.parse(JSON.stringify(this.adGroupOptions));
        initialAdGroupOptions.forEach((adGroupOption) => {
          adGroupOption.checked = true;
        });
      }
      const { flightEndDate } = this.lineItem;

      const { flipCampaignInfo } = this.plan;
      const dateObj = ioHelpers.calculateDatesForDashboardSwitch(
        formatDateInEST(flightStartDate),
        formatDateInEST(flightEndDate),
        flipCampaignInfo
      );
      const methodology = ioHelpers.getMethodology(flipCampaignInfo?.methodology?.value);
      const showUniques = flipCampaignInfo?.optimization?.impressions?.value !== 'total';
      const attribution =
        flipCampaignInfo?.optimization?.attribution?.value === 'Full Conversions'
          ? 'full'
          : 'partial';

      const conversionWindowValue = flipCampaignInfo?.conversionWindow?.value
        ? Number(flipCampaignInfo?.conversionWindow?.value)
        : 31;

      const conversionWindowType = flipCampaignInfo?.conversionWindow?.unit?.value || 'days';

      const conversionWindow =
        isBlank(conversionWindowType) || conversionWindowType === 'days'
          ? conversionWindowValue * 24
          : conversionWindowValue;

      const { eventOptions } = this;
      const localEventOpt = inert(eventOptions);
      const category = flipCampaignInfo?.goal?.category;
      const subCategory = flipCampaignInfo?.goal?.subCategories;
      const metricLabel = flipCampaignInfo?.goal?.metric?.label;
      const event = category === 'checkout' && metricLabel === 'ROAS' ? 'Revenue' : category;

      localEventOpt.forEach((element) => {
        element.selected =
          event === 'Revenue' ? element.event === event : element.category === category;
        element.open = element.selected;
        const { children } = element;
        children?.forEach((child) => {
          child.selected = subCategory?.includes(child.category);
        });
      });
      if (flipCampaignInfo !== null) {
        this.$store.set('dashboard/filters@eventOptions', localEventOpt);
        this.$store.set('dashboard/filters@conversionWindowDisplay', conversionWindowValue);
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          conversionWindowType.toUpperCase()
        );
        this.$store.set('dashboard/filters@conversionWindow', conversionWindow);
        this.$store.set('dashboard/filters@attribution', attribution);
        const totalOrUniqueRadioOptions = this.$store.copy(
          'dashboard/filters@totalOrUniqueRadioOptions'
        );
        totalOrUniqueRadioOptions[1].disabled = event === 'Revenue';
        totalOrUniqueRadioOptions.forEach((tuo) => {
          tuo.active =
            flipCampaignInfo?.optimization?.impressions?.value.toUpperCase() === tuo.text || false;
        });
        this.$store.set(
          'dashboard/filters@totalOrUniqueRadioOptions',
          JSON.parse(JSON.stringify(totalOrUniqueRadioOptions))
        );
        this.$store.set('dashboard/filters@showUniques', showUniques);
        this.$store.set('dashboard/filters@methodology', methodology);
      }
      if (dateObj !== null) {
        this.$store.set('dashboard/dates@startDate', dateObj.startDate);
        this.$store.set('dashboard/dates@endDate', dateObj.endDate);
        this.$store.set('dashboard/dates@compareStartDate', dateObj.compareStartDate);
        this.$store.set('dashboard/dates@compareEndDate', dateObj.compareEndDate);
      }
      this.$store.set('dashboard/filters@ioOptions', initialIoOptions);
      this.$store.set('dashboard/filters@adGroupOptions', initialAdGroupOptions);
      const mediaTypeOptions = await this.fetchSpecificMediaTypeOptions(
        initialIoOptions,
        initialAdGroupOptions
      );
      this.$store.set('dashboard/filters@mediaTypeOptions', mediaTypeOptions);

      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.DASHBOARD);
    },
    transformLineItem() {
      const output = {};

      // fetch fields with literal values
      this.fetchLiteralFieldData(this.lineItem, output);

      // remove changedValue if both originalValue & changedValue are same
      Object.keys(output).forEach((k) => {
        if (output[k].originalValue === output[k].changedValue) {
          delete output[k].changedValue;
        }
      });

      return output;
    },
    fetchLiteralFieldData(inputLineItem, output) {
      const fields = ['name', 'lineItemBudget', 'flightStartDate', 'flightEndDate', 'budgetGroup'];
      fields.forEach((f) => {
        output[f] = lineItemHelpers.getLineItemFieldBothValues(inputLineItem, f);
      });
    },

    onClickPause() {
      const updateStatus = pausedStatusAndWorkflowChange(this.lineItem.statusId);
      const updatedLineItem = Object.assign(this.lineItem.inertClone(), updateStatus);
      this.onStatusChange(updatedLineItem);
    },

    onClickPlay() {
      const updateStatus = playStatusAndWorkflowChange(this.lineItem.statusId);
      const updatedLineItem = Object.assign(this.lineItem.inertClone(), updateStatus);
      this.onStatusChange(updatedLineItem);
    },

    onStatusChange(updatedLineItem) {
      this.planIdForLineItemStatusCountLoading = this.plan.id;
      lineItemsApi
        .update(
          {
            planId: this.plan.id,
            id: this.lineItem.id,
          },
          updatedLineItem
        )
        .then(() => {
          this.$store.dispatch('common/updateLineItem', {
            _uuid: this.lineItem._uuid,
            lineItem: updatedLineItem,
          });

          this.refreshLineItemStatusCount();
          const emailData = {
            emails: [],
            notes: '',
            additionalEmails: [],
            skipNotification: false,
            emailSource: 'FLIPPLUS',
          };
          emailData.lineItems = [this.lineItem];
          const ownerUser = this.allUsers.find((item) => item.id === this.plan.ownerId);
          emailData.emails = [ownerUser.email];
          planApi.updateStatusEmailNotification(this.plan.id, emailData);
        });
    },
    refreshLineItemStatusCount() {
      this.loadLineItemStatusCount(this.account.id, this.advertiser.id, true, true);
      this.loadLineItemStatusCountForPlans(this.account.id, this.advertiser.id, true, true);
    },
    workFlowColor(displayName) {
      const code = LI_DISPLAY_STATUS_COLOR_CODES[displayName];
      if (isBlank(code)) {
        return '';
      }
      return `background-color: ${code} !important`;
    },
    onClickLineItemName() {
      this.activePlan = this.plan;
      this.expandedPlanId = undefined;
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.NEW_CAMPAIGNS);
      eBus.$emit('on-edit-item', {
        pageName: NEW_CAMPAIGN_PAGES.REVIEW_CAMPAIGN,
        pi: this.index,
        lineItemRef: this.lineItem.id,
      });
    },
    onCloneLineItem(plan, lineItem) {
      const clonedLineItem = lineItemHelpers.createCloneLineItem(lineItem);
      if (plan.statusId !== IOS_DRAFT) {
        clonedLineItem.statusId = LIS_NEW;
      }
      clonedLineItem.flightStartDate = null;
      clonedLineItem.flightEndDate = null;
      this.$store.dispatch('common/addLineItem', clonedLineItem);
    },
    returnWarningPayload(payload) {
      const { lineItem, warningTitle } = payload;
      this.warningTitle = warningTitle;
      this.showConfirmBtn = false;
      this.isErrorWarning = true;
      this.cancelBtnText = 'CLOSE';
      this.showLineItemWarning = true;
      this.headerText = 'Unable to save edits';
      this.labelYes = 'OK';
      return { isSaveEnabled: false, updatedLineItem: lineItem };
    },

    async onEditableSaveLineItem(lineItem) {
      const updatedLineItem = {
        ...lineItem,
        flightStartDate: this.tempLineItemStartDate,
        flightEndDate: this.tempLineItemEndDate,
        lineItemBudget: this.tempLineItemBudget === 'Flexible' ? 0 : this.tempLineItemBudget,
        name: this.tempLineItemName,
      };
      this.labelYes = 'YES';
      if (isBlank(this.tempLineItemStartDate)) {
        return this.returnWarningPayload({
          updatedLineItem,
          warningTitle: 'Please enter Ad group start date.',
        });
      }
      if (isBlank(this.tempLineItemEndDate)) {
        return this.returnWarningPayload({
          updatedLineItem,
          warningTitle: 'Please enter Ad group end date.',
        });
      }
      if (moment().isAfter(this.tempLineItemStartDate)) {
        return this.returnWarningPayload({
          updatedLineItem,
          warningTitle: 'Ad group start date must be after Today Date.',
        });
      }
      if (moment().isAfter(this.tempLineItemEndDate)) {
        return this.returnWarningPayload({
          updatedLineItem,
          warningTitle: 'Ad group end date must be after Today Date.',
        });
      }
      if (moment(this.tempLineItemStartDate).isAfter(this.tempLineItemEndDate)) {
        return this.returnWarningPayload({
          updatedLineItem,
          warningTitle: 'Ad group start date must be before ad group end date.',
        });
      }
      if (isBlank(this.tempLineItemName)) {
        this.warningTitle = 'Please enter ad group name.';
        this.showConfirmBtn = false;
        this.isErrorWarning = true;
        this.cancelBtnText = 'CLOSE';
        this.showLineItemWarning = true;
        this.tempLineItemName = this.lineItem.name;
        this.headerText = 'Unable to save edits';
        this.labelYes = 'OK';
        return { isSaveEnabled: false, updatedLineItem: lineItem };
      }
      if (this.tempLineItemBudget < 5000 && this.tempLineItemBudget !== 'Flexible') {
        return this.returnWarningPayload({
          updatedLineItem,
          warningTitle: 'The minimum budget for an ad group is $5,000.',
        });
      }
      if (this.tempLineItemBudget > 100000 && this.tempLineItemBudget !== 'Flexible') {
        return this.returnWarningPayload({
          updatedLineItem,
          warningTitle: 'The maximum budget for an ad group is $100,000.',
        });
      }
      if (
        this.checkValidEndDateForLineItem &&
        moment(this.plan.flightEndDate).isBefore(this.tempLineItemEndDate)
      ) {
        this.showLineItemConflictWarning = true;
        this.warningTitle =
          'This end date falls outside the campaign end date. <br /> Do you want to extend the campaign end date to match?';
        const crAllowed = _.contains(LI_STATUS_FOR_CR_ENABLED, lineItem.statusId);
        if (crAllowed) {
          this.showConfirmBtn = false;
          this.cancelBtnText = 'CLOSE';
        } else {
          this.showConfirmBtn = true;
          this.cancelBtnText = 'NO';
        }
        return { isSaveEnabled: false, updatedLineItem: lineItem };
      }
      return {
        isSaveEnabled: true,
        updatedLineItem: {
          ...lineItem,
          flightStartDate: this.tempLineItemStartDate,
          flightEndDate: this.tempLineItemEndDate,
          lineItemBudget: this.tempLineItemBudget === 'Flexible' ? 0 : this.tempLineItemBudget,
          name: this.tempLineItemName,
        },
      };
    },
    async onClickSaveLineItem(lineItem) {
      let copyLineItem = lineItem.inertClone();
      let savingEnabled = true;
      this.isSaveLoading = true;
      const { isSaveEnabled, updatedLineItem } = await this.onEditableSaveLineItem(lineItem);
      copyLineItem = new LineItem(updatedLineItem);
      savingEnabled = isSaveEnabled;
      if (savingEnabled) {
        let { statusId } = copyLineItem;
        if (copyLineItem.id) {
          const dbLineItem = await lineItemsApi.getLineItem(copyLineItem.id);
          statusId = dbLineItem.statusId;
        }
        const crAllowed = _.contains(LI_STATUS_FOR_CR_ENABLED, statusId);
        if (crAllowed) {
          this.createOrUpdateLineItemCR(copyLineItem);
          return;
        }
        if (!copyLineItem.planId) {
          copyLineItem.planId = this.plan.id;
        }
        copyLineItem.lucid = false;
        copyLineItem.crLineItems = [];
        if (copyLineItem.statusId === LIS_PENDING_ACTIVATION) {
          copyLineItem.workflowId = LIW_PENDING_AM;
        }
        this.updateLineItemInDB(copyLineItem);
      }
    },
    updateLineItemInDB(copyLineItem, callback) {
      lineItemsApi.create(this.plan.id, copyLineItem).then((updatedLineItem) => {
        this.$store.dispatch('common/updateLineItem', {
          _uuid: this.lineItem._uuid,
          lineItem: updatedLineItem,
        });
        if (copyLineItem.statusId !== LIS_DRAFT) {
          this.sendExportEmail(this.plan, copyLineItem.id);
        }
        this.tempLineItemStartDate = updatedLineItem.flightStartDate;
        this.tempLineItemEndDate = updatedLineItem.flightEndDate;
        this.tempLineItemBudget =
          updatedLineItem.lineItemBudget === 'Flexible' ? 0 : updatedLineItem.lineItemBudget;
        this.refreshLineItemStatusCount();
        if (callback) {
          callback(updatedLineItem);
        }
      });
    },
    createOrUpdateLineItemCR(parentLineItem) {
      let crLineItem;
      if (!isBlank(parentLineItem.crLineItems)) {
        crLineItem = parentLineItem.crLineItems.find((l1) =>
          CR_LINEITEM_STATUS.includes(l1.statusId)
        );
        if (!crLineItem) {
          crLineItem = new LineItem();
          crLineItem.type = LIT_CHANGE;
          crLineItem.statusId = LIS_CHANGE_PENDING_ACTIVATION;
          crLineItem.parentId = parentLineItem.id;
          crLineItem.planId = parentLineItem.planId;
          crLineItem.name = this.tempLineItemName;
        } else {
          crLineItem.type = LIT_CHANGE;
          crLineItem.statusId = LIS_CHANGE_PENDING_ACTIVATION;
          crLineItem.parentId = parentLineItem.id;
          crLineItem.planId = parentLineItem.planId;
          crLineItem.name = this.tempLineItemName;
        }
      } else {
        crLineItem = new LineItem();
        crLineItem.type = LIT_CHANGE;
        crLineItem.statusId = LIS_CHANGE_PENDING_ACTIVATION;
        crLineItem.parentId = parentLineItem.id;
        crLineItem.planId = parentLineItem.planId;
        crLineItem.name = this.tempLineItemName;
      }
      if (crLineItem) {
        crLineItem.flightStartDate = this.tempLineItemStartDate;
        crLineItem.flightEndDate = this.tempLineItemEndDate;
        crLineItem.lineItemBudget =
          this.tempLineItemBudget === 'Flexible' ? 0 : this.tempLineItemBudget;
        crLineItem.workflowId = LIW_PENDING_AM;
        crLineItem.name = this.tempLineItemName;
        crLineItem.flip = true;
        crLineItem.incrementality = true;
        crLineItem.updatedLineItem = false;
        this.saveOrUpdateCRLineItems(crLineItem);
        const updatedLineItem = {
          ...parentLineItem.inertClone(),
          flightStartDate: this.tempLineItemStartDate,
          flightEndDate: this.tempLineItemEndDate,
          lineItemBudget: this.tempLineItemBudget === 'Flexible' ? 0 : this.tempLineItemBudget,
          name: this.tempLineItemName,
          updatedLineItem: false,
        };
        this.$store.dispatch('common/updateLineItem', {
          _uuid: parentLineItem._uuid,
          lineItem: updatedLineItem,
        });
      }
      this.isSaveLoading = false;
      this.isLineItemEditable = false;
    },
    saveOrUpdateCRLineItems(l) {
      if (l.id) {
        lineItemsApi
          .update(
            {
              planId: l.planId,
              id: l.id,
            },
            l
          )
          .then(() => {
            this.refreshLineItemStatusCount();
            const notes = `Change Request updated for Ad Group Id: ${l.parentId}`;
            this.sendUpdateStatusEmailNotification(this.plan, notes);
          });
      } else {
        lineItemsApi.create(l.planId, l).then(() => {
          this.refreshLineItemStatusCount();
          const notes = `New Change Request created for Ad Group Id: ${l.parentId}`;
          this.sendUpdateStatusEmailNotification(this.plan, notes);
        });
      }
    },
    onEditLineItem(li) {
      this.isLineItemEditable = true;
      this.tempLineItemName = this.lineItem.name;
      this.readOnly = !isBlank(li.budgetGroup);
      this.tempLineItemBudget = !isBlank(li.budgetGroup) ? 'Flexible' : this.tempLineItemBudget;
    },
    onEditClonedLineItem(line) {
      this.isLineItemEditable = true;
      if (!this.tempLineItemName && !line.id && !this.isLineNameChanged) {
        this.tempLineItemName = this.lineItem.name;
      }
      this.readOnly = !isBlank(line.budgetGroup);
      this.tempLineItemBudget = !isBlank(line.budgetGroup) ? 'Flexible' : this.tempLineItemBudget;
      return true;
    },
    handleDiscardChanges() {
      if (this.isLineChanged()) {
        this.isErrorWarning = false;
        this.showLineItemWarning = true;
        this.warningTitle = 'Are you sure you want to cancel the changes?';
        this.showConfirmBtn = true;
        this.cancelBtnText = 'NO';
      } else {
        this.isLineItemEditable = false;
        this.isErrorWarning = false;
        this.showLineItemConflictWarning = false;
        this.isSaveLoading = false;
        this.showLineItemWarning = false;
      }
    },
    isLineChanged() {
      if (
        this.tempLineItemEndDate !== this.lineItem.flightEndDate ||
        this.tempLineItemStartDate !== this.lineItem.flightStartDate ||
        this.tempLineItemName !== this.lineItem.name ||
        (this.lineItem.lineItemBudget > 0 &&
          this.tempLineItemBudget !== this.lineItem.lineItemBudget)
      ) {
        return true;
      }
      return false;
    },
    setTempValuesForLineItems() {
      const { flightStartDate, flightEndDate, lineItemBudget, budgetGroup } = this.mergedLineItem;
      this.tempLineItemStartDate = !isBlank(this.tempLineItemStartDate)
        ? this.tempLineItemStartDate
        : flightStartDate.originalValue;
      this.tempLineItemEndDate = !isBlank(this.tempLineItemEndDate)
        ? this.tempLineItemEndDate
        : flightEndDate.originalValue;

      const budget =
        !isBlank(budgetGroup?.originalValue) && budgetGroup?.originalValue?.budget > 0
          ? 'Flexible'
          : lineItemBudget.originalValue;
      this.tempLineItemBudget = !isBlank(this.tempLineItemBudget)
        ? this.tempLineItemBudget
        : budget;
    },
    onChangeLineItemEndDate(data) {
      this.tempLineItemEndDate = data.end;
    },
    onChangeLineItemStartDate(data) {
      this.tempLineItemStartDate = data.start;
    },
    onChangeLineItemBudget(val) {
      if (!Number.isNaN(Number(val))) {
        this.tempLineItemBudget = val;
      }
    },

    handleCancelChanges() {
      this.isLineItemEditable = true;
      this.isSaveLoading = false;
      this.showLineItemWarning = false;
      const lineItemUpdated = {
        ...this.lineItem,
        flightStartDate: this.tempLineItemStartDate,
        flightEndDate: this.tempLineItemEndDate,
        lineItemBudget: this.tempLineItemBudget,
        name: this.tempLineItemName,
      };
      this.$store.dispatch('common/updateLineItem', {
        _uuid: this.lineItem._uuid,
        lineItem: { lineItemUpdated, updatedLineItem: false },
      });
      this.isErrorWarning = false;
      this.showLineItemConflictWarning = false;
      this.labelYes = 'YES';
      this.headerText = 'Cancel Ad Group Changes';
    },
    handleConfirmLineItemChanges() {
      this.showLineItemWarning = false;
      this.isSaveLoading = false;
      this.isLineItemEditable = true;
    },
    updatePlanEndDate() {
      const planToSave = this.plan.inertClone();
      planToSave.flightEndDate = this.tempLineItemEndDate;
      planApi.update(planToSave.id, planToSave);
    },

    adjustPlanEndDate() {
      this.isLineItemEditable = false;
      this.showLineItemConflictWarning = false;
      const copyLineItem = this.lineItem.inertClone();
      copyLineItem.flightStartDate = this.tempLineItemStartDate;
      copyLineItem.flightEndDate = this.tempLineItemEndDate;
      copyLineItem.lineItemBudget = this.tempLineItemBudget;
      this.updateLineItemInDB(copyLineItem, this.updatePlanEndDate);
    },
    onChangeLineItemName(val) {
      this.tempLineItemName = val;
      this.isLineNameChanged = true;
    },
    getStartLowerBound() {
      if (!isBlank(this.tempLineItemEndDate) && this.tempLineItemEndDate <= this.currentDate) {
        return this.nextDayDate;
      }
      if (this.plan && this.plan.flightStartDate) {
        return new Date(Math.max(this.nextDayDate, this.plan.flightStartDate));
      }
      return undefined;
    },
    getStartDateUpperBound() {
      if (
        this.tempLineItemEndDate >= this.currentDate &&
        this.plan.flightEndDate >= this.currentDate
      ) {
        if (this.plan && this.plan.flightEndDate) {
          const minDate = new Date(Math.min(this.tempLineItemEndDate, this.plan.flightEndDate));
          return minDate;
        }
      }
      if (
        this.tempLineItemEndDate <= this.currentDate &&
        this.plan.flightEndDate >= this.currentDate
      ) {
        return this.plan.flightEndDate;
      }

      return undefined;
    },
    getEndDateUpperBound() {
      if (this.plan.flightEndDate >= this.currentDate) {
        if (this.plan && this.plan.flightEndDate) {
          return this.plan.flightEndDate;
        }
      }
      if (this.tempLineItemEndDate > this.currentDate) {
        return this.tempLineItemEndDate;
      }
      return undefined;
    },
    getEndDateLowerBound() {
      if (!isBlank(this.tempLineItemEndDate) && this.tempLineItemEndDate <= this.currentDate) {
        return this.nextDayDate;
      }
      return new Date(
        Math.max(this.nextDayDate, this.tempLineItemStartDate, this.plan.flightStartDate)
      );
    },
    goalTooltip(flipCampaignInfo) {
      if (!flipCampaignInfo || !flipCampaignInfo.goal) {
        return '';
      }
      const { subCategories } = flipCampaignInfo.goal;
      return !isBlank(subCategories) && _.isArray(subCategories)
        ? `${flipCampaignInfo.goal.name}: ${subCategories.join(', ')}`
        : '';
    },
    showGoalTooltip(flipCampaignInfo) {
      return !isBlank(this.goalTooltip(flipCampaignInfo));
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-btn {
  pointer-events: none;
  opacity: 0.6;
}
::v-deep .k-btn {
  &:hover {
    span.label {
      color: #fff !important;
    }
  }
}
.lineItem_name_edit {
  cursor: pointer;
  &:hover {
    color: var(--selfserveprimarycolor);
  }
}
.stat-bubble {
  display: inline-block;
  background-color: #d92a36;
  color: #fff;
  padding: 2px 5px;
  border-radius: 12px;
  font-size: 11px;
  margin: 0px 5px 5px 0px;
  width: 90%;
}
.stat-bubble.st-activated {
  background-color: var(--selfserveprimarycolor);
}
tr.intr {
  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
}
.act-blank {
  display: inline-block;
  width: 36px;
}
.new {
  color: #000;
  background-color: #fdce07;
}
.old {
  text-decoration: line-through;
  color: #ccc;
}
::v-deep .lineItem-td .temp-line-item-date.form-input .form-input-field {
  padding: 0.6em 0.4em 0.6em 0.3em;
  text-align: center;
  font-size: inherit;
  color: #646464;
  font-family: 'Manrope', sans-serif;
  vertical-align: middle;
  position: relative;
}
::v-deep .lineItem-name .form-input .form-input-field {
  padding: 0.6em 0.4em 0.6em 0.3em;
  text-align: left;
  font-size: inherit;
  color: #646464;
  font-family: 'Manrope', sans-serif;
  vertical-align: middle;
  position: relative;
}
.disable-row {
  opacity: 0.8;
  pointer-events: none;
}
::v-deep .filled .form-input-field {
  height: 30px;
  border: 2px solid #afc6e4 !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
}
.ft-12 {
  font-size: 12px !important;
}
</style>
