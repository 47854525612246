<template>
  <p class="error-msg-field-text">
    <font-awesome-icon :icon="['fas', 'exclamation-circle']" /> {{ errorMsg }}
  </p>
</template>

<script>
export default {
  props: {
    errorMsg: {
      required: false,
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.error-msg-field-text {
  color: #ff0202;
  margin: 5px;
  font-size: 16px;
}
</style>
