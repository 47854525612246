<template>
  <div class="zip-wrap geo-itm">
    <div class="single-sel">
      <a
        v-for="(zo, index) in zipCodeOptions"
        :key="index"
        class="mp-btn selectbtn3"
        :class="[
          zo.id === 'comma' ? 'lend' : 'rend',
          zo.id === selectedZipCodeOption ? 'selectbtn-selected3' : '',
        ]"
        @click="onSelectZipCodeOption(zo)"
      >
        <font-awesome-icon :icon="['fa', 'check']" class="checked1b" />
        {{ zo.name }}
      </a>
    </div>
    <div v-if="selectedZipCodeOption === 'comma'" class="zip-comma">
      <k-text-area
        class="zipcom"
        :value="zipCodeText"
        :readonly="readonly"
        placeholder="Paste comma separated zip codes here"
        @change="onZipCodesChange"
      />
    </div>
    <div v-if="selectedZipCodeOption === 'upload'" class="upload-container">
      <div class="upload-box">
        <b-file-upload
          name="ZipCodeUpload"
          label="Browse"
          :required="true"
          prompt="no file selected"
          class="browse-file"
          :value="uploadValue"
          :line-item-id="lineItem.id"
          :plan-id="lineItem.planId"
          :readonly="readonly"
          @upload="onFileUpload"
          @onDeleteFile="onDeleteFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KTextArea from 'adready-vue/components/elements/k-text-area.vue';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import { inert } from 'adready-api/helpers/common';
import TargetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import { targetingSelectMixin } from '~/components/mixins/targeting-mixin';
import BFileUpload from '~/components/elements/b-file-upload.vue';

import { TAR_ZIP_CODES } from '~/constant';

export default {
  name: 'GeoTargetingZipCodes',
  components: {
    KTextArea,
    BFileUpload,
  },
  mixins: [
    lineItemMixin,
    TargetableElementOptionsMixin,
    validationsMixin.events,
    targetingSelectMixin,
  ],
  props: {
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedZipCodeOption: 'comma',
      zipCodeOptions: [
        { id: 'comma', name: 'Comma Separated' },
        { id: 'upload', name: 'Upload a file' },
      ],
      targetableElementId: TAR_ZIP_CODES,
      selectedTargeting: null,
    };
  },
  computed: {
    zipCodeText() {
      if (this.selectedTargeting?.value?.zipCodes) {
        return this.selectedTargeting.value.zipCodes;
      }
      return [];
    },
    uploadValue() {
      if (this.selectedTargeting?.value?.uploaded) {
        return this.selectedTargeting.value.uploaded;
      }
      return null;
    },
  },
  beforeMount() {
    const selectedTargeting = this.targetings.find(
      (tp) => tp.targetableElementId === this.targetableElementId
    );
    if (selectedTargeting) {
      this.selectedTargeting = inert(selectedTargeting);
    } else {
      this.selectedTargeting = this.createSelectedTargeting(this.targetableElementId);
    }
  },
  methods: {
    onSelectZipCodeOption(zo) {
      this.selectedZipCodeOption = zo.id;
    },
    onZipCodesChange(val) {
      if (val !== null && val !== '') {
        this.updateLineItemTargetingField('geoTargeting', 'zipCodes', val.split(','));
      } else {
        this.updateLineItemTargetingField('geoTargeting', 'zipCodes', val);
      }
    },

    onFileUpload(fileData) {
      this.updateLineItemTargetingField('geoTargeting', 'uploaded', fileData);
    },

    onDeleteFile() {
      this.updateLineItemTargetingField('geoTargeting', 'uploaded', null);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .form-input textarea.form-input-field {
  margin: 0.25em 0;
  padding: 0.5em 0.6em;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  vertical-align: middle;
  box-sizing: border-box;
  max-width: 466px;
  max-height: 126px;
  height: 126px;
  color: #757575;
}
.single-sel .mp-btn {
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.selectbtn3,
.selectbtn3:active,
.selectbtn3:visited {
  color: #7f7f7f !important;
  border: 1px solid #b3b3b3 !important;
  padding: 0px 12px !important;
  margin: 0px 6px 10px 0px;
  text-align: center;
  letter-spacing: 0.02em;
}
.selectbtn3:hover {
  background-color: #eee !important;
  color: #222 !important;
}
.selectbtn-selected3 {
  background-color: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  border: 1px solid var(--selfserveprimarycolor) !important;
}
.selectbtn-selected3:hover {
  background-color: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  border: 1px solid var(--selfserveprimarycolor) !important;
}
.single-sel .selectbtn3 {
  margin: 0px -1px 10px 0px;
  text-align: center;
}
.selectbtn3.lend {
  border-radius: 3px 0px 0px 3px;
}
.selectbtn3.rend {
  border-radius: 0px 3px 3px 0px;
  border-left: 0px !important;
}
.checked1b {
  margin-right: 3px;
  display: none;
}
.selectbtn-selected3 .checked1b {
  display: inline-block;
}
.mp-btn,
.mp-btn:active,
.mp-btn:visited {
  display: inline-block;
  border: 1px solid #444;
  padding: 13px 25px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Museo Sans 500', Arial, Helvetica, sans-serif;
  font-size: 12px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  cursor: pointer;
  color: #444;
  text-decoration: none;
  transition: all 0.5s ease;
}
.mp-btn:hover {
  background-color: #444;
  color: #fff;
}
.btn-teal {
  background-color: var(--selfserveprimarycolor);
  color: #fff;
}
.upload-container {
  display: inline-block;
  width: 350px;
  border: 1px solid #ddd;
  padding: unset;
  position: relative;
  .upload-box {
    position: relative;
    .browse-file {
      position: relative;
    }
    .upload-btn-box {
      position: absolute;
      width: 61px;
      height: 49px;
      top: -1px;
      text-align: center;
      .upload-icn-box {
        opacity: 0;
        position: absolute;
        inset: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
::v-deep .upload-wrap {
  background-color: #fff;
  padding: unset;
  position: relative;
}
::v-deep .uploaded-container .uploaded-wrap {
  padding: unset;
  margin: unset;
  text-align: unset;
  border-bottom: unset;
  width: 300px;
  position: relative;
  left: 20px;
}
::v-deep .uploaded-container .uploaded-wrap .row.between-xs.top-xs a {
  position: relative;
  bottom: 5px;
  left: 2px;
}
::v-deep .upload-section {
  & .upload-required {
    display: inline-block;
    padding: 2px 6px !important;
    margin: 11px 0px 0px 10px;
    border: 1px solid #b9b9b9;
    background: #fff;
    min-width: 66px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-family: 'Museo Sans 500', Arial, Helvetica, sans-serif;
    font-size: 12px;
    &::after {
      display: none;
    }
  }
}
::v-deep .upload-wrap .upload-section .progess-bar-wrap {
  display: block !important;
}
::v-deep .upload-wrap .progess-bar-wrap .progess-bar {
  width: 100%;
}
::v-deep .upload-wrap .upload-section .upload-required:hover {
  background-color: #565656;
}
::v-deep .drag-box {
  position: absolute;
  left: 90px;
  top: 12px;
}
::v-deep .upload-section .drag-box .drag-text {
  display: inline-block;
  font-size: 13px;
  color: #a1a6ad;
}
::v-deep .draghere-wrap {
  display: none;
}
::v-deep .progess-bar-wrap {
  display: block;
}
::v-deep .upload-section .drag-text {
  display: none;
}
::v-deep .uploaded-container .uploaded-info {
  font-size: 11px;
  padding-bottom: 5px;
}
::v-deep .uploaded-container .uploaded-img {
  width: 30px;
  margin: unset;
}
::v-deep .form-input label.required-label::after {
  display: none;
}
::v-deep .zipcom.form-input.focused label {
  display: none;
}
::v-deep .uploaded-icon {
  position: relative;
  top: 5px;
}
.upload-btn-box {
  position: absolute;
  width: 50px;
  height: 48px;
  background-color: var(--selfserveprimarycolor);
  color: #fff;
  right: 0px;
  bottom: 0px;
  padding: 9px 18px 11px 20px;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  cursor: pointer;
  & .drop-icon-sign {
    width: 12px;
    height: 12px;
  }
}
.upload-btn-box:hover {
  background-color: #444;
}
.zip-wrap.geo-itm {
  position: relative;
}
::v-deep textarea::placeholder {
  padding: 1px 5px;
  margin: 21px 0 0 13px;
  font-size: 15px;
  font-weight: 300;
  color: #aaaaaa;
  font-family: 'Manrope';
}
::v-deep .zipcom.form-input label {
  padding: 1px 3px;
  margin: 24px 0 0 13px;
}
</style>
