<template>
  <div>
    <div class="sec-grp">
      <div class="sec-title">Geo Targeting</div>
      <br />
      <div :class="`single-sel min ${readonly ? 'readonly-gt' : ''}`" style="margin-bottom: 20px;">
        <a
          v-for="(te, index) in targetingElements"
          :key="index"
          class="mp-btn selectbtn3"
          :class="[
            `${te.id === selectedTargetingId ? 'selectbtn-selected3' : ''}`,
            `${index === 0 ? 'lend' : ''} ${index === targetingElements.length - 1 ? 'rend' : ''}`,
          ]"
          @click="onSelectTargetingElement(te)"
        >
          <font-awesome-icon :icon="['fa', 'check']" class="checked1b" />
          {{ te.name }}
        </a>
      </div>
    </div>
    <div class="geos-wrap">
      <div
        v-if="selectedTargetingId === TAR_COUNTRY"
        class="form-group country-wrap geo-itm"
        :class="{ 'hide-form': hideNationalForm }"
      >
        <country-form
          ref="form"
          :line-item="lineItem"
          :plan="plan"
          :selected-targeting-id="TAR_COUNTRY"
          :targetings="targetings"
          :readonly="readonly"
          @input="onInput"
          @validation="onValidation"
        />
      </div>
      <div v-if="selectedTargetingId === TAR_STATE" class="form-group states-wrap geo-itm">
        <state-form
          ref="form"
          :line-item="lineItem"
          :plan="plan"
          :selected-targeting-id="TAR_STATE"
          :targetings="targetings"
          :readonly="readonly"
          @input="onInput"
          @validation="onValidation"
        />
      </div>
      <div v-if="selectedTargetingId === TAR_DMA" class="form-group dmas-wrap geo-itm">
        <dma-form
          ref="form"
          :line-item="lineItem"
          :plan="plan"
          :selected-targeting-id="TAR_DMA"
          :targetings="targetings"
          :readonly="readonly"
          @input="onInput"
          @validation="onValidation"
        />
      </div>
      <div v-if="selectedTargetingId === TAR_CITY" class="form-group cities-wrap geo-itm">
        <city-form
          ref="form"
          :line-item="lineItem"
          :plan="plan"
          :selected-targeting-id="TAR_CITY"
          :targetings="targetings"
          :readonly="readonly"
          @input="onInput"
          @validation="onValidation"
        />
      </div>
      <div v-if="selectedTargetingId === TAR_ZIP_CODES" cclass="zip-wrap geo-itm">
        <geo-targeting-zip-codes
          :line-item="lineItem"
          :plan="plan"
          :selected-targeting-id="TAR_ZIP_CODES"
          :targetings="targetings"
          :readonly="readonly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import TargetableElementOptionsMixin from '~/components/mixins/targetable-element-options-mixin';
import lineItemMixin from '~/components/mixins/line-item-mixin';
import targetingMixin from '~/components/mixins/targeting-mixin';
import GeoTargetingZipCodes from './geo-targeting-zip-codes.vue';
import CountryForm from './country-form.vue';
import StateForm from './state-form.vue';
import DmaForm from './dma-form.vue';
import CityForm from './city-form.vue';
import { TAR_CITY, TAR_COUNTRY, TAR_DMA, TAR_STATE, TAR_ZIP_CODES } from '~/constant';

export default {
  name: 'GeoTargeting',
  components: { GeoTargetingZipCodes, CountryForm, StateForm, DmaForm, CityForm },
  mixins: [lineItemMixin, targetingMixin, TargetableElementOptionsMixin, validationsMixin.events],
  props: {
    lineItem: {
      required: true,
      type: Object,
    },
    onSelectTargetingElement: {
      required: true,
      type: Function,
    },
    selectedTargetingId: {
      required: true,
      type: Number,
      default: null,
    },
    targetTypeName: {
      required: true,
      type: String,
    },
    hideNationalForm: {
      type: Boolean,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedZipCodeOption: 'comma',
      targetingElements: [
        { id: TAR_COUNTRY, name: 'National' },
        { id: TAR_STATE, name: 'State' },
        { id: TAR_DMA, name: 'DMA' },
        { id: TAR_CITY, name: 'City' },
        { id: TAR_ZIP_CODES, name: 'Zip Code' },
      ],
      zipCodeOptions: [
        { id: 'comma', name: 'Comma Separated' },
        { id: 'upload', name: 'Upload a file' },
      ],
      TAR_COUNTRY,
      TAR_CITY,
      TAR_DMA,
      TAR_STATE,
      TAR_ZIP_CODES,
    };
  },
  computed: {
    selectedValue() {
      if (this.selectedTargeting?.value) {
        return this.selectedTargeting.value;
      }
      return null;
    },
  },
  methods: {
    onSelectZipCodeOption(zo) {
      this.selectedZipCodeOption = zo.id;
    },
    onInput(val) {
      this.updateLineItemTargeting('geoTargeting', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.sec-title {
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
  color: #444;
  font-size: 20px;
  letter-spacing: 0.02em;
}
.hide-form {
  display: none;
}
.single-sel .mp-btn {
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.selectbtn3,
.selectbtn3:active,
.selectbtn3:visited {
  color: #7f7f7f !important;
  border: 1px solid #b3b3b3 !important;
  padding: 0px 12px !important;
  margin: 0px 6px 10px 0px;
  text-align: center;
  letter-spacing: 0.02em;
}
.selectbtn3:hover {
  background-color: #eee !important;
  color: #222 !important;
}
.selectbtn-selected3 {
  background-color: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  border: 1px solid var(--selfserveprimarycolor) !important;
}
.selectbtn-selected3:hover {
  background-color: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  border: 1px solid var(--selfserveprimarycolor) !important;
}
.single-sel .selectbtn3 {
  margin: 0px -1px 10px 0px;
  text-align: center;
}
.selectbtn3.lend {
  border-radius: 3px 0px 0px 3px;
}
.selectbtn3.rend {
  border-radius: 0px 3px 3px 0px;
  border-left: 0px !important;
}
.checked1b {
  margin-right: 3px;
  display: none;
}
.selectbtn-selected3 .checked1b {
  display: inline-block;
}
.mp-btn,
.mp-btn:active,
.mp-btn:visited {
  display: inline-block;
  border: 1px solid #444;
  padding: 13px 25px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Museo Sans 500', Arial, Helvetica, sans-serif;
  font-size: 12px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  cursor: pointer;
  color: #444;
  text-decoration: none;
  transition: all 0.5s ease;
}
.mp-btn:hover {
  background-color: #444;
  color: #fff;
}
.btn-teal {
  background-color: var(--selfserveprimarycolor);
  color: #fff;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #cccccc;
  min-height: 20px;
  border-radius: unset;
  position: relative;
  max-width: 848px;
}
::v-deep .form-input .form-input-field.v-select.multiple-input-field .vs__selected-options {
  padding: 10px;
}

::v-deep .form-input .form-input-field.v-select.vs--open .vs__dropdown-toggle {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  background: var(--selfserveprimarycolor) !important;
  color: #fff !important;
  font-weight: 300;
  font-size: 13px;
  font-family: 'Manrope', sans-serif;
  padding: 3px 7px;
  margin: 0px 4px 1px 0px;
  border-radius: 2px;
  display: inline-block;
  border: unset;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: rgba(255, 255, 255, 0.8);
}
</style>
<style>
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--selected {
  display: none;
  background-color: #d9d8d8;
  color: #525b65;
}
body .vs__dropdown-menu .vs__dropdown-option.vs__dropdown-option--highlight {
  color: #525b65;
  background-color: #d9d8d8;
}
body .vs__dropdown-menu .vs__dropdown-option:hover {
  background: #d9d8d8;
}
body .vs__dropdown-hover:active {
  background: #717171 !important;
  box-shadow: inset 0 0 10px #000000 !important;
}
body .vs__dropdown-hover span:active {
  color: white;
}
.geos-wrap {
  position: relative;
}
.readonly-gt {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
