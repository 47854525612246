import $ from 'jquery';
import { get } from 'vuex-pathify';

const props = {
  tabindex: {
    required: false,
    type: Number,
    default: undefined,
  },
};

export default {
  props,
};

export const tabOrderHelpers = {
  computed: {
    modalCounter: get('common/ui@modal'),

    /**
     * Get the tabindex for the given plan/line field.
     * Either 0 (can tab to it) or -1 (disabled for some reason).
     *
     * @return {Number} tab index
     */
    tabIndex() {
      if (this.modalCounter > 0 || (!this.lineItem && this.isInactiveRow)) {
        // cannot tab if:
        // - a modal is open
        // - in disabled order row
        return -1;
      }
      return 0;
    },

    /**
     * Returns -1 when tab should be disabled. Otherwise, returns undefined to
     * let component use it's own value.
     */
    isTabIndexDisabled() {
      if (this.tabIndex === -1) {
        return -1;
      }
      return undefined;
    },
  },

  methods: {
    // events

    onFocusInCell(ev) {
      const el = $(ev.target)
        .parents('td')
        .first();

      // eslint-disable-next-line eqeqeq
      if (el.attr('data-disable-focus') != 1) {
        this.onTdClick(ev);
      } else {
        el.attr('data-disable-focus', 0);
      }
    },

    onKeyUpCell(ev) {
      if (ev.key === 'Enter') {
        this.onTdClick(ev);
      }
    },

    /**
     * On click within the TD, activate the input or modal dialog
     */
    onTdClick(ev) {
      if (this.isLineItemReadOnly) {
        return;
      }

      let el = ev.target;

      // skip if unwanted parent
      if ($(el).parents('.modal-mask').length > 0) {
        return;
      }

      if (el.classList.contains('form-input-field')) {
        el.click();
        return;
      }

      if (el.tagName !== 'TD') {
        [el] = $(el).parents('td');
      }

      if (!el) {
        return; // can happen on DOM changes (like clicking 'change this line' button)
      }

      let cell =
        el.querySelector('.cell') || // advertiser/agency popups
        el.querySelector('.edit-add') || // edit-count plus button
        el.querySelector('.edit') ||
        el.querySelector('.toggle-btn') || // K-Toggle
        el.querySelector('.form-click'); // k-select click-to-activate wrapper

      if (cell) {
        // these els require a click to activate
        cell.click();
        return;
      }

      cell = el.querySelector('.form-date .form-input-field');
      if (cell) {
        cell.focus();
        return;
      }

      const input = el.querySelector('input') || el.querySelector('.form-input-field');
      if (input && (input !== document.activeElement || $(el).find(':focus').length > 0)) {
        input.focus();
      }
    },
  },
};
