import { inert, warn } from 'adready-api/helpers/common';
import { Errors } from 'adready-api/model/errors';
import EventBus from 'adready-vue/event-bus';

const INHERIT = 'inherit';
const HIDDEN = 'hidden';

function setWindowScroll(val) {
  document.getElementsByTagName('html')[0].style.overflow = val;
}

// In order to use this mixin, add to mixins, update refs in template to use the
// 'copy' var, update modal props to enable header and handle events, and
// implement the following:
//
// data:
//   copy: [], // initialize array, string, etc
//
// computed:
//   committableField: return field which is being edited, e.g., "this.lineItems.kpis"
//   showClickToEditCR: return true when 'click to edit' should be shown
//   hasValue: returns true if some value is set
//
// methods:
//   changesToCommit: return update object for plan/lineitem, e.g., { kpis: this.copy }
//   onShowModal: (optional) event which fires when showing the modal. add extra
//                logic here to, e.g., initialize an empty object into the copy array.
//
// Modal should look like:
// <Modal
//   v-if="showModal"
//   :header="true"
//   header-text="Notes"
//   @saveAndClose="onSaveAndClose"
//   @close="onCloseModal"
// >

function data() {
  return {
    copy: null,
    isDirty: false,

    // True if this field has been committed at least once during its life
    isCommitted: false,
  };
}

const computed = {
  /**
   * Should only be shown:
   *   - when editing line items
   *   - before first click (not dirty & modal not open)
   *   - for change requests
   *   - and when value is empty
   */
  showClickToEditCR() {
    return (
      this.lineItem !== undefined &&
      !this.isDirty &&
      !this.showModal &&
      this.isChangeRequest &&
      !this.hasValue
    );
  },
};

const watch = {
  copy: {
    immediate: false,
    deep: true,
    handler() {
      if (!this.isDirty && (this.showModal === undefined || this.showModal === true)) {
        // set dirty flag on any change to the data while modifying
        // modifying is defined as when the modal is open, if one is used for this field.
        // if not used, we look at any change to this field.
        //
        // currently this component is only used on modal fields.
        this.isDirty = true;
      }
    },
  },
};

const methods = {
  /**
   * Create an inert copy of the data to be modified
   */
  createInertCopy() {
    if (this.committableField) {
      this.copy = inert(this.committableField);
    } else {
      // copy the null/undefined
      if (!Object.prototype.hasOwnProperty.call(this._computedWatchers, 'committableField')) {
        warn(`committable component "${this.$options.name}" missing computed: committableField`);
      }
      this.copy = this.committableField;
    }
  },

  /**
   * Commits the current copy to the store.
   *
   * Override this method to commit using a different mutation (targeting, plan).
   */
  commit() {
    this.updateLineItem(this.changesToCommit());
    this.isCommitted = true;
  },

  // events

  /**
   * Handler tied to 'plus' or 'edit' button which displays Modal dialog.
   *
   * Creates a copy of the data to be modified for local editing, allowing user
   * to confirm or revert changes.
   */
  onClickEdit() {
    this.createInertCopy();

    this.$nextTick(() => {
      // open the modal on next tick to avoid altering the dirty flag when
      // setting the initial copy state above.
      this.showModal = true;
      const previewTooltip = document.getElementById('preview-info-wrapper');
      previewTooltip?.classList?.add('displayNone');
      // Remove window scroll when modal is open
      setWindowScroll(HIDDEN);
    });

    if (this.onShowModal) {
      this.onShowModal();
    }
  },

  /**
   * Handler tied to "Cancel" button in Modal dialogs
   */
  onCloseModal() {
    // Cancel = just close, discard errors after prompt
    EventBus.$emit('showDiscardWarning', this.isDirty, (promise) => {
      promise
        .then(() => {
          this.showModal = false;
          setWindowScroll(INHERIT);
          this.deferredErrors = new Errors(); // clear previous deferred errors
          this.$parent.$emit('onCloseModal', this.showModal);
          if (this.isDirty && !this.isCommitted) {
            // reset the flag if we are clearing it back to original state
            // on cancel.
            this.isDirty = false;
          }
        })
        .catch(() => {});
    });
  },

  /**
   *  Handler tied to "Save & Clone" button in Modal dialogs
   *
   *  Commits changes back into the Line Item or Plan, via this.commit().
   */
  onSaveAndClose() {
    // Commit changes and close modal
    this.commit();
    this.showModal = false;
    setWindowScroll(INHERIT);
    // Propagate any errors previously recorded.
    this.errors = this.deferredErrors;
    this.$emit('validation', this.errors);
    this.deferredErrors = new Errors();
    this.$parent.$emit('onCloseModal', this.showModal);
  },
};

export default {
  data,
  computed,
  methods,
  watch,
};
