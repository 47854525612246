<template>
  <div :class="[showIntermediateScreen ? 'intermediate-screen-bg' : '']">
    <IntermediateScreen
      v-if="showIntermediateScreen"
      :warning-message="warningMessage"
      :warning-action-button-title="warningActionButtonTitle"
      :page-title="pageTitle"
      :empty-message="emptyMessage"
      :page-action-button1-title="pageActionButton1Title"
      @warning-action-button-click="warningActionButtonClick"
      @page-action-button1-click="pageActionButton1Click"
    />
    <div v-else class="campaign-wrapper">
      <!-- add loader here -->
      <div v-if="!newCampaignLoader">
        <div class="new-campaign-wrap">
          <div class="ss-left-nav">
            <CampaignLeftNavbar
              :selected-page="selectedPage"
              :selected-page-index="selectedPageIndex"
              :page-index-validation-obj="pageIndexValidationObj"
              :page-index-visited="pageIndexVisited"
              :line-item="lineItem"
              :visited-indexes="visitedIndexes"
              @pageSelected="pageSelected"
            />
          </div>
          <div class="ss-main-wrap">
            <new-campaign
              v-if="selectedPage == NEW_CAMPAIGN_PAGES.CAMPAIGN_INFO"
              :key="newCampaignInfoKey"
              :page-index-validation-obj="pageIndexValidationObj"
              :page-index-visited="pageIndexVisited"
              :selected-page-index="selectedPageIndex"
              :is-readonly="isReadOnlyPlan"
            />
            <brand-safety-campaign
              v-else-if="selectedPage === NEW_CAMPAIGN_PAGES.BRAND_SAFETY"
              :key="getComponentUuid()"
              :page-index-validation-obj="pageIndexValidationObj"
              :page-index-visited="pageIndexVisited"
              :is-readonly="isReadOnlyPlan"
            />
            <BPlanLucidCampaignBrandLift
              v-if="selectedPage == NEW_CAMPAIGN_PAGES.LUCID"
              :page-index-validation-obj="pageIndexValidationObj"
              :page-index-visited="pageIndexVisited"
              :selected-page-index="selectedPageIndex"
              :is-readonly="isReadOnlyPlan"
            />
            <ad-group-info
              v-else-if="selectedPage == NEW_CAMPAIGN_PAGES.AD_GROUP_INFO"
              :key="getComponentUuid()"
              :line-item="lineItem"
              :plan="plan"
              :page-index-validation-obj="pageIndexValidationObj"
              :page-index-visited="pageIndexVisited"
              :selected-page-index="selectedPageIndex"
              @onPublisherInfoClick="onPublisherInfoClick"
            />
            <ad-group-targeting
              v-else-if="selectedPage == NEW_CAMPAIGN_PAGES.AD_GROUP_TARGETING"
              :key="getComponentUuid()"
              :line-item="lineItem"
              :plan="plan"
              :page-index-validation-obj="pageIndexValidationObj"
              :page-index-visited="pageIndexVisited"
              :selected-page-index="selectedPageIndex"
            />
            <ad-group-ads
              v-else-if="selectedPage == NEW_CAMPAIGN_PAGES.AD_GROUP_ADS"
              :line-item="lineItem"
              :page-index-validation-obj="pageIndexValidationObj"
              :page-index-visited="pageIndexVisited"
              :selected-page-index="selectedPageIndex"
              @toggleCreativeSpecs="toggleCreativeSpecs"
            />
            <review-campaign
              v-else-if="selectedPage == NEW_CAMPAIGN_PAGES.REVIEW_CAMPAIGN"
              :page-index-validation-obj="pageIndexValidationObj"
              :line-item-ref="activeItem.lineItemRef"
              :page-index-visited="pageIndexVisited"
              @pageSelected="pageSelected"
              @checkForPagesValidation="checkForPagesValidation"
            />
          </div>
        </div>
        <div class="ss-footer">
          <CampaignBottomNavbar
            :total-selectable-options="totalSelectableOptions"
            :selected-page="selectedPage"
            :selected-page-index="selectedPageIndex"
            :line-item-index-array="lineItemIndexArray"
            :visited-indexes="visitedIndexes"
            :is-readonly="isReadOnlyPlan"
            @pageSelected="pageSelected"
          />
        </div>
      </div>
    </div>
    <div v-if="newCampaignLoader" class="loading-spinner">
      <b-loading-spinner />
    </div>
    <CreativeSpecModal v-if="showCreativeSpecModal" @cancel="showCreativeSpecModal = false" />
    <PublisherInfoModal
      v-if="infoIconModalPayload !== null"
      :info-icon-modal-payload="infoIconModalPayload"
      @cancel="infoIconModalPayload = null"
    />
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import planApi from 'adready-api/api/plans';
import { Plan } from 'adready-api/model/plan';
import { LineItem } from 'adready-api/model/line-item';
import {
  IOS_CHANGE_APPLIED,
  IOS_CHANGE_NEW,
  IO_STATE_CHANGE,
  LIT_CHANGE,
  LIS_CHANGE_NEW,
  LIS_CHANGE_PENDING_ACTIVATION,
  LIS_CHANGE_DRAFT,
  IOS_NEW,
} from 'adready-api/constant';
import { isBlank } from 'adready-api/helpers/common';
import deepcloneHelpers from 'adready-api/helpers/deepclone-helpers';
import CampaignLeftNavbar from '~/components/campaigns/campaign-left-navbar.vue';
import NewCampaign from '~/components/campaigns/new-campaign.vue';
import CampaignBottomNavbar from '~/components/campaigns/campaign-bottom-navbar.vue';
import AdGroupInfo from '~/components/campaigns/ad-group-info.vue';
import AdGroupTargeting from '~/components/campaigns/ad-group-targeting.vue';
import AdGroupAds from '~/components/campaigns/ad-group-ads.vue';
import ReviewCampaign from '~/components/campaigns/review-campaign.vue';
import BrandSafetyCampaign from '~/components/campaigns/brand-safety-campaign.vue';
import BPlanLucidCampaignBrandLift from '~/components/campaigns/lucid/b-plan-lucid-campaign-brand-lift.vue';
import IntermediateScreen from '~/components/intermediate-screen.vue';
import { uuid } from '~/helpers/global/misc-helpers';
import { isStatusReadonly } from '~/helpers/line-item-status-helpers';

import {
  NEW_CAMPAIGN_PAGES,
  CAMPAIGN_NAV_BAR_OPTIONS,
  AD_GROUP_NAV_BAR_OPTIONS,
  ENUM_IS_WATCH_STATS,
  LAYOUT_TYPES,
  FLIP_SELECTED_MENU_PAGE,
} from '~/constant';
import { isAdvertiserSetupCompleted } from '~/util/utility-functions';
import authzMixin from '~/components/mixins/authz-mixin';
import CreativeSpecModal from '~/pages/CreativeSpecModal.vue';
import PublisherInfoModal from '~/pages/PublisherInfoModal.vue';

export default {
  name: 'NewCampaignDashboard',
  components: {
    CampaignLeftNavbar,
    NewCampaign,
    CampaignBottomNavbar,
    AdGroupInfo,
    AdGroupTargeting,
    AdGroupAds,
    ReviewCampaign,
    BrandSafetyCampaign,
    BPlanLucidCampaignBrandLift,
    IntermediateScreen,
    CreativeSpecModal,
    PublisherInfoModal,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  mixins: [authzMixin],
  data() {
    const liIndexArray = [];
    CAMPAIGN_NAV_BAR_OPTIONS.forEach(() => liIndexArray.push(0));

    return {
      infoIconModalPayload: null,
      showCreativeSpecModal: false,
      selectedPage: NEW_CAMPAIGN_PAGES.CAMPAIGN_INFO,
      NEW_CAMPAIGN_PAGES,
      newCampaignInfoKey: this.getComponentUuid(),
      totalSelectableOptions: CAMPAIGN_NAV_BAR_OPTIONS.length + 1,
      selectedPageIndex: 1,
      lineItemIndex: 0,
      lineItemIndexArray: [...liIndexArray, 0],
      warningMessage: '',
      warningActionButtonTitle: '',
      pageTitle: '',
      emptyMessage: '',
      pageActionButton1Title: '',
      pageIndexValidationObj: {},
      pageIndexVisited: {},
      visitedIndexes: [{ name: NEW_CAMPAIGN_PAGES.CAMPAIGN_INFO, pIndex: 1 }],
    };
  },
  computed: {
    plan: sync('common/plan'),
    activeItem: get('common/activeItem'),
    lineItems: get('common/plan@lineItems'),
    orgLineItems: sync('common/orgLineItems'),
    planState: sync('common/plan@state'),
    planParentId: sync('common/plan@parentId'),
    brandSafetyLevelId: sync('common/plan@brandSafetyLevelId'),
    planStatusId: sync('common/plan@statusId'),
    crPlans: sync('common/plan@crPlans'),
    newCampaignLoader: get('common/newCampaignLoader'),
    advertiser: get('common/advertiser'),
    refreshCampaignValidation: get('common/refreshCampaignValidation'),
    selectedMenuOption: get('common/selectedMenuOption'),

    isReadOnlyPlan() {
      if (isBlank(this.orgLineItems)) {
        return true;
      }

      const hasAdGroupOtherThanEndedOrCanceled = this.orgLineItems.some(
        (l) => !isStatusReadonly(l.statusId)
      );
      return !hasAdGroupOtherThanEndedOrCanceled;
    },
    lineItem() {
      if (!isBlank(this.lineItems) && this.lineItemIndex < this.lineItems.length) {
        return this.lineItems[this.lineItemIndex];
      }
      return null;
    },
    showIntermediateScreen() {
      if (!this.advertiser?.selfServe) {
        return false;
      }
      return this.isAdvertiserSetupIncomplete();
    },
  },
  watch: {
    lineItems: {
      handler(n) {
        this.setLineItemIndexArray(n || []);
      },
    },
    refreshCampaignValidation: {
      immediate: true,
      handler(n) {
        if (n) {
          this.newCampaignInfoKey = this.getComponentUuid();
          this.pageIndexValidationObj = {};
          this.pageIndexVisited = {};
          this.pageIndexVisited[1] = 1;
        }
      },
    },
    activeItem: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.newCampaignLoader) {
          const pi =
            AD_GROUP_NAV_BAR_OPTIONS.length * this.activeItem?.pi +
            CAMPAIGN_NAV_BAR_OPTIONS.length +
            1;
          if (this.activeItem?.pageName === NEW_CAMPAIGN_PAGES.AD_GROUP_INFO) {
            this.pageSelected(this.activeItem?.pageName, pi, this.activeItem?.pi);
          } else if (this.activeItem?.pageName === NEW_CAMPAIGN_PAGES.REVIEW_CAMPAIGN) {
            const reviewPageIndex =
              this?.lineItems?.length * AD_GROUP_NAV_BAR_OPTIONS.length +
              CAMPAIGN_NAV_BAR_OPTIONS.length +
              1;
            this.pageSelected(NEW_CAMPAIGN_PAGES.REVIEW_CAMPAIGN, reviewPageIndex, undefined, true);
          } else {
            this.pageSelected(NEW_CAMPAIGN_PAGES.CAMPAIGN_INFO, 1, undefined);
          }
        }
      },
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  async mounted() {
    if (this?.plan?.id) {
      await this.loadPlanData();
    } else {
      this.$store.dispatch('common/setNewCampaignLoader', false);
    }
    this.setLineItemIndexArray(this.lineItems || []);
  },
  methods: {
    onPublisherInfoClick(payload) {
      this.infoIconModalPayload = payload;
    },
    toggleCreativeSpecs(val) {
      this.showCreativeSpecModal = val;
    },
    checkForPagesValidation() {
      const reviewPageIndex =
        this?.lineItems?.length * AD_GROUP_NAV_BAR_OPTIONS.length +
        CAMPAIGN_NAV_BAR_OPTIONS.length +
        1;
      for (let i = 0; i < reviewPageIndex; i++) {
        this.pageIndexVisited[i] = 2;
      }
      this.pageSelected(NEW_CAMPAIGN_PAGES.REVIEW_CAMPAIGN, reviewPageIndex, undefined, false);
    },
    getComponentUuid() {
      return uuid();
    },
    preventNav(event) {
      if (
        this.selectedMenuOption === LAYOUT_TYPES.NEW_CAMPAIGNS ||
        localStorage.getItem(FLIP_SELECTED_MENU_PAGE) === LAYOUT_TYPES.NEW_CAMPAIGNS
      ) {
        event.returnValue =
          'Do you want to save before you refresh the page? If you do not, you will lose all changes or new entries related to this campaign.';
        return 'Do you want to save before you refresh the page? If you do not, you will lose all changes or new entries related to this campaign.';
      }
      return null;
    },
    isAdvertiserSetupIncomplete() {
      const incomplete = !isAdvertiserSetupCompleted(this.advertiser);
      if (incomplete) {
        this.warningMessage = 'You need to finish account setup before you can build campaigns';
        this.warningActionButtonTitle = 'Setup Account';
        this.warningActionButtonClick = this.openAccountSetup;
        this.pageTitle = 'Campaigns';
        this.emptyMessage = "Your account isn't setup.";
        this.pageActionButton1Title = 'Setup Account';
        this.pageActionButton1Click = this.openAccountSetup;
      }
      return incomplete;
    },
    openAccountSetup() {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.ACCOUNT_SETUP);
    },
    pageSelected(val, pageIndex, liIndex, isFirstReviewPage) {
      if (this.selectedPageIndex !== pageIndex) {
        if ((this.selectedPageIndex === 1 || pageIndex === 1) && !this.refreshCampaignValidation) {
          this.pageIndexVisited[1] = this.pageIndexVisited[1] ? this.pageIndexVisited[1] + 1 : 1;
        }
      }
      if (isFirstReviewPage) {
        this.pageIndexVisited[1] = 0;
      }
      this.selectedPage = val;
      this.selectedPageIndex = pageIndex;
      this.lineItemIndex = liIndex;
    },
    loadPlanData() {
      this.$store.dispatch('common/setNewCampaignLoader', true);
      planApi
        .read(this?.plan?.id, {
          includePacing: false,
        })
        .then((freshPlan) => {
          this.totalSelectableOptions =
            (freshPlan?.lineItems?.length || 0) * AD_GROUP_NAV_BAR_OPTIONS.length +
            CAMPAIGN_NAV_BAR_OPTIONS.length +
            1;
          if (!isBlank(freshPlan.crPlans)) {
            let crPlan = freshPlan.crPlans.find(
              (cp) => cp.state === 'CHANGE' && cp.statusId !== IOS_CHANGE_APPLIED
            );
            if (crPlan) {
              this.plan = deepcloneHelpers.mergePlan(freshPlan, crPlan);
              this.planState = IO_STATE_CHANGE;
              this.planStatusId = crPlan.statusId;
              this.planParentId = crPlan.parentId;
              this.brandSafetyLevelId = freshPlan.brandSafetyLevelId;
              this.crPlans = [];
            } else {
              crPlan = new Plan();
              this.plan = deepcloneHelpers.mergePlan(freshPlan, crPlan);
              this.planState = IO_STATE_CHANGE;
              this.planStatusId = IOS_CHANGE_NEW;
              this.planParentId = freshPlan.id;
              this.brandSafetyLevelId = freshPlan.brandSafetyLevelId;
              this.crPlans = [];
            }
          } else {
            const crPlan = new Plan();
            this.plan = deepcloneHelpers.mergePlan(freshPlan, crPlan);
            this.planState = IO_STATE_CHANGE;
            this.planStatusId = IOS_CHANGE_NEW;
            this.planParentId = freshPlan.id;
            this.brandSafetyLevelId = freshPlan.brandSafetyLevelId;
            this.crPlans = [];
          }
          this.orgLineItems = freshPlan.lineItems;
          if (freshPlan.statusId !== IOS_NEW && this.planState === IO_STATE_CHANGE) {
            this.setLineItems(freshPlan);
          }

          this.totalSelectableOptions =
            freshPlan?.lineItems?.length * AD_GROUP_NAV_BAR_OPTIONS.length +
            CAMPAIGN_NAV_BAR_OPTIONS.length;
        });

      this.$store.dispatch('common/setNewCampaignLoader', false);
      this.$store.dispatch('common/updateActiveItem', {
        ...this.activeItem,
      });
    },
    setLineItems(freshPlan) {
      const { lineItems } = freshPlan;
      lineItems.forEach((li) => {
        const { crLineItems } = li;
        if (!isBlank(crLineItems)) {
          let crLineItem = crLineItems.find((l1) =>
            [LIS_CHANGE_NEW, LIS_CHANGE_DRAFT, LIS_CHANGE_PENDING_ACTIVATION].includes(l1.statusId)
          );
          if (crLineItem) {
            crLineItem = deepcloneHelpers.mergeLineItem(li, crLineItem);
          } else if (this.plan.state === IO_STATE_CHANGE) {
            crLineItem = new LineItem();
            crLineItem = deepcloneHelpers.mergeLineItem(li, crLineItem);
            crLineItem.statusId = LIS_CHANGE_DRAFT;
            crLineItem.parentId = li.id;
            crLineItem.type = LIT_CHANGE;
            crLineItem.flip = true;
            crLineItem.incrementality = true;
          }
          this.$store.dispatch('common/updateLineItem', { _uuid: li._uuid, lineItem: crLineItem });
        }
      });
    },
    setLineItemIndexArray(lineItems) {
      const lineItemLength = lineItems.length;
      if (!lineItemLength) {
        return;
      }

      const liIndexArray = [];
      CAMPAIGN_NAV_BAR_OPTIONS.forEach(() => liIndexArray.push(0));

      this.totalSelectableOptions =
        lineItemLength * AD_GROUP_NAV_BAR_OPTIONS.length + CAMPAIGN_NAV_BAR_OPTIONS.length + 1;

      lineItems.map((item, index) =>
        AD_GROUP_NAV_BAR_OPTIONS.forEach(() => liIndexArray.push(index))
      );

      // This is for Review page.
      liIndexArray.push(0);
      this.lineItemIndexArray = liIndexArray;
    },
  },
};
</script>

<style lang="scss">
body {
  min-height: unset;
  margin: unset;
}
::-webkit-scrollbar {
  width: 0px !important;
  height: 7px;
}
</style>

<style lang="scss" scoped>
.campaign-wrapper {
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  line-height: 1.7;
  color: #48525c;
}
.new-campaign-wrap {
  display: flex;
  width: 100%;
  min-width: 1000px;
  max-width: 100%;
  height: calc(100vh - 80px);
  padding: 34px 0px 0px 0;
  margin: 0 auto;
  background-color: #ffffff;

  ::v-deep {
    .form-input {
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #485257;
      &.focused,
      &.filled {
        label {
          background-color: #fff;
        }
        &.zipcom {
          label {
            display: none;
          }
        }
      }
      &.form-date {
        svg {
          z-index: 2;
        }
      }
      &#creativeName {
        .form-input-field {
          border-radius: none;
          background-color: #fff;
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
      &.form-select {
        // .form-input-field {
        //   border: none;
        // }
        .vs__dropdown-toggle {
          font-size: 14px;
          font-weight: 500;
          color: #485257;
          background-color: #f7f9fb;
          border: none;
          border-radius: 5px;
          .vs__selected-options {
            .vs__selected {
              font-size: 14px;
              font-weight: 500;
              color: #485257;
              font-family: inherit;
            }
            .vs__search {
              background-color: transparent;
            }
          }
        }
      }
      label {
        background-color: transparent;
        color: #485257;
      }
      .form-input-field {
        border-radius: 5px;
        background-color: #f7f9fb;
        border: 1px solid #d3d6dd;
        box-shadow: none;
      }
    }
    .ss-mdrop {
      .ss-mdrop-btn {
        border-radius: 5px;
        background-color: #f7f9fb;
        border: 1px solid #d3d6dd;
        font-size: 14px;
        font-weight: 500;
        color: #485257;
        .form-input-field {
          border: none;
          background-color: transparent;
        }
      }
    }
    .pulisher-targeting-container {
      .ss-mdrop {
        .ss-mdrop-content {
          border-radius: 5px;
        }
      }
    }
    .form-input-wrap {
      border: none;
      .form-click {
        border-radius: 5px;
        background-color: #f7f9fb;
        border: 1px solid #d3d6dd;
        font-size: 14px;
        font-weight: 500;
        color: #485257;
      }
      .selected-text {
        font-size: 14px;
        font-weight: 500;
        color: #485257;
      }
    }
    .upload-container {
      border-radius: 5px;
      background-color: #f7f9fb;
      border: 1px solid #d3d6dd;
      font-size: 14px;
      font-weight: 500;
      color: #485257;
      overflow: hidden;
      .upload-wrap {
        background-color: #f7f9fb;
      }
    }
  }
}
.ss-left-nav {
  top: 52px;
  left: 0px;
  z-index: 1;
  min-width: 350px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.ss-main-wrap {
  z-index: 1;
  min-width: calc(100% - 350px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.ss-footer {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 60px;
  margin: 0;
  background-color: #ebf0f4;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.intermediate-screen-bg {
  height: calc(100vh - 65px);
  background-color: #fff;
}
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
