<template>
  <div class="flp-top-panels">
    <campaign-stats-tiles :tiles="tiles" />
  </div>
</template>
<script>
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { formatDateForAPI } from '~/util/apiDateFormat';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { eBus } from '~/main';
import * as util from '~/util/utility-functions';

export default {
  name: 'CampaignStats',

  components: {
    CampaignStatsTiles: () =>
      import(
        /* webpackChunkName: "campaign-stats-tiles" */ '~/components/campaigns/campaign-stats-tiles.vue'
      ),
  },

  data() {
    return {
      tiles: [],
      tilesData: {},
      mediaTypes: [],
      ioOptions: [],
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    universalPixelId: get('common/universalPixelId'),
    campaignDates: get('dashboard/campaignDates'),
    isCampaignDatesChanged: get('dashboard/isCampaignDatesChanged'),
    payload() {
      const result = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
        mediaTypes: this.mediaTypes,
        ioIds: this.allIOIds,
        endDate: formatDateForAPI(this.campaignDates.endDate),
        startDate: formatDateForAPI(this.campaignDates.startDate),
        pixel: this.universalPixelId,
        conversionWindow: 31 * 24,
        attribution: 'full',
        methodology: 'last_touch',
      };
      return result;
    },
    filteredIOOptions() {
      return this.ioOptions.filter((f) => f.campaignId);
    },
    allIOIds() {
      return this.filteredIOOptions.map((m) => m.key);
    },
    // campaignDates() {
    //   const dates = {};
    //   dates.startDate = '';
    //   dates.endDate = '';

    //   if (this.filteredIOOptions.length === 0) {
    //     return dates;
    //   }
    //   const campaignStartDates = this.filteredIOOptions.map((c) => new Date(c.startDate));
    //   const campaignEndDates = this.filteredIOOptions.map((c) => new Date(c.endDate));
    //   dates.startDate = new Date(Math.min.apply(null, campaignStartDates));
    //   dates.endDate = new Date(Math.max.apply(null, campaignEndDates));
    //   if (dates.endDate >= new Date()) {
    //     // For current or future date
    //     const yesterday = new Date();
    //     dates.endDate = new Date(yesterday.setDate(yesterday.getDate() - 1)); // yesterday's date
    //   }
    //   return dates;
    // },
    statCalls() {
      const primaryValues = {
        IMPRESSION: { title: 'Spend', dataType: 'CURRENCY' },
        REACH: { title: 'Reach', dataType: 'VISITS' },
        PAGE_VISITS: { title: 'Page Visits', dataType: 'VISITS' },
        CHECKOUTS: { title: 'Checkouts', dataType: 'VISITS' },
        REVENUE: { title: 'Revenue', dataType: 'CURRENCY' },
        LEADS: { title: 'Leads', dataType: 'VISITS' },
        ACTIVITIES: { title: 'Activities', dataType: 'VISITS' },
      };

      const secondaryValues = {
        IMPRESSION: { title: 'IMPs', dataType: 'VISITS' },
        REACH: { title: 'Freq', dataType: 'FREQUENCY' },
        PAGE_VISITS: { title: 'CPA', dataType: 'CURRENCY' },
        CHECKOUTS: { title: 'CPA', dataType: 'CURRENCY' },
        REVENUE: { title: 'ROAS', dataType: 'PERCENTAGE' },
        LEADS: { title: 'CPA', dataType: 'CURRENCY' },
        ACTIVITIES: { title: 'CPA', dataType: 'CURRENCY' },
      };

      const statCalls = [];
      statCalls.push({ type: 'IMPRESSION', fn: this.fetchImpressions });
      statCalls.push({ type: 'REACH', fn: this.fetchReach });
      statCalls.push({ type: 'PAGE_VISITS', fn: this.fetchPageVisits });
      statCalls.push({ type: 'CHECKOUTS', fn: this.fetchCheckouts });
      statCalls.push({ type: 'REVENUE', fn: this.fetchRevenue });
      statCalls.push({ type: 'LEADS', fn: this.fetchLeads });
      statCalls.push({ type: 'ACTIVITIES', fn: this.fetchActivities });

      statCalls.forEach((call) => {
        const callType = call.type;
        call.primary = primaryValues[callType];
        call.secondary = secondaryValues[callType];
      });

      return statCalls;
    },
  },
  watch: {
    immediate: true,
    isCampaignDatesChanged: {
      async handler(nv) {
        if (nv) {
          await this.initTilesData();
          await this.loadData();
          this.$store.set('dashboard/isCampaignDatesChanged', false);
        }
      },
    },
  },
  created() {
    eBus.$on('on-select-advertiser', this.onSelectAdvertiser);
  },
  beforeDestroy() {
    eBus.$off('on-select-advertiser', this.onSelectAdvertiser);
  },
  async mounted() {
    try {
      this.mediaTypes = await this.loadMediaTypes();
      this.ioOptions = await this.loadIOOptions();
      this.initTilesData();
      this.loadData();
    } catch (err) {
      console.error('error mounting campaign-stats', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    validateParams(payload, extraKeys = []) {
      const keys = ['client', 'advertiser', 'startDate', 'endDate'];
      const finalKeys = [...keys, ...extraKeys];
      if (payload.ioIds.length === 0) {
        return false;
      }
      return !util.isAnyBlank(payload, finalKeys);
    },
    async onSelectAdvertiser() {
      this.tiles = [];
      this.tilesData = {};
      this.mediaTypes = await this.loadMediaTypes();
      this.ioOptions = await this.loadIOOptions();
      this.initTilesData();
      this.loadData();
    },
    async loadMediaTypes() {
      let newData = [];
      try {
        const data = await advertiserReportsAPI.mediaTypes(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString({
            advertiser: this.advertiser ? this.advertiser.name : '',
            client: this.account ? this.account.name : '',
            includeNonFlip: util.isFlamingoInstance(),
          })
        );

        if (data.length !== 0) {
          for (let i = 0; i < data.length; i++) {
            newData.push({ value: data[i], checked: false });
          }
        }
      } catch (err) {
        newData = [];
      }

      return newData.filter((f) => f.checked).map((m) => m.value);
    },
    async loadIOOptions() {
      let ioOptions = [];
      if (
        !this.account ||
        !this.advertiser ||
        isBlank(this.account.name) ||
        isBlank(this.advertiser.name) ||
        isBlank(this.advertiser.xandrAdvertiserId)
      ) {
        return [];
      }
      try {
        ioOptions = await advertiserReportsAPI.ioNames(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString({
            advertiser: this.advertiser ? this.advertiser.name : '',
            client: this.account ? this.account.name : '',
            includeNonFlip: util.isFlamingoInstance(),
          })
        );

        if (ioOptions.length !== 0) {
          for (let i = 0; i < ioOptions.length; i++) {
            ioOptions[i].checked = true;
          }
        }
      } catch (err) {
        ioOptions = [];
      }

      return ioOptions;
    },
    initTilesData() {
      this.statCalls.forEach((call) => {
        const callType = call.type;
        const dataObj = {
          loading: true,
          primaryTitle: call.primary.title,
          primaryValue: 0,
          primaryIsInfinity: false,
          primaryValueType: call.primary.dataType,
          secondaryTitle: call.secondary.title,
          secondaryValue: 0,
          secondaryIsInfinity: false,
          secondaryValueType: call.secondary.dataType,
        };

        this.tilesData[callType] = dataObj;
      });
    },
    loadData() {
      this.statCalls.forEach((call) => {
        const callType = call.type;
        this.tilesData[callType].loading = true;
        call.fn(callType, { ...this.payload });
      });
    },
    processResponse(callType, res, readKeys) {
      this.tilesData[callType].loading = false;

      readKeys.forEach((key, i) => {
        const valueKey = i === 0 ? 'primary' : 'secondary';
        const objRef = res[key];
        this.tilesData[callType][`${valueKey}Value`] = objRef ? objRef?.currentValue : 0;
        this.tilesData[callType][`${valueKey}IsInfinity`] = objRef
          ? objRef.isCurrentInfinity
          : false;
      });

      this.tiles = Object.values(this.tilesData);
    },
    fetchImpressions(callType, payload) {
      const summaryParams = buildQueryString(payload);
      const readKeys = ['spend', 'impressions'];
      const dataValidated = this.validateParams(payload);
      if (dataValidated) {
        advertiserReportsAPI
          .impressionsSummary(this.advertiser.id, summaryParams)
          .then((res) => {
            this.processResponse(callType, res, readKeys);
          })
          .catch((error) => {
            console.error(error);
            this.tilesData[callType].loading = false;
          });
      } else {
        this.processResponse(callType, {}, readKeys);
      }
    },
    fetchReach(callType, payload) {
      const summaryParams = buildQueryString(payload);
      const readKeys = ['reach', 'frequency'];
      const dataValidated = this.validateParams(payload);
      if (dataValidated) {
        advertiserReportsAPI
          .performanceMetricsReach(this.advertiser.id, summaryParams)
          .then((res) => {
            this.processResponse(callType, res, readKeys);
          })
          .catch((error) => {
            console.error(error);
            this.tilesData[callType].loading = false;
          });
      } else {
        this.processResponse(callType, {}, readKeys);
      }
    },
    fetchConversions(callType, payload, category, event = '') {
      payload.category = category;
      payload.event = event;

      const summaryParams = buildQueryString(payload);
      const isRevenueEvent = event.toUpperCase() === 'REVENUE';
      const readKeys = isRevenueEvent ? ['revenue', 'roas'] : ['visits', 'cpa'];
      const conversionKeys = ['xandrId', 'pixel', 'category'];
      const dataValidated = this.validateParams(payload, conversionKeys);
      if (dataValidated) {
        advertiserReportsAPI
          .conversionsSummary(this.advertiser.id, summaryParams)
          .then((res) => {
            this.processResponse(callType, res, readKeys);
          })
          .catch((error) => {
            console.error(error);
            this.tilesData[callType].loading = false;
          });
      } else {
        this.processResponse(callType, {}, readKeys);
      }
    },
    fetchPageVisits(callType, payload) {
      this.fetchConversions(callType, payload, 'pagevisit');
    },
    fetchCheckouts(callType, payload) {
      this.fetchConversions(callType, payload, 'checkout');
    },
    fetchRevenue(callType, payload) {
      this.fetchConversions(callType, payload, 'checkout', 'Revenue');
    },
    fetchLeads(callType, payload) {
      this.fetchConversions(callType, payload, 'lead');
    },
    fetchActivities(callType, payload) {
      this.fetchConversions(callType, payload, 'activity');
    },
  },
};
</script>
<style lang="scss" scoped>
.flp-top-panels {
  display: flex;
  justify-content: space-between;
}
</style>
