<template>
  <div
    id="CAMPAIGN_BRAND_SAFETY"
    :class="`brand-safety-campaign-cont ${isReadonly ? 'readonly-bl' : ''}`"
    style="padding: 40px 50px; text-align: left;"
  >
    <div style="height: 0px;"></div>
    <div class="body-sec-title">
      Brand Safety &amp; Verification
    </div>
    <div class="ss-title2">Brand Safety</div>
    <div style="height: 30px;"></div>
    <div class="range-slider-cont">
      <div class="ss-title2">None</div>
      <div class="ss-title2 fright" style="margin-right: 1px;">Very Safe</div>
      <div class="range-slider">
        <input
          id="range-safety"
          class="range-slider__range show-range-alerts"
          type="range"
          :value="currRangeValue"
          min="0"
          :max="maxBrandSafetyLevelIndex"
          step="1"
          :style="
            `background: linear-gradient(90deg, var(--selfserveprimarycolor) ${25 *
              currRangeValue}%, rgb(215, 220, 223) ${25 * currRangeValue + 0.1}%);`
          "
          @input="inputRangeChange"
        />
        <div class="rtics">
          <div
            v-for="(tickVal, index) in allBrandSafetyLevels"
            :key="index"
            :class="`rtic tic${index}`"
          ></div>
        </div>
        <div class="range-warning2 ">{{ fetchBrandSafetyLevelText(currRangeValue) }}</div>
      </div>
    </div>
    <div style="height: 100px;"></div>
    <div class="sec-spacer"></div>
    <div class="ss-title2">Verification</div>
    <span
      data-tool-tip="We take a very proactive approach to verification, with a number of measures including putting Moat and Protected Media on all campaigns by default. This results in some of the lowest ad fraud rates you will see in the industry. We do not charge extra for these so we recommend leaving both turned on."
      class="intip4 info-icon"
    >
      <font-awesome-icon :icon="['far', 'info-circle']" />
    </span>
    <div style="height: 30px;"></div>
    <div
      v-for="(vp, index) in allVerificationPartners"
      :key="index"
      class="pop-view-partners"
      :class="isVerificationPartnerSelected(vp.id) ? 'pactive' : ''"
      @click="onVerificationPartnerInput(vp)"
    >
      <span
        class="pop-view9b pteal dv1"
        :class="isVerificationPartnerSelected(vp.id) ? 'pactive' : ''"
      >
        <font-awesome-icon :icon="['fas', 'check']" class="fa-w-16 pcheck2" />
        <img :src="`/assets/${vp.name}.png`" alt="bg" class="popimg" />
        <div class="pop-view-txt">{{ vp.name }}</div>
      </span>
    </div>
    <div style="height: 60px;"></div>
  </div>
</template>

<script>
/* eslint-disable */

import { inert, isBlank } from 'adready-api/helpers/common';
import { get, sync } from 'vuex-pathify';
import forklift from '~/components/mixins/forklift-mixin';
import campaignValidationMixin from '~/components/mixins/campaign-validation-mixin';
import * as util from '~/util/utility-functions';

export default {
  name: 'BrandSafetyCampaign',
  mixins: [forklift],
  props: {
    pageIndexVisited: {
      default: () => {},
      type: Object,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currRangeValue: 3,
    };
  },

  computed: {
    plan: sync('common/plan'),
    brandSafetyLevelId: sync('common/plan@brandSafetyLevelId'),
    verificationPartners: sync('common/plan@verificationPartners'),
    updatePlan: sync('common/plan@updatePlan'),
    allBrandSafetyLevels: get('common/allBrandSafetyLevels'),
    allVerificationPartners: get('common/allVerificationPartners'),
    maxBrandSafetyLevelIndex() {
      if (!this.allBrandSafetyLevels.length) {
        return 0;
      }
      return this.allBrandSafetyLevels.length - 1;
    },
  },

  async mounted() {
    util.scrollToElement('CAMPAIGN_BRAND_SAFETY');
    await this.loadBrandSafetyLevels();
    await this.loadVerificationPartners();
    const index = this.allBrandSafetyLevels.findIndex((o) => o.id === this.brandSafetyLevelId);
    this.currRangeValue = index < 0 ? 0 : index;
    this.pageIndexVisited[2] = this.pageIndexVisited[2] ? this.pageIndexVisited[2] + 1 : 1;
  },

  methods: {
    selectedVerificationPartnerIds() {
      const vp = isBlank(this.verificationPartners)
        ? {}
        : JSON.parse(JSON.stringify(this.verificationPartners));
      if (!vp.value) {
        vp.value = [];
      }
      return vp.value;
    },
    fetchBrandSafetyLevelText(safetyLevelIndex) {
      return this.allBrandSafetyLevels.length
        ? this.allBrandSafetyLevels[safetyLevelIndex].levelText
        : '';
    },
    isVerificationPartnerSelected(id) {
      if (isBlank(this.verificationPartners)) {
        return false;
      }
      const partnerIds = this.selectedVerificationPartnerIds();
      return partnerIds.includes(id);
    },
    onVerificationPartnerInput(val) {
      const partnerIds = this.selectedVerificationPartnerIds();
      let dt = inert(partnerIds);
      if (partnerIds.includes(val.id)) {
        dt = partnerIds.filter((c) => c !== val.id);
      } else {
        dt.push(val.id);
      }

      this.verificationPartners = { value: dt };
      this.updatePlan = true;
    },
    inputRangeChange(e) {
      this.currRangeValue = Number(e.target.value);
      this.brandSafetyLevelId = this.allBrandSafetyLevels[this.currRangeValue].id;
      this.updatePlan = true;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}
.brand-safety-campaign-cont {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  color: #48525c;
  width: 960px;
  & .body-sec-title {
    margin-bottom: 30px;
    color: var(--selfserveprimarycolor);
    font-weight: 500;
    font-size: 1.6em;
  }
  & .ss-title2 {
    display: inline-block;
  }
  & .range-slider-cont {
    width: 70%;
    & .range-slider {
      width: 100%;
      max-width: 100%;
      display: inline-block;
      vertical-align: top;
      float: right;
      margin: 10px 0 0 0%;
      & .range-warning2 {
        font-size: 14px;
        margin-top: -7px;
        color: #6f6f6f;
      }
      & input[type='range']::-webkit-slider-thumb {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background: #555;
        cursor: pointer;
        -webkit-appearance: none;

        &:before {
          border: solid 2px #555;
          height: 32px;
          width: 32px;
          background: #555;
        }
      }

      & input[type='range']::-webkit-slider-thumb:hover {
        box-shadow: 0 0 0 3px var(--selfserveprimarycolor), 0 0 0 5px #424242;
      }

      /* All the same stuff for Firefox */
      & input[type='range']::-moz-range-thumb:hover {
        box-shadow: 0 0 0 3px var(--selfserveprimarycolor), 0 0 0 5px #424242;
      }

      /* All the same stuff for IE */
      & input[type='range']::-ms-thumb:hover {
        box-shadow: 0 0 0 3px var(--selfserveprimarycolor), 0 0 0 5px #424242;
      }

      & .rtics {
        display: block;
        border-bottom: 1px solid #ddd;
        height: 13px;
        width: calc(100% - 21px);
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 9px;
        & .rtic {
          width: 1px;
          height: 13px;
          background-color: #ddd;
          display: inline-block;
          position: absolute;
          &.tic0 {
            left: 0%;
          }
          &.tic1 {
            left: 25%;
          }
          &.tic2 {
            left: 50%;
          }
          &.tic3 {
            left: 75%;
          }
          &.tic4 {
            left: 100%;
          }
        }
      }
      & .range-slider__range {
        background: #666;
        -webkit-appearance: none;
        width: 100%;
        height: 11px;
        border-radius: 5px;
        outline: none;
        padding: 0;
        margin: 0;
      }
    }
  }
  & .sec-spacer {
    padding-top: 40px;
    border-bottom: 1px dashed #ddd;
    margin-bottom: 40px;
    width: 85%;
  }
}
.fright {
  float: right;
}
.pop-view-partners {
  display: inline-block;
  max-width: 30%;
  width: 130px;
  height: 130px;
  margin: 0px 2%;
  cursor: pointer;
  text-align: center;
  .pop-view9b {
    display: inline-block;
    /*max-width: 30%;*/
    width: 130px;
    height: 130px;
    border: 3px solid #f5f5f5;
    /*margin: 0px 2%;*/
    border-radius: 120px;
    padding-top: 42px;
    cursor: pointer;
    text-align: center;
    &.pactive {
      border: 3px solid var(--selfserveprimarycolor);
    }
  }
  &:hover {
    .pcheck2 {
      opacity: 1;
      transform: scale(1);
    }
    .pbig {
      color: #333;
    }
    &.pactive .pop-view-txt {
      top: 55px;
    }
    .pop-view-txt {
      color: var(--selfserveprimarycolor) !important;
      top: 50px;
    }
  }
}
.pcheck2 {
  background-color: var(--selfserveprimarycolor) !important;
  width: 24px !important;
  height: 24px !important;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  margin: -37px 0px 0px 98px;
  color: #fff;
  display: block;
  opacity: 0;
  transform: scale(1.4);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pactive .pcheck2 {
  opacity: 1;
  transform: scale(1);
}

.pactive .pop-view-txt {
  color: var(--selfserveprimarycolor) !important;
}
.pop-icon {
  font-size: 39px;
  color: #666;
  margin-bottom: 10px;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.popimg {
  display: inline-block;
  width: 100%;
  max-width: 76%;
  margin-top: 10px;
}

.pbig {
  font-size: 48px;
  margin-top: 10px;
  color: #6b7579;
}

.pactive .pbig {
  color: #333;
}

.pop-view-txt {
  font-size: 15px;
  color: #a7aaaf;
  color: #4d535a;
  position: relative;
  top: 55px;
  pointer-events: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pactive .pop-view-txt {
  color: var(--selfserveprimarycolor);
  top: 55px;
}
.ver-txt {
  display: inline-block;
  max-width: 450px;
  float: left;
  color: #555;
  font-size: 15px;
  margin-bottom: 40px;
}
.info-icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  margin-left: 5px;
}

.intip4 {
  position: relative;
  top: 0;
}
.intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  padding: 10px 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: left;
  text-transform: none;
  content: attr(data-tool-tip);
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
  background-color: #464646;
  color: #fff;
  font-size: 13px;
  pointer-events: none;
  text-transform: none;
  max-width: 450px;
  margin-left: -120px;
  width: max-content;
}

.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 170%;
}
.readonly-bl {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
